import moment from "moment";
import { EmployeeAttendanceDTO } from "../models/employeeAttendancy";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();

const getAttendance = async (from?: Date, to?: Date): Promise<EmployeeAttendanceDTO[]> => {
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  if (!client) {
    return [];
  }

  const result = await client.post<EmployeeAttendanceDTO[]>("reports/global/employeesAttendanceReport", {
    fromDate,
    toDate,
    reportType: "detailedReport",
    location_id: null,
  });

  return result.data;
};

const employeeAttendancyService = {
  getAttendance,
};

export default employeeAttendancyService;
