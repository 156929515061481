import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { LateCancellation, toLateCancellation } from "../models/lateCancellation";
import lateCancellationsServices from "../services/lateCancellationsServices";
import { ApplicationState } from "./store";

interface State {
  all: LateCancellation[] | null;
  selected: LateCancellation[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TPayload {
  all: LateCancellation[];
}

const fetchLateCancellations = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchLateCancellations.start());

    try {
      const response = await lateCancellationsServices.getLateCancellations(from, to);

      dispatch(fetchLateCancellations.success({ all: response.map(toLateCancellation) }));
    } catch {
      dispatch(fetchLateCancellations.error());
    }
  },
  {
    start: createActionCreator("@@LATE_CANCELLATIONS/START"),
    error: createActionCreator("@@LATE_CANCELLATIONS/ERROR"),
    success: createActionCreator("@@LATE_CANCELLATIONS", resolve => (payload: TPayload) => resolve(payload)),
  }
);

const setSelectedLateCancellations = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { lateCancellations } = getState();

    if (!lateCancellations.all) {
      return dispatch(setSelectedLateCancellations.error());
    }

    if (!selectedId) {
      return dispatch(setSelectedLateCancellations.success(null));
    }

    const selected = lateCancellations.all.filter(l => selectedId.includes(l.id));

    dispatch(setSelectedLateCancellations.success(selected));
  },
  {
    error: createActionCreator("@@SELECTED_LATE_CANCELLATION/ERROR"),
    success: createActionCreator(
      "@@SELECTED_LATE_CANCELLATION/SUCCESS",
      resolve => (selected: LateCancellation[] | null) => resolve(selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { lateCancellations } = getState();

    if (!lateCancellations.all || !selectedId) {
      return dispatch(setSingleSelected.error());
    }

    const selected = lateCancellations.all.find(l => l.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    error: createActionCreator("@@SINGLE/LATE_CANCELLATION/ERROR"),
    success: createActionCreator("@@SINGLE/LATE_CANCELLATION/SUCCESS", resolve => (l: LateCancellation) => resolve(l)),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { lateCancellations } = getState();

    if (!lateCancellations.all || !selectedId) {
      return dispatch(removeSingleSelected.error());
    }

    const removed = lateCancellations.all.find(l => l.id === selectedId);

    if (!removed) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(removed));
  },
  {
    error: createActionCreator("@@REMOVE/LATE_CANCELLATION/ERROR"),
    success: createActionCreator("@@REMOVE/LATE_CANCELLATION/SUCCESS", resolve => (l: LateCancellation) => resolve(l)),
  }
);

const actions = {
  fetchLateCancellations,
  setSelectedLateCancellations,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchLateCancellations.start, state => ({ ...state, loading: true })),
  handleAction(fetchLateCancellations.error, state => ({ ...state, loading: false })),
  handleAction(fetchLateCancellations.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedLateCancellations.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };

export type { State };
