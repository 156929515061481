import { ActiveEmployee, ActiveEmployeeDTO, toActiveEmployee } from "../models/activeEmployees";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getActiveEmployees = async (): Promise<ActiveEmployee[]> => {
  const boxId = queryParamsService.getBoxId();
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const result = await client.get<ActiveEmployeeDTO[]>(`user/getSystemUsers/${boxId}`);

  return result.data.map(toActiveEmployee);
};

const activeEmployeesService = {
  getActiveEmployees,
};

export default activeEmployeesService;
