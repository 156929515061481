import moment from "moment";
import { LostLeadDTO, LostLeadResponse } from "../models/lostLeads";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getLostLeads = async (from?: Date, to?: Date): Promise<LostLeadDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<LostLeadResponse>(`box/${boxId}/lostLeads`, {
    fromDate,
    toDate,
    reportType: "detailedReport",
    locationId: null,
  });

  return result.data.report;
};

const lostLeadsService = {
  getLostLeads,
};

export default lostLeadsService;
