import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Entrance, toEntrance } from "../models/entrance";
import entranceService from "../services/entranceService";
import { ApplicationState } from "./store";

interface State {
  all: Entrance[] | null;
  selected: Entrance[] | null;
  loading: boolean;
}

type TPayload = {
  all: Entrance[] | null;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchEntrances = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchEntrances.start());

    try {
      const data = await entranceService.getEntrances(from, to);
      dispatch(fetchEntrances.success({ all: data.map(toEntrance) }));
    } catch {
      dispatch(fetchEntrances.error());
    }
  },
  {
    start: createActionCreator("@@ENTRANCE/START"),
    error: createActionCreator("@@ENTRANCE/ERROR"),
    success: createActionCreator("@@ENTRANCE/SUCCESS", resolve => (e: TPayload) => resolve(e)),
  }
);

const setSelected = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { entrances } = getState();

    if (!entrances.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelected.success(null));
    }

    const selected = entrances.all.filter(e => selectedId.includes(e.id));
    dispatch(setSelected.success(selected));
  },
  {
    success: createActionCreator("@@ENTRANCE/SELECT_ALL/SUCCESS", resolve => (e: Entrance[] | null) => resolve(e)),
  }
);

const setSingleSelect = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { entrances } = getState();

    if (!entrances.all) {
      return;
    }

    const selected = entrances.all.find(e => e.id === selectedId);
    if (!selected) {
      return dispatch(setSingleSelect.error());
    }

    dispatch(setSingleSelect.success(selected));
  },
  {
    error: createActionCreator("@@ENTRANCE/SINGLE/ERROR"),
    success: createActionCreator("@@ENTRANCE/SINGLE/SUCCESS", resolve => (e: Entrance) => resolve(e)),
  }
);

const removeSelect = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { entrances } = getState();

    if (!entrances.all) {
      return;
    }

    const selected = entrances.all.find(e => e.id === selectedId);
    if (!selected) {
      return dispatch(removeSelect.error());
    }

    dispatch(removeSelect.success(selected));
  },
  {
    error: createActionCreator("@@ENTRANCE/REMOVE/ERROR"),
    success: createActionCreator("@@ENTRANCE/REMOVE/SUCCESS", resolve => (e: Entrance) => resolve(e)),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchEntrances.start, state => ({ ...state, loading: true })),
  handleAction(fetchEntrances.error, state => ({ ...state, loading: false })),
  handleAction(fetchEntrances.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelected.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelect.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelect.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(e => e.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchEntrances,
  setSelected,
  setSingleSelect,
  removeSelect,
};

export { actions, reducer };

export type { State };
