import moment from "moment";
import { MemberBaseDTO } from "./memberBase";
import { toDayOfWeek, NumberOrNull } from "./misc";
import { getStartAndEndTime } from "./regulars";

//TODO Waiting for external API for wage property!

interface TListOfCoaches {
  firstCoach: string;
  secondCoach: string;
}

interface ClassesSummaryDTO extends MemberBaseDTO {
  date: string; //date
  day_of_week: number; //day
  time: string; //start time
  end_time: string; //end time
  time_diff_minute: string; //duration
  status: string; //status
  coach_fk: number; //coach
  coach_name: string; //coach
  second_coach_fk: number; //second coach
  second_coach_name: string; //second coach
  category_name: string; //class
  schedule_user_registered: number; //booked members
  schedule_user_checked_in: number; //checked in members
  standby_members: number; //stand by
  max_users: number;
  private_salary: NumberOrNull;
  private_salary_second_coach: NumberOrNull;
}

interface ClassesSummary {
  id: string;
  date: string;
  dayOfWeek: string | null;
  // userId: number; TODO
  startTime: number;
  timeResult: string;
  status: string;
  coachFk: number;
  coachesNames: string | null;
  coachName: string;
  secondCoachFk: number;
  secondCoachName: string;
  coachesFk: string[] | null;
  standByMembers: number;
  location: string;
  locationId: number;
  class: string;
  bookedMembers: number;
  checkedInMembers: number;
  occupationRate: string;
  attendanceRate: string;
  totalMinutes: string;
  coachesTotalWage: NumberOrNull;
}

// TODO: on row click for classes, needs to redirect to classes page (new page created by Michael) - add page config functionality for this
const toClassesSummary = (c: ClassesSummaryDTO): ClassesSummary => ({
  id: c.id.toString(),
  // userId: TODO - either id or series_fk
  date: moment(c.date).toISOString(),
  dayOfWeek: (toDayOfWeek(c.day_of_week)?.label as string) || null,
  startTime: moment.duration(c.time).asHours(),
  timeResult: getStartAndEndTime(c.time, c.end_time),
  status: c.status.toLowerCase() === "active" ? "activeClass" : "canceledClass",
  coachesFk: c.coach_fk
    ? c.second_coach_fk
      ? [c.coach_fk.toString(), c.second_coach_fk.toString()]
      : [c.coach_fk.toString()]
    : null,
  coachesNames:
    c.coach_name && c.coach_name.trim().length
      ? c.second_coach_name && c.second_coach_name.trim().length
        ? `${c.coach_name}, ${c.second_coach_name}`
        : c.coach_name
      : null,
  coachFk: c.coach_fk,
  coachName: c.coach_name,
  secondCoachFk: c.second_coach_fk,
  secondCoachName: c.second_coach_name,
  standByMembers: c.standby_members,
  location: c.location,
  locationId: c.locations_box_fk,
  class: c.category_name,
  bookedMembers: c.schedule_user_registered,
  checkedInMembers: c.schedule_user_checked_in,
  occupationRate: `${((c.schedule_user_registered / c.max_users) * 100).toFixed(1)}`,
  attendanceRate: `${((c.schedule_user_checked_in / c.max_users) * 100).toFixed(1)}`,
  totalMinutes: c.time_diff_minute.split(".")[0],
  coachesTotalWage:
    c.private_salary && c.private_salary_second_coach ? c.private_salary + c.private_salary_second_coach : null,
});

export type { ClassesSummaryDTO, ClassesSummary };

export { toClassesSummary };
