import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Ticket } from "../../icons/ticket.svg";
import { ReactComponent as ToggleOn } from "../../icons/toggleOn.svg";
import { ReactComponent as TwoTickets } from "../../icons/twoTickets.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ExpiredSessionPack } from "../../models/expiredSessionPacks";
import csvService from "../../services/csvService";
import expiredMembershipsService from "../../services/expiredMembershipsService";
import { actions as expiredSessionPacksActions } from "../../store/expiredSessionPacksStore";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { expiredSessionPacksSelector, selectedExpiredSessionPacksSelector } from "../reportSelector";
import { Page } from "./page";

const cancellationOptions: TOption[] = [
  { id: "sessionCanceled", label: "sessionCanceled" },
  { id: "sessionExpired", label: "sessionExpired" },
];

const expiredSessionReport = (dateFormat: string): Page<ExpiredSessionPack> => ({
  id: "expired-sessions-report",
  searchTerms: ["fullName", "phone"],
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipType",
      label: t("membership"),
    },
  ],
  description: t("expiredSessionPacksDesc"),
  defaultFilters: [
    {
      key: "endedAt",
      value: moment().subtract(30, "days").startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "endedAt",
      value: moment().startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "endedAt",
  },
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("sessionPacks"),
    },
    {
      key: "membershipStatus",
      toLocaleString: false,
      aggregator: "percentage",
      specificValue: "sessionExpired",
      label: t("sessionsExpired"),
    },
    {
      key: "membershipStatus",
      toLocaleString: false,
      aggregator: "percentage",
      specificValue: "sessionCanceled",
      label: t("sessionsCanceled"),
    },
    //TODO Add active customers
  ],
  fetchOnlyData: expiredSessionPacksActions.fetchAllExpiredSessionPacks,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: expiredSessionPacksActions.setSelectedExpiredSessionPacks,
  setSingleSelected: expiredSessionPacksActions.setSingleSelected,
  removeSingleSelected: expiredSessionPacksActions.removeSingleSelected,
  isDataLoading: state => state.expiredSessionPacks.loading,
  title: t("expiredSessionPacks"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 15,
      valueRenderer: item => item.fullName,
    },
    {
      id: "phone",
      label: t("phone"),
      width: 15,
      valueRenderer: item => item.phone,
    },
    {
      id: "membershipType",
      label: t("membershipType"),
      valueRenderer: item => t(item.membershipType as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.expiredSessionPacks.all || []).map(
          expiredSessionPack => expiredSessionPack.membershipType
        );
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v, label: v }));
      },
    },
    {
      id: "sessionsLeft",
      label: t("sessionsLeft"),
      valueRenderer: item => item.sessionsLeft,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "endedAt",
      label: t("endDateMembership"),
      valueRenderer: item => item.endedAt && moment(item.endedAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["today", "thisMonth", "lastThirtyDays", "thisYear", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "membershipStatus",
      label: t("endingReason"),
      valueRenderer: item => t(item.membershipStatus as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      showAll: t("all"),
      width: 15,
      filterOptionsSelector: () => cancellationOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getExpiredSessionPacks(entities, dateFormat),
  card: {
    titleRender: (item: ExpiredSessionPack) => item.fullName,
    rows: [
      {
        icon: () => <TwoTickets />,
        textRender: (item: ExpiredSessionPack) => item.membershipType,
      },
      {
        icon: () => <Calendar />,
        textRender: (item: ExpiredSessionPack) => {
          const endDate = moment(item.endedAt).format(dateFormat);

          return item.membershipStatus === "cancelled"
            ? t("sessionCanceled", { endDate })
            : t("sessionExpired", { endDate });
        },
      },
      {
        icon: () => <Ticket />,
        textRender: (item: ExpiredSessionPack) => t("sessionsPacksLeft", { sessionsLeft: item.sessionsLeft }),
      },
      {
        icon: () => <ToggleOn />,
        textRender: (item: ExpiredSessionPack) =>
          item.membershipStatus === "cancelled" ? t("activeCustomer") : t("inactiveCustomer"),
      },
    ],
  },
  entitiesSelector: expiredSessionPacksSelector,
  selectedEntitiesSelector: selectedExpiredSessionPacksSelector,
  fetchRawPostData: expiredMembershipsService.getSessionUsers,
});

export default expiredSessionReport;
