import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Preservation, toPreservation } from "../models/preservation";
import preservationService from "../services/preservationService";
import { MasterFilter } from "./filtersStore";
import { ApplicationState } from "./store";

type State = {
  all: Preservation[] | null;
  selected: Preservation[] | null;
  loading: boolean;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

type TPayload = {
  all: Preservation[] | null;
};

const fetchPreservations = Object.assign(
  (postData: MasterFilter[]) => async (dispatch: Dispatch) => {
    dispatch(fetchPreservations.start());

    try {
      const data = await preservationService.getAllPreservations(postData);
      dispatch(fetchPreservations.success({ all: data.map(toPreservation) }));
    } catch {
      dispatch(fetchPreservations.error());
    }
  },
  {
    start: createActionCreator("@@ALL_PRESERVATIONS/START_FETCH"),
    success: createActionCreator("@@ALL_PRESERVATIONS/FETCH", resolve => (all: TPayload) => resolve(all)),
    error: createActionCreator("@@ALL_PRESERVATIONS/ERROR_FETCH"),
  }
);

const setSelectedPreservations = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { preservation } = getState();

    if (!preservation.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedPreservations.success(null));
    }

    const selected = preservation.all.filter(p => selectedId.includes(p.id));
    dispatch(setSelectedPreservations.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_PRESERVATIONS", resolve => (selected: Preservation[] | null) =>
      resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_PRESERVATIONS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { preservation } = getState();

    if (!preservation.all) {
      return;
    }

    const selectedPreservation = preservation.all.find(p => p.id === selectedId);

    if (!selectedPreservation) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedPreservation));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_PRESERVATION/SUCCESS",
      resolve => (payload: Preservation) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_PRESERVATION/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { preservation } = getState();

    if (!preservation.all) {
      return;
    }

    const selectedPreservation = preservation.all.find(p => p.id === selectedId);

    if (!selectedPreservation) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedPreservation));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_PRESERVATION/SUCCESS",
      resolve => (payload: Preservation) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_PRESERVATION/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchPreservations.start, state => ({ ...state, loading: true })),
  handleAction(fetchPreservations.success, (state, { payload }) => ({
    ...state,
    all: payload.all,
    loading: false,
  })),
  handleAction(fetchPreservations.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedPreservations.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(pr => pr.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchPreservations,
  setSelectedPreservations,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
