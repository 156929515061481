import moment from "moment";
import { LateCancellationDTO } from "../models/lateCancellation";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();
const boxId = queryParamsService.getBoxId();

const getLateCancellations = async (fromDate?: Date, toDate?: Date): Promise<LateCancellationDTO[]> => {
  if (!client) {
    return [];
  }

  const from = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
  const to = toDate ? moment(toDate).format("YYYY-MM-DD") : null;

  const result = await client.post<LateCancellationDTO[]>(`box/${boxId}/lateCancellation`, {
    from,
    to,
  });

  return result.data;
};

const lateCancellationsServices = {
  getLateCancellations,
};

export default lateCancellationsServices;
