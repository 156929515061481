import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Ticket } from "../../icons/ticket.svg";
import { ReactComponent as TwoTickets } from "../../icons/twoTickets.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ExpiringSession } from "../../models/expiringSessions";
import csvService from "../../services/csvService";
import expiringMembershipsService from "../../services/expiringMembershipsService";
import { actions as expiringSessionsActions } from "../../store/expiringSessionsStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { expiringSessionsSelector, selectedExpiringSessionsSelector } from "../reportSelector";
import { Page } from "./page";

const taskOptions: TOption[] = [
  { id: "taskNone", label: "taskNone" },
  { id: "taskFuture", label: "taskFuture" },
  { id: "taskPast", label: "taskPast" },
];

const futureMembershipOptions: TOption[] = [
  { id: "no", label: "no" },
  { id: "yes", label: "yes" },
];

const expiringSessionsReport = (dateFormat: string): Page<ExpiringSession> => ({
  id: "expiring-sessions-report",
  title: t("expiringSessionsTitle"),
  searchTerms: ["fullName", "phone"],
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipType",
      label: t("membership"),
    },
    {
      id: "expiredAt",
      label: t("expiredAt"),
    },
    {
      id: "sessionsLeft",
      label: t("sessionsLeft"),
    },
    {
      id: "futureBooking",
      label: t("futureClasses"),
    },
  ],
  rawUserId: "user_fk",
  description: t("expiringSessionsDescription"),
  defaultSort: {
    order: "asc",
    orderBy: "sessionsLeft",
  },
  defaultFilters: [
    {
      key: "expiredAt",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "expiredAt",
      value: moment().add(2, "weeks").startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("sessionPacks"),
    },
    {
      key: "membershipType",
      aggregator: "mostUsed",
      toLocaleString: false,
      label: t("mostPopularSession"),
      formatLabel: true,
      showSpecificValue: true,
    },
    {
      toLocaleString: false,
      key: "sessionsLeft",
      aggregator: "sum",
      label: t("sessionsLeft"),
    },
    {
      key: "futureBooking",
      toLocaleString: false,
      aggregator: "sum",
      label: t("futureClasses"),
    },
  ],
  entitiesSelector: expiringSessionsSelector,
  selectedEntitiesSelector: selectedExpiringSessionsSelector,
  setSingleSelected: expiringSessionsActions.setSingleSelected,
  removeSingleSelected: expiringSessionsActions.removeSingleSelected,
  setSelectedEntities: expiringSessionsActions.setSelectedExpiringSessions,
  fetchOnlyData: expiringSessionsActions.fetchExpiringSessions,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  fetchRawPostData: expiringMembershipsService.getExpiringSessions,
  isLead: false,
  csvGenerator: entities => csvService.getExpiringSessions(entities, dateFormat),
  isDataLoading: state => state.expiringSessions.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: expiringSession => expiringSession.fullName,
      width: 20,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: session => session.phone,
      width: 10,
    },
    {
      id: "membershipType",
      label: t("membership"),
      valueRenderer: session => session.membershipType,
      width: 15,
      showAll: t("all"),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.expiringSessions.all || []).map(session => session.membershipType);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(s => ({ id: s || "", label: s || "" }));
      },
    },
    {
      id: "startedAt",
      label: t("startDateMembership"),
      valueRenderer: session => moment(session.startedAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["all", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "expiredAt",
      label: t("endDateMembership"),
      periodPrefixes: ["today", "thisMonth", "nextTwoWeeks", "custom"],
      valueRenderer: session => moment(session.expiredAt).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "sessionsLeft",
      label: t("sessionsLeft"),
      valueRenderer: session => session.sessionsLeft,
      filterType: "slider",
      showFilterChip: true,
      width: 15,
    },
    {
      id: "futureBooking",
      label: t("futureClasses"),
      valueRenderer: session => session.futureBooking,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "futureMembership",
      label: t("searchIsFutureMembership"),
      valueRenderer: session => t(session.futureMembership as Phrases),
      filterType: "searchable",
      showAll: t("all"),
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => futureMembershipOptions.map(m => ({ id: m.id, label: t(m.label as Phrases) })),
    },
    {
      id: "task",
      label: t("taskStatus"),
      valueRenderer: session => t(session.task as Phrases),
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => taskOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
  ],
  card: {
    titleRender: (session: ExpiringSession) => session.fullName,
    rows: [
      {
        icon: () => <TwoTickets />,
        textRender: session => session.membershipType || "",
      },
      {
        icon: () => <Ticket />,
        textRender: session => t("sessionsLeftData", { sessions: session.sessionsLeft }),
      },
      {
        icon: () => <Calendar />,
        textRender: session => t("expiringOn", { expireDate: moment(session.end).format(dateFormat) }),
      },
    ],
  },
});

export default expiringSessionsReport;
