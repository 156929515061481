import moment from "moment";
import { SalesDTO } from "../models/sales";
import { MasterFilter } from "../store/filtersStore";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();


const getAllSales = async (from?: Date, to?: Date): Promise<SalesDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const response = await client.post<SalesDTO[]>("reports/getBoxSales", {
    action: null,
    created_by_user: null,
    from_date: fromDate,
    to_date: toDate,
    location_box_id: null,
    report_type: "detailedReport",
    sub_action: null,
  });

  return response.data;
};

const getALlSummarizedSales = async (data?: MasterFilter[]): Promise<SalesDTO[]> => {
  if (!client || !data || !data.length) {
    return [];
  }

  const findDate = data.find(d => d.key === "date")?.value;
  const findAction = data.find(d => d.key === "action")?.value[0];
  const findSubAction = data.find(d => d.key === "subAction")?.value[0];
  const findCreatedBy = data.find(d => d.key === "createdBy")?.value[0];
  const findLocation = data.find(d => d.key === "location")?.value[0];

  if ((!findDate || !findDate[0] || !findDate[1]) || !findAction || !findSubAction || !findCreatedBy || !findLocation) {
    return [];
  }

  const momentFormDate = moment(findDate[0]);
  const momentToDate = moment(findDate[1]);

  const fromDate = momentFormDate.isValid() ? momentFormDate.format("YYYY-MM-DD") : null;
  const toDate = momentToDate.isValid() ? momentToDate.format("YYYY-MM-DD") : null;

  const valueOrNull = (value: string, toNumber = false) => value === "null" ? null : toNumber ? +value : value;

  const postBody = {
    action: valueOrNull(findAction),
    created_by_user: valueOrNull(findCreatedBy, true),
    department_id: null,
    from_date: fromDate,
    location_box_id: valueOrNull(findLocation, true),
    report_type: "summaryReport",
    sub_action: valueOrNull(findSubAction),
    to_date: toDate
  };

  const response = await client.post<SalesDTO[]>("reports/getBoxSales", postBody);

  return response.data;
};




const salesService = {
  getAllSales,
  getALlSummarizedSales
};

export default salesService;
