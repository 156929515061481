import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as CalendarCheck } from "../../icons/calendar-check.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as Pause } from "../../icons/pause.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Ticket } from "../../icons/ticket.svg";
import { ReactComponent as TwoTickets } from "../../icons/twoTickets.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Session } from "../../models/sessions";
import csvService from "../../services/csvService";
import sessionService from "../../services/sessionService";
import { actions as uniqueFilters } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as tspActions } from "../../store/transparentSessionPacksStore";
import { transparentSessionPacksSelector, selectedTransparentSessionPacksSelector } from "../reportSelector";
import { Page } from "./page";

const statusOptions: TOption[] = [
  { id: "inactive", label: "inactive" },
  { id: "future", label: "future" },
  { id: "active", label: "active" },
  { id: "activeMemberWithFutureCancel", label: "activeMemberWithFutureCancel" },
];

const TransparentSessionPacksReport = (dateFormat: string): Page<Session> => ({
  id: "transparent-sessions-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
    {
      url: "multiple-holds",
      icon: <Pause />,
      title: "multiHolds",
      size: "460px",
    },
  ],
  title: t("transparentSessionPacks"),
  memberHoldProperty: "membershipId",
  description: t("transparentSessionPacksDesc"),
  rawUserId: "user_fk",
  defaultSort: {
    order: "asc",
    orderBy: "sessionsLeft",
  },
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("sessionPacks"),
    },
    {
      key: "membershipType",
      toLocaleString: false,
      aggregator: "mostUsed",
      label: t("mostPopularSession"),
      formatLabel: true,
      showSpecificValue: true,
    },
  ],
  entitiesSelector: transparentSessionPacksSelector,
  selectedEntitiesSelector: selectedTransparentSessionPacksSelector,
  setSelectedEntities: tspActions.setSelectedTSP,
  setSingleSelected: tspActions.setSingleSelected,
  removeSingleSelected: tspActions.removeSingleSelected,
  fetchRawData: sessionService.getAllTransparentSessions,
  isDataLoading: state => state.transparentSessionPacks.loading,
  isLead: false,
  fetchDataActions: [
    tspActions.fetchData,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
    uniqueFilters.fetchFilterValues,
  ],
  csvGenerator: entities => csvService.getTransparentSessionPacks(entities, dateFormat),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: session => session.fullName,
      width: 15,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: session => session.phone,
      width: 11,
    },
    {
      id: "departmentId",
      label: t("department"),
      valueRenderer: session => session.department,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 13,
      filterOptionsSelector: state => {
        const values = (state.transparentSessionPacks.all || []).map(session => ({
          id: session.departmentId,
          value: session.department,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(a => a.id);
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: v.value }));
      },
    },
    {
      id: "membershipType",
      label: t("planType"),
      valueRenderer: session => session.membershipType,
      width: 22,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.transparentSessionPacks.all || []).map(session => session.membershipType);
        const uniqueValues = _.uniq(values).filter(v => !!v);
        return uniqueValues.map(v => ({ id: v, label: v })) as TOption[];
      },
    },
    {
      id: "status",
      label: t("status"),
      valueRenderer: session => session.status && t(session.status as Phrases),
      width: 10,
      filterType: "multipleSearchable",
      showFilterChip: true,
      showAll: t("all"),
      newPage: true,
      filterOptionsSelector: () => statusOptions.map(s => ({ id: s.id, label: t(s.label as Phrases) })),
    },
    {
      id: "price",
      label: t("originalPrice"),
      valueRenderer: session => session.price,
      filterType: "slider",
      showFilterChip: true,
      width: 15,
    },
    {
      id: "paid",
      label: t("paid"),
      valueRenderer: session => session.paid,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "start",
      label: t("startDateMembership"),
      valueRenderer: session => (session.start ? moment(session.start).format(dateFormat) : t("without")),
      filterType: "period",
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "end",
      label: t("endDateMembership"),
      valueRenderer: session => (session.end ? moment(session.end).format(dateFormat) : t("without")),
      filterType: "period",
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "sessionsLeft",
      label: t("sessionsLeft"),
      valueRenderer: session => session.sessionsLeft,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "futureBooking",
      label: t("futureClasses"),
      valueRenderer: session => session.futureBooking,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "totalSessions",
      label: t("totalSessions"),
      valueRenderer: session => session.totalSessions,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
  ],
  card: {
    titleRender: session => session.fullName,
    rows: [
      {
        icon: () => <TwoTickets />,
        textRender: session => session.membershipType || "",
      },
      {
        icon: () => <Ticket />,
        textRender: session =>
          t("sessionsLeftOutOf", { sessionsLeft: session.sessionsLeft, totalSessions: session.totalSessions }),
      },
      {
        icon: () => <CalendarCheck />,
        textRender: session => t("futureBookingData", { booking: session.futureBooking }),
      },
    ],
  },
});

export default TransparentSessionPacksReport;
