import { createActionCreator, createReducer } from "deox";
import { Dispatch } from "redux";
import { FilterValuesType } from "../models/filterValues";
import filterValuesServices from "../services/filterValuesService";
import { ApplicationState } from "./store";

type State = {
  uniqueFilterValues: FilterValuesType | null;
};

const fetchFilterValues = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { filterValues } = getState();

    if (filterValues.uniqueFilterValues) {
      return;
    }

    try {
      const data = await filterValuesServices.getFilterValues();
      dispatch(fetchFilterValues.success(data));
    } catch (e) {
      dispatch(fetchFilterValues.error());
      return;
    }
  },
  {
    error: createActionCreator("@@FILTER_VALUES/FETCH/ERROR"),
    success: createActionCreator("@@FILTER_VALUES/FETCH/SUCCESS", resolve => (data: FilterValuesType | null) =>
      resolve(data)
    ),
  }
);

const defaultState: State = {
  uniqueFilterValues: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchFilterValues.success, (state, action) => ({
    ...state,
    uniqueFilterValues: action.payload,
  })),
]);

const actions = {
  fetchFilterValues,
};

export { actions, reducer };

export type { State };
