import moment from "moment";
import { LeadStatisticsDTO } from "../models/leadsStatistics";
import { MasterFilter } from "../store/filtersStore";
import { getBaseClient } from "./serviceUtils";

type TPostBody = {
  fromDate: string;
  toDate: string;
  locationBoxFk: null | number;
  reportType: string;
  selectedMonth: string;
  selectedYear: string;
};

const getLeadsStatistics = async (data?: MasterFilter[]): Promise<LeadStatisticsDTO[]> => {
  const client = getBaseClient();

  if (!client || !data) {
    return [];
  }

  const date = data.find(d => d.key === "date")?.value;
  const timePeriod = data.find(d => d.key === "timePeriod")?.value[0];
  const year = data.find(d => d.key === "year")?.value[0];
  const month = data.find(d => d.key === "month")?.value[0];
  const location = data.find(d => d.key === "location")?.value[0];

  if ((!date || !date.length) || !timePeriod || !year || !month || !location) {
    return [];
  }

  const locationBoxFk = location === "all" ? null : +location;

  let postBodyData: TPostBody = {
    fromDate: "2020-09-30T21:00:00.000Z",
    toDate: "2020-10-31T21:59:59.999Z",
    locationBoxFk: null,
    reportType: "months",
    selectedMonth: "yearSummary",
    selectedYear: "2019",
  };

  if (timePeriod === "monthly") {
    const fromDate = moment()
      .month(+month)
      .startOf("month")
      .toISOString();

    const toDate = moment()
      .month(+month)
      .endOf("month")
      .toISOString();

    const selectedYear = year as string;

    const selectedMonth = month;

    postBodyData = {
      fromDate,
      toDate,
      locationBoxFk,
      reportType: "months",
      selectedMonth: selectedMonth,
      selectedYear,
    };
  }

  if (timePeriod === "all") {
    const fromDate = moment()
      .year(+year)
      .startOf("year")
      .toISOString();

    const toDate = moment()
      .year(+year)
      .endOf("year")
      .toISOString();

    const selectedYear = year as string;

    postBodyData = {
      fromDate,
      toDate,
      locationBoxFk,
      reportType: "months",
      selectedMonth: "all",
      selectedYear,
    };
  }

  if (timePeriod === "dates") {
    const fromDate = date[0];
    const toDate = date[1];

    postBodyData = {
      fromDate,
      toDate,
      locationBoxFk,
      reportType: "months",
      selectedMonth: "dates",
      selectedYear: moment().year().toString(),
    };
  }

  const response = await client.post<LeadStatisticsDTO[]>("/reports/getLeadsStatistics", postBodyData);

  return response.data;
};

const leadStatisticsService = {
  getLeadsStatistics,
};

export default leadStatisticsService;
