import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as DeleteMessage } from "../../icons/deleteMessage.svg";
import { ReactComponent as MembershipCard } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { CanceledMemberships } from "../../models/canceledMemberships";
import canceledMembershipsService from "../../services/canceledMembershipsService";
import csvService from "../../services/csvService";
import { actions as canceledMembershipsActions } from "../../store/canceledMembershipsStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { canceledMembershipsSelector, selectedCanceledMembershipsSelector } from "../reportSelector";
import { Page } from "./page";

const CanceledMembershipsReport = (dateFormat: string): Page<CanceledMemberships> => ({
  id: "cancelled-memberships-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membership",
      label: t("membership"),
    },
  ],
  searchTerms: ["fullName", "phone"],
  description: t("canceledMembershipsDesc"),
  defaultSort: {
    order: "desc",
    orderBy: "cancelledAtDate",
  },
  defaultFilters: [
    {
      key: "cancelledAtDate",
      value: moment().startOf("month").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "cancelledAtDate",
      value: moment().endOf("month").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      toLocaleString: false,
      label: t("cancellations"),
    },
    {
      key: "cancelReason",
      aggregator: "mostUsed",
      label: t("mostCommonCancellationReason"),
      toLocaleString: false,
      formatLabel: true,
      showSpecificValue: true,
    },
  ],
  fetchOnlyData: canceledMembershipsActions.fetchCanceledMemberships,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: canceledMembershipsActions.setSelectedMemberships,
  setSingleSelected: canceledMembershipsActions.setSingleSelected,
  removeSingleSelected: canceledMembershipsActions.removeSingleSelected,
  isDataLoading: state => state.canceledMemberships.loading,
  title: t("canceledMemberships"),
  columns: [
    {
      id: "fullName",
      width: 20,
      label: t("customerName"),
      valueRenderer: item => item.fullName,
    },
    {
      id: "phone",
      width: 10,
      label: t("phone"),
      valueRenderer: item => item.phone,
    },
    {
      id: "department",
      label: t("department"),
      valueRenderer: item => item.department,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.canceledMemberships.all || []).map(canceledMembership => ({
          id: canceledMembership.department,
          value: canceledMembership.department,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(item => item.id);
        return uniqueValues.map(v => ({ id: v.id!, label: v.value }));
      },
    },
    {
      id: "membership",
      label: t("membership"),
      valueRenderer: item => item.membership,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.canceledMemberships.all || []).map(canceledMembership => ({
          id: canceledMembership.membership,
          value: canceledMembership.membership,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(item => item.id);
        return uniqueValues.map(v => ({ id: v.id!, label: v.value }));
      },
    },
    {
      id: "cancelledAtDate",
      label: t("cancelledAtDate"),
      valueRenderer: item => moment(item.cancelledAtDate).format(dateFormat),
      filterType: "period",
      newPage: true,
      periodPrefixes: ["today", "thisMonth", "lastThirtyDays", "thisYear", "custom"],
      showFilterChip: true,
      width: 15,
    },
    {
      id: "cancelReason",
      label: t("cancelReason"),
      valueRenderer: item => item.cancelReason,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.canceledMemberships.all || []).map(canceledMembership => ({
          id: canceledMembership.cancelReason,
          value: canceledMembership.cancelReason,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(item => item.id);
        return uniqueValues.map(v => ({ id: v.id!, label: v.value }));
      },
    },
    {
      id: "refund",
      label: t("refund"),
      width: 15,
      valueRenderer: item => item.refund,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      filterOptionsSelector: state => {
        const values = (state.canceledMemberships.all || []).map(canceledMembership => ({
          id: canceledMembership.refund,
          value: canceledMembership.refund,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(item => item.id);
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: v.value }));
      },
    },
    {
      id: "salesPerson",
      width: 20,
      label: t("salesPerson"),
      valueRenderer: item => item.salesPerson,
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getCanceledMemberships(entities, dateFormat),
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <MembershipCard />,
        textRender: item => item.membership && t(item.membership as Phrases),
      },
      {
        icon: () => <Calendar />,
        textRender: item =>
          t("cancelledWithDate", { cancelledWithDate: moment(item.cancelledAtDate).format(dateFormat) }),
      },
      {
        icon: () => <DeleteMessage />,
        textRender: item => (!item.cancelReason ? "" : t(item.cancelReason as Phrases)),
      },
    ],
  },
  entitiesSelector: canceledMembershipsSelector,
  selectedEntitiesSelector: selectedCanceledMembershipsSelector,
  fetchRawPostData: canceledMembershipsService.getCanceledMemberships,
});

export default CanceledMembershipsReport;
