import { createReducer, createActionCreator } from "deox";
import { Dispatch } from "redux";
import { Organization } from "../models/organization";
import organizationsService from "../services/organizationsService";
import { ApplicationState } from "./store";

type State = {
  entities: Organization[] | null;
};

const fetchOrganizations = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { organizations } = getState();

    if (organizations.entities) {
      return;
    }

    try {
      const data = await organizationsService.getOrganizations();
      dispatch(fetchOrganizations.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ORGANIZATIONS/FETCH", resolve => (organizations: Organization[]) =>
      resolve(organizations)
    ),
  }
);

const defaultState: State = {
  entities: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchOrganizations.success, (state, action) => ({ ...state, entities: action.payload })),
]);

const actions = {
  fetchOrganizations,
};

export { actions, reducer };
export type { State };
