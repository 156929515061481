import { Organization, OrganizationDTO, toOrganization } from "../models/organization";
import { getBaseClient } from "./serviceUtils";

const getOrganizations = async (): Promise<Organization[]> => {
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const result = await client.post<OrganizationDTO[]>("/user/getOrganizations");

  return result.data.map(toOrganization);
};

const organizationsService = {
  getOrganizations,
};

export default organizationsService;
