import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { Phrases } from "../infrastructure/translations/phrases";
import { toDayOfWeek } from "./misc";

interface StaffActionDTO {
  user_fk: number;
  log_created_at: string;
  day_of_week: number;
  staff_name: string;
  action: string;
  role_fk: number;
  sub_action: string | null;
  user_name: string;
}

interface StaffAction {
  id: string;
  date: string;
  dayOfWeek: string | null;
  dayOfWeekId: string | null;
  userId: number;
  staffMember: string;
  action: string;
  subAction: string | null;
  member: string;
}

const toStaffAction = (s: StaffActionDTO): StaffAction => ({
  id: `${s.user_fk}${s.role_fk}${s.log_created_at}${s.day_of_week}`,
  userId: s.user_fk,
  date: moment(s.log_created_at).toISOString(),
  dayOfWeek: (toDayOfWeek(s.day_of_week)?.label as string) || null,
  dayOfWeekId: toDayOfWeek(s.day_of_week)?.id || null,
  staffMember: s.staff_name,
  action: s.action ? t(s.action as Phrases) : t("unknown"),
  subAction: s.sub_action ? t(s.sub_action as Phrases) : t("unknown"),
  member: s.user_name,
});

export { toStaffAction };

export type { StaffAction, StaffActionDTO };
