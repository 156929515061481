import moment from "moment";
import { GroupMemberDTO } from "../models/groupMembers";
import { getBaseClient } from "./serviceUtils";

const getGroupMembers = async (from?: Date, to?: Date): Promise<GroupMemberDTO[]> => {
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const response = await client.post<GroupMemberDTO[]>("/reports/global/headGroupReport", {
    fromDate,
    toDate,
  });

  return response.data;
};

const groupMemberService = {
  getGroupMembers,
};

export default groupMemberService;
