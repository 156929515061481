import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Member, toMember } from "../models/member";
import membersService from "../services/membersService";
import { ApplicationState } from "./store";

type State = {
  all: Member[] | null;
  selected: Member[] | null;
  loading: boolean;
};

type TPayload = {
  all: Member[] | null;
};

const fetchActiveMembers = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { members } = getState();

    if (members.all) {
      return;
    }

    dispatch(fetchActiveMembers.start());

    try {
      const data = await membersService.getActiveMembers();
      dispatch(fetchActiveMembers.success({ all: data.map(toMember) }));
    } catch {
      dispatch(fetchActiveMembers.error());
      return;
    }
  },
  {
    error: createActionCreator("@@REPORTS/FETCH_MEMBERS/ERROR"),
    start: createActionCreator("@@REPORTS/FETCH_MEMBERS/START"),
    success: createActionCreator("@@REPORTS/FETCH_MEMBERS", resolve => (members: TPayload) => resolve(members)),
  }
);

const setSelectedMembers = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { members } = getState();

    if (!members.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedMembers.success(null));
    }
    const selected = members.all.filter(member => selectedId.includes(member.id));
    dispatch(setSelectedMembers.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_MEMBERS", resolve => (selected: Member[] | null) =>
      resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_MEMBERS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { members } = getState();

    if (!members.all) {
      return;
    }

    const selectedMember = members.all.find(member => member.id === selectedId);

    if (!selectedMember) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedMember));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_MEMBER/SUCCESS", resolve => (payload: Member) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_MEMBER/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { members } = getState();

    if (!members.all) {
      return;
    }

    const selectedMember = members.all.find(member => member.id === selectedId);

    if (!selectedMember) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedMember));
  },
  {
    success: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_MEMBER/SUCCESS", resolve => (payload: Member) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_MEMBER/ERROR"),
  }
);

const defaultState: State = {
  all: null,
  selected: null,
  loading: true,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchActiveMembers.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(fetchActiveMembers.error, state => ({
    ...state,
    loading: false,
  })),
  handleAction(fetchActiveMembers.start, state => ({
    ...state,
    loading: true,
  })),
  handleAction(setSelectedMembers.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedMember => selectedMember.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchActiveMembers,
  setSelectedMembers,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
