import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ExpiringSession, toExpiringSession } from "../models/expiringSessions";
import expiringMembershipsService from "../services/expiringMembershipsService";
import { ApplicationState } from "./store";

type State = {
  all: ExpiringSession[] | null;
  selected: ExpiringSession[] | null;
  loading: boolean;
};

type TPayload = {
  all: ExpiringSession[] | null;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchExpiringSessions = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchExpiringSessions.start());

    try {
      const data = await expiringMembershipsService.getExpiringSessions(from, to);
      dispatch(fetchExpiringSessions.success({ all: data.map(toExpiringSession) }));
    } catch {
      fetchExpiringSessions.error();
      return;
    }
  },
  {
    start: createActionCreator("@@EXPIRING_SESSIONS/START"),
    success: createActionCreator("@@EXPIRING_SESSIONS/SUCCESS", resolve => (expiringSessions: TPayload) =>
      resolve(expiringSessions)
    ),
    error: createActionCreator("@@EXPIRING_SESSIONS/ERROR"),
  }
);

const setSelectedExpiringSessions = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiringSessions } = getState();

    if (!expiringSessions.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedExpiringSessions.success(null));
    }

    const selected = expiringSessions.all.filter(expiringSession => selectedId.includes(expiringSession.id));

    dispatch(setSelectedExpiringSessions.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_EXPIRING_SESSIONS/SUCCESS",
      resolve => (setSelectedExpiringSessions: ExpiringSession[] | null) => resolve(setSelectedExpiringSessions)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_EXPIRING_SESSIONS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiringSessions } = getState();

    if (!expiringSessions.all) {
      return;
    }

    const selected = expiringSessions.all.find(exSession => exSession.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SINGLE_SELECTED_EXPIRING_SESSION",
      resolve => (selectedLeads: ExpiringSession) => resolve(selectedLeads)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_EXPIRING_SESSION/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiringSessions } = getState();

    if (!expiringSessions.all) {
      return;
    }

    const selected = expiringSessions.all.find(exSession => exSession.id === selectedId);

    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/REMOVE_EXPIRING_SESSION", resolve => (selectedLeads: ExpiringSession) =>
      resolve(selectedLeads)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_EXPIRING_SESSION/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchExpiringSessions.start, state => ({ ...state, loading: true })),
  handleAction(fetchExpiringSessions.error, state => ({ ...state, loading: false })),
  handleAction(fetchExpiringSessions.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedExpiringSessions.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchExpiringSessions,
  setSelectedExpiringSessions,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };
export type { State };
