import { PreservationDTO } from "../models/preservation";
import { MasterFilter } from "../store/filtersStore";
import { getBaseClient } from "./serviceUtils";

type ServiceParams = {
  url: string;
  body: {
    location_id: null | number;
    report_type: null | number;
  };
};

const getAllPreservations = async (data?: MasterFilter[]): Promise<PreservationDTO[]> => {
  const client = getBaseClient();

  if (!client || !data) {
    return [];
  }

  const type = data.find(d => d.key === "type");
  const location = data.find(d => d.key === "location");

  if (!location || !type) {
    return [];
  }

  const location_id = (!location.value[0] || location.value[0] === "all") ? null : +location.value[0];

  let serviceParams: ServiceParams = {
    url: "/reports/global/getTrainingByTimePeriod",
    body: {
      location_id,
      report_type: null,
    },
  };

  switch (type.value[0]) {
    case "booking":
      serviceParams = {
        url: "/reports/global/getTrainingByTimePeriod",
        body: {
          location_id,
          report_type: null,
        },
      };
      break;

    case "checkIn":
      serviceParams = {
        url: "/reports/global/getTrainingByTimePeriod",
        body: {
          location_id,
          report_type: 1,
        },
      };
      break;

    case "entrance":
      serviceParams = {
        url: "/reports/global/getEntersByTimePeriod",
        body: {
          location_id,
          report_type: null,
        },
      };
      break;

    default:
      break;
  }

  const result = await client.post<PreservationDTO[]>(serviceParams.url, serviceParams.body);

  return result.data;
};

const preservationService = {
  getAllPreservations,
};

export default preservationService;
