import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";

interface DebtDTO extends MemberBaseDTO {
  cancelled: number;
  price: number;
  debt: number;
  created_by_user: string;
  name: string;
  location_box_location: string;
}

interface Debt extends ReportEntity {
  membershipStatusId: number;
  membershipStatus: string;
  price: number;
  userId: number;
  debt: number;
  doneBy: string;
  membership: string;
}

const toDebt = (debt: DebtDTO): Debt => ({
  id: debt.id.toString(),
  userId: debt.user_fk,
  firstName: debt.first_name,
  lastName: debt.last_name,
  fullName: `${debt.first_name} ${debt.last_name}`,
  membershipStatusId: debt.cancelled,
  membershipStatus: debt.cancelled === 0 ? "inactive" : "active",
  price: debt.price,
  debt: debt.debt,
  membership: debt.name,
  createdAt: moment(debt.created_at).toISOString(),
  doneBy: debt.created_by_user,
  location: debt.location_box_location,
  locationId: debt.locations_box_fk,
});

export { toDebt };

export type { DebtDTO, Debt };
