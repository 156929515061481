import { LocationDTO, Location, toLocation } from "../models/location";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getLocations = async (): Promise<Location[]> => {
  const boxId = queryParamsService.getBoxId();
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const result = await client.get<LocationDTO[]>(`/locationsBox/${boxId}`);
  return result.data.map(toLocation);
};

const locationsService = { getLocations };

export default locationsService;
