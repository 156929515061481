import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ExpiredMemberships, toExpiredMemberships } from "../models/expiredMemberships";
import expiredMembersService from "../services/expiredMembershipsService";
import { ApplicationState } from "./store";

type State = {
  all: ExpiredMemberships[] | null;
  selected: ExpiredMemberships[] | null;
  loading: boolean;
};

type TPayload = {
  all: ExpiredMemberships[] | null;
};

const fetchExpiredMemberships = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchExpiredMemberships.start());

    try {
      const data = await expiredMembersService.getAllExpiredMemberships(from, to);

      dispatch(fetchExpiredMemberships.success({ all: data.map(toExpiredMemberships) }));
    } catch {
      dispatch(fetchExpiredMemberships.error());
    }
  },
  {
    start: createActionCreator("@@ALL_EXPIRED_MEMBERSHIPS/START_FETCH"),
    error: createActionCreator("@@ALL_EXPIRED_MEMBERSHIPS/ERROR_FETCH"),
    success: createActionCreator("@@ALL_EXPIRED_MEMBERSHIPS/FETCH", resolve => (expiredMemberships: TPayload) =>
      resolve(expiredMemberships)
    ),
  }
);

const setSelectedExpiredMemberships = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiredMemberships } = getState();

    if (!expiredMemberships.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedExpiredMemberships.success(null));
    }

    const selected = expiredMemberships.all.filter(expiredMemberships => selectedId.includes(expiredMemberships.id));

    dispatch(setSelectedExpiredMemberships.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_EXPIRED_MEMBERSHIPS",
      resolve => (setSelectedExpiredMemberships: ExpiredMemberships[] | null) => resolve(setSelectedExpiredMemberships)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_EXPIRED_MEMBERSHIPS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiredMemberships } = getState();

    if (!expiredMemberships.all) {
      return;
    }

    const selectedExMemberships = expiredMemberships.all.find(exMembership => exMembership.id === selectedId);

    if (!selectedExMemberships) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedExMemberships));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_EXPIRED_MEMBERSHIP/SUCCESS",
      resolve => (payload: ExpiredMemberships) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_EXPIRED_MEMBERSHIP/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiredMemberships } = getState();

    if (!expiredMemberships.all) {
      return;
    }

    const selectedExMemberships = expiredMemberships.all.find(exMembership => exMembership.id === selectedId);

    if (!selectedExMemberships) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedExMemberships));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_EXPIRED_MEMBERSHIP/SUCCESS",
      resolve => (payload: ExpiredMemberships) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_EXPIRED_MEMBERSHIP/ERROR"),
  }
);

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchExpiredMemberships.start, state => ({ ...state, loading: true })),
  handleAction(fetchExpiredMemberships.error, state => ({ ...state, loading: false })),
  handleAction(fetchExpiredMemberships.success, (state, { payload }) => ({
    ...state,
    loading: false,
    all: payload.all,
  })),
  handleAction(setSelectedExpiredMemberships.success, (state, { payload }) => ({
    ...state,
    selected: payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected
      ? state.selected.filter(selectedExMembership => selectedExMembership.id !== payload.id)
      : null,
  })),
]);

const actions = {
  fetchExpiredMemberships,
  setSelectedExpiredMemberships,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
