import moment from "moment";
import { ConvertedLeadDTO } from "../models/convertedLeads";
import { LeadDTO } from "../models/leads";
import { LeadInProcessDTO } from "../models/leadsInProcess";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

type TTypeConverted = {
  report: ConvertedLeadDTO[];
  summary: ConvertedLeadDTO[];
};

const client = getBaseClient();
const boxId = queryParamsService.getBoxId();

// TODO

const getAllLeads = async (from?: Date, to?: Date): Promise<LeadDTO[]> => {
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  if (!client) {
    return [];
  }

  const result = await client.post<LeadDTO[]>("/reports/global/allleads", {
    fromDate,
    toDate,
    locationBox: null,
  });

  return result.data;
};

const getLeadsInProcess = async (): Promise<LeadInProcessDTO[]> => {
  if (!client) {
    return [];
  }

  const result = await client.get<LeadInProcessDTO[]>(`/box/${boxId}/openLeads/null`);

  return result.data;
};

const getConvertedLeads = async (from?: Date, to?: Date): Promise<ConvertedLeadDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<TTypeConverted>("/lead/getLeadConverted/8", {
    coachId: null,
    fromDate,
    locationId: null,
    reportType: "detailedReport",
    toDate,
  });

  return result.data.report;
};

const leadsService = {
  getAllLeads,
  getLeadsInProcess,
  getConvertedLeads,
};

export default leadsService;
