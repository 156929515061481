import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as ToggleOff } from "../../icons/toggleOff.svg";
import { ReactComponent as ToggleOn } from "../../icons/toggleOn.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Absence } from "../../models/absence";
import absenceService from "../../services/absenceService";
import csvService from "../../services/csvService";
import { actions as absenceActions } from "../../store/absenceStore";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { selectedAbsencesSelector, absencesSelector } from "../reportSelector";
import { Page } from "./page";

const typeOptions: TOption[] = [
  { id: "check_in", label: "checkIn" },
  { id: "registration", label: "classRegistration" },
  { id: "entrance", label: "entersMembership" },
];

const AbsenceReport = (dateFormat: string): Page<Absence> => ({
  id: "absence-report",
  description: t("absenceDesc"),
  // hideActions: true,
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  rawUserId: "user_fk",
  title: t("absence"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("noShows"),
    },
    {
      key: "cancelled",
      toLocaleString: false,
      aggregator: "percentage",
      label: t("membershipCanceled"),
      specificValue: "membershipCanceled",
    },
  ],
  defaultSort: {
    //TODO Change when you have update from the spreadsheet
    order: "asc",
    orderBy: "fullName",
  },
  fetchPostRequest: absenceActions.fetchAbsences,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  entitiesSelector: absencesSelector,
  selectedEntitiesSelector: selectedAbsencesSelector,
  setSelectedEntities: absenceActions.setSelectedAbsences,
  setSingleSelected: absenceActions.setSingleSelected,
  removeSingleSelected: absenceActions.removeSingleSelected,
  isLead: false,
  csvGenerator: entities => csvService.getAbsences(entities, dateFormat),
  isDataLoading: state => state.absences.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: entity => entity.fullName,
      width: 20,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: entity => entity.phone,
      width: 15,
    },
    {
      id: "lastSeen",
      label: t("lastSeen"),
      valueRenderer: item => (item.lastSeen ? moment(item.lastSeen).format(dateFormat) : t("never")),
      width: 15,
    },
    {
      id: "membershipType",
      label: t("membership"),
      valueRenderer: entity => entity.membershipType,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.absences.all || []).map(a => ({ id: a.membershipType, label: a.membershipType }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: v.label }));
      },
    },
    {
      id: "cancelled",
      label: t("membershipStatus"),
      valueRenderer: entity => (entity.cancelled === 0 ? t("active") : t("inactive")),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.absences.all || []).map(a => a.cancelled);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v.toString(), label: v === 0 ? t("active") : t("inactive") }));
      },
    },
  ],
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: item => (item.lastSeen ? <Calendar /> : null),
        textRender: item =>
          item.lastSeen ? t("lastSeenPeriod", { lastSeenPeriod: moment(item.lastSeen).format(dateFormat) }) : null,
      },
      {
        icon: item => (item.membershipType === "activeMembership" ? <ToggleOff /> : <ToggleOn />),
        textRender: item => (item.cancelled === 0 ? t("activeMembership") : t("membershipCanceled")),
      },
    ],
  },
  fetchRawMasterData: absenceService.getAbsences,
  masterFilter: {
    mainLabel: t("showNoShowsBy"),
    defaultFilters: [
      {
        key: "type",
        value: ["check_in"],
      },
      {
        key: "fromDate",
        value: [moment().subtract(7, "days").startOf("day").toISOString()],
      },
    ],
    filters: [
      {
        id: "type",
        label: t("showNoShowsBy"),
        filterType: "masterSearchable",
        options: () => typeOptions.map(o => ({ ...o, label: t(o.label as Phrases) })),
        connector: t("since"),
      },
      {
        id: "fromDate",
        label: t("lastSeen"),
        filterType: "singlePeriod",
        datesFromNow: true,
        periodPrefixes: ["laseSevenDays", "fourteenDaysAgo", "thirtyDaysAgo", "custom"],
      },
    ],
  },
});

export default AbsenceReport;
