import { MemberDTO } from "./member";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OrganizationDTO extends MemberDTO {}

interface Organization {
  id: number;
  name: string;
}

const toOrganization = (organizationDto: OrganizationDTO): Organization => ({
  id: organizationDto.id,
  name: organizationDto.last_name,
});

export type { Organization, OrganizationDTO };

export { toOrganization };
