import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as MembershipCard } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as Pause } from "../../icons/pause.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ActiveMembership } from "../../models/activeMemberships";
import csvService from "../../services/csvService";
import membersService from "../../services/membersService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as transparentMembershipActions } from "../../store/transparentMembership";
import { transparentMembershipsSelector, selectedTransparentMembershipsSelector } from "../reportSelector";
import { Page } from "./page";

const statusOptions: TOption[] = [
  { id: "inactive", label: "inactive" },
  { id: "future", label: "future" },
  { id: "active", label: "active" },
  { id: "futureCancellation", label: "futureCancellation" },
];

// TODO: Fix it not to duplicate with active memberships and create a better model

const transparentMembershipsReport = (dateFormat: string): Page<ActiveMembership> => ({
  id: "transparent-memberships-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  description: t("transparentMembershipsDescription"),
  title: t("transparentMembershipsTitle"),
  memberHoldProperty: "membershipId",
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
    {
      url: "multiple-holds",
      icon: <Pause />,
      title: "multiHolds",
      size: "460px",
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "createdAt",
  },
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      label: t("membership"),
      toLocaleString: false,
    },
    {
      key: "membershipType",
      aggregator: "mostUsed",
      label: t("mostPopularPlan"),
      formatLabel: true,
      toLocaleString: false,
      showSpecificValue: true,
    },
  ],
  searchTerms: ["fullName", "phone"],
  entitiesSelector: transparentMembershipsSelector,
  selectedEntitiesSelector: selectedTransparentMembershipsSelector,
  setSelectedEntities: transparentMembershipActions.setSelected,
  setSingleSelected: transparentMembershipActions.setSingleSelected,
  removeSingleSelected: transparentMembershipActions.removeSingleSelected,
  fetchDataActions: [
    transparentMembershipActions.fetchTransparentMemberships,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  isLead: false,
  csvGenerator: entities => csvService.getActiveMembershipCsv(entities, dateFormat),
  isDataLoading: state => state.transparentMemberships.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: transparentMembership => transparentMembership.fullName,
      width: 20,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: transparentMembership => transparentMembership.phone,
      width: 10,
    },
    {
      id: "departmentId",
      label: t("department"),
      valueRenderer: transparentMembership => transparentMembership.department,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.transparentMemberships.all || []).map(membership => ({
          id: membership.departmentId,
          value: membership.department,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(a => a.id);
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: v.value }));
      },
    },
    {
      id: "membershipType",
      label: t("membership"),
      valueRenderer: transparentMembership => transparentMembership.membershipType,
      width: 15,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.transparentMemberships.all || []).map(membership => membership.membershipType);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v, label: v }));
      },
    },
    {
      id: "status",
      label: t("status"),
      valueRenderer: transparentMembership => t(transparentMembership.status as Phrases),
      width: 15,
      filterType: "multipleSearchable",
      showFilterChip: true,
      showAll: t("all"),
      newPage: true,
      filterOptionsSelector: () => statusOptions.map(s => ({ id: s.id, label: t(s.label as Phrases) })),
    },
    {
      id: "price",
      label: t("originalPrice"),
      valueRenderer: transparentMembership => transparentMembership.price,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "paid",
      label: t("paid"),
      valueRenderer: transparentMembership => transparentMembership.paid,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "start",
      label: t("startDateMembership"),
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      valueRenderer: transparentMembership =>
        transparentMembership.start && moment(transparentMembership.start).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "end",
      label: t("endDateMembership"),
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      valueRenderer: transparentMembership =>
        transparentMembership.end && moment(transparentMembership.end).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "createdAt",
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      label: t("createdAt"),
      valueRenderer: transparentMembership => moment(transparentMembership.createdAt).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
  ],
  card: {
    titleRender: (item: ActiveMembership) => item.fullName,
    rows: [
      {
        icon: () => <MembershipCard />,
        textRender: (item: ActiveMembership) => item.membershipType,
      },
      {
        icon: () => <Calendar />,
        textRender: (item: ActiveMembership) => {
          if (!item.start && !item.end) {
            return t("unlimitedMembership");
          }

          if (!item.start && !!item.end) {
            return `${t("endsAt")} ${moment(item.end).format(dateFormat)}`;
          }

          if (!!item.start && !item.end) {
            return `${moment(item.start).format(dateFormat)} - ${t("unlimitedMembership")}`;
          }

          return `${moment(item.start).format(dateFormat)} - ${moment(item.end).format(dateFormat)}`;
        },
      },
    ],
  },
  fetchRawData: membersService.getTransparentMemberships,
});

export default transparentMembershipsReport;
