import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { RestrictedMember, toRestrictedMember } from "../models/restrictedMembers";
import { restrictedMembersService } from "../services/restrictedMembersService";
import { ApplicationState } from "./store";

interface State {
  all: RestrictedMember[] | null;
  selected: RestrictedMember[] | null;
  loading: boolean;
}

interface RestrictedMembersPayload {
  all: RestrictedMember[] | null;
}

interface SelectedRestrictedMembersPayload {
  selected: RestrictedMember[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchRestrictedMembers = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { restrictedMembers } = getState();

    if (restrictedMembers.all) {
      return;
    }

    dispatch(fetchRestrictedMembers.start());

    try {
      const data = await restrictedMembersService.getRestrictedMembers();
      dispatch(fetchRestrictedMembers.success({ all: data.map(toRestrictedMember) }));
    } catch {
      dispatch(fetchRestrictedMembers.failure());
    }
  },
  {
    start: createActionCreator("@@RESTRICTED_MEMBERS/START_FETCH"),
    success: createActionCreator("@@RESTRICTED_MEMBERS/FETCH_SUCCESS", resolve => (payload: RestrictedMembersPayload) =>
      resolve(payload.all)
    ),
    failure: createActionCreator("@@RESTRICTED_MEMBERS/FETCH_FAILURE"),
  }
);

const setSelected = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { restrictedMembers } = getState();

    if (!restrictedMembers.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelected.success({ selected: null }));
      return;
    }

    const selected = restrictedMembers.all.filter(v => selectedId.includes(v.id));
    dispatch(setSelected.success({ selected: selected }));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SELECTED_RESTRICTED_MEMBERS",
      resolve => (payload: SelectedRestrictedMembersPayload) => resolve(payload.selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { restrictedMembers } = getState();

    if (!restrictedMembers.all) {
      return;
    }

    const selected = restrictedMembers.all.find(v => v.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SINGLE_SELECTED_RESTRICTED_MEMBERS",
      resolve => (payload: RestrictedMember) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_RESTRICTED_MEMBERS/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { restrictedMembers } = getState();

    if (!restrictedMembers.all) {
      return;
    }

    const selected = restrictedMembers.all.find(v => v.id === selectedId);

    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/REMOVE_SINGLE_SELECTED_RESTRICTED_MEMBERS",
      resolve => (payload: RestrictedMember) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED_RESTRICTED_MEMBERS/ERROR"),
  }
);

const actions = {
  fetchRestrictedMembers,
  setSelected,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.selected = null;
        draft.all = null;
      });
    }
    return state;
  }),
  handleAction(fetchRestrictedMembers.start, state => ({ ...state, loading: true })),
  handleAction(fetchRestrictedMembers.success, (state, { payload }) => ({
    ...state,
    loading: false,
    all: payload,
  })),
  handleAction(fetchRestrictedMembers.failure, state => ({ ...state, loading: false })),
  handleAction(setSelected.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };
export type { State };
