import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as Pause } from "../../icons/pause.svg";
import { ReactComponent as Counter } from "../../icons/rowCount.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { MembersOnHold } from "../../models/membersOnHold";
import csvService from "../../services/csvService";
import membersOnHoldService from "../../services/membersOnHoldService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as membersActions } from "../../store/membersOnHoldStore";
import { actions as messagesActions } from "../../store/messageStore";
import { membersOnHoldSelector, selectedMembersOnHoldSelector } from "../reportSelector";
import { Page } from "./page";

const holdReasonOptions = [
  { id: -1, label: "unknownHoldReason" },
  { id: 1, label: "holdReasonMedicalReason" },
  { id: 2, label: "holdReasonMilitaryDuty" },
  { id: 3, label: "holdReasonVacation" },
  { id: 4, label: "holdReasonStudies" },
  { id: 5, label: "holdReasonOther" },
  { id: 6, label: "holdReasonPregnancy" },
  { id: 7, label: "holdReasonPersonal" },
  { id: 8, label: "holdReasonGrief" },
  { id: 9, label: "holdReasonMarriage" },
  { id: 10, label: "holdReasonCoronavirus" },
];

const membersOnHoldReport = (dateFormat: string): Page<MembersOnHold> => ({
  id: "members-on-hold-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  searchTerms: ["fullName", "end", "holdReason"],
  title: t("membershipSuspended"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
    {
      url: "multiple-holds-update",
      icon: <Pause />,
      title: "multiHolds",
      size: "460px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    //TODO Add membership
    {
      id: "start",
      label: t("holdStartedAt"),
    },
    {
      id: "end",
      label: t("holdEndedAt"),
    },
    {
      id: "holdDays",
      label: t("totalSuspendedDays"),
    },
  ],
  description: t("membersOnHoldDescription"),
  rawUserId: "user_fk",
  defaultSort: {
    order: "asc",
    orderBy: "end",
  },
  defaultFilters: [
    {
      key: "end",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "end",
      value: moment().add(2, "weeks").startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      toLocaleString: false,
      label: t("members"),
    },
    {
      key: "holdDays",
      aggregator: "sum",
      toLocaleString: false,
      label: t("totalSuspendedDays"),
    },
    {
      key: "holdReason",
      aggregator: "mostUsedPercentage",
      toLocaleString: false,
      label: t("suspendReason"),
      formatLabel: false,
    },
  ],
  entitiesSelector: membersOnHoldSelector,
  selectedEntitiesSelector: selectedMembersOnHoldSelector,
  setSingleSelected: membersActions.setSingle,
  removeSingleSelected: membersActions.removeSingle,
  setSelectedEntities: membersActions.setSelectedMembers,
  fetchOnlyData: membersActions.fetchMembersOnHold,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  isLead: false,
  csvGenerator: entities => csvService.getMembersOnHold(entities, dateFormat),
  isDataLoading: state => state.membersOnHold.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: m => m.fullName,
      width: 20,
    },
    {
      id: "start",
      label: t("holdStartedAt"),
      valueRenderer: m => (m.start ? moment(m.start).format(dateFormat) : null),
      width: 10,
    },
    {
      id: "end",
      label: t("holdEndedAt"),
      valueRenderer: m => (m.end ? moment(m.end).format(dateFormat) : null),
      width: 10,
      filterType: "period",
      periodPrefixes: ["nextWeek", "nextTwoWeeks", "thisMonth", "custom"],
      newPage: true,
      showFilterChip: true,
    },
    {
      id: "holdDays",
      label: t("totalSuspendedDays"),
      valueRenderer: m => m.holdDays,
      width: 10,
      filterType: "slider",
      newPage: false,
      showFilterChip: true,
    },
    {
      id: "holdReasonId",
      label: t("suspendReason"),
      valueRenderer: m => m.holdReason,
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 20,
      filterOptionsSelector: () => holdReasonOptions.map(m => ({ id: m.id.toString(), label: t(m.label as Phrases) })),
    },
  ],
  card: {
    titleRender: m => m.fullName,
    rows: [
      {
        icon: () => <Counter />,
        textRender: m => t("daysFreeze", { days: m.holdDays }),
      },
      {
        icon: () => <Calendar />,
        textRender: m => (m.end ? t("endOn", { endDate: moment(m.end).format(dateFormat) }) : t("unknown")),
      },
      {
        icon: () => <Pause />,
        textRender: m => (m.holdReason ? t(m.holdReason as Phrases) : t("unknownHoldReason")),
      },
    ],
  },
  fetchRawPostData: membersOnHoldService.getMembersOnHold,
});

export default membersOnHoldReport;
