import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Transaction, toTransaction } from "../models/transaction";
import transactionService from "../services/transactionService";
import { ApplicationState } from "./store";

interface State {
  all: Transaction[] | null;
  selected: Transaction[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TPayload {
  all: Transaction[] | null;
}

const fetchTransactions = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchTransactions.start());

    try {
      const data = await transactionService.getTransactions(from, to);

      dispatch(fetchTransactions.success({ all: data.map(toTransaction) }));
    } catch {
      dispatch(fetchTransactions.error());
    }
  },
  {
    start: createActionCreator("@@TRANSACTION/START"),
    error: createActionCreator("@@TRANSACTION/ERROR"),
    success: createActionCreator("@@TRANSACTION/SUCCESS", resolve => (t: TPayload) => resolve(t.all)),
  }
);

const setSelectedTransactions = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transaction } = getState();

    if (!transaction.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedTransactions.success(null));
    }

    const selected = transaction.all.filter(t => selectedId.includes(t.id));
    dispatch(setSelectedTransactions.success(selected));
  },
  {
    success: createActionCreator("@@TRANSACTION/SELECT_ALL/SUCCESS", resolve => (t: Transaction[] | null) =>
      resolve(t)
    ),
  }
);

const setSingleSelect = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transaction } = getState();

    if (!transaction.all) {
      return;
    }

    const selected = transaction.all.find(t => t.id === selectedId);
    if (!selected) {
      return dispatch(setSingleSelect.error());
    }

    dispatch(setSingleSelect.success(selected));
  },
  {
    error: createActionCreator("@@TRANSACTION/SINGLE/ERROR"),
    success: createActionCreator("@@TRANSACTION/SINGLE/SUCCESS", resolve => (t: Transaction) => resolve(t)),
  }
);

const removeSelect = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transaction } = getState();

    if (!transaction.all) {
      return;
    }

    const selected = transaction.all.find(t => t.id === selectedId);
    if (!selected) {
      return dispatch(removeSelect.error());
    }

    dispatch(removeSelect.success(selected));
  },
  {
    error: createActionCreator("@@TRANSACTION/REMOVE/ERROR"),
    success: createActionCreator("@@TRANSACTION/REMOVE/SUCCESS", resolve => (t: Transaction) => resolve(t)),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchTransactions.start, state => ({ ...state, loading: true })),
  handleAction(fetchTransactions.error, state => ({ ...state, loading: false })),
  handleAction(fetchTransactions.success, (state, action) => ({
    ...state,
    all: action.payload,
    loading: false,
  })),
  handleAction(setSelectedTransactions.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelect.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelect.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(t => t.id !== action.payload.id) : null,
  })),
]);

const actions = { fetchTransactions, setSelectedTransactions, setSingleSelect, removeSelect };

export { actions, reducer };

export type { State };
