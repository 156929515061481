import moment from "moment";
import { CanceledMembershipsDTO } from "../models/canceledMemberships";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getCanceledMemberships = async (from?: Date, to?: Date): Promise<CanceledMembershipsDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const response = await client.post<CanceledMembershipsDTO[]>(`membership/getCancelledMembershipsOfBox/${boxId}`, {
    fromDate,
    locationId: null,
    searchBy: "cancelledDate",
    toDate,
  });

  return response.data;
};

const canceledMembershipsService = {
  getCanceledMemberships,
};

export default canceledMembershipsService;
