import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";

const isWeekDay = (value: string): string => moment(value.charAt(value.length - 1)).format("dddd");

interface ShiftSummaryDTO extends MemberBaseDTO {
  date: string;
  day_of_week: string;
  time: string;
  category: string;
  coach_full_name: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  membership_type_name: string | null;
  end_membership: string | null;
  sessions_left: number | null;
  debt: string | null;
  injury: string | null;
  checked_in: number;
  user_fk: number;
  location: string;
  email: string;
  schedule_id: number;
  series_fk: number;
  role_fk: number | null;
  lead_id: number | null;
}

interface ShiftSummary extends BaseReportEntity {
  fullName: string;
  date: string | null;
  exactDay: string;
  time: string | null;
  workout: string;
  coachFullName: string;
  userId: number;
  membershipType: string | null;
  endMembership: string | null;
  sessionsLeft: number | null;
  debt: string | null;
  injury: string | null;
  email: string;
  checkedIn: string;
  phone: string | null;
  customerType: string;
  leadId?: string;
  roleFk?: string;
  timeDigit: number;
}

const toShiftSummary = (shiftSummary: ShiftSummaryDTO): ShiftSummary => ({
  id: `${shiftSummary.user_fk}${shiftSummary.schedule_id}${shiftSummary.series_fk}`,
  firstName: shiftSummary.first_name,
  lastName: shiftSummary.last_name,
  userId: shiftSummary.user_fk,
  fullName: `${shiftSummary.first_name} ${shiftSummary.last_name}`,
  date: shiftSummary.date ? moment(shiftSummary.date).toISOString() : null,
  exactDay: isWeekDay(shiftSummary.day_of_week),
  time: moment(`${shiftSummary.date} ${shiftSummary.time}`).toISOString(),
  workout: shiftSummary.category,
  coachFullName: shiftSummary.coach_full_name,
  phone: shiftSummary.phone,
  membershipType: shiftSummary.membership_type_name,
  endMembership: shiftSummary.end_membership,
  sessionsLeft: shiftSummary.sessions_left,
  debt: shiftSummary.debt,
  injury: shiftSummary.injury,
  checkedIn: shiftSummary.checked_in === 0 ? t("no") : t("yes"),
  location: shiftSummary.location,
  email: shiftSummary.email,
  timeDigit: moment.duration(shiftSummary.time).asHours(),
  customerType:
    shiftSummary.lead_id !== null && shiftSummary.role_fk !== 3
      ? "lead"
      : shiftSummary.role_fk === 3
      ? "member"
      : "staffMember",
});

export { toShiftSummary };

export type { ShiftSummary, ShiftSummaryDTO };
