import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { PeriodPrefixes, TOption } from "../../components/table/types";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as ShoppingCart } from "../../icons/shoppingCart.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Sales } from "../../models/sales";
import csvService from "../../services/csvService";
import salesService from "../../services/salesService";
import { actions as activeEmployeeActions } from "../../store/activeEmployeesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { salesSelector, selectedSalesSelector } from "../reportSelector";
import { actions as salesActions } from "./../../store/salesStore";
import { Page } from "./page";

const actionOptions: TOption[] = [
  { id: "null", label: "all" },
  { id: "Purchase", label: "newPurchase" },
  { id: "renew", label: "membershipRenewal" },
];

const subActionOptions: TOption[] = [
  { id: "null", label: "all" },
  { id: "inPurchaseOccasion", label: "inPurchaseOccasion" },
  { id: "debt", label: "debt" },
  { id: "noReceiptPayment", label: "noReceiptPayment" },
  { id: "separatedPayment", label: "separatedPayment" },
  { id: "recurringSales", label: "recurringSales" },
];

const SalesSummaryReport = (dateFormat: string): Page<Sales> => ({
  id: "sales-summary-report",
  rawUserId: "user_fk",
  searchTerms: ["fullName"],
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  description: t("salesDescription"),
  defaultSort: {
    order: "desc",
    orderBy: "saleDate",
  },
  tableView: [
    {
      id: "sales-report",
      label: t("detailsView"),
      isDefaultView: false,
    },
    {
      id: "sales-summary-report",
      label: t("summaryView"),
      isDefaultView: true,
    },
  ],
  summaryItems: [
    //TODO Add total quantity
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("justItems"),
    },
    {
      key: "value",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sum",
      label: t("originalPriceTotal"),
    },
    {
      key: "paid",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sum",
      label: t("actualPaidTotal"),
    },
    {
      key: "balance",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sum",
      label: t("totalBalances"),
    },
  ],
  fetchDataActions: [
    activeEmployeeActions.fetchActiveEmployes,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: salesActions.setSelectedSales,
  entitiesSelector: salesSelector,
  selectedEntitiesSelector: selectedSalesSelector,
  setSingleSelected: salesActions.setSingleSelected,
  removeSingleSelected: salesActions.removeSingleSelected,
  hideLocations: true,
  isDataLoading: state => state.sales.loading,
  title: t("sales"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 10,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "saleDate",
      label: t("saleDate"),
      width: 7,
      valueRenderer: entity => moment(entity.saleDate).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["today", "yesterday", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
    },
    {
      id: "department",
      label: t("department"),
      width: 7,
      valueRenderer: entity => entity.departmentName,
    },
    {
      id: "doneBy",
      label: t("salePerson"),
      width: 7,
      valueRenderer: entity => entity.doneBy,
      showFilterChip: true,
      newPage: true,
      filterType: "searchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({ id: sale.doneBy, label: sale.doneBy }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: v.label }));
      },
    },
    {
      id: "productType",
      label: t("itemType"),
      width: 7,
      valueRenderer: entity => t(entity.productType as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "searchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({ id: sale.productType, label: sale.productType }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "membershipId",
      label: t("item"),
      width: 7,
      valueRenderer: entity => t(entity.membershipName as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({
          id: sale.membershipId,
          label: sale.membershipName,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: t(v.label as Phrases) }));
      },
    },
    {
      id: "membershipPrice",
      label: t("sellingPrice"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.membershipPrice,
    },
    {
      id: "discount",
      label: t("discount"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.discount,
    },
    {
      id: "paid",
      label: t("paid"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.paid,
    },
    {
      id: "balance",
      label: t("debtOrBalance"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.balance,
    },
    {
      id: "value",
      label: t("netIncome"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.value,
    },
    {
      id: "saleType",
      label: t("saleType"),
      width: 7,
      valueRenderer: entity => t(entity.saleType as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({
          id: sale.saleType,
          label: sale.saleType,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "paymentType",
      label: t("methodOfPayment"),
      width: 7,
      valueRenderer: entity => t(entity.paymentType as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({
          id: sale.paymentType,
          label: sale.paymentType,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
  ],
  isLead: false,
  card: {
    hasNewMobileLayout: true,
    rows: [
      {
        label: () => t("membershipName"),
        textRender: item => item.membershipName,
      },
      {
        label: item => (item.departmentName ? t("department") : t("noDepartment")),
        textRender: item => (item.departmentName ? item.departmentName : t("unlimitedMembership")),
      },
      {
        textRender: item => `${item.countMt ? item.countMt : 0} ${t("purchases")}`,
        icon: () => <ShoppingCart />,
      },
      {
        textRender: item => item.value,
        label: () => t("originalPrice"),
      },
      {
        textRender: item => item.paid,
        label: () => t("paid"),
      },
      {
        textRender: item => item.balance,
        label: item => (item.balance! >= 0 ? t("debt") : t("balance")),
      },
    ],
  },
  fetchRawMasterData: salesService.getALlSummarizedSales,
  fetchPostRequest: salesActions.fetchAllSummarizedSales,
  csvGenerator: entities => csvService.getAllSales(entities, dateFormat),
  hideFilters: false,
  masterFilter: {
    mainLabel: "",
    defaultFilters: [
      {
        key: "date",
        value: [moment().startOf("day").toISOString(), moment().endOf("day").toISOString()],
      },
      {
        key: "action",
        value: ["null"],
      },
      {
        key: "subAction",
        value: ["null"],
      },
      {
        key: "createdBy",
        value: ["null"],
      },
      {
        key: "location",
        value: ["null"],
      },
    ],
    filters: [
      {
        id: "date",
        label: t("scheduleDate"),
        filterType: "doublePeriod",
        periodPrefixes: ["today", "laseSevenDays", "lastFourteenDays", "lastThirtyDays", "custom"] as PeriodPrefixes[],
      },
      {
        id: "action",
        label: t("purchaseType"),
        filterType: "masterSearchable",
        options: () => actionOptions.map(o => ({ ...o, label: t(o.label as Phrases) })),
      },
      {
        id: "subAction",
        label: t("paymentMethod"),
        filterType: "masterSearchable",
        options: () => subActionOptions.map(o => ({ ...o, label: t(o.label as Phrases) })),
      },
      {
        id: "createdBy",
        label: t("doneBy"),
        filterType: "masterSearchable",
        options: state => {
          const employees: TOption[] = (state.activeEmployees.all || []).map(e => ({ id: e.id, label: e.fullName }));
          return [{ id: "null", label: t("staffMember") }, ...employees];
        },
      },
      {
        id: "location",
        label: t("location"),
        filterType: "masterSearchable",
        options: state => {
          const stateLocations = (state.locations.locations || []).map(l => ({ id: l.id.toString(), label: l.name }));
          return [{ id: "null", label: t("allLocations") }, ...stateLocations];
        },
      },
    ],
  },
});

export default SalesSummaryReport;
