import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { DICT_CACHE_DURATION_MS } from "../constants";
import { Phrases } from "../infrastructure/translations/phrases";
import { getBaseClient } from "./serviceUtils";

type TLanguage = {
  [index in keyof Phrases]: string;
};

type DictCache = {
  langId: number;
  phrases: TLanguage;
  updatedDate: number;
};

type Langs = "ar" | "en" | "es" | "he" | "tr";
type Direction = "rtl" | "ltr";

type Lang = {
  code: Langs;
  direction: Direction;
  fontSettingsPrimary: CSSProperties;
  fontSettingsSecondary: CSSProperties;
};

type LanguageConfigResponse = Lang & {
  phrases: TLanguage;
};

const fontSettingsHebrewPrimary: CSSProperties = {
  fontFamily: "Heebo,sans-serif",
  letterSpacing: "2px",
};

const fontSettingsHebrewSecondary: CSSProperties = {
  fontFamily: "Assistant,sans-serif",
};

const fontSettingsRegularPrimary: CSSProperties = {
  fontFamily: "Coolvetica,sans-serif",
  letterSpacing: "3px",
};

const fontSettingsRegularSecondary: CSSProperties = {
  fontFamily: "Helvetica,sans-serif",
};

const langs: { [index: number]: Lang } = {
  1: {
    code: "en",
    direction: "ltr",
    fontSettingsPrimary: fontSettingsRegularPrimary,
    fontSettingsSecondary: fontSettingsRegularSecondary,
  },
  4: {
    code: "tr",
    direction: "ltr",
    fontSettingsPrimary: fontSettingsRegularPrimary,
    fontSettingsSecondary: fontSettingsRegularSecondary,
  },
  5: {
    code: "he",
    direction: "rtl",
    fontSettingsPrimary: fontSettingsHebrewPrimary,
    fontSettingsSecondary: fontSettingsHebrewSecondary,
  },
  8: {
    code: "es",
    direction: "ltr",
    fontSettingsPrimary: fontSettingsRegularPrimary,
    fontSettingsSecondary: fontSettingsRegularSecondary,
  },
  9: {
    code: "ar",
    direction: "rtl",
    fontSettingsPrimary: fontSettingsHebrewPrimary,
    fontSettingsSecondary: fontSettingsHebrewSecondary,
  },
};

const LOCAL_STORAGE_KEY = "dict";

const getLanguageConfig = async (langId: number): Promise<LanguageConfigResponse | null> => {
  const cached = getCached(langId);

  if (cached) {
    return {
      ...langs[langId],
      phrases: cached.phrases,
    };
  }

  const client = getBaseClient();

  if (!client) {
    return null;
  }

  let result: TLanguage | null = null;
  try {
    const response = await client.get<TLanguage>(`/language/${langId}`);
    result = response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }

  const response: LanguageConfigResponse = {
    ...langs[langId],
    phrases: result,
  };

  addCache(langId, response);

  return response;
};

const getCached = (langId: number): DictCache | null => {
  const cacheItemRaw: string | null = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!cacheItemRaw) {
    return null;
  }

  const cacheItem: DictCache = JSON.parse(cacheItemRaw);
  const cacheAge = new Date().getTime() - cacheItem.updatedDate;

  if (cacheItem.langId !== langId || cacheAge > DICT_CACHE_DURATION_MS) {
    return null;
  }

  return cacheItem;
};

const addCache = (langId: number, langConfig: LanguageConfigResponse): void => {
  const cacheItem: DictCache = {
    langId,
    phrases: langConfig.phrases,
    updatedDate: new Date().getTime(),
  };

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cacheItem));
};

const languageService = {
  getLanguageConfig,
};

export default languageService;

export type { Langs, LanguageConfigResponse };
