import { createActionCreator, createReducer } from "deox";
import { Dispatch } from "redux";
import { Location } from "../models/location";
import locationsService from "../services/locationsService";
import { ApplicationState } from "./store";

type State = {
  locations: Location[] | null;
};

const fetchLocations = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { locations } = getState();

    if (locations.locations) {
      return;
    }

    try {
      const data = await locationsService.getLocations();
      dispatch(fetchLocations.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@LOCATIONS/FETCH", resolve => (locations: Location[]) => resolve(locations)),
  }
);

const defaultState: State = {
  locations: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchLocations.success, (state, action) => ({ ...state, locations: action.payload })),
]);

const actions = {
  fetchLocations,
};

export { actions, reducer };

export type { State };
