import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { NumberOrNull, StringOrNull } from "./misc";

interface GroupMemberDTO extends MemberBaseDTO {
  user_fk: number;
  name: StringOrNull; //memberships
  groups_id: NumberOrNull; //no. of members
  start: StringOrNull; //started at
  end: StringOrNull; //ended at
  price: NumberOrNull; //original price
  paid: NumberOrNull; //paid
  count: NumberOrNull;
}

interface GroupMember extends ReportEntity {
  membership: StringOrNull;
  membersNumber: number | null;
  startedAt: StringOrNull;
  endedAt: StringOrNull;
  userId: number;
  price: NumberOrNull;
  paid: NumberOrNull;
  count: NumberOrNull;
}

const toGroupMember = (gm: GroupMemberDTO, groupDataByEntity: _.Dictionary<GroupMemberDTO[]>): GroupMember => ({
  id: `${gm.id}${gm.user_fk}${gm.paid}${gm.price}`,
  userId: gm.user_fk,
  firstName: gm.first_name,
  lastName: gm.last_name,
  fullName: `${gm.first_name} ${gm.last_name}`,
  phone: gm.phone,
  membership: gm.name,
  membersNumber: groupDataByEntity[gm.groups_id!].length,
  startedAt: gm.start ? moment(gm.start).toISOString() : null,
  endedAt: gm.end ? moment(gm.end).toISOString() : null,
  price: gm.price,
  paid: gm.paid,
  location: gm.location,
  count: gm.count || 0,
});

export { toGroupMember };

export type { GroupMember, GroupMemberDTO };
