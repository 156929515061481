import "react-day-picker/lib/style.css";
import "react-virtualized/styles.css";
import React from "react";
import ReactDOM from "react-dom";
/* import App from "./app"; */
import * as serviceWorker from "./serviceWorker";

const render = () => {
  //eslint-disable-next-line
  const App = require("./app").default;

  ReactDOM.render(<App />, document.getElementById("root"));
};

render();
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app", render);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
