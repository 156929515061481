import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ClassesSummary, toClassesSummary } from "../models/classesSummary";
import classesSummaryService from "../services/classesSummaryService";
import { MasterFilter } from "./filtersStore";
import { ApplicationState } from "./store";

interface State {
  all: ClassesSummary[] | null;
  selected: ClassesSummary[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TPayload {
  all: ClassesSummary[];
}

const fetchClassesSummary = Object.assign(
  (data: MasterFilter[]) => async (dispatch: Dispatch) => {
    dispatch(fetchClassesSummary.start());

    try {
      const response = await classesSummaryService.getAllClassesSummary(data);
      dispatch(fetchClassesSummary.success({ all: response.map(toClassesSummary) }));
    } catch {
      dispatch(fetchClassesSummary.error());
    }
  },
  {
    start: createActionCreator("@@CLASSES_SUMMARY/START"),
    error: createActionCreator("@@CLASSES_SUMMARY/ERROR"),
    success: createActionCreator("@@CLASSES_SUMMARY", resolve => (payload: TPayload) => resolve(payload)),
  }
);

const setSelectedClassesSummary = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { classesSummary } = getState();

    if (!classesSummary.all) {
      return dispatch(setSelectedClassesSummary.error());
    }

    if (!selectedId) {
      return dispatch(setSelectedClassesSummary.success(null));
    }

    const selected = classesSummary.all.filter(c => selectedId.includes(c.id));

    dispatch(setSelectedClassesSummary.success(selected));
  },
  {
    error: createActionCreator("@@SELECTED_CLASSES_SUMMARY/ERROR"),
    success: createActionCreator("@@SELECTED_CLASSES_SUMMARY/SUCCESS", resolve => (selected: ClassesSummary[] | null) =>
      resolve(selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { classesSummary } = getState();

    if (!classesSummary.all || !selectedId) {
      return dispatch(setSingleSelected.error());
    }

    const selected = classesSummary.all.find(c => c.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    error: createActionCreator("@@SINGLE/CLASSES_SUMMARY/ERROR"),
    success: createActionCreator("@@SINGLE/CLASSES_SUMMARY/SUCCESS", resolve => (c: ClassesSummary) => resolve(c)),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { classesSummary } = getState();

    if (!classesSummary.all || !selectedId) {
      return dispatch(removeSingleSelected.error());
    }

    const removed = classesSummary.all.find(c => c.id === selectedId);

    if (!removed) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(removed));
  },
  {
    error: createActionCreator("@@REMOVE/CLASSES_SUMMARY/ERROR"),
    success: createActionCreator("@@REMOVE/CLASSES_SUMMARY/SUCCESS", resolve => (c: ClassesSummary) => resolve(c)),
  }
);

const actions = {
  fetchClassesSummary,
  setSelectedClassesSummary,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchClassesSummary.start, state => ({ ...state, loading: true })),
  handleAction(fetchClassesSummary.error, state => ({ ...state, loading: false })),
  handleAction(fetchClassesSummary.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedClassesSummary.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(c => c.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };

export type { State };
