import moment from "moment";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";

interface RenewalDTO extends MemberBaseDTO {
  membership_type: string;
  created_by_user: string;
}

interface Renewal extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string | null;
  email: string;
  membershipType: string;
  userId: number;
  purchaseDate: Date | string;
  doneByUser: string;
  location: string;
}

const toRenewal = (renewals: RenewalDTO): Renewal => ({
  id: renewals.id.toString(),
  userId: renewals.user_fk,
  firstName: renewals.first_name,
  lastName: renewals.last_name,
  fullName: `${renewals.first_name} ${renewals.last_name}`,
  phone: renewals.phone,
  email: renewals.email,
  membershipType: renewals.membership_type,
  purchaseDate: moment(renewals.created_at).toISOString(),
  doneByUser: renewals.created_by_user,
  location: renewals.location,
});

export { toRenewal };
export type { RenewalDTO, Renewal };
