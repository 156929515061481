import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull, yesNoToBool } from "./misc";

const toStatus = (active: 0 | 1 | 2, cancelled: 0 | 1) => {
  if (active === 0) {
    return "inactive";
  }

  if (active === 2) {
    return "future";
  }

  if (active === 1 && cancelled === 0) {
    return "active";
  }

  if (active === 1 && cancelled === 1) {
    return "activeMemberWithFutureCancel";
  }

  return "Incompatible Status";
};

interface SessionDTO extends MemberBaseDTO {
  membership_type: StringOrNull;
  department_id: number | null;
  department_name: StringOrNull;
  active: 0 | 1 | 2;
  cancelled: 0 | 1;
  paid: number;
  price: number;
  start: StringOrNull;
  end: StringOrNull;
  sessions_left: number;
  membership_user_id: number;
  user_future_schedules: number | null;
  sessions: number;
}

interface Session extends ReportEntity {
  departmentId: number | null;
  department: StringOrNull;
  email: string;
  membershipType: StringOrNull;
  userId: number;
  membershipId: number;
  status: string;
  price: number;
  paid: number;
  start: StringOrNull;
  end: StringOrNull;
  sessionsLeft: number;
  futureBooking: number | null;
  totalSessions: number;
}

const toSession = (session: SessionDTO): Session => ({
  id: session.membership_user_id.toString(),
  userId: session.user_fk,
  firstName: session.first_name,
  lastName: session.last_name,
  fullName: `${session.first_name} ${session.last_name}`,
  phone: session.phone,
  email: session.email,
  department: session.department_name,
  departmentId: session.department_id,
  membershipType: session.membership_type,
  membershipId: session.membership_user_id,
  status: toStatus(session.active, session.cancelled),
  price: session.price,
  paid: session.paid,
  start: session.start ? moment(session.start).toISOString() : null,
  end: session.end ? moment(session.end).toISOString() : null,
  sessionsLeft: session.sessions_left,
  futureBooking: session.user_future_schedules ? session.user_future_schedules : 0,
  totalSessions: session.sessions,
  createdAt: moment(session.created_at).toISOString(),
  allowSMS: yesNoToBool(session.allow_sms),
  allowEmails: yesNoToBool(session.allow_mailing_list),
  location: session.location,
});

export type { SessionDTO, Session };

export { toSession };
