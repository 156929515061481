import moment from "moment";

import { BookingCancellationDTO } from "../models/bookingCancellation";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getBookingCancellations = async (from?: Date, to?: Date): Promise<BookingCancellationDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const response = await client.post<BookingCancellationDTO[]>(`scheduleUser/getCancelledSchedulesUsers/${boxId}`, {
    fromDate,
    toDate,
  });

  return response.data;
};

const bookingCancellationServices = {
  getBookingCancellations,
};

export default bookingCancellationServices;
