import Axios, { AxiosInstance } from "axios";
import queryParamsService from "./queryParamsService";

const baseApiUrl = process.env.REACT_APP_API_URL;

const getBaseClient = (): AxiosInstance | null => {
  const token = queryParamsService.getToken();
  const boxFk = queryParamsService.getBoxId();

  if (!token || !boxFk) {
    return null;
  }

  const client = Axios.create({
    baseURL: baseApiUrl,
    headers: {
      accessToken: token,
      boxFk,
    },
  });

  return client;
};

export { getBaseClient };
