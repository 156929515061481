import { StringOrNull } from "./misc";

interface TMonth {
  label: string;
  value: number;
}

interface MembershipForecastDTO {
  department_name: StringOrNull;
  membership_type: string;
  count_mt: string;
  cur0: string;
  cur1: string;
  cur2: string;
  cur3: string;
  cur4: string;
  cur5: string;
  cur6: string;
  cur7: string;
  cur8: string;
  cur9: string;
  cur10: string;
  cur11: string;
}

interface MembershipForecast {
  id: string;
  departmentName: string;
  membershipType: string;
  countMt: number;
  currentMonth: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  month7: number;
  month8: number;
  month9: number;
  month10: number;
  month11: number;
  month12: number;
}

const toMembershipForecast = (
  m: MembershipForecastDTO,
  indexAsKey: number,
  entityCountMt: string
): MembershipForecast => {
  let newCountMt = +entityCountMt;

  return {
    id: indexAsKey.toString(),
    departmentName: m.department_name ? m.department_name : "-1",
    membershipType: m.membership_type ? m.membership_type : "-1",
    countMt: +m.count_mt,
    currentMonth: newCountMt = newCountMt - +m.cur0,
    month2: newCountMt = newCountMt - +m.cur1,
    month3: newCountMt = newCountMt - +m.cur2,
    month4: newCountMt = newCountMt - +m.cur3,
    month5: newCountMt = newCountMt - +m.cur4,
    month6: newCountMt = newCountMt - +m.cur5,
    month7: newCountMt = newCountMt - +m.cur6,
    month8: newCountMt = newCountMt - +m.cur6,
    month9: newCountMt = newCountMt - +m.cur8,
    month10: newCountMt = newCountMt - +m.cur9,
    month11: newCountMt = newCountMt - +m.cur10,
    month12: newCountMt = newCountMt - +m.cur11,
  };
};

export { toMembershipForecast };

export type { MembershipForecastDTO, MembershipForecast };
