import { SessionDTO } from "../models/sessions";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();
const boxId = queryParamsService.getBoxId();

const getAllSessions = async (): Promise<SessionDTO[]> => {
  if (!client) {
    return [];
  }

  const result = await client.get<SessionDTO[]>(`/membership/sessions/${boxId}/detailedReport/null`);

  return result.data;
};

const getAllTransparentSessions = async (): Promise<SessionDTO[]> => {
  if (!client) {
    return [];
  }

  const result = await client.get<SessionDTO[]>(`membership/sessions/${boxId}/transparentReport/null`);

  return result.data;
};

const sessionService = {
  getAllSessions,
  getAllTransparentSessions,
};

export default sessionService;
