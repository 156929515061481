import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as BirthdayCake } from "../../icons/birthdayCake.svg";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Birthday } from "../../models/birthday";
import birthdayService from "../../services/birthdayService";
import csvService from "../../services/csvService";
import { actions as birthdayActions } from "../../store/birthdayStore";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { selectedBirthdaysSelector, birthdaysSelector } from "../reportSelector";
import { Page } from "./page";

const birthdayReport = (dateFormat: string): Page<Birthday> => ({
  id: "birthday-report",
  description: t("birthDayDescription"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  title: t("birthday"),
  searchTerms: ["fullName", "phone"],
  defaultFilters: [
    {
      key: "birthdayDate",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "birthdayDate",
      value: moment().add(7, "days").startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("birthdays"),
    },
    {
      key: "age",
      aggregator: "average",
      toLocaleString: false,
      label: t("agesAverage"),
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "birthdayDate",
  },
  fetchOnlyData: birthdayActions.fetchBirthdays,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  entitiesSelector: birthdaysSelector,
  selectedEntitiesSelector: selectedBirthdaysSelector,
  setSelectedEntities: birthdayActions.setSelectedBirthdays,
  setSingleSelected: birthdayActions.setSingleSelected,
  removeSingleSelected: birthdayActions.removeSingleSelected,
  isLead: false,
  csvGenerator: entities => csvService.getAllBirthdays(entities, dateFormat),
  isDataLoading: state => state.birthdays.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: birthday => birthday.fullName,
      width: 20,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: birthday => birthday.phone,
      width: 20,
    },
    {
      id: "birthdayDate",
      label: t("birthday"),
      valueRenderer: birthday => {
        const birthdayLocale = dateFormat === "MM/DD/YYYY" ? "MM/DD" : "DD/MM";
        return !birthday.birthdayDate ? t("invalidDate") : moment(birthday.birthdayDate).format(birthdayLocale);
      },
      filterType: "period",
      newPage: true,
      periodPrefixes: ["today", "yesterday", "tomorrow", "thisWeek", "custom"],
      showFilterChip: true,
      width: 15,
    },
    {
      id: "age",
      label: t("age"),
      valueRenderer: birthday => birthday.age,
      width: 15,
      filterType: "slider",
      showFilterChip: true,
    },
  ],
  card: {
    titleRender: (item: Birthday) => item.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: (item: Birthday) => {
          const birthdayLocale = dateFormat === "MM/DD/YYYY" ? "dddd, MMMM D" : "dddd, D MMMM";
          return moment(item.birthdayDate).format(birthdayLocale);
        },
      },
      {
        icon: () => <BirthdayCake />,
        textRender: (item: Birthday) => t("yearsOld", { age: item.age }),
      },
    ],
  },
  fetchRawPostData: birthdayService.getBirthdays,
});
export default birthdayReport;
