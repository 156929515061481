import moment from "moment";
import { BaseReportEntity } from "./base";
import { getTimeDifferenceInHours, dateToDecimalTime } from "./misc";

const toWeekDay = (day: string) => {
  switch (day) {
    case "day-2":
      return "monday";
    case "day-3":
      return "tuesday";
    case "day-4":
      return "wednesday";
    case "day-5":
      return "thursday";
    case "day-6":
      return "friday";
    case "day-7":
      return "saturday";
    case "day-1":
      return "sunday";

    default:
      return "invalidDay";
  }
};

interface EmployeeAttendanceDTO {
  id: number;
  user_fk: number;
  full_name: string;
  created_at: string | null;
  entrance_date_time: string | null;
  placemark: string | null;
  exit_date_time: string | null;
  day_of_week: string | null;
  placemark_exit: string | null;
  users_boxes_id: number;
}

interface EmployeeAttendance extends BaseReportEntity {
  fullName: string;
  date: string | null;
  userId: number;
  day: string | null;
  enteringTimeFull: string | null;
  exitingTimeFull: string | null;
  enteringTime: number | null;
  enteringLocation: string | null;
  exitingTime: number | null;
  exitingLocation: string | null;
  totalHours: number | null;
  locationId: number | null;
}

const toEmployeeAttendance = (att: EmployeeAttendanceDTO): EmployeeAttendance => ({
  id: att.id.toString(),
  fullName: att.full_name,
  date: att.created_at ? moment(att.created_at).toISOString() : null,
  userId: att.user_fk, // TODO - this should be a bit different
  day: att.day_of_week ? toWeekDay(att.day_of_week) : null,
  enteringTime: dateToDecimalTime(att.entrance_date_time),
  enteringTimeFull: att.entrance_date_time ? moment(att.entrance_date_time).toISOString() : null,
  exitingTimeFull: att.exit_date_time
    ? moment(att.exit_date_time).toISOString()
    : moment().startOf("day").toISOString(),
  enteringLocation: att.placemark,
  exitingTime: dateToDecimalTime(att.exit_date_time, true),
  exitingLocation: att.placemark_exit,
  totalHours: getTimeDifferenceInHours(
    att.entrance_date_time ? moment(att.entrance_date_time) : moment(),
    att.exit_date_time ? moment(att.exit_date_time) : moment()
  ),
  locationId: att.users_boxes_id,
});

export type { EmployeeAttendanceDTO, EmployeeAttendance };
export { toEmployeeAttendance, getTimeDifferenceInHours };
