import moment from "moment";
import { RegularDTO } from "../models/regulars";
import { getBaseClient } from "./serviceUtils";

const getRegulars = async (from?: Date, to?: Date): Promise<RegularDTO[]> => {
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<RegularDTO[]>("reports/global/regularClients", {
    fromDate,
    toDate,
  });

  return result.data;
};

const regularsService = {
  getRegulars,
};

export default regularsService;
