import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { toLead, Lead } from "../models/leads";
import leadsService from "../services/leadsService";
import { ApplicationState } from "./store";

type State = {
  all: Lead[] | null;
  selected: Lead[] | null;
  loading: boolean;
};

type TPayload = {
  all: Lead[] | null;
};

const fetchAllLeads = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchAllLeads.start());

    try {
      const data = await leadsService.getAllLeads(from, to);
      dispatch(fetchAllLeads.success({ all: data.map(toLead) }));
    } catch {
      dispatch(fetchAllLeads.error());
      return;
    }
  },
  {
    start: createActionCreator("@@ALL_LEADS/START_FETCH"),
    error: createActionCreator("@@ALL_LEADS/ERROR_FETCH"),
    success: createActionCreator("@@ALL_LEADS/FETCH_SUCCESS", resolve => (leads: TPayload) => resolve(leads)),
  }
);

const setSelectedLeads = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leads } = getState();

    if (!leads.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedLeads.success(null));
      return;
    }

    const selected = leads.all.filter(lead => selectedId.includes(lead.id));
    dispatch(setSelectedLeads.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_LEADS", resolve => (selected: Lead[] | null) =>
      resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_LEADS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leads } = getState();

    if (!leads.all) {
      return;
    }

    const selected = leads.all.find(lead => lead.id === selectedId);
    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_LEADS", resolve => (selected: Lead) =>
      resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_LEADS/ERROR"),
  }
);

const removeSelectedLeads = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leads } = getState();

    if (!leads.all) {
      return;
    }

    const selected = leads.all.find(lead => lead.id === selectedId);
    if (!selected) {
      return dispatch(removeSelectedLeads.error());
    }

    dispatch(removeSelectedLeads.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/REMOVE_SELECTED_LEADS", resolve => (selected: Lead) => resolve(selected)),
    error: createActionCreator("@@REPORTS/REMOVE_SELECTED_LEADS/ERROR"),
  }
);

const defaultState: State = {
  all: null,
  selected: null,
  loading: true,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchAllLeads.start, state => ({ ...state, loading: true })),
  handleAction(fetchAllLeads.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(fetchAllLeads.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedLeads.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelectedLeads.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchAllLeads,
  setSelectedLeads,
  setSingleSelected,
  removeSelectedLeads,
};

export { actions, reducer };

export type { State };
