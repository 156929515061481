import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Renewal, toRenewal } from "../models/renewals";
import renewalsService from "../services/renewalsService";
import { ApplicationState } from "./store";

interface State {
  all: Renewal[] | null;
  selected: Renewal[] | null;
  loading: boolean;
}

interface TPayloadRenewals {
  all: Renewal[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchRenewals = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchRenewals.start());

    try {
      const data = await renewalsService.getRenewals(from, to);
      dispatch(fetchRenewals.success({ all: data.map(toRenewal) }));
    } catch {
      dispatch(fetchRenewals.error());
    }
  },
  {
    start: createActionCreator("@@RENEWALS/START_FETCH"),
    success: createActionCreator("@@RENEWALS/FETCH_SUCCESS", resolve => (payload: TPayloadRenewals) =>
      resolve(payload)
    ),
    error: createActionCreator("@@RENEWALS/FETCH_ERROR"),
  }
);

const setSelectedRenewals = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { renewals } = getState();

    if (!renewals.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedRenewals.success(null));
    }

    const selected = renewals.all.filter(renewal => selectedId.includes(renewal.id));
    dispatch(setSelectedRenewals.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_RENEWALS", resolve => (payload: Renewal[] | null) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_RENEWALS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { renewals } = getState();

    if (!renewals.all) {
      return;
    }

    const selectedRenewal = renewals.all.find(renewal => renewal.id === selectedId);

    if (!selectedRenewal) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedRenewal));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_RENEWAL/SUCCESS", resolve => (payload: Renewal) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_RENEWAL/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { renewals } = getState();

    if (!renewals.all) {
      return;
    }

    const selectedRenewal = renewals.all.find(renewal => renewal.id === selectedId);

    if (!selectedRenewal) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedRenewal));
  },
  {
    success: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_RENEWAL/SUCCESS", resolve => (payload: Renewal) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_RENEWAL/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchRenewals.start, state => ({ ...state, loading: true })),
  handleAction(fetchRenewals.error, state => ({ ...state, loading: false })),
  handleAction(fetchRenewals.success, (state, action) => ({
    ...state,
    loading: false,
    all: action.payload.all,
  })),
  handleAction(setSelectedRenewals.success, (state, action) => ({ ...state, selected: action.payload })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedRenewal => selectedRenewal.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchRenewals,
  setSelectedRenewals,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
