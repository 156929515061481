import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Preservation } from "../../models/preservation";
import csvService from "../../services/csvService";
import preservationService from "../../services/preservationService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { preservationSelector, selectedPreservationsSelector } from "../reportSelector";
import { actions as preservationActions } from "./../../store/preservationStore";
import { Page } from "./page";

const typeOptions: TOption[] = [
  { id: "booking", label: "classRegistration" },
  { id: "checkIn", label: "checkIn" },
  { id: "entrance", label: "entersMembership" },
];

const PreservationReport = (): Page<Preservation> => ({
  id: "preservation-report",
  description: t("preservationDesc"),
  title: t("preservation"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  searchTerms: ["fullName"],
  rawUserId: "user_fk",
  defaultSort: {
    order: "asc",
    orderBy: "fullName",
  },
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  //TODO Dynamic ships only for preservation by booking!
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],

  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  entitiesSelector: preservationSelector,
  selectedEntitiesSelector: selectedPreservationsSelector,
  setSelectedEntities: preservationActions.setSelectedPreservations,
  setSingleSelected: preservationActions.setSingleSelected,
  hideLocations: true,
  removeSingleSelected: preservationActions.removeSingleSelected,
  isDataLoading: state => state.preservation.loading,
  csvGenerator: entities => csvService.getPreservations(entities),
  isLead: false,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: entity => entity.fullName,
      width: 9,
    },
    {
      id: "week1",
      label: t("week1"),
      valueRenderer: entity => entity.week1,
      ratingVariation: entity => entity.ratingWeek1,
      width: 9,
    },
    {
      id: "week2",
      label: t("week2"),
      valueRenderer: entity => entity.week2,
      ratingVariation: entity => entity.ratingWeek2,
      width: 9,
    },
    {
      id: "week3",
      label: t("week3"),
      valueRenderer: entity => entity.week3,
      ratingVariation: entity => entity.ratingWeek3,
      width: 9,
    },
    {
      id: "week4",
      label: t("week4"),
      valueRenderer: entity => entity.week4,
      ratingVariation: entity => entity.ratingWeek4,
      width: 9,
    },
    {
      id: "week5",
      label: t("week5"),
      valueRenderer: entity => entity.week5,
      ratingVariation: entity => entity.ratingWeek5,
      width: 9,
    },
    {
      id: "week6",
      label: t("week6"),
      valueRenderer: entity => entity.week6,
      ratingVariation: entity => entity.ratingWeek6,
      width: 9,
    },
    {
      id: "week7",
      label: t("week7"),
      valueRenderer: entity => entity.week7,
      ratingVariation: entity => entity.ratingWeek7,
      width: 9,
    },
    {
      id: "week8",
      label: t("week8"),
      valueRenderer: entity => entity.week8,
      ratingVariation: entity => entity.ratingWeek8,
      width: 9,
    },
    {
      id: "week9",
      label: t("week9"),
      valueRenderer: entity => entity.week9,
      ratingVariation: entity => entity.ratingWeek9,
      width: 9,
    },
    {
      id: "week10",
      label: t("week10"),
      valueRenderer: entity => entity.week10,
      ratingVariation: entity => entity.ratingWeek10,
      width: 9,
    },
  ],
  card: {
    titleRender: () => null,
    rows: [
      {
        textRender: () => null,
      },
    ],
  },
  fetchRawMasterData: preservationService.getAllPreservations,
  desktopOnly: true,
  fetchPostRequest: preservationActions.fetchPreservations,
  masterFilter: {
    mainLabel: t("preservationSetting"),
    defaultFilters: [
      {
        key: "type",
        value: ["booking"],
      },
      {
        key: "location",
        value: ["all"],
      },
    ],
    filters: [
      {
        id: "type",
        label: t("type"),
        filterType: "masterSearchable",
        options: () => typeOptions.map(o => ({ ...o, label: t(o.label as Phrases) })),
      },
      {
        id: "location",
        label: t("location"),
        filterType: "masterSearchable",
        options: state => {
          const locations = state.locations.locations || [];
          if (!locations.length) {
            return [];
          }

          const transformedLocations: TOption[] = locations.map(l => ({ id: l.id.toString(), label: l.name }));
          return [{ id: "all", label: t("allLocations") }, ...transformedLocations];
        },
      },
    ],
  },
});

export default PreservationReport;
