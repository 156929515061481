import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as PaperMoneyFilled } from "../../icons/paperMoneyFilled.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as ShoppingCart } from "../../icons/shoppingCart.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Sales } from "../../models/sales";
import csvService from "../../services/csvService";
import salesService from "../../services/salesService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { salesSelector, selectedSalesSelector } from "../reportSelector";
import { actions as salesActions } from "./../../store/salesStore";
import { Page } from "./page";

const SalesReport = (dateFormat: string): Page<Sales> => ({
  id: "sales-report",
  rawUserId: "user_fk",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  searchTerms: ["fullName"],
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  description: t("salesDescription"),
  defaultSort: {
    order: "desc",
    orderBy: "saleDate",
  },
  tableView: [
    {
      id: "sales-report",
      label: t("detailsView"),
      isDefaultView: true,
    },
    {
      id: "sales-summary-report",
      label: t("summaryView"),
      isDefaultView: false,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("purchases"),
    },
    {
      key: "membershipName",
      toLocaleString: false,
      aggregator: "mostUsed",
      label: t("mostPopularPlan"),
      formatLabel: true,
      showSpecificValue: true,
    },
    {
      key: "paid",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sum",
      label: t("paidTotal"),
    },
    {
      key: "balance",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sumOfNegativeValues",
      label: t("totalBalances"),
    },
    {
      key: "balance",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sumOfPositiveValues",
      label: t("totalDebts"),
    },
  ],
  defaultFilters: [
    {
      key: "saleDate",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "saleDate",
      value: moment().endOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  fetchOnlyData: salesActions.fetchAllSales,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: salesActions.setSelectedSales,
  entitiesSelector: salesSelector,
  selectedEntitiesSelector: selectedSalesSelector,
  setSingleSelected: salesActions.setSingleSelected,
  removeSingleSelected: salesActions.removeSingleSelected,
  isDataLoading: state => state.sales.loading,
  title: t("sales"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 10,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "saleDate",
      label: t("saleDate"),
      width: 7,
      valueRenderer: entity => moment(entity.saleDate).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["today", "yesterday", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
    },
    {
      id: "department",
      label: t("department"),
      width: 7,
      valueRenderer: entity => entity.departmentName,
    },
    {
      id: "doneBy",
      label: t("salePerson"),
      width: 7,
      valueRenderer: entity => entity.doneBy,
      showFilterChip: true,
      newPage: true,
      filterType: "searchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({ id: sale.doneBy, label: sale.doneBy }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: v.label }));
      },
    },
    {
      id: "productType",
      label: t("itemType"),
      width: 7,
      valueRenderer: entity => (entity.productType ? t(entity.productType as Phrases) : t("unknown")),
      showFilterChip: true,
      newPage: true,
      filterType: "searchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({ id: sale.productType, label: sale.productType }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "membershipId",
      label: t("item"),
      width: 7,
      valueRenderer: entity => (entity.membershipName ? t(entity.membershipName as Phrases) : t("unknown")),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({
          id: sale.membershipId,
          label: sale.membershipName,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: t(v.label as Phrases) }));
      },
    },
    {
      id: "membershipPrice",
      label: t("sellingPrice"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.membershipPrice,
    },
    {
      id: "discount",
      label: t("discount"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.discount,
    },
    {
      id: "paid",
      label: t("paid"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.paid,
    },
    {
      id: "balance",
      label: t("debtOrBalance"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.balance,
    },
    {
      id: "value",
      label: t("netIncome"),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.value,
    },
    {
      id: "saleType",
      label: t("saleType"),
      width: 7,
      valueRenderer: entity => t(entity.saleType as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({
          id: sale.saleType,
          label: sale.saleType,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "paymentType",
      label: t("methodOfPayment"),
      width: 7,
      valueRenderer: entity => t(entity.paymentType as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.sales.all || []).map(sale => ({
          id: sale.paymentType,
          label: sale.paymentType,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
  ],
  isLead: false,
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: item => t("purchasedAt", { purchasedAt: moment(item.saleDate).format(dateFormat) }),
      },
      {
        icon: () => <ShoppingCart />,
        textRender: item => item.membershipName,
      },
      {
        icon: () => <PaperMoneyFilled />,
        textRender: item => t("amountPaidMobile", { amountPaidMobile: item.paid }),
      },
    ],
  },
  fetchRawPostData: salesService.getAllSales,
  csvGenerator: entities => csvService.getAllSales(entities, dateFormat),
});

export default SalesReport;
