import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import _ from "lodash";
import { Dispatch } from "redux";
import { GroupMember, toGroupMember } from "../models/groupMembers";
import groupMembersService from "../services/groupMembersService";
import { ApplicationState } from "./store";

interface State {
  all: GroupMember[] | null;
  selected: GroupMember[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

type GroupMemberPayload = GroupMember[] | null;

const fetchGroupMembers = Object.assign(
  () => async (dispatch: Dispatch) => {
    dispatch(fetchGroupMembers.start());

    try {
      const data = await groupMembersService.getGroupMembers();
      // const filteredData = _.uniqBy(data, "id");
      const groupDataByEntity = _.groupBy(data, "groups_id");

      dispatch(fetchGroupMembers.success(data.map(entity => toGroupMember(entity, groupDataByEntity))));
    } catch {
      dispatch(fetchGroupMembers.error());
    }
  },
  {
    start: createActionCreator("@@GROUP_MEMBERS/START_FETCH"),
    success: createActionCreator("@@GROUP_MEMBERS/FETCH_SUCCESS", resolve => (payload: GroupMemberPayload) =>
      resolve(payload)
    ),
    error: createActionCreator("@@GROUP_MEMBERS/FETCH_ERROR"),
  }
);

const setSelectedGroupMembers = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { groupMembers } = getState();

    if (!groupMembers.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedGroupMembers.error());
    }

    const selected = groupMembers.all.filter(groupMembers => selectedId.includes(groupMembers.id));
    dispatch(setSelectedGroupMembers.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_GROUP_MEMBERS", resolve => (payload: GroupMemberPayload) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_GROUP_MEMBERS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { groupMembers } = getState();

    if (!groupMembers.all) {
      return;
    }

    const selected = groupMembers.all.find(groupMembers => groupMembers.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_GROUP_MEMBER", resolve => (payload: GroupMember) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_GROUP_MEMBER/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { groupMembers } = getState();

    if (!groupMembers.all) {
      return;
    }

    const selected = groupMembers.all.find(groupMembers => groupMembers.id === selectedId);

    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED", resolve => (payload: GroupMember) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED/ERROR"),
  }
);

const actions = {
  fetchGroupMembers,
  setSelectedGroupMembers,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchGroupMembers.start, state => ({ ...state, loading: true })),
  handleAction(fetchGroupMembers.success, (state, action) => ({
    ...state,
    loading: false,
    all: action.payload,
  })),
  handleAction(fetchGroupMembers.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedGroupMembers.error, state => ({
    ...state,
    selected: null,
  })),
  handleAction(setSelectedGroupMembers.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(g => g.id !== action.payload.id) : null,
  })),
]);

export { actions, reducer };

export type { State };
