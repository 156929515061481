import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { toExternalMembership, ExternalMembership } from "../models/externalMembership";
import externalMembershipService from "../services/externalMembershipService";
import { ApplicationState } from "./store";

type State = {
  all: ExternalMembership[] | null;
  selected: ExternalMembership[] | null;
  loading: boolean;
};

type TPayload = {
  all: ExternalMembership[] | null;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchExternalMemberships = Object.assign(
  (from: Date, to: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchExternalMemberships.start());

    try {
      const data = await externalMembershipService.getExternalMemberships(from, to);
      dispatch(fetchExternalMemberships.success({ all: data.map(toExternalMembership) }));
    } catch {
      dispatch(fetchExternalMemberships.error());
      return;
    }
  },
  {
    start: createActionCreator("@@EXTERNAL_MEMBERSHIPS/START_FETCH"),
    error: createActionCreator("@@EXTERNAL_MEMBERSHIPS/ERROR_FETCH"),
    success: createActionCreator("@@EXTERNAL_MEMBERSHIPS/SUCCESS_FETCH", resolve => (data: TPayload) => resolve(data)),
  }
);

const setSelected = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { externalMemberships } = getState();

    if (!externalMemberships.all) {
      return dispatch(setSelected.error());
    }

    if (!selectedId) {
      return dispatch(setSelected.success(null));
    }

    const selected = externalMemberships.all.filter(m => selectedId.includes(m.id));
    return dispatch(setSelected.success(selected));
  },
  {
    error: createActionCreator("@@EXTERNAL_MEMBERSHIPS/SET_SELECTED/ERROR"),
    success: createActionCreator(
      "@@EXTERNAL_MEMBERSHIPS/SET_SELECTED/SUCCESS",
      resolve => (s: ExternalMembership[] | null) => resolve(s)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { externalMemberships } = getState();

    if (!externalMemberships.all) {
      return dispatch(setSingleSelected.error());
    }

    const selected = externalMemberships.all.find(m => m.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@EXTERNAL_MEMBERSHIPS/SET_SINGLE/SUCCESS",
      resolve => (selected: ExternalMembership) => resolve(selected)
    ),
    error: createActionCreator("@@EXTERNAL_MEMBERSHIPS/SET_SINGLE/ERROR"),
  }
);

const removeSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { externalMemberships } = getState();

    if (!externalMemberships.all) {
      return dispatch(removeSelected.error());
    }

    const selected = externalMemberships.all.find(m => m.id === selectedId);
    if (!selected) {
      return dispatch(removeSelected.error());
    }

    dispatch(removeSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@EXTERNAL_MEMBERSHIPS/REMOVE_SINGLE/SUCCESS",
      resolve => (selected: ExternalMembership) => resolve(selected)
    ),
    error: createActionCreator("@@EXTERNAL_MEMBERSHIPS/SET_SINGLE/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchExternalMemberships.start, state => ({ ...state, loading: true })),
  handleAction(fetchExternalMemberships.error, state => ({ ...state, loading: false })),
  handleAction(fetchExternalMemberships.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelected.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(m => m.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchExternalMemberships,
  setSingleSelected,
  setSelected,
  removeSelected,
};

export { actions, reducer };

export type { State };
