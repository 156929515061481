import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull, yesNoToBool } from "./misc";

const toFutureMembership = (membership: 0 | 1) => {
  if (membership === 0) {
    return "no";
  }

  if (membership === 1) {
    return "yes";
  }

  return "N/A";
};

interface ExpiringSessionDTO extends MemberBaseDTO {
  muid: number;
  membership_type: StringOrNull;
  start: string;
  end: string;
  sessions_left: number;
  user_future_schedules: number | null;
  has_future_membership: 0 | 1;
}

interface ExpiringSession extends ReportEntity {
  membershipType: StringOrNull;
  startedAt: Date | string;
  email: string;
  userId: number;
  expiredAt: Date | string;
  sessionsLeft: number;
  futureBooking: number | null;
  futureMembership: string;
}

const toExpiringSession = (session: ExpiringSessionDTO): ExpiringSession => ({
  id: session.muid.toString(),
  userId: session.user_fk,
  firstName: session.first_name,
  lastName: session.last_name,
  fullName: `${session.first_name} ${session.last_name}`,
  phone: session.phone,
  email: session.email,
  membershipType: session.membership_type,
  startedAt: moment(session.start).toISOString(),
  expiredAt: moment(session.end).toISOString(),
  sessionsLeft: session.sessions_left,
  futureBooking: session.user_future_schedules,
  futureMembership: toFutureMembership(session.has_future_membership),
  allowSMS: yesNoToBool(session.allow_sms),
  allowEmails: yesNoToBool(session.allow_mailing_list),
  location: session.location,
  locationId: session.locations_box_fk,
  task: session.reminder,
});

export type { ExpiringSession, ExpiringSessionDTO };
export { toExpiringSession };
