import * as locales from "@material-ui/core/locale";
import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Langs, LanguageConfigResponse } from "./services/languageService";
import "./scss/local-fonts.scss";

// EN

// Title
const fontSettingsDefaultPrimary: CSSProperties = {
  fontFamily: "Coolvetica,sans-serif",
  letterSpacing: "2px",
  fontWeight: 600,
};

// Sub-title
const fontSettingsDefaultSecondary: CSSProperties = {
  fontFamily: "Helvetica,sans-serif",
  fontWeight: 500,
};

// Content
const fontSettingsDefaultTertiary: CSSProperties = {
  fontFamily: "Helvetica,sans-serif",
  fontWeight: 500,
};

// HE
// Title
const fontSettingsHebrewPrimary: CSSProperties = {
  fontFamily: "Heebo,sans-serif",
  letterSpacing: "1px",
  fontWeight: 500,
};

// Sub-title
const fontSettingsHebrewSecondary: CSSProperties = {
  fontFamily: "Heebo,sans-serif",
  fontWeight: 500,
};

// Content
const fontSettingsHebrewTertiary: CSSProperties = {
  fontFamily: "Heebo,sans-serif",
  fontWeight: 500,
};

const localeConfig: {
  [index in Langs]: {
    muiLocale: locales.Localization;
    fontSettingsPrimary: CSSProperties;
    fontSettingsSecondary: CSSProperties;
    fontSettingsTertiary?: CSSProperties;
  };
} = {
  en: {
    muiLocale: locales.enUS,
    fontSettingsPrimary: fontSettingsDefaultPrimary,
    fontSettingsSecondary: fontSettingsDefaultSecondary,
    fontSettingsTertiary: fontSettingsDefaultTertiary,
  },
  tr: {
    muiLocale: locales.trTR,
    fontSettingsPrimary: fontSettingsDefaultPrimary,
    fontSettingsSecondary: fontSettingsDefaultSecondary,
  },
  he: {
    muiLocale: locales.heIL,
    fontSettingsPrimary: fontSettingsHebrewPrimary,
    fontSettingsSecondary: fontSettingsHebrewSecondary,
    fontSettingsTertiary: fontSettingsHebrewTertiary,
  },
  es: {
    muiLocale: locales.esES,
    fontSettingsPrimary: fontSettingsDefaultPrimary,
    fontSettingsSecondary: fontSettingsDefaultSecondary,
  },
  ar: {
    muiLocale: locales.enUS,
    fontSettingsPrimary: fontSettingsDefaultPrimary,
    fontSettingsSecondary: fontSettingsDefaultSecondary,
  },
};

const colors = {
  border: "#f0f0f0",
  midGray: "#989696",
  darkGray: "#585858",
  text: "#343434",
  main: "#0052a6",
  red: "#ff3345",
  yellow: "#fecf6b",
  white: "#ffffff",
  darkWhite: "#f6f6f6",
  lightGray: "#d3d3d3",
  purple: "#b620e0",
};

const createTheme = (languageConfig: LanguageConfigResponse | null): Theme => {
  const direction = languageConfig?.direction || "ltr";
  const currentLocaleConfig = localeConfig[languageConfig?.code || "en"];

  return createMuiTheme(
    {
      direction,
      shape: {
        borderRadius: 3,
      },
      typography: {
        // THIS IS USED AS "CONTENT"
        body1: {
          ...currentLocaleConfig.fontSettingsTertiary,
          direction,
          fontWeight: 100,
          fontSize: 14,
        },
        body2: {
          ...currentLocaleConfig.fontSettingsTertiary,
          direction,
          fontSize: 14,
        },
        h1: {
          ...currentLocaleConfig.fontSettingsPrimary,
          fontSize: 21,
          color: colors.text,
        },
        h2: {
          ...currentLocaleConfig.fontSettingsTertiary,
          fontSize: 18,
          color: colors.text,
        },
        h3: {
          ...currentLocaleConfig.fontSettingsTertiary,
          direction,
          fontSize: 14,
        },
        h4: {
          ...currentLocaleConfig.fontSettingsTertiary,
          direction,
          fontSize: 14,
          color: colors.midGray,
        },
        subtitle1: {
          ...currentLocaleConfig.fontSettingsSecondary,
          direction,
        },
        button: {
          ...currentLocaleConfig.fontSettingsTertiary,
          fontSize: 16,
        },
      },
      palette: {
        border: colors.border,
        primary: {
          main: colors.main,
        },
        error: {
          main: colors.red,
        },
        favorite: colors.yellow,
        secondary: {
          light: colors.white,
          main: colors.darkWhite,
          dark: colors.lightGray,
        },
        text: {
          primary: colors.text,
          secondary: colors.darkGray,
          hint: colors.purple,
        },
        success: {
          main: "#00d181",
        },
        background: {
          paper: "#fcfcfc",
        },
        divider: colors.midGray,
      },
      shadow: {
        flatBottom: "0 -2px 15px -5px rgba(0, 0, 0, 0.5)",
        main: "0 2px 15px -5px rgba(0, 0, 0, 0.5)",
        mobile: "0px 0px 10px -7px rgba(0, 0, 0, 0.5)",
        reportCard: "1px 1px 10px -5px rgba(0, 0, 0, 0.25)",
        popover: "0px 0px 4px 0px rgba(0, 0, 0, 0.5)",
        secondary: "0 0 5px rgba(0, 0, 0, 0.5)",
        selectMenu: "0 2px 20px -5px rgba(0, 0, 0, 0.5)",
        messagePopup: "0px 2px 20px -5px rgba(0,0,0,0.75)",
      },
      rtl: direction === "rtl",
    },
    currentLocaleConfig.muiLocale
  );
};

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    shadow: {
      main: React.CSSProperties["boxShadow"];
      secondary: React.CSSProperties["boxShadow"];
      popover: React.CSSProperties["boxShadow"];
      flatBottom: React.CSSProperties["boxShadow"];
      reportCard: React.CSSProperties["boxShadow"];
      mobile: React.CSSProperties["boxShadow"];
      selectMenu: React.CSSProperties["boxShadow"];
      messagePopup: React.CSSProperties["boxShadow"];
    };
    rtl: boolean;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends Theme {}
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    favorite: React.CSSProperties["color"];
    border: React.CSSProperties["color"];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions {
    favorite: React.CSSProperties["color"];
    border: React.CSSProperties["color"];
  }
}

export default createTheme;
