import { connectRouter, routerMiddleware, RouterRootState, RouterState } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { PersistConfig, PersistedState, persistReducer, persistStore } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import history from "../history";
import * as absences from "./absenceStore";
import * as activeEmployees from "./activeEmployeesStore";
import * as activeMemberships from "./activeMembershipStore";
import * as attendanceExternalMembers from "./attendanceExternalMembersStore";
import * as allBirthdays from "./birthdayStore";
import * as bookingCancellation from "./bookingCancellationStore";
import * as canceledMemberships from "./canceledMembershipsStore";
import * as classesSummary from "./classesSummaryStore";
import * as convertedLeads from "./convertedLeadsStore";
import * as debt from "./debtStore";
import * as employeeAttendance from "./employeeAttendancyStore";
import * as entrances from "./entranceStore";
import * as expiredMemberships from "./expiredMembershipsStore";
import * as allExpiredSessionPacks from "./expiredSessionPacksStore";
import * as expiringMemberships from "./expiringMembershipsStore";
import * as expiringSessions from "./expiringSessionsStore";
import * as externalMemberships from "./externalMembershipStore";
import * as favorites from "./favoritesStore";
import * as filters from "./filtersStore";
import * as filterValues from "./filterValuesStore";
import * as groupMembers from "./groupMembersStore";
import * as inactiveMembers from "./inactiveMembersStore";
import * as lateCancellations from "./lateCancellationsStore";
import * as allLeadsInProcess from "./leadsInProcessStore";
import * as leadsStatistics from "./leadsStatisticsStore";
import * as allLeads from "./leadsStore";
import * as locations from "./locationsStore";
import * as allLostLeads from "./lostLeadsStore";
import * as membershipForecast from "./membershipForecastStore";
import * as membersOnHold from "./membersOnHoldStore";
import * as membersProperties from "./membersPropertiesStore";
import * as members from "./membersStore";
import * as messages from "./messageStore";
import * as organizations from "./organizationsStore";
import * as preservation from "./preservationStore";
import * as regulars from "./regularsStore";
import * as renewals from "./renewalsStore";
import * as restrictedMembers from "./restrictedMembersStore";
import * as sales from "./salesStore";
import * as sessions from "./sessionsStore";
import * as settings from "./settingsStore";
import * as shiftSummary from "./shiftSummaryStore";
import * as sort from "./sortStore";
import * as staffActions from "./staffActionsStore";
import * as transaction from "./transactionStore";
import * as transparentMemberships from "./transparentMembership";
import * as transparentSessionPacks from "./transparentSessionPacksStore";
import * as trialClasses from "./trialClassesStore";

interface ApplicationState extends RouterRootState {
  absences: absences.State & PersistPartial;
  activeEmployees: activeEmployees.State;
  activeMemberships: activeMemberships.State & PersistPartial;
  attendanceExternalMembers: attendanceExternalMembers.State & PersistPartial;
  birthdays: allBirthdays.State & PersistPartial;
  bookingCancellations: bookingCancellation.State & PersistPartial;
  canceledMemberships: canceledMemberships.State & PersistPartial;
  classesSummary: classesSummary.State & PersistPartial;
  convertedLeads: convertedLeads.State & PersistPartial;
  debt: debt.State & PersistPartial;
  employeeAttendance: employeeAttendance.State & PersistPartial;
  entrances: entrances.State & PersistPartial;
  expiredMemberships: expiredMemberships.State & PersistPartial;
  expiredSessionPacks: allExpiredSessionPacks.State & PersistPartial;
  expiringMemberships: expiringMemberships.State & PersistPartial;
  expiringSessions: expiringSessions.State & PersistPartial;
  externalMemberships: externalMemberships.State & PersistPartial;
  favorites: favorites.State;
  filters: filters.State;
  filterValues: filterValues.State;
  groupMembers: groupMembers.State & PersistPartial;
  inactiveMembers: inactiveMembers.State & PersistPartial;
  lateCancellations: lateCancellations.State & PersistPartial;
  leads: allLeads.State & PersistPartial;
  leadsInProcess: allLeadsInProcess.State & PersistPartial;
  leadsStatistics: leadsStatistics.State & PersistPartial;
  locations: locations.State;
  lostLeads: allLostLeads.State & PersistPartial;
  members: members.State & PersistPartial;
  membershipForecast: membershipForecast.State & PersistPartial;
  membersOnHold: membersOnHold.State & PersistPartial;
  membersProperties: membersProperties.State & PersistPartial;
  messages: messages.State;
  organizations: organizations.State;
  preservation: preservation.State & PersistPartial;
  regulars: regulars.State & PersistPartial;
  renewals: renewals.State & PersistPartial;
  restrictedMembers: restrictedMembers.State & PersistPartial;
  router: RouterState;
  sales: sales.State & PersistPartial;
  sessions: sessions.State & PersistPartial;
  settings: settings.State;
  shiftSummary: shiftSummary.State & PersistPartial;
  sort: sort.State;
  staffActions: staffActions.State & PersistPartial;
  transaction: transaction.State & PersistPartial;
  transparentMemberships: transparentMemberships.State & PersistPartial;
  transparentSessionPacks: transparentSessionPacks.State & PersistPartial;
  trialClasses: trialClasses.State & PersistPartial;
}

const stateKeysToSync: (keyof ApplicationState)[] = ["filters", "settings", "sort"];

export const persistConfig: PersistConfig<ApplicationState, ApplicationState, PersistConfig<members.State>> = {
  key: "root",
  storage,
  whitelist: stateKeysToSync,
  migrate: state => {
    const currentUrl = window.location.pathname;
    const splitUrl = currentUrl.split("/");

    if (currentUrl.endsWith("/") && splitUrl.length === 4) {
      return Promise.resolve({} as PersistedState);
    }

    if (splitUrl.length === 3) {
      return Promise.resolve({} as PersistedState);
    }

    return Promise.resolve(state);
  },
};

const selectedMembersPersistConfig: PersistConfig<members.State> = {
  key: "selectedMembers",
  storage,
  whitelist: ["selected"],
};

const selectedActiveMemberships: PersistConfig<activeMemberships.State> = {
  key: "selectedActiveMemberships",
  storage,
  whitelist: ["selected"],
};

const selectedLeadsPersistConfig: PersistConfig<allLeads.State> = {
  key: "selectedLeads",
  storage,
  whitelist: ["selected"],
};

const selectedConvertedLeadsConfig: PersistConfig<convertedLeads.State> = {
  key: "selectedConvertedLeads",
  storage,
  whitelist: ["selected"],
};

const selectedExpiringMembershipsPersistConfig: PersistConfig<expiringMemberships.State> = {
  key: "selectedExpiringMemberships",
  storage,
  whitelist: ["selected"],
};

const selectedLeadsInProcessConfig: PersistConfig<allLeadsInProcess.State> = {
  key: "selectedLeadsInProcess",
  storage,
  whitelist: ["selected"],
};

const selectedExpiredMembershipsConfig: PersistConfig<expiredMemberships.State> = {
  key: "selectedExpiredMemberships",
  storage,
  whitelist: ["selected"],
};

const selectedBirthdaysConfig: PersistConfig<allBirthdays.State> = {
  key: "selectedBirthdays",
  storage,
  whitelist: ["selected"],
};

const selectedTrialClassesConfig: PersistConfig<trialClasses.State> = {
  key: "selectedTrialClasses",
  storage,
  whitelist: ["selected"],
};

const selectedLostLeadsConfig: PersistConfig<allLostLeads.State> = {
  key: "selectedLostLeads",
  storage,
  whitelist: ["selected"],
};

const selectedExpiredSessionPacks: PersistConfig<allExpiredSessionPacks.State> = {
  key: "selectedExpiredSessionPacks",
  storage,
  whitelist: ["selected"],
};

const selectedExpiringSessions: PersistConfig<expiringSessions.State> = {
  key: "selectedExpiringSessions",
  storage,
  whitelist: ["selected"],
};

const selectedRenewalsConfig: PersistConfig<renewals.State> = {
  key: "selectedRenewals",
  storage,
  whitelist: ["selected"],
};

const selectedSessions: PersistConfig<sessions.State> = {
  key: "selectedSessions",
  storage,
  whitelist: ["selected"],
};

const selectedDebts: PersistConfig<debt.State> = {
  key: "selectedDebts",
  storage,
  whitelist: ["selected"],
};

const selectedInactiveMembers: PersistConfig<inactiveMembers.State> = {
  key: "selectedInactiveMembers",
  storage,
  whitelist: ["selected"],
};

const selectedSales: PersistConfig<sales.State> = {
  key: "selectedSales",
  storage,
  whitelist: ["selected"],
};

const selectedGroupMember: PersistConfig<groupMembers.State> = {
  key: "selectedGroupMember",
  storage,
  whitelist: ["selected"],
};
const selectedCanceledMemberships: PersistConfig<canceledMemberships.State> = {
  key: "selectedCanceledMemberships",
  storage,
  whitelist: ["selected"],
};

const selectedMembersOnHold: PersistConfig<membersOnHold.State> = {
  key: "selectedMembersOnHold",
  storage,
  whitelist: ["selected"],
};

const selectedAttendance: PersistConfig<employeeAttendance.State> = {
  key: "selectedEmployeeAttendance",
  storage,
  whitelist: ["selected"],
};

const selectedMembersProperties: PersistConfig<membersProperties.State> = {
  key: "selectedMembersProperties",
  storage,
  whitelist: ["selectedMembersProperties"],
};

const selectedEntrances: PersistConfig<entrances.State> = {
  key: "selectedEntrances",
  storage,
  whitelist: ["selected"],
};

const selectedTransactions: PersistConfig<transaction.State> = {
  key: "selectedTransaction",
  storage,
  whitelist: ["selected"],
};

const selectedShiftSummary: PersistConfig<shiftSummary.State> = {
  key: "selectedShiftSummary",
  storage,
  whitelist: ["selected"],
};

const selectedRegulars: PersistConfig<regulars.State> = {
  key: "selectedRegulars",
  storage,
  whitelist: ["selected"],
};

const selectedRestrictedMembers: PersistConfig<restrictedMembers.State> = {
  key: "selectedRestrictedMembers",
  storage,
  whitelist: ["selected"],
};

const selectedBookingCancellation: PersistConfig<bookingCancellation.State> = {
  key: "bookingCancellations",
  storage,
  whitelist: ["selected"],
};

const selectedTransparentSessionPacks: PersistConfig<transparentSessionPacks.State> = {
  key: "transparentSessionPacks",
  storage,
  whitelist: ["selected"],
};

const selectedStaffActions: PersistConfig<staffActions.State> = {
  key: "selectedStaffActions",
  storage,
  whitelist: ["selected"],
};

const selectedExternalMemberships: PersistConfig<externalMemberships.State> = {
  key: "externalMemberships",
  storage,
  whitelist: ["selected"],
};

const selectedLateCancellations: PersistConfig<lateCancellations.State> = {
  key: "selectedLateCancellations",
  storage,
  whitelist: ["selected"],
};

const selectedAbsences: PersistConfig<absences.State> = {
  key: "selectedAbsences",
  storage,
  whitelist: ["selected"],
};

const selectedTransparentMemberships: PersistConfig<transparentMemberships.State> = {
  key: "selectedTransparentMemberships",
  storage,
  whitelist: ["selected"],
};

const selectedAttendanceExternalMembers: PersistConfig<attendanceExternalMembers.State> = {
  key: "selectedAttendanceExternalMembers",
  storage,
  whitelist: ["selected"],
};

const selectedMembershipForecasts: PersistConfig<membershipForecast.State> = {
  key: "selectedMembershipForecasts",
  storage,
  whitelist: ["selected"],
};

const selectedPreservation: PersistConfig<preservation.State> = {
  key: "selectedPreservation",
  storage,
  whitelist: ["selected"],
};
const selectedLeadsStatistics: PersistConfig<leadsStatistics.State> = {
  key: "selectedLeadsStatistics",
  storage,
  whitelist: ["selected"],
};
const selectedClassesSummary: PersistConfig<classesSummary.State> = {
  key: "selectedClassesSummary",
  storage,
  whitelist: ["selected"],
};

const rootReducer = combineReducers<ApplicationState>({
  absences: persistReducer(selectedAbsences, absences.reducer),
  activeEmployees: activeEmployees.reducer,
  activeMemberships: persistReducer(selectedActiveMemberships, activeMemberships.reducer),
  attendanceExternalMembers: persistReducer(selectedAttendanceExternalMembers, attendanceExternalMembers.reducer),
  birthdays: persistReducer(selectedBirthdaysConfig, allBirthdays.reducer),
  bookingCancellations: persistReducer(selectedBookingCancellation, bookingCancellation.reducer),
  canceledMemberships: persistReducer(selectedCanceledMemberships, canceledMemberships.reducer),
  classesSummary: persistReducer(selectedClassesSummary, classesSummary.reducer),
  convertedLeads: persistReducer(selectedConvertedLeadsConfig, convertedLeads.reducer),
  debt: persistReducer(selectedDebts, debt.reducer),
  employeeAttendance: persistReducer(selectedAttendance, employeeAttendance.reducer),
  entrances: persistReducer(selectedEntrances, entrances.reducer),
  expiredMemberships: persistReducer(selectedExpiredMembershipsConfig, expiredMemberships.reducer),
  expiredSessionPacks: persistReducer(selectedExpiredSessionPacks, allExpiredSessionPacks.reducer),
  expiringMemberships: persistReducer(selectedExpiringMembershipsPersistConfig, expiringMemberships.reducer),
  expiringSessions: persistReducer(selectedExpiringSessions, expiringSessions.reducer),
  externalMemberships: persistReducer(selectedExternalMemberships, externalMemberships.reducer),
  favorites: favorites.reducer,
  filters: filters.reducer,
  filterValues: filterValues.reducer,
  groupMembers: persistReducer(selectedGroupMember, groupMembers.reducer),
  inactiveMembers: persistReducer(selectedInactiveMembers, inactiveMembers.reducer),
  lateCancellations: persistReducer(selectedLateCancellations, lateCancellations.reducer),
  leads: persistReducer(selectedLeadsPersistConfig, allLeads.reducer),
  leadsInProcess: persistReducer(selectedLeadsInProcessConfig, allLeadsInProcess.reducer),
  leadsStatistics: persistReducer(selectedLeadsStatistics, leadsStatistics.reducer),
  locations: locations.reducer,
  lostLeads: persistReducer(selectedLostLeadsConfig, allLostLeads.reducer),
  members: persistReducer(selectedMembersPersistConfig, members.reducer),
  membershipForecast: persistReducer(selectedMembershipForecasts, membershipForecast.reducer),
  membersOnHold: persistReducer(selectedMembersOnHold, membersOnHold.reducer),
  membersProperties: persistReducer(selectedMembersProperties, membersProperties.reducer),
  messages: messages.reducer,
  organizations: organizations.reducer,
  preservation: persistReducer(selectedPreservation, preservation.reducer),
  regulars: persistReducer(selectedRegulars, regulars.reducer),
  renewals: persistReducer(selectedRenewalsConfig, renewals.reducer),
  restrictedMembers: persistReducer(selectedRestrictedMembers, restrictedMembers.reducer),
  router: connectRouter(history),
  sales: persistReducer(selectedSales, sales.reducer),
  sessions: persistReducer(selectedSessions, sessions.reducer),
  settings: settings.reducer,
  shiftSummary: persistReducer(selectedShiftSummary, shiftSummary.reducer),
  sort: sort.reducer,
  staffActions: persistReducer(selectedStaffActions, staffActions.reducer),
  transaction: persistReducer(selectedTransactions, transaction.reducer),
  transparentMemberships: persistReducer(selectedTransparentMemberships, transparentMemberships.reducer),
  transparentSessionPacks: persistReducer(selectedTransparentSessionPacks, transparentSessionPacks.reducer),
  trialClasses: persistReducer(selectedTrialClassesConfig, trialClasses.reducer),
});

const middlewares = [thunk, routerMiddleware(history)];

const composeEnhancers =
  typeof window === "object" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistedStore = persistStore(store);

export { store, persistedStore };
export type { ApplicationState };
