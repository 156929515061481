import _ from "lodash";
import { BaseReportEntity } from "../models/base";
import { SMSTypeDTO, SMSType, SMSProviderType } from "../models/message";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getSmsDetails = async (): Promise<SMSType | null> => {
  const boxId = queryParamsService.getBoxId();
  const client = getBaseClient();

  if (!client) {
    return null;
  }

  const result = await client.get<SMSTypeDTO>(`/box/model/${boxId}`);

  if (!result) {
    return null;
  }

  return {
    smsBank: result.data.sms_bank,
    smsProvider: result.data.sms_provider_id,
  };
};

const getProviderDetails = async (providerId: number): Promise<SMSProviderType[] | null> => {
  const client = getBaseClient();

  if (!client) {
    return null;
  }

  const result = await client.get<SMSProviderType[]>(`/getSmsProviderDetailsById/${providerId}`);

  if (!result) {
    return null;
  }

  return result.data;
};

const sendMessage = async (
  type: "push" | "sms",
  charCount: number,
  isLeads: boolean,
  text: string,
  selectedEntities: BaseReportEntity[],
  rawEntities: any[],
  reportEntityIds: string
): Promise<unknown> => {
  const client = getBaseClient();
  let message_array: any[];
  let users: any[];

  // TODO: Make more generic
  const entitiesId = selectedEntities.map(en => en.userId);
  const filteredMembers = rawEntities.filter(member => entitiesId.includes(member[reportEntityIds]));

  if (!client || !filteredMembers.length) {
    return;
  }

  const dynamicKeysRegEx = /\{{(.*?)\}}/g;

  const results = Array.from(text.matchAll(dynamicKeysRegEx));

  const tokens = results.map(res => res);

  if (!tokens.length) {
    message_array = [{ text }];

    users = filteredMembers.map(entity => ({
      ...entity,
      message: [{ text }],
    }));
  } else {
    message_array = tokens.reduce((acc, token, index, collection) => {
      const tokenIndex = text.indexOf(token[0]);

      const lastIndex = index > 0 ? text.indexOf(collection[index - 1][0]) + collection[index - 1][0].length : 0;

      tokenIndex > 0 && acc.push({ text: text.slice(lastIndex, tokenIndex) });

      acc.push({
        key: token[1],
        keyDB: _.snakeCase(token[1]),
      });

      if (collection.length === index + 1 && tokenIndex + token[0].length < collection.length) {
        acc.push(text.slice(tokenIndex + token[0].length, text.length));
      }

      return acc;
    }, [] as any[]);

    users = filteredMembers.map(entity => {
      const staticKeys = message_array.map(key => {
        if (key.keyDB) {
          const keyDB = key.keyDB as any;
          return { staticKey: entity[keyDB] };
        }

        return key;
      });

      const message = staticKeys;
      return {
        ...entity,
        message,
      };
    });
  }

  const bodyPostRequest = {
    class_name: "ReportMessage",
    type,
    estimated_unit_count: charCount,
    is_leads: isLeads,
    message_array,
    users,
  };


  const sendMessage = await client.post("messagesCenter/sendMessage", bodyPostRequest);

  if (sendMessage.status !== 200) {
    throw new Error(sendMessage.statusText);
  }

  return sendMessage.data;
};

const messageService = {
  getSmsDetails,
  getProviderDetails,
  sendMessage,
};

export default messageService;
