const getIntInRange = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// eslint-disable-next-line
const pipe = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
  fns.reduce((prevFn, nextFn) => value => nextFn(prevFn(value)), fn1);

const printContent = (content: string): void => {
  const iframe = document.createElement("iframe");

  iframe.name = "frame1";
  iframe.style.width = "0";
  iframe.style.height = "0";
  document.body.appendChild(iframe);

  const frameDoc = iframe.contentWindow;

  if (!frameDoc) {
    return;
  }

  frameDoc.document.open();

  frameDoc.document.write(`va
    <html>
      <head>
        <style>
          @page {
            size: landscape;
          }
        </style>
      </head>
      <body dir=${document.body.dir}>
        <pre>${content}</pre>
      </body>
    </html>
  `);

  frameDoc.document.close();

  iframe.contentWindow?.focus();
  iframe.contentWindow?.print();
};

const isInIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
};

const isMobile = (): boolean => "ontouchstart" in window || !!navigator.msMaxTouchPoints;

const titleize = (input: string): string =>
  input
    .split(" ")
    .map(word => (word.length < 3 ? word : word.charAt(0).toUpperCase().concat(word.slice(1, word.length))))
    .join(" ");

const countOccurrences = (arr: any[], val: any): number => arr.reduce((a, v) => (v === val ? ++a : a), 0);

export { getIntInRange, isInIframe, isMobile, pipe, printContent, titleize, countOccurrences };
