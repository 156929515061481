import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { Phrases } from "../infrastructure/translations/phrases";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { Gender, getTaskLabel, Task, yesNoToBool } from "./misc";

interface MemberDTO extends MemberBaseDTO {
  active: number;
  additional_phone: string | null;
  address: string | null;
  age: string;
  birthday: string;
  child: number;
  city: string | null;
  country: string | null;
  date_waiver: null;
  employee_id: number | null;
  epidemic_statement: number;
  full_path_image_user: string;
  has_basics_workshop: number;
  has_changed_property: number;
  has_insurance: number;
  has_nutrition_counseling: number;
  has_professional_meeting: number;
  has_waiver: number;
  height: string;
  image_public_id_cloudinary: null;
  medical_cert: 0;
  personal_id: number | null;
  restricted: number;
  restricted_time: string | null;
  rfid: null;
  state: null;
  suspended: number;
  union_fk: number | null;
  union_name: string | null;
  weight: string;
  zip: string | null;
  task: string | null;
}

interface Member extends ReportEntity {
  age: number | null;
  email: string;
  userId: number;
  gender: Gender;
  organization: string | null;
  organizationId: number;
}

const toMember = (memberDto: MemberDTO): Member => ({
  id: memberDto.user_fk.toString(),
  userId: memberDto.user_fk,
  age: memberDto.age ? Math.floor(+memberDto.age) : null,
  allowEmails: yesNoToBool(memberDto.allow_mailing_list),
  allowSMS: yesNoToBool(memberDto.allow_sms),
  createdAt: moment(memberDto.created_at).toISOString(),
  email: memberDto.email,
  firstName: memberDto.first_name,
  fullName: `${memberDto.first_name} ${memberDto.last_name}`,
  gender: memberDto.gender,
  lastName: memberDto.last_name,
  location: memberDto.location,
  locationId: memberDto.locations_box_fk,
  organization: memberDto.union_name || "",
  organizationId: memberDto.union_fk || -1,
  phone: memberDto.phone,
  task: memberDto.reminder ? t(memberDto.reminder as Phrases) : t("taskNone"),
  taskLabel: getTaskLabel(memberDto.reminder),
});

export type { Gender, MemberDTO, Member, Task };
export { toMember };
