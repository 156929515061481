import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { CanceledMemberships, toCanceledMemberships } from "../models/canceledMemberships";
import canceledMembershipsService from "../services/canceledMembershipsService";
import { ApplicationState } from "./store";

interface State {
  all: CanceledMemberships[] | null;
  selected: CanceledMemberships[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TCanceledPayload {
  all: CanceledMemberships[] | null;
}

interface TSetCanceledPayload {
  selected: CanceledMemberships[] | null;
}

const fetchCanceledMemberships = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchCanceledMemberships.start());

    try {
      const response = await canceledMembershipsService.getCanceledMemberships(from, to);
      dispatch(fetchCanceledMemberships.success({ all: response.map(toCanceledMemberships) }));
    } catch {
      dispatch(fetchCanceledMemberships.failure());
    }
  },
  {
    start: createActionCreator("@@CANCELED_MEMBERSHIPS/START_FETCH"),
    success: createActionCreator("@@CANCELED_MEMBERSHIPS/FETCH_SUCCESS", resolve => (payload: TCanceledPayload) =>
      resolve(payload.all)
    ),
    failure: createActionCreator("@@CANCELED_MEMBERSHIPS/FETCH_FAILURE"),
  }
);

const setSelectedMemberships = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { canceledMemberships } = getState();

    if (!canceledMemberships.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedMemberships.success({ selected: null }));
      return;
    }

    const selected = canceledMemberships.all.filter(canceledMembership => selectedId.includes(canceledMembership.id));

    dispatch(setSelectedMemberships.success({ selected: selected }));
  },
  {
    success: createActionCreator("@@REPORTS/SELECTED_SUCCESS", resolve => (payload: TSetCanceledPayload) =>
      resolve(payload.selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { canceledMemberships } = getState();

    if (!canceledMemberships.all) {
      return;
    }

    const selected = canceledMemberships.all.find(canceledMembership => canceledMembership.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SINGLE_CANCELED_MEMBERSHIPS/SUCCESS",
      resolve => (payload: CanceledMemberships) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_CANCELED_MEMBERSHIPS/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { canceledMemberships } = getState();

    if (!canceledMemberships.all) {
      return;
    }

    const selected = canceledMemberships.all.find(canceledMembership => canceledMembership.id === selectedId);

    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/REMOVE_SINGLE_CANCELED_MEMBERSHIPS/SUCCESS",
      resolve => (payload: CanceledMemberships) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SINGLE_CANCELED_MEMBERSHIPS/ERROR"),
  }
);

const actions = {
  fetchCanceledMemberships,
  setSelectedMemberships,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchCanceledMemberships.start, state => ({ ...state, loading: true })),
  handleAction(fetchCanceledMemberships.success, (state, action) => ({
    ...state,
    loading: false,
    all: action.payload,
  })),
  handleAction(fetchCanceledMemberships.failure, state => ({ ...state, loading: false })),
  handleAction(setSelectedMemberships.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };

export type { State };
