import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { BookingCancellation, toBookingCancellation } from "../models/bookingCancellation";
import bookingCancellationServices from "../services/bookingCancellationService";
import { ApplicationState } from "./store";

interface State {
  all: BookingCancellation[] | null;
  selected: BookingCancellation[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TPayload {
  all: BookingCancellation[];
}

const fetchBookingCancellations = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchBookingCancellations.start());

    try {
      const response = await bookingCancellationServices.getBookingCancellations(from, to);
      dispatch(fetchBookingCancellations.success({ all: response.map(toBookingCancellation) }));
    } catch {
      dispatch(fetchBookingCancellations.error());
    }
  },
  {
    start: createActionCreator("@@BOOKING_CANCELLATION/START"),
    error: createActionCreator("@@BOOKING_CANCELLATION/ERROR"),
    success: createActionCreator("@@BOOKING_CANCELLATION", resolve => (payload: TPayload) => resolve(payload)),
  }
);

const setSelectedBookingCancellations = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { bookingCancellations } = getState();

    if (!bookingCancellations.all) {
      return dispatch(setSelectedBookingCancellations.error());
    }

    if (!selectedId) {
      return dispatch(setSelectedBookingCancellations.success(null));
    }

    const selected = bookingCancellations.all.filter(b => selectedId.includes(b.id));

    dispatch(setSelectedBookingCancellations.success(selected));
  },
  {
    error: createActionCreator("@@SELECTED_BOOKING_CANCELLATION/ERROR"),
    success: createActionCreator(
      "@@SELECTED_BOOKING_CANCELLATION/SUCCESS",
      resolve => (selected: BookingCancellation[] | null) => resolve(selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { bookingCancellations } = getState();

    if (!bookingCancellations.all || !selectedId) {
      return dispatch(setSingleSelected.error());
    }

    const selected = bookingCancellations.all.find(b => b.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    error: createActionCreator("@@SINGLE/BOOKING_CANCELLATION/ERROR"),
    success: createActionCreator("@@SINGLE/BOOKING_CANCELLATION/SUCCESS", resolve => (b: BookingCancellation) =>
      resolve(b)
    ),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { bookingCancellations } = getState();

    if (!bookingCancellations.all || !selectedId) {
      return dispatch(removeSingleSelected.error());
    }

    const removed = bookingCancellations.all.find(b => b.id === selectedId);

    if (!removed) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(removed));
  },
  {
    error: createActionCreator("@@REMOVE/BOOKING_CANCELLATION/ERROR"),
    success: createActionCreator("@@REMOVE/BOOKING_CANCELLATION/SUCCESS", resolve => (b: BookingCancellation) =>
      resolve(b)
    ),
  }
);

const actions = {
  fetchBookingCancellations,
  setSelectedBookingCancellations,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchBookingCancellations.start, state => ({ ...state, loading: true })),
  handleAction(fetchBookingCancellations.error, state => ({ ...state, loading: false })),
  handleAction(fetchBookingCancellations.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedBookingCancellations.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(b => b.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };

export type { State };
