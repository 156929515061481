import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull, NumberOrNull } from "./misc";

interface CanceledMembershipsDTO extends MemberBaseDTO {
  department_name: StringOrNull; //department
  membership_type_name: StringOrNull; //membership
  start: StringOrNull; //start
  end: StringOrNull; //end
  updated_at: Date; //cancel date
  cancel_reason_name: StringOrNull; //cancel reason
  amount: NumberOrNull; //refund
  comment: StringOrNull; //cancel comment
  created_by_user: StringOrNull; //done by
  sales_man: StringOrNull; //sales person
}

interface CanceledMemberships extends ReportEntity {
  email: StringOrNull;
  department: StringOrNull;
  membership: StringOrNull;
  userId: number;
  startedAt: StringOrNull;
  endedAt: StringOrNull;
  cancelReason: StringOrNull;
  cancelComment: StringOrNull;
  doneBy: StringOrNull;
  salesPerson: StringOrNull;
  cancelledAtDate: StringOrNull;
  refund: NumberOrNull;
}

const toCanceledMemberships = (canceledMembership: CanceledMembershipsDTO): CanceledMemberships => ({
  id: canceledMembership.id.toString(),
  firstName: canceledMembership.first_name,
  userId: canceledMembership.user_fk,
  lastName: canceledMembership.last_name,
  fullName: `${canceledMembership.first_name} ${canceledMembership.last_name}`,
  phone: canceledMembership.phone,
  email: canceledMembership.email,
  department: canceledMembership.department_name,
  membership: canceledMembership.membership_type_name,
  startedAt: moment(canceledMembership.start).toISOString(),
  endedAt: moment(canceledMembership.end).toISOString(),
  cancelReason: canceledMembership.cancel_reason_name,
  cancelComment: canceledMembership.comment,
  doneBy: canceledMembership.created_by_user,
  salesPerson: canceledMembership.sales_man,
  cancelledAtDate: moment(canceledMembership.updated_at).toISOString(),
  refund: canceledMembership.amount,
  location: canceledMembership.location,
});

export { toCanceledMemberships };

export type { CanceledMembershipsDTO, CanceledMemberships };
