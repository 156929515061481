import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { MembersOnHold, toMemberOnHold } from "../models/membersOnHold";
import membersOnHoldService from "../services/membersOnHoldService";
import { ApplicationState } from "./store";

type State = {
  all: MembersOnHold[] | null;
  selected: MembersOnHold[] | null;
  loading: boolean;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

type TPayload = {
  all: MembersOnHold[] | null;
};

const fetchMembersOnHold = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchMembersOnHold.start());

    try {
      const data = await membersOnHoldService.getMembersOnHold(from, to);
      dispatch(fetchMembersOnHold.success({ all: data.map(toMemberOnHold) }));
    } catch {
      dispatch(fetchMembersOnHold.error());
    }
  },
  {
    start: createActionCreator("@@MEMBERS_ON_HOLD/START"),
    error: createActionCreator("@@MEMBERS_ON_HOLD/ERROR"),
    success: createActionCreator("@@MEMBERS_ON_HOLD/SUCCESS", resolve => (members: TPayload) => resolve(members)),
  }
);

const setSelectedMembers = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersOnHold } = getState();

    if (!membersOnHold.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedMembers.success(null));
      return;
    }

    const selected = membersOnHold.all.filter(member => selectedId.includes(member.id));
    dispatch(setSelectedMembers.success(selected));
  },
  {
    success: createActionCreator("@@SELECT/MEMBERS_ON_HOLD/SUCCESS", resolve => (members: MembersOnHold[] | null) =>
      resolve(members)
    ),
  }
);

const setSingle = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersOnHold } = getState();

    if (!membersOnHold.all) {
      return;
    }

    const selected = membersOnHold.all.find(m => m.id === selectedId);
    if (!selected) {
      return dispatch(setSingle.error());
    }

    dispatch(setSingle.success(selected));
  },
  {
    success: createActionCreator("@@SINGLE/MEMBERS_ON_HOLD/SUCCESS", resolve => (member: MembersOnHold) =>
      resolve(member)
    ),
    error: createActionCreator("@@SINGLE/MEMBERS_ON_HOLD_ERROR"),
  }
);

const removeSingle = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersOnHold } = getState();

    if (!membersOnHold.all) {
      return;
    }

    const selected = membersOnHold.all.find(m => m.id === selectedId);
    if (!selected) {
      return dispatch(removeSingle.error());
    }

    dispatch(removeSingle.success(selected));
  },
  {
    success: createActionCreator("@@REMOVE/MEMBERS_ON_HOLD/SUCCESS", resolve => (member: MembersOnHold) =>
      resolve(member)
    ),
    error: createActionCreator("@@REMOVE/MEMBERS_ON_HOLD_ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchMembersOnHold.start, state => ({ ...state, loading: true })),
  handleAction(fetchMembersOnHold.error, state => ({ ...state, loading: false })),
  handleAction(fetchMembersOnHold.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedMembers.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingle.error, state => ({ ...state })),
  handleAction(removeSingle.error, state => ({ ...state })),
  handleAction(setSingle.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingle.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(m => m.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchMembersOnHold,
  setSingle,
  removeSingle,
  setSelectedMembers,
};

export { actions, reducer };
export type { State };
