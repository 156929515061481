import { Avatar } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as CalendarWithCheckIcon } from "../../icons/calendar-check.svg";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Warmup } from "../../icons/warmup.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { AttendanceExternalMember } from "../../models/attendanceExternalMembers";
import attendanceExternalMembersService from "../../services/attendanceExternalMembersService";
import csvService from "../../services/csvService";
import { actions as attendanceExternalMembersActions } from "../../store/attendanceExternalMembersStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { attendanceExternalMembersSelector, selectedAttendanceExternalMembersSelector } from "../reportSelector";
import { Page } from "./page";

const AttendanceExternalMembersReport = (dateFormat: string, timeFormat: string): Page<AttendanceExternalMember> => ({
  id: "attendance-external-members-report",
  title: t("externalAttendanceReport"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),

  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  rawUserId: "user_fk",
  searchTerms: ["fullName"],
  description: t("attendanceExternalMembersDesc"),
  defaultSort: {
    order: "desc",
    orderBy: "classDate",
  },
  defaultFilters: [
    {
      key: "bookingTime",
      value: moment().startOf("month").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "bookingTime",
      value: moment().endOf("month").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      toLocaleString: false,
      label: t("bookings"),
    },
    {
      key: "bookingStatus",
      aggregator: "positiveCountPercent",
      toLocaleString: false,
      specificValue: "checkedIn",
      label: t("checkinRate"),
    },
    {
      key: "bookingStatus",
      aggregator: "positiveCountPercent",
      toLocaleString: false,
      specificValue: "cancelled",
      label: t("cancellations"),
    },
  ],
  entitiesSelector: attendanceExternalMembersSelector,
  selectedEntitiesSelector: selectedAttendanceExternalMembersSelector,
  setSelectedEntities: attendanceExternalMembersActions.setSelectedAttendanceExternalMembers,
  fetchOnlyData: attendanceExternalMembersActions.fetchAttendanceExternalMembers,
  setSingleSelected: attendanceExternalMembersActions.setSingleSelected,
  removeSingleSelected: attendanceExternalMembersActions.removeSingleSelected,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  isLead: false,
  csvGenerator: entities => csvService.getAttendanceExternalMembers(entities, dateFormat, timeFormat),
  isDataLoading: state => state.attendanceExternalMembers.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: entity => entity.fullName,
      width: 9,
    },
    {
      id: "bookingTime",
      label: t("registeredDate"),
      valueRenderer: entity => moment(entity.bookingTime).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 9,
    },
    {
      id: "class",
      label: t("class"),
      valueRenderer: entity => entity.class,
      width: 9,
    },
    {
      id: "classDate",
      label: t("scheduleDate"),
      valueRenderer: entity => moment(entity.bookingTime).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["all", "thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 9,
    },
    {
      id: "classDay",
      label: t("scheduleDay"),
      valueRenderer: entity => t(entity.classDay as Phrases),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 9,
      filterOptionsSelector: state => {
        const data = (state.attendanceExternalMembers.all || []).map(a => ({
          id: a.classDay,
          label: a.classDay,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: t(item.label as Phrases) }));
      },
    },
    {
      id: "classTime",
      label: t("scheduleTime"),
      filterType: "timeSlider",
      valueRenderer: entity =>
        entity.classTime ? moment().startOf("day").add(entity.classTime, "hours").format(timeFormat) : null,
      width: 9,
    },
    {
      id: "coachName",
      label: t("coach"),
      valueRenderer: entity => t(entity.coachName as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 9,
      filterOptionsSelector: state => {
        const data = (state.attendanceExternalMembers.all || []).map(a => ({
          id: a.coachName,
          label: a.coachName,
          icon: <Avatar>{a.coachName[0]}</Avatar>,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: t(item.label as Phrases), icon: item.icon }));
      },
    },
    {
      id: "membershipTypeName",
      label: t("externalMembership"),
      valueRenderer: entity => entity.membershipTypeName,
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 9,
      filterOptionsSelector: state => {
        const data = (state.attendanceExternalMembers.all || []).map(a => ({
          id: a.membershipTypeName,
          label: a.membershipTypeName,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: item.label }));
      },
    },
    {
      id: "bookingStatus",
      label: t("bookingStatus"),
      valueRenderer: entity => t(entity.bookingStatus as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 9,
      filterOptionsSelector: state => {
        const data = (state.attendanceExternalMembers.all || []).map(a => ({
          id: a.bookingStatus,
          label: a.bookingStatus,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: t(item.label as Phrases) }));
      },
    },
    // {
    //   id: "cancelledBy",
    //   label: t("cancelledBy"),
    //   valueRenderer: entity => t(entity.cancelledBy as Phrases),
    //   filterType: "searchable",
    //   newPage: true,
    //   showFilterChip: true,
    //   width: 9,
    //   filterOptionsSelector: state => {
    //     const data = (state.attendanceExternalMembers.all || []).map(a => ({
    //       id: a.cancelledBy,
    //       label: a.cancelledBy,
    //       icon: <Avatar>{a.cancelledBy![0]}</Avatar>,
    //     }));
    //     const uniqueData = _.uniqBy(data, "id");
    //     return uniqueData.map(item => ({ id: item.id!.toString(), label: t(item.label as Phrases), icon: item.icon }));
    //   },
    // },
    {
      id: "updatedAt",
      label: t("updateDate"),
      valueRenderer: entity => moment(entity.updatedAt).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 9,
    },
  ],
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Warmup />,
        textRender: item => item.class,
      },
      {
        icon: () => <Clock />,
        textRender: item =>
          `${moment(item.classDate).format(`${"dddd"}, ${dateFormat}`)} ${moment(item.classTime).format(timeFormat)}`,
      },
      {
        icon: () => <CalendarWithCheckIcon />,
        textRender: item => t("bookingStatusForClass", { bookingStatus: item.bookingStatus }),
      },
    ],
  },
  fetchRawPostData: attendanceExternalMembersService.getAttendanceExternalMembersService,
});

export default AttendanceExternalMembersReport;
