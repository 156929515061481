import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as ConvertedAt } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Sources } from "../../icons/sources.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ConvertedLead } from "../../models/convertedLeads";
import csvService from "../../services/csvService";
import leadsService from "../../services/leadsService";
import { actions as activeEmployeeActions } from "../../store/activeEmployeesStore";
import { actions as convertedLeadsActions } from "../../store/convertedLeadsStore";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { convertedLeadsSelector, selectedConvertedLeadsSelector } from "../reportSelector";
import { Page } from "./page";

const convertedLeadsReport = (dateFormat: string): Page<ConvertedLead> => ({
  id: "converted-leads-report",
  entityClick: entity => wrapperActions.openLeadDetails({ leadId: entity.userId.toString() }),
  title: t("leadConvertedReport"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipType",
      label: t("membership"),
    },
  ],
  rawUserId: "user_fk",
  searchTerms: ["fullName", "phone"],
  description: t("convertedLeadsDescription"),
  defaultSort: {
    order: "desc",
    orderBy: "convertedAt",
  },
  defaultFilters: [
    {
      key: "createdAt",
      value: moment().startOf("month").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "createdAt",
      value: moment().endOf("month").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      toLocaleString: false,
      key: "id",
      aggregator: "count",
      label: t("leads"),
    },
    {
      key: "source",
      aggregator: "mostUsedPercentage",
      toLocaleString: false,
      label: t("source"),
      formatLabel: false,
    },
  ],
  entitiesSelector: convertedLeadsSelector,
  selectedEntitiesSelector: selectedConvertedLeadsSelector,
  setSelectedEntities: convertedLeadsActions.setSelectedConvertedLeads,
  fetchOnlyData: convertedLeadsActions.fetchConvertedLeads,
  setSingleSelected: convertedLeadsActions.setSingleSelected,
  removeSingleSelected: convertedLeadsActions.removeSingleSelected,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
    activeEmployeeActions.fetchActiveEmployes,
  ],
  isLead: false,
  csvGenerator: entities => csvService.getConvertedLeads(entities, dateFormat),
  isDataLoading: state => state.convertedLeads.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: lead => lead.fullName,
      width: 20,
    },
    {
      id: "convertedAt",
      label: t("convertedAt"),
      valueRenderer: lead => moment(lead.createdAt).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "membershipType",
      label: t("membershipType"),
      valueRenderer: lead => lead.membershipType,
      showFilterChip: true,
      newPage: true,
      width: 15,
    },
    {
      id: "doneByUserId",
      label: t("convertedBy"),
      valueRenderer: lead => lead.doneByUser,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const data = (state.activeEmployees.all || []).map(a => ({
          id: a.id,
          label: a.fullName,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id!.toString(), label: t(item.label as Phrases) }));
      },
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      valueRenderer: lead => moment(lead.createdAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    // TODO: Fix source id once added
    {
      id: "source",
      label: t("source"),
      valueRenderer: lead => t(lead.source as Phrases),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const sources = [
          ...(state.filterValues.uniqueFilterValues?.sources || []),
          { id: "noSource", name: t("noSource") },
        ];
        return sources.map(source => ({
          id: source.id.toString(),
          label: source.name,
        }));
      },
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: leadInProcess => leadInProcess.phone,
      width: 20,
    },
  ],
  card: {
    titleRender: (item: ConvertedLead) => item.fullName,
    rows: [
      {
        icon: () => <ConvertedAt />,
        textRender: (item: ConvertedLead) =>
          t("convertedAtDate", { convertedDate: moment(item.convertedAt).format(dateFormat) }),
      },
      {
        icon: () => <Sources />,
        textRender: (item: ConvertedLead) =>
          item.source !== "noSource" ? t("cameFrom", { source: item.source }) : t(item.source as Phrases),
      },
    ],
  },
  fetchRawPostData: leadsService.getConvertedLeads,
});

export default convertedLeadsReport;
