import { Avatar } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as MembershipCard } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Renewal } from "../../models/renewals";
import csvService from "../../services/csvService";
import renewalsService from "../../services/renewalsService";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as renewalsActions } from "../../store/renewalsStore";
import { renewalsSelector, selectedRenewalsSelector } from "../reportSelector";
import { Page } from "./page";

const RenewalsReport = (dateFormat: string): Page<Renewal> => ({
  id: "renewals-report",
  title: t("membershipRenew"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  searchTerms: ["fullName", "phone"],
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipType",
      label: t("membership"),
    },
  ],
  rawUserId: "user_fk",
  description: t("renewalsDescription"),
  defaultFilters: [
    {
      key: "purchaseDate",
      value: moment().startOf("year").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "purchaseDate",
      value: moment().endOf("year").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "purchaseDate",
  },
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("plan"),
    },
    {
      key: "membershipType",
      toLocaleString: false,
      aggregator: "mostUsed",
      label: t("mostPopularPlan"),
      formatLabel: true,
      showSpecificValue: true,
    },
  ],
  fetchOnlyData: renewalsActions.fetchRenewals,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: renewalsActions.setSelectedRenewals,
  setSingleSelected: renewalsActions.setSingleSelected,
  removeSingleSelected: renewalsActions.removeSingleSelected,
  isDataLoading: state => state.renewals.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 20,
      valueRenderer: item => item.fullName,
    },
    {
      id: "phone",
      label: t("phone"),
      width: 20,
      valueRenderer: item => item.phone,
    },
    {
      id: "membershipType",
      label: t("membership"),
      width: 20,
      valueRenderer: item => item.membershipType,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.renewals.all || []).map(renewal => renewal.membershipType);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v, label: v }));
      },
    },
    {
      id: "purchaseDate",
      label: t("renewDate"),
      valueRenderer: item => moment(item.purchaseDate).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["today", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "doneByUser",
      label: t("user"),
      width: 20,
      valueRenderer: item => item.doneByUser,
      filterType: "searchable",
      showFilterChip: true,
      newPage: true,
      showAll: t("all"),
      filterOptionsSelector: state => {
        const values = (state.renewals.all || []).map(renewal => ({
          id: renewal.doneByUser,
          label: renewal.doneByUser,
          icon: <Avatar>{renewal.doneByUser[0]}</Avatar>,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(value => ({ id: value.id.toString(), label: value.label, icon: value.icon }));
      },
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getRenewals(entities, dateFormat),
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <MembershipCard />,
        textRender: item => item.membershipType,
      },
      {
        icon: () => <Calendar />,
        textRender: item => t("purchasedAt", { purchasedAt: moment(item.purchaseDate).format(dateFormat) }),
      },
    ],
  },
  entitiesSelector: renewalsSelector,
  selectedEntitiesSelector: selectedRenewalsSelector,
  fetchRawPostData: renewalsService.getRenewals,
});

export default RenewalsReport;
