/* eslint-disable no-console */
import { BaseReportEntity } from "../../models/base";
import { LeadsStatisticsDisplay } from "../../store/leadsStatisticsStore";
import absenceReport from "./absenceReport";
import activeMembershipsReport from "./activeMembershipsReport";
import activeMembersReport from "./activeMembersReport";
import allLeadsReport from "./allLeadsReport";
import attendanceExternalMembersReport from "./attendanceExternalMembersReport";
import birthdayReport from "./birthdayReport";
import bookingCancellationReport from "./bookingCancellationReport";
import canceledMembershipsReport from "./canceledMembershipsReport";
import classesSummaryReport from "./classesSummaryReport";
import convertedLeadsReport from "./convertedLeadsReport";
import debtReport from "./debtReport";
import employeeAttendanceReport from "./employeeAttendanceReport";
import entranceReport from "./entranceReport";
import expiredMembershipsReport from "./expiredMembershipsReport";
import expiredSessionReport from "./expiredSessionReport";
import expiringMembershipsReport from "./expiringMembershipsReport";
import expiringSessionsReport from "./expiringSessionsReport";
import externalMembershipReport from "./externalMembershipsReport";
import groupMemberReport from "./groupMemberReport";
import inactiveMemberReport from "./inactiveMembersReport";
import lateCancellationsReport from "./lateCancellationsReport";
import leadsInProcessReport from "./leadsInProcessReport";
import leadsStatistics from "./leadsStatisticsReport";
import lostLeadsReport from "./lostLeadsReport";
import membershipForecastReport from "./membershipForecastReport";
import membersOnHoldReport from "./membersOnHoldReport";
import membersPropertiesReport from "./membersPropertiesReport";
import { Page, PageId } from "./page";
import preservationReport from "./preservationReport";
import regularsReport from "./regularsReport";
import renewalsReport from "./renewalsReport";
import restrictedMembersReport from "./restrictedMembersReport";
import salesReport from "./salesReport";
import salesSummaryReport from "./salesSummaryReport";
import sessionsReport from "./sessionsReport";
import shiftSummaryReport from "./shiftSummaryReport";
import staffActionsReport from "./staffActionsReport";
import transactionReport from "./transactionReport";
import transparentMembershipsReport from "./transparentMembershipsReport";
import transparentSessionPacksReport from "./transparentSessionPacksReport";
import trialClassesReport from "./trialClassesReport";

const reportPageConfig = (
  dateFormat: string,
  timeFormat: string,
  getCurrentCurrency: any,
  leadsStatisticsDisplay?: LeadsStatisticsDisplay
): (Page<any> | null)[] => [
    activeMembershipsReport(dateFormat),
    absenceReport(dateFormat),
    activeMembersReport(dateFormat),
    allLeadsReport(dateFormat),
    birthdayReport(dateFormat),
    bookingCancellationReport(dateFormat, timeFormat),
    canceledMembershipsReport(dateFormat),
    classesSummaryReport(dateFormat, timeFormat, getCurrentCurrency),
    convertedLeadsReport(dateFormat),
    debtReport(dateFormat, getCurrentCurrency),
    employeeAttendanceReport(dateFormat, timeFormat),
    entranceReport(dateFormat, timeFormat),
    expiredMembershipsReport(dateFormat),
    expiredSessionReport(dateFormat),
    expiringMembershipsReport(dateFormat),
    expiringSessionsReport(dateFormat),
    externalMembershipReport(dateFormat),
    groupMemberReport(dateFormat),
    inactiveMemberReport(),
    lateCancellationsReport(dateFormat, timeFormat),
    leadsInProcessReport(dateFormat),
    leadsStatistics(leadsStatisticsDisplay),
    lostLeadsReport(dateFormat),
    membersOnHoldReport(dateFormat),
    membersPropertiesReport(),
    preservationReport(),
    regularsReport(timeFormat),
    renewalsReport(dateFormat),
    restrictedMembersReport(dateFormat),
    salesReport(dateFormat),
    salesSummaryReport(dateFormat),
    sessionsReport(dateFormat),
    shiftSummaryReport(dateFormat, timeFormat),
    staffActionsReport(dateFormat, timeFormat),
    transactionReport(dateFormat, timeFormat, getCurrentCurrency),
    transparentMembershipsReport(dateFormat),
    transparentSessionPacksReport(dateFormat),
    trialClassesReport(dateFormat, timeFormat),
    attendanceExternalMembersReport(dateFormat, timeFormat),
    membershipForecastReport(),
  ];

const getPageConfigById = <TEntity extends BaseReportEntity>(
  id: PageId,
  dateFormat: string,
  timeFormat: string,
  getCurrentCurrency: any,
  leadsStatisticsDisplay?: LeadsStatisticsDisplay
): Page<TEntity> => {
  const config = reportPageConfig(dateFormat, timeFormat, getCurrentCurrency, leadsStatisticsDisplay).find(
    p => p!.id === id
  );

  if (!config) {
    console.error(`Page with id ${id} not configured`);
  }

  return config as Page<TEntity>;
};

export { getPageConfigById, reportPageConfig };
