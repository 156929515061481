import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Session, toSession } from "../models/sessions";
import sessionsService from "../services/sessionService";
import { ApplicationState } from "./store";

type State = {
  all: Session[] | null;
  selected: Session[] | null;
  loading: boolean;
};

type TPayload = {
  all: Session[] | null;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchAllSessions = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sessions } = getState();

    if (sessions.all) {
      return;
    }

    dispatch(fetchAllSessions.start());

    try {
      const data = await sessionsService.getAllSessions();
      dispatch(fetchAllSessions.success({ all: data.map(toSession) }));
    } catch {
      dispatch(fetchAllSessions.error());
    }
  },
  {
    start: createActionCreator("@@SESSIONS/START"),
    success: createActionCreator("@@SESSIONS/SUCCESS", resolve => (sessions: TPayload) => resolve(sessions)),
    error: createActionCreator("@@SESSIONS/ERROR"),
  }
);

const setSelectedSessions = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sessions } = getState();

    if (!sessions.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedSessions.success(null));
    }
    const selected = sessions.all.filter(session => selectedId.includes(session.id));

    dispatch(setSelectedSessions.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_SESSIONS/SUCCESS", resolve => (selected: Session[] | null) =>
      resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_SESSIONS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sessions } = getState();

    if (!sessions.all) {
      return;
    }

    const selectedSession = sessions.all.find(session => session.id === selectedId);

    if (!selectedSession) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedSession));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_SESSION/SUCCESS", resolve => (payload: Session) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_SESSION/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sessions } = getState();

    if (!sessions.all) {
      return;
    }

    const selectedSession = sessions.all.find(session => session.id === selectedId);

    if (!selectedSession) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedSession));
  },
  {
    success: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_SESSION/SUCCESS", resolve => (payload: Session) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_SESSION/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchAllSessions.start, state => ({ ...state, loading: true })),
  handleAction(fetchAllSessions.error, state => ({ ...state, loading: false })),
  handleAction(fetchAllSessions.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedSessions.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedSession => selectedSession.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchAllSessions,
  setSelectedSessions,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };
export type { State };
