import moment from "moment";
import { BaseReportEntity } from "./base";
import { coordinatorTransform } from "./expiringMemberships";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull } from "./misc";

const toCustomerStatus = (active: 0 | 1) => {
  if (active === 0) {
    return "inactive";
  }

  if (active === 1) {
    return "active";
  }

  return "invalid";
};

// const toEndReason = (cancel: string) => {
//   const formattedCancel = cancel.toLowerCase();

//   if (formattedCancel === "yes") {
//     return "cancelled";
//   }

//   if (formattedCancel === "no") {
//     return "expired";
//   }

//   return "other";
// };

interface ExpiredMembershipsDTO extends MemberBaseDTO {
  active: 0 | 1;
  cancelled: string;
  deactivate_time: StringOrNull;
  end: StringOrNull;
  head: 0 | 1 | null;
  membership_type: string;
  membership_user_id: number;
  start: StringOrNull;
}

interface ExpiredMemberships extends BaseReportEntity {
  fullName: string;
  firstName: string;
  lastName: string;
  phone: StringOrNull;
  membershipType: string;
  isCoordinator: string;
  customerStatus: string;
  userId: number;
  membershipStatus: string;
  endedAt: StringOrNull;
  location: string;
}

const toExpiredMemberships = (m: ExpiredMembershipsDTO): ExpiredMemberships => ({
  id: m.membership_user_id.toString(),
  customerStatus: toCustomerStatus(m.active),
  firstName: m.first_name,
  lastName: m.last_name,
  fullName: `${m.first_name} ${m.last_name}`,
  phone: m.phone,
  userId: m.user_fk,
  membershipType: m.membership_type,
  isCoordinator: coordinatorTransform(m.head),
  membershipStatus: m.cancelled === "yes" ? "membershipCanceled" : "membershipExpired",
  endedAt: moment(m.end).toISOString(),
  location: m.location,
});

export type { ExpiredMembershipsDTO, ExpiredMemberships };
export { toExpiredMemberships };
