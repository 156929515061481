import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";

const transformEntity = (entity: number | string): string => {
  if (!entity || entity === "empty") {
    return "no";
  }

  return "yes";
};

const transformSubscription = (option: string): string => {
  if (option !== "no" && option !== "yes") {
    return "empty";
  }

  return option;
};

interface MemberPropertiesDTO extends MemberBaseDTO {
  membership_type: string;
  has_insurance: number;
  has_waiver: number;
  medical_cert: number;
  restricted: number;
}

interface MemberProperties extends ReportEntity {
  membershipType: string;
  hasInsurance: string;
  hasMedicalCertificate: string;
  userId: number;
  smsSubscribe: string;
  emailSubscribe: string;
  email: string;
  hasWaiver: string;
  isRestricted: string;
}

const toMemberProperties = (memberProperties: MemberPropertiesDTO): MemberProperties => ({
  id: memberProperties.user_fk.toString(),
  userId: memberProperties.user_fk,
  firstName: memberProperties.first_name,
  lastName: memberProperties.last_name,
  fullName: `${(memberProperties.first_name, memberProperties.last_name)}`,
  membershipType: memberProperties.membership_type,
  hasInsurance: transformEntity(memberProperties.has_insurance),
  hasMedicalCertificate: transformEntity(memberProperties.medical_cert),
  smsSubscribe: transformSubscription(memberProperties.allow_mailing_list),
  emailSubscribe: transformSubscription(memberProperties.allow_sms),
  phone: memberProperties.phone,
  email: memberProperties.email,
  location: memberProperties.email,
  hasWaiver: transformEntity(memberProperties.has_waiver),
  isRestricted: transformEntity(memberProperties.restricted),
});

export type { MemberPropertiesDTO, MemberProperties };

export { toMemberProperties };
