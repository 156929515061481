import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import _ from "lodash";
import { Dispatch } from "redux";
import { MemberProperties, toMemberProperties } from "../models/membersProperties";
import membersPropertiesService from "../services/membersPropertiesService";
import { ApplicationState } from "./store";

interface State {
  allMembersProperties: MemberProperties[] | null;
  selectedMembersProperties: MemberProperties[] | null;
  loading: boolean;
}

const defaultState: State = {
  allMembersProperties: null,
  selectedMembersProperties: null,
  loading: false,
};

interface TMembersPropsPayload {
  allMembersProperties: MemberProperties[] | null;
}

const fetchAllMembersProperties = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersProperties } = getState();

    if (membersProperties.allMembersProperties) {
      return;
    }

    dispatch(fetchAllMembersProperties.start());

    try {
      const data = await membersPropertiesService.getAllMembersProperties();
      const uniqueData = _.uniqBy(data, "id");
      dispatch(fetchAllMembersProperties.success({ allMembersProperties: uniqueData.map(toMemberProperties) }));
    } catch {
      dispatch(fetchAllMembersProperties.error());
    }
  },
  {
    start: createActionCreator("@@MEMBERS_PROPERTIES/START"),
    error: createActionCreator("@@MEMBERS_PROPERTIES/ERROR"),
    success: createActionCreator("@@MEMBERS_PROPERTIES/SUCCESS", resolve => (members: TMembersPropsPayload) =>
      resolve(members.allMembersProperties)
    ),
  }
);

const setSelectedMembersProperties = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersProperties } = getState();

    if (!membersProperties.allMembersProperties) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedMembersProperties.success(null));
      return;
    }

    const selectedMember = membersProperties.allMembersProperties.filter(m => selectedId.includes(m.id));
    dispatch(setSelectedMembersProperties.success(selectedMember));
  },
  {
    success: createActionCreator(
      "@@SELECT/MEMBERS_PROPERTIES/SUCCESS",
      resolve => (membersProperties: MemberProperties[] | null) => resolve(membersProperties)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersProperties } = getState();

    if (!membersProperties.allMembersProperties) {
      return;
    }

    const selected = membersProperties.allMembersProperties.find(m => m.id === selectedId);
    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@SINGLE_SELECTED/MEMBERS_PROPERTIES/SUCCESS",
      resolve => (memberProperties: MemberProperties) => resolve(memberProperties)
    ),
    error: createActionCreator("@@SET_SINGLE_SELECTED/MEMBERS_PROPERTIES/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membersProperties } = getState();

    if (!membersProperties.allMembersProperties) {
      return;
    }

    const selected = membersProperties.allMembersProperties.find(m => m.id === selectedId);
    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REMOVE_SINGLE_SELECTED/MEMBERS_PROPERTIES/SUCCESS",
      resolve => (memberProperties: MemberProperties) => resolve(memberProperties)
    ),
    error: createActionCreator("@@REMOVE_SINGLE_SELECTED/MEMBERS_PROPERTIES_ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.allMembersProperties = null;
        draft.selectedMembersProperties = null;
      });
    }
    return state;
  }),
  handleAction(fetchAllMembersProperties.start, state => ({ ...state, loading: true })),
  handleAction(fetchAllMembersProperties.error, state => ({ ...state, loading: false })),
  handleAction(fetchAllMembersProperties.success, (state, { payload }) => ({
    ...state,
    allMembersProperties: payload,
    loading: false,
  })),
  handleAction(setSelectedMembersProperties.success, (state, { payload }) => ({
    ...state,
    selectedMembersProperties: payload,
  })),
  handleAction(setSingleSelected.error, state => ({ ...state })),
  handleAction(removeSingleSelected.error, state => ({ ...state })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selectedMembersProperties: state.selectedMembersProperties
      ? [...state.selectedMembersProperties, action.payload]
      : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selectedMembersProperties: state.selectedMembersProperties
      ? state.selectedMembersProperties.filter(m => m.id !== action.payload.id)
      : null,
  })),
]);

const actions = {
  fetchAllMembersProperties,
  setSelectedMembersProperties,
  setSingleSelected,
  removeSingleSelected,
};
export type { State };

export { reducer, actions };
