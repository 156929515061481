import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Class } from "../../icons/warmup.svg";
import t from "../../infrastructure/translations/i18n";
import { BookingCancellation } from "../../models/bookingCancellation";
import bookingCancellationService from "../../services/bookingCancellationService";
import csvService from "../../services/csvService";
import { actions as bookingActions } from "../../store/bookingCancellationStore";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { bookingCancellationSelector, selectedBookingCancellationSelector } from "../reportSelector";
import { Page } from "./page";

const bookingCancellationReport = (dateFormat: string, timeFormat: string): Page<BookingCancellation> => ({
  id: "booking-cancellation-report",
  rawUserId: "user_fk",
  title: t("bookingCancellationTitle"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  description: t("bookingCancellationDescription"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },

    {
      id: "classDate",
      label: t("date"),
    },
    {
      id: "classTime",
      label: t("time"),
    },
    {
      id: "classId",
      label: t("workout"),
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "classDate",
  },
  defaultFilters: [
    {
      key: "classDate",
      value: moment().subtract(30, "days").startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "classDate",
      value: moment().startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("cancellations"),
    },
    {
      key: "class",
      showSpecificValue: true,
      toLocaleString: false,
      aggregator: "mostUsed",
      formatLabel: true,
      label: t("withMostCancellations"),
    },
    {
      key: "hoursBeforeClass",
      showHours: true,
      toLocaleString: false,
      aggregator: "average",
      label: t("averageCancellationTime"),
    },
  ],
  entitiesSelector: bookingCancellationSelector,
  setSelectedEntities: bookingActions.setSelectedBookingCancellations,
  setSingleSelected: bookingActions.setSingleSelected,
  selectedEntitiesSelector: selectedBookingCancellationSelector,
  searchTerms: ["fullName"],
  removeSingleSelected: bookingActions.removeSingleSelected,
  fetchOnlyData: bookingActions.fetchBookingCancellations,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  isLead: false,
  csvGenerator: entities => csvService.getBookingCancellation(entities, dateFormat, timeFormat),
  isDataLoading: state => state.bookingCancellations.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: b => b.fullName,
      width: 14,
    },
    {
      id: "classId",
      label: t("class"),
      valueRenderer: b => b.class,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 14,
      filterOptionsSelector: state =>
        // TODO Once the API is updated, update the map
        (state.filterValues.uniqueFilterValues?.categories || []).map(b => ({
          id: _.camelCase(b.name || "unknown"),
          label: b.name,
        })),
    },
    {
      id: "classDate",
      label: t("scheduleDate"),
      valueRenderer: b => (b.classDate ? moment(b.classDate).format(dateFormat) : null),
      filterType: "period",
      periodPrefixes: ["all", "today", "lastSevenDays", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 14,
    },
    {
      id: "classTime",
      label: t("scheduleTime"),
      filterType: "timeSlider",
      valueRenderer: b => (b.classTime ? moment().startOf("day").add(b.classTime, "hours").format(timeFormat) : null),
      width: 14,
    },
    {
      id: "registeredAt",
      label: t("registerTime"),
      valueRenderer: b =>
        b.registeredAt
          ? `${moment(b.registeredAt).format(dateFormat)} ${moment(b.registeredAt).format(timeFormat)}`
          : null,
      filterType: "period",
      width: 14,
      newPage: true,
      showFilterChip: true,
      periodPrefixes: ["today", "lastSevenDays", "lastThirtyDays", "custom"],
    },
    {
      id: "cancellationTime",
      label: t("cancelTime"),
      valueRenderer: b =>
        b.cancellationTime
          ? `${moment(b.cancellationTime).format(dateFormat)} ${moment(b.cancellationTime).format(timeFormat)}`
          : null,
      filterType: "period",
      periodPrefixes: ["today", "lastSevenDays", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 14,
    },
    {
      id: "hoursBeforeClass",
      label: t("cancelledHours"),
      valueRenderer: b => b.hoursBeforeClass,
      showFilterChip: true,
      filterType: "slider",
      width: 14,
    },
  ],
  card: {
    titleRender: b => b.fullName,
    rows: [
      {
        icon: () => <Class />,
        textRender: b => b.class,
      },
      {
        icon: () => <Clock />,
        textRender: b => (b.hoursBeforeClass ? t("cancelledBeforeClassData", { cancelled: b.hoursBeforeClass }) : null),
      },
    ],
  },
  fetchRawPostData: bookingCancellationService.getBookingCancellations,
});

export default bookingCancellationReport;
