import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Birthday, toBirthday } from "../models/birthday";
import birthdayService from "../services/birthdayService";
import { ApplicationState } from "./store";

type State = {
  all: Birthday[] | null;
  selected: Birthday[] | null;
  loading: boolean;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

type TPayload = {
  all: Birthday[] | null;
};

const fetchBirthdays = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchBirthdays.start());

    try {
      const data = await birthdayService.getBirthdays(from, to);

      dispatch(fetchBirthdays.success({ all: data.map(toBirthday) }));
    } catch {
      dispatch(fetchBirthdays.error());
    }
  },
  {
    start: createActionCreator("@@ALL_BIRTHDAYS/START_FETCH"),
    success: createActionCreator("@@ALL_BIRTHDAYS/FETCH", resolve => (all: TPayload) => resolve(all)),
    error: createActionCreator("@@ALL_BIRTHDAYS/ERROR_FETCH"),
  }
);

const setSelectedBirthdays = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { birthdays } = getState();

    if (!birthdays.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedBirthdays.success(null));
    }

    const selected = birthdays.all.filter(birthday => selectedId.includes(birthday.id));
    dispatch(setSelectedBirthdays.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_BIRTHDAYS",
      resolve => (setSelectedBirthdays: Birthday[] | null) => resolve(setSelectedBirthdays)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_BIRTHDAYS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { birthdays } = getState();

    if (!birthdays.all) {
      return;
    }

    const selectedBirthday = birthdays.all.find(birthday => birthday.id === selectedId);

    if (!selectedBirthday) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedBirthday));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_BIRTHDAY/SUCCESS", resolve => (payload: Birthday) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_BIRTHDAY/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { birthdays } = getState();

    if (!birthdays.all) {
      return;
    }

    const selectedBirthday = birthdays.all.find(birthday => birthday.id === selectedId);

    if (!selectedBirthday) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedBirthday));
  },
  {
    success: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_BIRTHDAY/SUCCESS", resolve => (payload: Birthday) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_BIRTHDAY/ERROR"),
  }
);

const actions = {
  fetchBirthdays,
  setSelectedBirthdays,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchBirthdays.start, state => ({ ...state, loading: true })),
  handleAction(fetchBirthdays.success, (state, { payload }) => ({
    ...state,
    loading: false,
    all: payload.all,
  })),
  handleAction(fetchBirthdays.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedBirthdays.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedBirthday => selectedBirthday.id !== payload.id) : null,
  })),
]);

export { actions, reducer };

export type { State };
