import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { daysOfWeek } from "../../models/misc";
import { StaffAction } from "../../models/staffActions";
import csvService from "../../services/csvService";
import staffActionsService from "../../services/staffActionsService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as staffActions } from "../../store/staffActionsStore";
import { staffActionsSelector, selectedStaffActionsSelector } from "../reportSelector";
import { Page } from "./page";

const StaffActionsReport = (dateFormat: string, timeFormat: string): Page<StaffAction> => ({
  id: "staff-actions-report",
  title: t("staffActions"),
  entityClick: entity => wrapperActions.openStaffDetails({ coachId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  description: t("staffActionsDesc"),
  rawUserId: "user_fk",
  defaultSort: {
    order: "asc",
    orderBy: "date",
  },
  searchTerms: ["staffMember", "member"],
  defaultFilters: [
    {
      key: "date",
      value: moment().startOf("day").toISOString() as unknown,
      default: true,
      type: "moreThan",
    },
    {
      key: "date",
      value: moment().endOf("day").toISOString() as unknown,
      default: true,
      type: "lessThan",
    },
  ],
  columns: [
    {
      id: "date",
      label: t("time"),
      valueRenderer: entity => moment(entity.date).format(`${dateFormat} ${timeFormat}`),
      filterType: "period",
      periodPrefixes: ["today", "yesterday", "lastSevenDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "dayOfWeekId",
      label: t("day"),
      valueRenderer: entity => (entity.dayOfWeek || entity.dayOfWeek === "0" ? t(entity.dayOfWeek as Phrases) : null),
      width: 10,
      filterType: "searchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: () => daysOfWeek.map(d => ({ id: d.id.toString(), label: t(d.label as Phrases) })),
    },
    // TODO: add search option
    {
      id: "staffMember",
      label: t("staffMember"),
      valueRenderer: entity => entity.staffMember,
      width: 10,
      filterType: "searchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.staffActions.all || []).map(s => ({ id: s.staffMember, label: s.staffMember }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id, label: v.label }));
      },
    },
    {
      id: "action",
      label: t("action"),
      valueRenderer: entity => entity.action,
      width: 10,
      filterType: "searchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.staffActions.all || []).map(s => ({ id: s.action, label: s.action }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "subAction",
      label: t("subAction"),
      valueRenderer: entity => entity.subAction,
      width: 10,
      filterType: "searchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.staffActions.all || []).map(s => ({ id: s.subAction, label: s.subAction }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: t(v.label as Phrases) }));
      },
    },
    {
      id: "member",
      label: t("relatedUser"),
      valueRenderer: entity => entity.member,
      width: 10,
    },
  ],
  card: {
    titleRender: () => "",
    rows: [
      {
        textRender: () => "",
      },
    ],
  },
  fetchOnlyData: staffActions.fetchStaffActions,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: staffActions.setSelectedStaffActions,
  setSingleSelected: staffActions.setSingleSelected,
  removeSingleSelected: staffActions.removeSingleSelected,
  isDataLoading: state => state.staffActions.loading,
  entitiesSelector: staffActionsSelector,
  selectedEntitiesSelector: selectedStaffActionsSelector,
  fetchRawPostData: staffActionsService.getStaffActions,
  isLead: false,
  csvGenerator: entities => csvService.getStaffActions(entities, dateFormat, timeFormat),
  desktopOnly: true,
});

export default StaffActionsReport;
