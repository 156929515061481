import { createReducer, createActionCreator } from "deox";
import { Dispatch } from "redux";
import wrapperActions from "../actions/wrapperActions";
import { PageId } from "../pages/pageConfig/page";

type State = {
  favoriteReports: PageId[];
};

const toggleFavorite = Object.assign(
  (data: { reportId: PageId; isFavorite: boolean }) => (dispatch: Dispatch) => {
    wrapperActions.addReportToFavorites({ reportId: data.reportId, toggled: data.isFavorite });

    if (data.isFavorite) {
      dispatch(toggleFavorite.add(data.reportId));
    } else {
      dispatch(toggleFavorite.remove(data.reportId));
    }
  },
  {
    add: createActionCreator("@@FAVORITES/ADD", resolve => (reportId: PageId) => resolve(reportId)),
    remove: createActionCreator("@@FAVORITES/REMOVE", resolve => (reportId: PageId) => resolve(reportId)),
  }
);

const defaultState: State = {
  favoriteReports: [],
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(toggleFavorite.add, (state, action) =>
    state.favoriteReports.includes(action.payload)
      ? state
      : {
          ...state,
          favoriteReports: [...state.favoriteReports, action.payload],
        }
  ),
  handleAction(toggleFavorite.remove, (state, action) =>
    state.favoriteReports.includes(action.payload)
      ? {
          ...state,
          favoriteReports: [...state.favoriteReports.filter(r => r !== action.payload)],
        }
      : state
  ),
]);

const actions = {
  toggleFavorite,
};

export { actions, reducer };

export type { State };
