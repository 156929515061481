import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull } from "./misc";

interface InactiveMember extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  phone: StringOrNull;
  email: string;
  allowSMS: StringOrNull;
  userId: number;
  allowEmails: StringOrNull;
  location: StringOrNull;
  locationId: number;
}

const toInactiveMember = (inactiveMember: MemberBaseDTO): InactiveMember => {
  return {
    id: inactiveMember.id.toString(),
    firstName: inactiveMember.first_name,
    lastName: inactiveMember.last_name,
    userId: inactiveMember.user_fk,
    fullName: `${inactiveMember.first_name} ${inactiveMember.last_name}`,
    phone: inactiveMember.phone,
    email: inactiveMember.email,
    allowSMS: inactiveMember.allow_sms,
    allowEmails: inactiveMember.allow_mailing_list,
    location: inactiveMember.location,
    locationId: inactiveMember.locations_box_fk,
  };
};

export { toInactiveMember };

export type { InactiveMember };
