import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as MembershipCard } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as ToggleOn } from "../../icons/toggleOn.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ExpiredMemberships } from "../../models/expiredMemberships";
import csvService from "../../services/csvService";
import expiredMembersService from "../../services/expiredMembershipsService";
import { actions as expiredMembersActions } from "../../store/expiredMembershipsStore";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { expiredMembersSelector, selectedExpiredMembersSelector } from "../reportSelector";
import { Page } from "./page";

const coordinatorOptions: TOption[] = [
  { id: "yes", label: "yes" },
  { id: "no", label: "no" },
];

const cancellationOptions: TOption[] = [
  { id: "membershipCanceled", label: "membershipCanceled" },
  { id: "membershipExpired", label: "membershipExpired" },
];

const statusOptions: TOption[] = [
  { id: "active", label: "active" },
  { id: "inactive", label: "inactive" },
];

const expiredMembershipsReport = (dateFormat: string): Page<ExpiredMemberships> => ({
  id: "expired-memberships-report",
  description: t("expiredMembershipsDescription"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  title: t("membershipExpired"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipType",
      label: t("membership"),
    },
    {
      id: "endedAt",
      label: t("expiredAt"),
    },
  ],
  rawUserId: "user_fk",
  searchTerms: ["fullName", "phone"],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("plan"),
    },
    {
      key: "membershipStatus",
      aggregator: "percentage",
      specificValue: "expired",
      toLocaleString: false,
      label: t("planExpired"),
    },
    {
      key: "membershipStatus",
      aggregator: "percentage",
      toLocaleString: false,
      specificValue: "cancelled",
      label: t("planCanceled"),
    },
  ],
  defaultSort: {
    order: "asc",
    orderBy: "endedAt",
  },
  defaultFilters: [
    {
      key: "endedAt",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "endedAt",
      value: moment().add(14, "days").startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  fetchOnlyData: expiredMembersActions.fetchExpiredMemberships,
  fetchDataActions: [
    locationsActions.fetchLocations,
    filterValuesActions.fetchFilterValues,
    messagesActions.fetchSmsBank,
  ],
  entitiesSelector: expiredMembersSelector,
  selectedEntitiesSelector: selectedExpiredMembersSelector,
  setSelectedEntities: expiredMembersActions.setSelectedExpiredMemberships,
  setSingleSelected: expiredMembersActions.setSingleSelected,
  removeSingleSelected: expiredMembersActions.removeSingleSelected,
  isLead: false,
  csvGenerator: entities => csvService.getAllExpiredMemberships(entities, dateFormat),
  isDataLoading: state => state.expiredMemberships.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: expiredMembership => expiredMembership.fullName,
      width: 20,
    },
    {
      id: "membershipType",
      label: t("membershipType"),
      valueRenderer: expiredMembership => expiredMembership.membershipType,
      filterType: "searchable",
      showAll: t("all"),
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const values = (state.expiredMemberships.all || []).map(membership => membership.membershipType);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v, label: v }));
      },
    },
    {
      id: "isCoordinator",
      label: t("isCoordinator"),
      valueRenderer: expiredMembership => t(expiredMembership.isCoordinator as Phrases),
      filterType: "searchable",
      newPage: true,
      showAll: t("all"),
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => coordinatorOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
    {
      id: "membershipStatus",
      label: t("endingReason"),
      valueRenderer: expiredMembership => t(expiredMembership.membershipStatus as Phrases),
      filterType: "searchable",
      newPage: true,
      showAll: t("all"),
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => cancellationOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
    {
      id: "customerStatus",
      label: t("memberStatus"),
      valueRenderer: expiredMembership => t(expiredMembership.customerStatus as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => statusOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
    {
      id: "endedAt",
      label: t("endDateMembership"),
      valueRenderer: item => {
        const endDate = moment(item.endedAt).format(dateFormat);

        return item.membershipStatus === "cancelled"
          ? t("cancelledAtDate", { endDate })
          : t("expiredAtDate", { endDate });
      },
      filterType: "period",
      periodPrefixes: ["today", "thisMonth", "lastThirtyDays", "thisYear", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
  ],
  card: {
    titleRender: (item: ExpiredMemberships) => item.fullName,
    rows: [
      {
        icon: () => <MembershipCard />,
        textRender: (item: ExpiredMemberships) => item.membershipType,
      },
      {
        icon: () => <Calendar />,
        textRender: (item: ExpiredMemberships) => {
          const endDate = moment(item.endedAt).format(dateFormat);

          return endDate;
          // return item.membershipStatus === "cancelled"
          //   ? t("membershipCanceled", { endDate })
          //   : t("membershipExpired", { endDate });
        },
      },
      {
        icon: () => <ToggleOn />,
        textRender: (item: ExpiredMemberships) =>
          item.customerStatus === "active" ? t("activeCustomer") : t("inactiveCustomer"),
      },
    ],
  },
  fetchRawPostData: expiredMembersService.getAllExpiredMemberships,
});

export default expiredMembershipsReport;
