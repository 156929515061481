import moment from "moment";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { Gender, StringOrNull, YesNoBoolean } from "./misc";

interface LostLeadResponse {
  report: LostLeadDTO[];
  summary: LostLeadDTO[];
}

interface LostLeadDTO extends MemberBaseDTO {
  lead_source: StringOrNull;
  lead_source_id: number | null;
  lost_reason: StringOrNull;
  gender: Gender;
}

interface LostLead extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  gender: Gender;
  phone: StringOrNull;
  email: string;
  userId: number;
  lostReason: string;
  createdAt: string | Date;
  leadSourceId: string;
  leadSource: StringOrNull;
  allowSms: YesNoBoolean;
  allowEmail: YesNoBoolean;
  lostDate: Date | string;
}

const toLostLead = (lostLead: LostLeadDTO): LostLead => ({
  id: lostLead.id.toString(),
  userId: lostLead.id,
  firstName: lostLead.first_name,
  lastName: lostLead.last_name,
  fullName: `${lostLead.first_name} ${lostLead.last_name}`,
  gender: lostLead.gender,
  phone: lostLead.phone,
  email: lostLead.email,
  lostReason: lostLead.lost_reason ? lostLead.lost_reason : "-1",
  createdAt: moment(lostLead.created_at).toISOString(),
  leadSourceId: lostLead.lead_source_id ? lostLead.lead_source_id.toString() : "-1",
  leadSource: lostLead.lead_source,
  allowEmail: lostLead.allow_mailing_list,
  allowSms: lostLead.allow_sms,
  location: lostLead.location,
  lostDate: moment(lostLead.updated_at).toISOString(),
});

export type { LostLead, LostLeadDTO, LostLeadResponse };

export { toLostLead };
