import { createActionCreator, createReducer } from "deox";
import { Dispatch } from "redux";
import { SMSType, SMSProviderType } from "../models/message";
import messageService from "../services/messageService";
import { ApplicationState } from "./store";

type State = {
  messageSms: SMSType | null;
  messageProvider: number | null;
  messageText: string | null;
};

const fetchSmsBank = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { messages } = getState();
    let smsProvider;

    if (messages.messageSms && messages.messageProvider) {
      return;
    }

    try {
      const data = await messageService.getSmsDetails();
      if (!data) {
        return;
      }

      dispatch(fetchSmsBank.smsSuccess(data));

      if (!data) {
        return;
      }
      smsProvider = data.smsProvider;
    } catch {
      dispatch(fetchSmsBank.smsError());
      return;
    }

    try {
      const data = await messageService.getProviderDetails(smsProvider);
      if (!data) {
        return;
      }

      dispatch(fetchSmsBank.providerSuccess(data));
    } catch {
      dispatch(fetchSmsBank.providerError());
      return;
    }
  },
  {
    smsSuccess: createActionCreator("@@MESSAGES/SMS_BANK/SUCCESS", resolve => (smsBank: SMSType) => resolve(smsBank)),
    providerSuccess: createActionCreator(
      "@@MESSAGES/SMS_PROVIDER/SUCCESS",
      resolve => (smsProvider: SMSProviderType[]) => resolve(smsProvider)
    ),
    smsError: createActionCreator("@@MESSAGES/SMS_BANK/ERROR"),
    providerError: createActionCreator("@@MESSAGES/SMS_PROVIDER/ERROR"),
  }
);

const allSentMessages = Object.assign(
  (text: string) => (dispatch: Dispatch) => {
    dispatch(allSentMessages.pushMessage(text));
  },
  {
    pushMessage: createActionCreator("@@MESSAGES/PUSH_MESSAGE", resolve => (messageText: string) =>
      resolve(messageText)
    ),
  }
);

const defaultState: State = {
  messageSms: null,
  messageProvider: null,
  messageText: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchSmsBank.smsSuccess, (state, action) => ({
    ...state,
    messageSms: action.payload,
  })),
  handleAction(fetchSmsBank.providerSuccess, (state, action) => ({
    ...state,
    messageProvider: action.payload[0].char_count,
  })),
  handleAction(fetchSmsBank.smsError, state => ({ ...state })),
  handleAction(fetchSmsBank.providerError, state => ({ ...state })),
  handleAction(allSentMessages.pushMessage, (state, action) => ({ ...state, messageText: action.payload })),
]);

const actions = {
  fetchSmsBank,
  allSentMessages,
};

export { actions, reducer };

export type { State };
