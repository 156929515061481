import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Session, toSession } from "../models/sessions";
import sessionService from "../services/sessionService";
import { ApplicationState } from "./store";

type State = {
  all: Session[] | null;
  selected: Session[] | null;
  loading: boolean;
};

type TPayload = {
  all: Session[] | null;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchData = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentSessionPacks } = getState();

    if (transparentSessionPacks.all) {
      return;
    }

    dispatch(fetchData.start());

    try {
      const data = await sessionService.getAllTransparentSessions();
      dispatch(fetchData.success({ all: data.map(toSession) }));
    } catch {
      dispatch(fetchData.error());
    }
  },
  {
    start: createActionCreator("@@TRANSPARENT_SESSION_PACKS/START"),
    success: createActionCreator("@@TRANSPARENT_SESSION_PACKS/SUCCESS", resolve => (t: TPayload) => resolve(t)),
    error: createActionCreator("@@TRANSPARENT_SESSION_PACKS/ERROR"),
  }
);

const setSelectedTSP = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentSessionPacks } = getState();

    if (!transparentSessionPacks.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedTSP.success(null));
    }

    const selected = transparentSessionPacks.all.filter(t => selectedId.includes(t.id));

    dispatch(setSelectedTSP.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_TRANSPARENT_SESSION_PACKS/SUCCESS",
      resolve => (selected: Session[] | null) => resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_TRANSPARENT_SESSION_PACKS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentSessionPacks } = getState();

    if (!transparentSessionPacks.all) {
      return;
    }

    const selectedTSP = transparentSessionPacks.all.find(t => t.id === selectedId);

    if (!selectedTSP) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedTSP));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_TRANSPARENT_SESSION_PACK/SUCCESS",
      resolve => (payload: Session) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_TRANSPARENT_SESSION_PACK/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentSessionPacks } = getState();

    if (!transparentSessionPacks.all) {
      return;
    }

    const selectedTSP = transparentSessionPacks.all.find(t => t.id === selectedId);

    if (!selectedTSP) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedTSP));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_TRANSPARENT_SESSION_PACK/SUCCESS",
      resolve => (payload: Session) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_TRANSPARENT_SESSION_PACK/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchData.start, state => ({ ...state, loading: true })),
  handleAction(fetchData.error, state => ({ ...state, loading: false })),
  handleAction(fetchData.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedTSP.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(t => t.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchData,
  setSelectedTSP,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };
export type { State };
