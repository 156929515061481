import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { yesNoToBool } from "./misc";

const toStatus = (active: 0 | 1 | 2, cancelled: 0 | 1) => {
  if (active === 0) {
    return "inactive";
  }

  if (active === 2) {
    return "future";
  }

  if (active === 1 && cancelled === 0) {
    return "active";
  }

  if (active === 1 && cancelled === 1) {
    return "futureCancellation";
  }

  return "Incompatible Status";
};

interface ActiveMembershipDTO extends MemberBaseDTO {
  active: 0 | 1 | 2;
  cancelled: 0 | 1;
  start: Date | null;
  end: Date | null;
  child: number;
  groups_id: null | number;
  price: number;
  head: number;
  department_id: number | null;
  department_name: string | null;
  prop_type: null;
  membership_type: string;
  membership_user_id: number;
  active_hold: null;
  paid: number;
}

interface ActiveMembership extends ReportEntity {
  department: string | null;
  departmentId: number | null;
  userId: number;
  membershipType: string;
  membershipId: number;
  status: string;
  email: string;
  price: number;
  paid: number;
  start: Date | null;
  end: Date | null;
}

const toActiveMembership = (m: ActiveMembershipDTO): ActiveMembership => ({
  id: m.membership_user_id.toString(),
  firstName: m.first_name,
  lastName: m.last_name,
  userId: m.user_fk,
  fullName: `${m.first_name} ${m.last_name}`,
  phone: m.phone,
  email: m.email,
  department: m.department_name,
  departmentId: m.department_id,
  membershipType: m.membership_type,
  membershipId: m.membership_user_id,
  status: toStatus(m.active, m.cancelled),
  price: m.price,
  paid: m.paid,
  start: m.start,
  end: m.end,
  allowSMS: yesNoToBool(m.allow_sms),
  allowEmails: yesNoToBool(m.allow_mailing_list),
  location: m.location,
  createdAt: moment(m.created_at).toISOString(),
});

export type { ActiveMembershipDTO, ActiveMembership };
export { toActiveMembership };
