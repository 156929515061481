import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as PaperMoneyFilled } from "../../icons/paperMoneyFilled.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as ShoppingCart } from "../../icons/shoppingCart.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Transaction } from "../../models/transaction";
import csvService from "../../services/csvService";
import transactionService from "../../services/transactionService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as transactionActions } from "../../store/transactionStore";
import { transactionSelector, selectedTransactionsSelector } from "../reportSelector";
import { Page } from "./page";

const transactionTypeOptions: TOption[] = [
  { id: "regular", label: "regular" },
  { id: "recurring", label: "recurring" },
];

const TransactionReport = (dateFormat: string, timeFormat: string, locationCurrency: any): Page<Transaction> => ({
  id: "transactions-report",
  title: t("transactionsReport"),
  searchTerms: ["transactionDate", "fullName", "customerId", "endsWith", "voucher"],
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  description: t("transactionDesc"),
  defaultSort: {
    order: "desc",
    orderBy: "transactionDate",
  },
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("transactions"),
    },
    {
      key: "amount",
      toLocaleString: true,
      showCurrency: true,
      aggregator: "sum",
      label: t("totalAmount"),
    },
    {
      key: "transactionType",
      toLocaleString: false,
      aggregator: "positiveCountPercent",
      label: t("regularPayments"),
      specificValue: "REGULAR",
    },
    {
      key: "transactionStatus",
      toLocaleString: false,
      aggregator: "positiveCountPercent",
      label: t("successTransactions"),
      specificValue: "SUCCESS",
    },
  ],
  defaultFilters: [
    {
      key: "transactionDate",
      value: moment().startOf("day").toISOString() as unknown,
      default: true,
      type: "moreThan",
    },
    {
      key: "transactionDate",
      value: moment().endOf("day").toISOString() as unknown,
      default: true,
      type: "lessThan",
    },
  ],
  rawUserId: "user_fk",
  isLead: false,
  isDataLoading: state => state.transaction.loading,
  columns: [
    {
      id: "id",
      label: t("customerName"),
      width: 10,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "transactionDate",
      label: t("transactionDate"),
      periodPrefixes: ["today", "yesterday", "thisMonth", "lastThirtyDays", "custom"],
      valueRenderer: entity => moment(entity.transactionDate).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "fullName",
      width: 10,
      label: t("customerName"),
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "customerId",
      label: t("customerIdentifier"),
      width: 10,
      valueRenderer: entity => entity.customerId,
    },
    {
      id: "membershipType",
      label: t("item"),
      valueRenderer: entity => entity.membershipType,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 10,
      filterOptionsSelector: state => {
        const values = (state.transaction.all || []).map(transaction => ({
          id: transaction.membershipType,
          value: transaction.membershipType,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(item => item.id);
        return uniqueValues.map(v => ({ id: v.id!, label: v.value }));
      },
    },
    {
      id: "transactionType",
      label: t("transactionType"),
      valueRenderer: entity => t(entity.transactionType as Phrases),
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 10,
      filterOptionsSelector: () => transactionTypeOptions.map(o => ({ id: o.id, label: t(o.label as Phrases) })),
    },
    {
      id: "amount",
      label: t("amount"),
      valueRenderer: entity =>
        `${locationCurrency ? locationCurrency.currency.currency_symbol : ""} ${entity.amount.toLocaleString()}`,
      filterType: "slider",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "numberOfPayments",
      label: t("numberOfPayment"),
      valueRenderer: entity => entity.numberOfPayments,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "endsWith",
      label: t("endsWith"),
      valueRenderer: entity => entity.endsWith,
      width: 10,
    },
    {
      id: "voucher",
      label: t("voucherId"),
      valueRenderer: entity => entity.voucher,
      width: 10,
    },
    {
      id: "receipt",
      label: t("receipt"),
      isLink: true,
      valueRenderer: entity => entity.receipt,
      width: 10,
    },
    {
      id: "transactionStatus",
      label: t("transactionStatus"),
      valueRenderer: entity => t(entity.transactionStatus as Phrases),
      width: 10,
    },
    {
      id: "doneBy",
      label: t("salePerson"),
      valueRenderer: entity => entity.doneBy,
      width: 10,
    },
  ],
  fetchOnlyData: transactionActions.fetchTransactions,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: transactionActions.setSelectedTransactions,
  setSingleSelected: transactionActions.setSingleSelect,
  removeSingleSelected: transactionActions.removeSelect,
  csvGenerator: entities => csvService.getAllTransactions(entities, dateFormat),
  entitiesSelector: transactionSelector,
  selectedEntitiesSelector: selectedTransactionsSelector,
  fetchRawPostData: transactionService.getTransactions,
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: item =>
          `${moment(item.transactionDate).format(dateFormat)} ${
            locationCurrency ? locationCurrency.currency.currency_symbol : ""
          }${moment(item.transactionDate).format(timeFormat)}`,
      },
      {
        icon: () => <PaperMoneyFilled />,
        textRender: item => `${locationCurrency ? locationCurrency.currency.currency_symbol : ""} ${item.amount}`,
      },
      {
        icon: () => <ShoppingCart />,
        textRender: item => (item.membershipType ? item.membershipType : t("unknownItem")),
      },
    ],
  },
});

export default TransactionReport;
