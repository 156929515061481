import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as CalendarWithIcon } from "../../icons/calendarWithIcon.svg";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Warmup } from "../../icons/warmup.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { LateCancellation } from "../../models/lateCancellation";
import csvService from "../../services/csvService";
import lateCancellationsServices from "../../services/lateCancellationsServices";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as lateCancellationsActions } from "../../store/lateCancellationsStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { lateCancellationsSelector, selectedLateCancellationsSelector } from "../reportSelector";
import { Page } from "./page";

const LateCancellationsReport = (dateFormat: string, timeFormat: string): Page<LateCancellation> => ({
  id: "late-cancellation-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipName",
      label: t("membership"),
    },
    {
      id: "scheduleDate",
      label: t("date"),
    },
    //TODO Add time chip
  ],
  searchTerms: ["fullName"],
  title: t("lateCancellationReport"),
  description: t("lateCancellationDescription"),
  defaultSort: {
    order: "desc",
    orderBy: "scheduleDate",
  },
  defaultFilters: [
    {
      key: "scheduleDate",
      value: moment().subtract(30, "days").startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "scheduleDate",
      value: moment().startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      label: t("cancellations"),
      aggregator: "count",
      toLocaleString: false,
    },
    {
      key: "seriesName",
      toLocaleString: false,
      aggregator: "mostUsed",
      formatLabel: true,
      showSpecificValue: true,
      label: t("withMostCancellations"),
    },
    {
      key: "cancellationHours",
      toLocaleString: false,
      aggregator: "average",
      label: t("averageCancellationTime"),
      showHours: true,
    },
  ],
  entitiesSelector: lateCancellationsSelector,
  setSelectedEntities: lateCancellationsActions.setSelectedLateCancellations,
  setSingleSelected: lateCancellationsActions.setSingleSelected,
  selectedEntitiesSelector: selectedLateCancellationsSelector,
  removeSingleSelected: lateCancellationsActions.removeSingleSelected,
  fetchOnlyData: lateCancellationsActions.fetchLateCancellations,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  isLead: false,
  csvGenerator: entities => csvService.getLateCancellations(entities, dateFormat, timeFormat),
  isDataLoading: state => state.lateCancellations.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 12,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "membershipType",
      label: t("planType"),
      valueRenderer: entity => t(entity.membershipType as Phrases),
      filterType: "searchable",
      newPage: true,
      showAll: t("all"),
      showFilterChip: true,
      width: 12,
      filterOptionsSelector: state => {
        const values = (state.lateCancellations.all || []).map(l => ({
          id: l.membershipType,
          value: l.membershipType,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(a => a.id);
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: t(v.value as Phrases) }));
      },
    },
    {
      id: "membershipName",
      label: t("membership"),
      valueRenderer: entity => entity.membershipName,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 12,
      filterOptionsSelector: state => {
        const values = (state.lateCancellations.all || []).map(l => ({
          id: l.membershipName,
          value: l.membershipName,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(a => a.id);
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: v.value }));
      },
    },
    {
      id: "seriesName",
      label: t("class"),
      valueRenderer: entity => entity.seriesName,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 12,
      filterOptionsSelector: state => {
        const values = (state.lateCancellations.all || []).map(l => ({
          id: l.seriesName,
          value: l.seriesName,
        }));
        const uniqueValues = _.uniqBy(values, "id").filter(a => a.id);
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: v.value }));
      },
    },
    {
      id: "scheduleDate",
      label: t("classTime"),
      valueRenderer: entity =>
        `${moment(entity.scheduleDate).format(dateFormat)} ${moment(entity.scheduleDate).format(timeFormat)}`,
      filterType: "period",
      periodPrefixes: ["all", "today", "lastSevenDays", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 12,
    },
    {
      id: "cancellationHours",
      label: t("cancelledHours"),
      width: 12,
      valueRenderer: item => item.cancellationHours,
      filterType: "slider",
    },
    {
      id: "cancelDate",
      label: t("cancelDate"),
      valueRenderer: entity =>
        entity.cancelDate ? moment(entity.cancelDate).format(`${dateFormat} ${timeFormat}`) : null,
      filterType: "period",
      periodPrefixes: ["today", "lastSevenDays", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 12,
    },
  ],
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Warmup />,
        textRender: item => item.seriesName,
      },
      {
        icon: () => <Clock />,
        textRender: item =>
          t("startedAt", {
            date: `${moment(item.scheduleDate).format(dateFormat)} ${moment(item.scheduleDate).format(timeFormat)}`,
          }),
      },
      {
        icon: () => <CalendarWithIcon />,
        textRender: item =>
          item.cancellationHours
            ? t("cancelledBeforeClassData", { cancelled: item.cancellationHours.toFixed(1) })
            : null,
      },
    ],
  },
  fetchRawPostData: lateCancellationsServices.getLateCancellations,
});

export default LateCancellationsReport;
