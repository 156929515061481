import moment from "moment";
import { StringOrNull } from "./misc";

interface RestrictedMemberDTO {
  id: number;
  user_fk: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  location: string;
  locations_box_fk: number;
  date: string;
}

interface RestrictedMember {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;
  userId: number;
  location: string;
  locationId: number;
  lastSeen: StringOrNull;
}

const toRestrictedMember = (restrictedMember: RestrictedMemberDTO): RestrictedMember => ({
  id: restrictedMember.id.toString(),
  userId: restrictedMember.user_fk,
  firstName: restrictedMember.first_name,
  lastName: restrictedMember.last_name,
  fullName: `${restrictedMember.first_name} ${restrictedMember.last_name}`,
  phone: restrictedMember.phone,
  email: restrictedMember.email,
  location: restrictedMember.location,
  locationId: restrictedMember.locations_box_fk,
  lastSeen: restrictedMember.date ? moment(restrictedMember.date).toISOString() : null,
});

export { toRestrictedMember };

export type { RestrictedMember, RestrictedMemberDTO };
