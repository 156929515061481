import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as CreatedAt } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Sources } from "../../icons/sources.svg";
import { ReactComponent as TrailClasses } from "../../icons/trialClasses.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Lead } from "../../models/leads";
import csvService from "../../services/csvService";
import leadService from "../../services/leadsService";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as leadActions } from "../../store/leadsStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { allLeadsSelector, selectedLeadsSelector } from "../reportSelector";
import { Page } from "./page";

const taskOptions: TOption[] = [
  { id: "taskNone", label: "taskNone" },
  { id: "taskFuture", label: "taskFuture" },
  { id: "taskPast", label: "taskPast" },
];

const trialClassOption: TOption[] = [
  { id: "bookedForTrial", label: "bookedForTrial" },
  { id: "notBookedForTrial", label: "notBookedForTrial" },
];

const allLeadsReport = (dateFormat: string): Page<Lead> => ({
  id: "all-leads-report",
  rawUserId: "id",
  entityClick: entity => wrapperActions.openLeadDetails({ leadId: entity.userId.toString() }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  description: t("allLeadsDescription"),
  title: t("allLeads"),
  defaultSort: {
    order: "desc",
    orderBy: "createdAt",
  },
  defaultFilters: [
    {
      key: "createdAt",
      value: moment().startOf("month").toISOString(),
      type: "moreThan",
      default: true,
    },
    {
      key: "createdAt",
      value: moment().endOf("month").toISOString(),
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("leads"),
    },
    {
      key: "trialClass",
      aggregator: "positiveCountPercent",
      toLocaleString: false,
      specificValue: "bookedForTrial",
      label: t("bookedForClasses"),
    },
    {
      key: "sourcesName",
      toLocaleString: false,
      aggregator: "mostUsedPercentage",
      label: t("sources"),
    },
    {
      key: "statusName",
      toLocaleString: false,
      aggregator: "mostUsedPercentage",
      label: t("status"),
    },
  ],
  entitiesSelector: allLeadsSelector,
  setSingleSelected: leadActions.setSingleSelected,
  removeSingleSelected: leadActions.removeSelectedLeads,
  setSelectedEntities: leadActions.setSelectedLeads,
  selectedEntitiesSelector: selectedLeadsSelector,
  fetchOnlyData: leadActions.fetchAllLeads,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  isLead: true,
  csvGenerator: entities => csvService.getAllLeadsCsv(entities, dateFormat),
  searchTerms: ["fullName", "phone"],
  isDataLoading: state => state.leads.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: lead => lead.fullName,
      width: 20,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: lead => lead.phone,
      width: 15,
    },
    {
      id: "statusId",
      label: t("status"),
      valueRenderer: lead =>
        lead.statusName === "Converted to Member" || lead.statusName === "Lost"
          ? t(lead.statusName as Phrases)
          : lead.statusName,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state =>
        state.filterValues.uniqueFilterValues?.statuses.map(status => {
          // const camelCased = _.camelCase(status.name);

          return {
            id: status.id.toString(),
            label:
              status.name === "Converted to Member" || status.name === "Lost" ? t(status.name as Phrases) : status.name,
          };
        }) || [],
    },
    {
      id: "sourceId",
      label: t("source"),
      valueRenderer: lead => lead.sourceName,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const sources = [...(state.filterValues.uniqueFilterValues?.sources || []), { id: -1, name: t("noSource") }];
        return sources.map(source => ({
          id: source.id.toString(),
          label: source.name,
        }));
      },
    },
    {
      id: "createdAt",
      label: t("personCreatedAt"),
      valueRenderer: member => moment(member.createdAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["today", "thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "updatedAt",
      label: t("personUpdatedAt"),
      valueRenderer: lead => moment(lead.updatedAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "trialClass",
      label: t("trialLesson"),
      valueRenderer: lead => t(lead.trialClass as Phrases),
      filterType: "searchable",
      showAll: t("all"),
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => trialClassOption.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
    {
      id: "task",
      label: t("taskStatus"),
      valueRenderer: lead => lead.taskLabel,
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => taskOptions.map(r => ({ id: r.id.toString(), label: t(r.label as Phrases) })),
    },
  ],
  card: {
    titleRender: (item: Lead) => item.fullName,
    rows: [
      {
        icon: () => <CreatedAt />,
        textRender: (item: Lead) =>
          t("personCreatedAt", { personCreatedAt: moment(item.createdAt).format(dateFormat) }),
      },
      {
        icon: () => <Sources />,
        textRender: (item: Lead) => item.sourceName || t("none"),
      },
      {
        icon: () => <TrailClasses />,
        textRender: (item: Lead) =>
          item.trialClass === "notBookedForTrial" ? t("notBookedForTrial") : t("bookedForTrial"),
      },
    ],
  },
  fetchRawPostData: leadService.getAllLeads,
});

export default allLeadsReport;
