import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { LeadStatistics, toLeadStatistics } from "../models/leadsStatistics";
import leadStatisticsService from "../services/leadsStatisticsService";
import { MasterFilter } from "./filtersStore";
import { ApplicationState } from "./store";

type LeadsStatisticsDisplay = {
  timePeriod: string | null;
  displayType: string | null;
};

interface State {
  all: LeadStatistics[] | null;
  selected: LeadStatistics[] | null;
  loading: boolean;
  display: LeadsStatisticsDisplay;
}

// TODO: find a better way
const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
  display: {
    timePeriod: null,
    displayType: null
  },
};

interface TPayload {
  all: LeadStatistics[];
}

const fetchLeadsStatistics = Object.assign(
  (data: MasterFilter[]) => async (dispatch: Dispatch) => {
    dispatch(fetchLeadsStatistics.start());

    try {
      const response = await leadStatisticsService.getLeadsStatistics(data);

      const timePeriod = data.find(d => d.key === "timePeriod")?.value[0] || null;
      const displayType = data.find(d => d.key === "displayType")?.value[0] || null;

      dispatch(fetchLeadsStatistics.additionalProperty({ timePeriod, displayType }));
      dispatch(
        fetchLeadsStatistics.success({
          all: response.map((item, index) => toLeadStatistics(item, index)),
        })
      );
    } catch {
      dispatch(fetchLeadsStatistics.error());
    }
  },
  {
    start: createActionCreator("@@LEADS_STATISTICS/START"),
    error: createActionCreator("@@LEADS_STATISTICS/ERROR"),
    additionalProperty: createActionCreator("@@LEADS_STATISTIC/DISPLAY_TYPE", resolve => (payload: LeadsStatisticsDisplay) =>
      resolve(payload)
    ),
    success: createActionCreator("@@LEADS_STATISTICS", resolve => (payload: TPayload) => resolve(payload)),
  }
);

const setSelectedLeadsStatistics = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsStatistics } = getState();

    if (!leadsStatistics.all) {
      return dispatch(setSelectedLeadsStatistics.error());
    }

    if (!selectedId) {
      return dispatch(setSelectedLeadsStatistics.success(null));
    }

    const selected = leadsStatistics.all.filter(l => selectedId.includes(l.id));

    dispatch(setSelectedLeadsStatistics.success(selected));
  },
  {
    error: createActionCreator("@@SELECTED_LEADS_STATISTICS/ERROR"),
    success: createActionCreator(
      "@@SELECTED_LEADS_STATISTICS/SUCCESS",
      resolve => (selected: LeadStatistics[] | null) => resolve(selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsStatistics } = getState();

    if (!leadsStatistics.all || !selectedId) {
      return dispatch(setSingleSelected.error());
    }

    const selected = leadsStatistics.all.find(l => l.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    error: createActionCreator("@@SINGLE/LEAD_STATISTICS/ERROR"),
    success: createActionCreator("@@SINGLE/LEAD_STATISTICS/SUCCESS", resolve => (l: LeadStatistics) => resolve(l)),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsStatistics } = getState();

    if (!leadsStatistics.all || !selectedId) {
      return dispatch(removeSingleSelected.error());
    }

    const removed = leadsStatistics.all.find(l => l.id === selectedId);

    if (!removed) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(removed));
  },
  {
    error: createActionCreator("@@REMOVE/LEAD_STATISTICS/ERROR"),
    success: createActionCreator("@@REMOVE/LEAD_STATISTICS/SUCCESS", resolve => (l: LeadStatistics) => resolve(l)),
  }
);

const actions = {
  fetchLeadsStatistics,
  setSelectedLeadsStatistics,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchLeadsStatistics.start, state => ({ ...state, loading: true })),
  handleAction(fetchLeadsStatistics.error, state => ({ ...state, loading: false })),
  handleAction(fetchLeadsStatistics.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(fetchLeadsStatistics.additionalProperty, (state, action) => ({
    ...state,
    display: action.payload,
  })),
  handleAction(setSelectedLeadsStatistics.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };

export type { State, LeadsStatisticsDisplay };
