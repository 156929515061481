import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Membership } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Entrance } from "../../models/entrance";
import csvService from "../../services/csvService";
import entranceService from "../../services/entranceService";
import { actions as entrancesActions } from "../../store/entranceStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { entranceSelector, selectedEntranceSelector } from "../reportSelector";
import { Page } from "./page";

const entranceTagOptions: TOption[] = [
  { id: "yes", label: "yes" },
  { id: "no", label: "no" },
];

const entranceReport = (dateFormat: string, timeFormat: string): Page<Entrance> => ({
  id: "entrance-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membership",
      label: t("membership"),
    },
    {
      id: "entranceDate",
      label: t("lastSeen"),
    },
  ],
  searchTerms: ["fullName"],
  rawUserId: "user_fk",
  title: t("entersMembership"),
  description: t("entranceDescription"),
  defaultSort: {
    order: "desc",
    orderBy: "entranceDate",
  },
  defaultFilters: [
    {
      key: "entranceDate",
      value: moment().startOf("day").toISOString() as unknown,
      default: true,
      type: "moreThan",
    },
    {
      key: "entranceDate",
      value: moment().endOf("day").toISOString() as unknown,
      default: true,
      type: "lessThan",
    },
  ],
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      label: t("entersMembership"),
      toLocaleString: false,
    },
    {
      key: "entranceDate",
      aggregator: "mostUsed",
      showSpecificValue: true,
      formatLabel: true,
      label: t("strongestHour"),
      formatToTime: true,
      toLocaleString: false,
    },
    // {
    //   key: "entranceDate",
    //   aggregator: "lessUsed",
    //   label: t("weakestHour"),
    //   showSpecificValue: true,
    //   formatLabel: true,
    //   formatToTime: true,
    //   toLocaleString: false,
    // },
  ],
  entitiesSelector: entranceSelector,
  setSingleSelected: entrancesActions.setSingleSelect,
  removeSingleSelected: entrancesActions.removeSelect,
  setSelectedEntities: entrancesActions.setSelected,
  selectedEntitiesSelector: selectedEntranceSelector,
  fetchOnlyData: entrancesActions.fetchEntrances,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  isLead: false,
  csvGenerator: entities => csvService.getEntrances(entities, dateFormat, timeFormat),
  isDataLoading: state => state.entrances.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: e => e.fullName,
      width: 14,
    },
    {
      id: "entranceDate",
      label: t("entranceDate"),
      valueRenderer: e => (e.entranceDate ? moment(e.entranceDate).format(dateFormat) : t("unknown")),
      filterType: "period",
      newPage: true,
      periodPrefixes: ["today", "yesterday", "thisWeek", "thisMonth", "custom"],
      showFilterChip: true,
      width: 14,
    },
    {
      id: "entranceTime",
      label: t("enteringTime"),
      valueRenderer: e => (e.entranceTime ? moment(e.entranceTime).format(timeFormat) : t("unknown")),
      width: 14,
    },
    {
      id: "membership",
      label: t("membership"),
      valueRenderer: e => e.membership,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 14,
      filterOptionsSelector: state => {
        const values = (state.entrances.all || []).map(e => ({
          id: e.membership as string,
          label: e.membership as string,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id, label: v.label }));
      },
    },
    {
      id: "sessionsLeft",
      label: t("sessionsLeft"),
      valueRenderer: e => e.sessionsLeft,
      filterType: "slider",
      showFilterChip: true,
      width: 14,
    },
    {
      id: "enteredWithFab",
      label: t("chipEnterenace"),
      valueRenderer: e => t(e.enteredWithFab as Phrases),
      filterType: "searchable",
      showAll: t("all"),
      showFilterChip: true,
      width: 14,
      filterOptionsSelector: () => entranceTagOptions.map(e => ({ id: e.id, label: t(e.label as Phrases) })),
    },
  ],
  card: {
    titleRender: e => e.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: e =>
          t("entranceDateData", {
            entranceDate: e.entranceDate ? moment(e.entranceDate).format(dateFormat) : t("unknown"),
          }),
      },
      {
        icon: () => <Clock />,
        textRender: e => (e.entranceTime ? moment(e.entranceTime).format(timeFormat) : t("unknown")),
      },
      {
        icon: () => <Membership />,
        textRender: e => (e.membership ? e.membership : t("unknown")),
      },
    ],
  },
  fetchRawPostData: entranceService.getEntrances,
});

export default entranceReport;
