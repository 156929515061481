import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as PaperMoney } from "../../icons/paperMoney.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as ShoppingCart } from "../../icons/shoppingCart.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Debt } from "../../models/debt";
import csvService from "../../services/csvService";
import { debtService } from "../../services/debtService";
import { actions as debtActions } from "../../store/debtStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { debtSelector, selectedDebtsSelector } from "../reportSelector";
import { Page } from "./page";

const statusOptions = [
  { id: 0, label: "inactive" },
  { id: 1, label: "active" },
];

const DebtReport = (dateFormat: string, locationCurrency: any): Page<Debt> => ({
  id: "debt-report",
  description: t("debtDesc"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membership",
      label: t("membership"),
    },
    {
      id: "price",
      label: t("price"),
    },
    {
      id: "debt",
      label: t("debt"),
    },
    {
      id: "createdAt",
      label: t("createdAt"),
    },
  ],
  rawUserId: "user_fk",
  searchTerms: ["fullName", "membershipStatus"],
  defaultSort: {
    order: "desc",
    orderBy: "createdAt",
  },
  summaryItems: [
    {
      key: "id",
      label: t("Debts"),
      aggregator: "count",
      toLocaleString: false,
    },
    {
      key: "debt",
      label: t("totalDebts"),
      aggregator: "sum",
      showCurrency: true,
      toLocaleString: true,
    },
  ],
  fetchDataActions: [debtActions.fetchDebts, locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: debtActions.setSelectedDebts,
  setSingleSelected: debtActions.setSingleSelected,
  removeSingleSelected: debtActions.removeSelectedDebt,
  isDataLoading: state => state.debt.loading,
  title: t("Debts"),
  columns: [
    {
      id: "fullName",
      width: 20,
      label: t("customerName"),
      valueRenderer: item => item.fullName,
    },
    {
      id: "membership",
      label: t("membership"),
      valueRenderer: item => item.membership,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const data = (state.debt.all || []).map(debt => ({
          id: debt.membership,
          label: debt.membership,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: t(item.label as Phrases) }));
      },
    },
    {
      id: "membershipStatusId",
      label: t("membershipStatus"),
      valueRenderer: d => t(d.membershipStatus as Phrases),
      filterType: "searchable",
      showAll: t("all"),
      width: 15,
      showFilterChip: true,
      filterOptionsSelector: () => statusOptions.map(s => ({ id: s.id.toString(), label: t(s.label as Phrases) })),
    },
    {
      id: "price",
      label: t("originalPrice"),
      valueRenderer: item => `${locationCurrency ? locationCurrency.currency.currency_symbol : ""} ${item.price}`,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "debt",
      label: t("debt"),
      valueRenderer: item =>
        `${locationCurrency ? locationCurrency.currency.currency_symbol : ""} ${item.debt.toLocaleString()}`,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      valueRenderer: item => moment(item.createdAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["all", "today", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "doneBy",
      label: t("salePerson"),
      valueRenderer: item => item.doneBy,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const data = (state.debt.all || []).map(debt => ({
          id: debt.doneBy,
          label: debt.doneBy,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: t(item.label as Phrases) }));
      },
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getDebts(entities, dateFormat),
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <PaperMoney />,
        textRender: item =>
          t("quantityDebt", {
            quantityDebt: `${
              locationCurrency ? locationCurrency.currency.currency_symbol : ""
            } ${item.debt.toString()}`,
          }),
      },
      {
        icon: () => <ShoppingCart />,
        textRender: item => item.membership,
      },
      {
        icon: () => <Calendar />,
        textRender: item => t("createdAtDate", { creationDate: moment(item.createdAt).format(dateFormat) }),
      },
    ],
  },
  entitiesSelector: debtSelector,
  selectedEntitiesSelector: selectedDebtsSelector,
  fetchRawData: debtService.getAllDebts,
});

export default DebtReport;
