import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { toDayOfWeek } from "./misc";

const getSeriesName = (value: string): string => {
  const seriesName = value.split(",");

  return seriesName[0];
};

const getStartAndEndTime = (startTime: string, endTime: string): string => {
  const startTimeToArray = startTime.split(":");
  const endTimeToArray = endTime.split(":");
  const timeToTime = [...startTimeToArray, ...endTimeToArray];
  const result = `${timeToTime[0]}:${timeToTime[1]} - ${timeToTime[3]}:${timeToTime[4]}`;

  return result;
};

interface RegularDTO {
  id: number;
  user_fk: number;
  first_name: string;
  last_name: string;
  series_name: string;
  box_category_name: string;
  day: string;
  start_time: string;
  end_time: string;
  coach_name: string;
  membership_user_active: number;
  location: string;
}

interface Regular {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userId: number;
  seriesName: string;
  class: string;
  day: string | null;
  dayId: string | null;
  scheduleTime: string;
  startTime: number;
  coach: string;
  membershipActive: string;
  location: string;
}

const toRegular = (regular: RegularDTO): Regular => ({
  id: regular.id.toString(),
  userId: regular.user_fk,
  firstName: regular.first_name,
  lastName: regular.last_name,
  fullName: `${regular.first_name} ${regular.last_name}`,
  seriesName: getSeriesName(regular.series_name),
  class: regular.box_category_name,
  day: (toDayOfWeek(regular.day)?.label as string) || null,
  dayId: toDayOfWeek(regular.day)?.id || null,
  startTime: moment.duration(regular.start_time).asHours(),
  scheduleTime: getStartAndEndTime(regular.start_time, regular.end_time),
  coach: regular.coach_name ? regular.coach_name : t("unknown"),
  membershipActive: regular.membership_user_active === 1 ? "active" : "inactive",
  location: regular.location,
});

export { toRegular, getStartAndEndTime };

export type { RegularDTO, Regular };
