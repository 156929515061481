import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { yesNoToBool, getTaskLabel } from "./misc";

const isTrialClasses = (fk: unknown | null): string => (fk ? "bookedForTrial" : "notBookedForTrial");

interface LeadDTO extends MemberBaseDTO {
  lead_date: null | string;
  status: null | string | number;
  source: null | string;
  comment: null | string;
  source_fk: null | number;
  status_fk: null | number;
  lost_reasons_fk: null | string | number;
  source_name: null | string;
  status_name: string | null;
  schedule_user_fk: null | unknown;
}

interface Lead extends ReportEntity {
  statusId: string;
  statusName: string;
  sourceId: string;
  sourceName: string;
  trialClass: string;
  updatedAt: string;
  userId: number;
}

const toLead = (leadDto: LeadDTO): Lead => ({
  id: leadDto.id.toString(),
  userId: leadDto.id,
  allowEmails: yesNoToBool(leadDto.allow_mailing_list),
  allowSMS: yesNoToBool(leadDto.allow_sms),
  createdAt: moment(leadDto.created_at).toISOString(),
  firstName: leadDto.first_name,
  lastName: leadDto.last_name,
  fullName: `${leadDto.first_name} ${leadDto.last_name}`,
  location: leadDto.location,
  locationId: leadDto.locations_box_fk,
  phone: leadDto.phone,
  task: leadDto.reminder,
  taskLabel: getTaskLabel(leadDto.reminder),
  statusId: (leadDto.status_fk || -1).toString(),
  statusName: leadDto.status_name || "",
  sourceId: (leadDto.source_fk || -1).toString(),
  sourceName: !leadDto.source_name ? t("noSource") : leadDto.source_name,
  updatedAt: moment(leadDto.updated_at).toISOString(),
  trialClass: isTrialClasses(leadDto.schedule_user_fk),
});

export type { LeadDTO, Lead };

export { toLead };
