import moment from "moment";
import { StaffActionDTO } from "../models/staffActions";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();

const getStaffActions = async (from?: Date, to?: Date): Promise<StaffActionDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<StaffActionDTO[]>("reports/global/staffActions", {
    fromDate,
    toDate,
  });

  return result.data;
};

const staffActionsService = {
  getStaffActions,
};

export default staffActionsService;
