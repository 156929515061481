import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ActiveMembership, toActiveMembership } from "../models/activeMemberships";
import memberService from "../services/membersService";
import { ApplicationState } from "./store";

type State = {
  all: ActiveMembership[] | null;
  selected: ActiveMembership[] | null;
  loading: boolean;
};

type TPayLoad = {
  all: ActiveMembership[] | null;
};

const fetchTransparentMemberships = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentMemberships } = getState();

    if (transparentMemberships.all) {
      return;
    }

    dispatch(fetchTransparentMemberships.start());

    try {
      const data = await memberService.getActiveMemberships();
      dispatch(fetchTransparentMemberships.success({ all: data.map(toActiveMembership) }));
    } catch {
      dispatch(fetchTransparentMemberships.error());
      return;
    }
  },
  {
    start: createActionCreator("@@REPORTS/FETCH_TRANSPARENT_MEMBERSHIPS/START"),
    error: createActionCreator("@@REPORTS/FETCH_TRANSPARENT_MEMBERSHIPS/ERROR"),
    success: createActionCreator("@@REPORTS/FETCH_TRANSPARENT_MEMBERSHIPS", resolve => (memberships: TPayLoad) =>
      resolve(memberships)
    ),
  }
);

const setSelected = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentMemberships } = getState();

    if (!transparentMemberships.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelected.success(null));
    }

    const selected = transparentMemberships.all.filter(m => selectedId.includes(m.id));
    dispatch(setSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_TRANSPARENT_MEMBERSHIPS",
      resolve => (selectedMembers: ActiveMembership[] | null) => resolve(selectedMembers)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_TRANSPARENT_MEMBERSHIPS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentMemberships } = getState();

    if (!transparentMemberships.all) {
      return;
    }

    const selectedTransparentMembership = transparentMemberships.all.find(
      transparentMembership => transparentMembership.id === selectedId
    );

    if (!selectedTransparentMembership) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedTransparentMembership));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_TRANSPARENT_MEMBERSHIP/SUCCESS",
      resolve => (payload: ActiveMembership) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_TRANSPARENT_MEMBERSHIP/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { transparentMemberships } = getState();

    if (!transparentMemberships.all) {
      return;
    }

    const selectedTransparentMembership = transparentMemberships.all.find(
      transparentMembership => transparentMembership.id === selectedId
    );

    if (!selectedTransparentMembership) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedTransparentMembership));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_TRANSPARENT_MEMBERSHIP/SUCCESS",
      resolve => (payload: ActiveMembership) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_TRANSPARENT_MEMBERSHIP/ERROR"),
  }
);

const defaultState: State = {
  all: null,
  selected: null,
  loading: true,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchTransparentMemberships.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(fetchTransparentMemberships.error, state => ({
    ...state,
    loading: false,
  })),
  handleAction(fetchTransparentMemberships.start, state => ({
    ...state,
    loading: true,
  })),
  handleAction(setSelected.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedItem => selectedItem.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchTransparentMemberships,
  setSelected,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
