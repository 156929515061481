import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { AttendanceExternalMember, toAttendanceExternalMember } from "../models/attendanceExternalMembers";
import attendanceExternalMembersService from "../services/attendanceExternalMembersService";
import { ApplicationState } from "./store";

interface State {
  all: AttendanceExternalMember[] | null;
  selected: AttendanceExternalMember[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TPayload {
  all: AttendanceExternalMember[];
}

const fetchAttendanceExternalMembers = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchAttendanceExternalMembers.start());

    try {
      const response = await attendanceExternalMembersService.getAttendanceExternalMembersService(from, to);
      dispatch(fetchAttendanceExternalMembers.success({ all: response.map(toAttendanceExternalMember) }));
    } catch {
      dispatch(fetchAttendanceExternalMembers.error());
    }
  },
  {
    start: createActionCreator("@@ATTENDANCE_EXTERNAL_MEMBERS/START"),
    error: createActionCreator("@@ATTENDANCE_EXTERNAL_MEMBERS/ERROR"),
    success: createActionCreator("@@ATTENDANCE_EXTERNAL_MEMBERS", resolve => (payload: TPayload) => resolve(payload)),
  }
);

const setSelectedAttendanceExternalMembers = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { attendanceExternalMembers } = getState();

    if (!attendanceExternalMembers.all) {
      return dispatch(setSelectedAttendanceExternalMembers.error());
    }

    if (!selectedId) {
      return dispatch(setSelectedAttendanceExternalMembers.success(null));
    }

    const selected = attendanceExternalMembers.all.filter(a => selectedId.includes(a.id));

    dispatch(setSelectedAttendanceExternalMembers.success(selected));
  },
  {
    error: createActionCreator("@@SELECTED_ATTENDANCE_EXTERNAL_MEMBERS/ERROR"),
    success: createActionCreator(
      "@@SELECTED_ATTENDANCE_EXTERNAL_MEMBERS/SUCCESS",
      resolve => (selected: AttendanceExternalMember[] | null) => resolve(selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { attendanceExternalMembers } = getState();

    if (!attendanceExternalMembers.all || !selectedId) {
      return dispatch(setSingleSelected.error());
    }

    const selected = attendanceExternalMembers.all.find(a => a.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    error: createActionCreator("@@SINGLE/ATTENDANCE_EXTERNAL_MEMBER/ERROR"),
    success: createActionCreator(
      "@@SINGLE/ATTENDANCE_EXTERNAL_MEMBER/SUCCESS",
      resolve => (b: AttendanceExternalMember) => resolve(b)
    ),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { attendanceExternalMembers } = getState();

    if (!attendanceExternalMembers.all || !selectedId) {
      return dispatch(removeSingleSelected.error());
    }

    const removed = attendanceExternalMembers.all.find(a => a.id === selectedId);

    if (!removed) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(removed));
  },
  {
    error: createActionCreator("@@REMOVE/ATTENDANCE_EXTERNAL_MEMBER/ERROR"),
    success: createActionCreator(
      "@@REMOVE/ATTENDANCE_EXTERNAL_MEMBER/SUCCESS",
      resolve => (b: AttendanceExternalMember) => resolve(b)
    ),
  }
);

const actions = {
  fetchAttendanceExternalMembers,
  setSelectedAttendanceExternalMembers,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchAttendanceExternalMembers.start, state => ({ ...state, loading: true })),
  handleAction(fetchAttendanceExternalMembers.error, state => ({ ...state, loading: false })),
  handleAction(fetchAttendanceExternalMembers.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(setSelectedAttendanceExternalMembers.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(a => a.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };

export type { State };
