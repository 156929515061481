import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import GenderIcon from "../../components/genderIcon";
import { TOption } from "../../components/table/types";
import { ReactComponent as Age } from "../../icons/age.svg";
import { ReactComponent as Female } from "../../icons/female.svg";
import { ReactComponent as Gender } from "../../icons/gender.svg";
import { ReactComponent as Male } from "../../icons/male.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { Member } from "../../models/member";
import csvService from "../../services/csvService";
import membersService from "../../services/membersService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as membersActions } from "../../store/membersStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as organizationsActions } from "../../store/organizationsStore";
import { activeMembersSelector, selectedMembersSelector } from "../reportSelector";
import { Page } from "./page";

const taskOptions: TOption[] = [
  { id: "taskNone", label: "taskNone" },
  { id: "taskFuture", label: "taskFuture" },
  { id: "taskPast", label: "taskNone" },
];

const activeMembersReport = (dateFormat: string): Page<Member> => ({
  id: "active-members-report",
  rawUserId: "user_fk",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  description: t("activeMembersDescription"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  title: t("activeMembersReport"),
  searchTerms: ["fullName", "phone"],
  defaultSort: {
    order: "asc",
    orderBy: "fullName",
  },
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      toLocaleString: false,
      label: t("members"),
    },
    {
      key: "gender",
      aggregator: "percentage",
      label: t("male"),
      toLocaleString: false,
      specificValue: "male",
      icon: <Male />,
    },
    {
      toLocaleString: false,
      key: "gender",
      aggregator: "percentage",
      label: t("female"),
      specificValue: "female",
      icon: <Female />,
    },
    {
      key: "age",
      aggregator: "average",
      toLocaleString: false,
      label: t("averageAge"),
    },
  ],
  entitiesSelector: activeMembersSelector,
  selectedEntitiesSelector: selectedMembersSelector,
  setSelectedEntities: membersActions.setSelectedMembers,
  setSingleSelected: membersActions.setSingleSelected,
  removeSingleSelected: membersActions.removeSingleSelected,
  fetchDataActions: [
    membersActions.fetchActiveMembers,
    locationsActions.fetchLocations,
    organizationsActions.fetchOrganizations,
    messagesActions.fetchSmsBank,
  ],
  isLead: false,
  csvGenerator: entities => csvService.getActiveMembersCsv(entities, dateFormat),
  isDataLoading: state => state.members.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: member => member.fullName,
      showFilterChip: false,
      width: 20,
    },
    {
      id: "gender",
      label: t("gender"),
      valueRenderer: member => <GenderIcon gender={member.gender} />,
      filterType: "toggle",
      valuePrintRenderer: member => t(member.gender),
      showFilterChip: true,
      width: 10,
      filterOptionsSelector: () => [
        { id: "male", label: t("male"), icon: <Male /> },
        { id: "female", label: t("female"), icon: <Female /> },
      ],
    },
    {
      id: "age",
      label: t("age"),
      valueRenderer: member => member.age,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: member => member.phone,
      showFilterChip: false,
      width: 15,
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      valueRenderer: member => moment(member.createdAt).format(dateFormat),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "organizationId",
      label: t("organization"),
      valueRenderer: member => member.organization,
      filterType: "searchable",
      showNone: t("none"),
      showAll: t("allOrganizations"),
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state =>
        (state.organizations.entities || []).map(r => ({ id: r.id.toString(), label: r.name })),
    },
    {
      id: "task",
      label: t("taskStatus"),
      valueRenderer: member => member.task,
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => taskOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
  ],
  card: {
    titleRender: (item: Member) => item.fullName,
    rows: [
      {
        icon: () => <Gender />,
        textRender: (item: Member) => t(item.gender),
      },
      {
        icon: () => <Age />,
        textRender: (item: Member) => t("yearsOld", { age: item.age ? Math.floor(+item.age) : "--" }),
      },
    ],
  },
  fetchRawData: membersService.getActiveMembers,
});

export default activeMembersReport;
