import _ from "lodash";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Bandage } from "../../icons/bandage.svg";
import { ReactComponent as EditTable } from "../../icons/editTable.svg";
import { ReactComponent as Email } from "../../icons/email.svg";
import { ReactComponent as Insurance } from "../../icons/insurance.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Sms } from "../../icons/sms.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { MemberProperties } from "../../models/membersProperties";
import csvService from "../../services/csvService";
import membersPropertiesService from "../../services/membersPropertiesService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as membersPropertiesActions } from "../../store/membersPropertiesStore";
import { actions as messagesActions } from "../../store/messageStore";
import { membersPropertiesSelector, selectedMembersPropertiesSelector } from "../reportSelector";
import { Page } from "./page";

const filterOptions: TOption[] = [
  { id: "no", label: "no" },
  { id: "yes", label: "yes" },
];

const MembersPropertiesReport = (): Page<MemberProperties> => ({
  id: "members-properties-report",
  rawUserId: "id",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  description: t("membersPropertiesDesc"),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  defaultSort: {
    order: "asc",
    orderBy: "customerName",
  },
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      label: t("members"),
      toLocaleString: false,
    },
    {
      key: "hasWaiver",
      aggregator: "positiveCountPercent",
      label: t("waiver"),
      toLocaleString: false,
    },
    {
      key: "hasInsurance",
      aggregator: "positiveCountPercent",
      label: t("hasInsurance"),
      toLocaleString: false,
    },
    {
      key: "hasMedicalCertificate",
      aggregator: "positiveCountPercent",
      label: t("hasMedicalCert"),
      toLocaleString: false,
    },
    {
      key: "smsSubscribe",
      aggregator: "positiveCountPercent",
      label: t("smsSubscription"),
      toLocaleString: false,
    },
    {
      key: "emailSubscribe",
      aggregator: "positiveCountPercent",
      label: t("emailSubscription"),
      toLocaleString: false,
    },
  ],
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 10,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "membershipType",
      label: t("membership"),
      valueRenderer: entity => entity.membershipType,
      width: 15,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.membersProperties.allMembersProperties || []).map(
          membership => membership.membershipType
        );
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v, label: v }));
      },
    },
    {
      id: "hasInsurance",
      label: t("insurance"),
      width: 15,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      valueRenderer: entity => t(entity.hasInsurance as Phrases),
      filterOptionsSelector: () => filterOptions.map(option => ({ id: option.id, label: t(option.label as Phrases) })),
    },
    {
      id: "hasWaiver",
      label: t("waiver"),
      width: 15,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      valueRenderer: entity => t(entity.hasWaiver as Phrases),
      filterOptionsSelector: () =>
        filterOptions.map(option => {
          return { id: option.id, label: t(option.label as Phrases) };
        }),
    },
    {
      id: "hasMedicalCertificate",
      label: t("medicalCertificate"),
      width: 15,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      valueRenderer: entity => t(entity.hasMedicalCertificate as Phrases),
      filterOptionsSelector: () => filterOptions.map(option => ({ id: option.id, label: t(option.label as Phrases) })),
    },
    {
      id: "smsSubscribe",
      label: t("smsSubscription"),
      width: 15,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      valueRenderer: entity => t(entity.smsSubscribe as Phrases),
      filterOptionsSelector: () => filterOptions.map(option => ({ id: option.id, label: t(option.label as Phrases) })),
    },
    {
      id: "emailSubscribe",
      label: t("emailSubscription"),
      width: 15,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      valueRenderer: entity => t(entity.emailSubscribe as Phrases),
      filterOptionsSelector: () => filterOptions.map(option => ({ id: option.id, label: t(option.label as Phrases) })),
    },
    {
      id: "isRestricted",
      label: t("isRestricted"),
      width: 15,
      newPage: true,
      showFilterChip: true,
      filterType: "searchable",
      valueRenderer: entity => t(entity.isRestricted as Phrases),
      filterOptionsSelector: () => filterOptions.map(option => ({ id: option.id, label: t(option.label as Phrases) })),
    },
    {
      id: "phone",
      label: t("phone"),
      width: 10,
      valueRenderer: entity => entity.phone,
    },
  ],
  fetchDataActions: [
    membersPropertiesActions.fetchAllMembersProperties,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: membersPropertiesActions.setSelectedMembersProperties,
  setSingleSelected: membersPropertiesActions.setSingleSelected,
  removeSingleSelected: membersPropertiesActions.removeSingleSelected,
  isDataLoading: state => state.membersProperties.loading,
  title: t("membersProperties"),
  isLead: false,
  csvGenerator: (entities: Record<string, unknown>[]) =>
    csvService.getMembersProperties(entities as MemberProperties[]),
  entitiesSelector: membersPropertiesSelector,
  selectedEntitiesSelector: selectedMembersPropertiesSelector,
  fetchRawData: membersPropertiesService.getAllMembersProperties,
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <EditTable />,
        textRender: item => (item.hasWaiver === "no" ? t("waiverApproved") : t("waiverUnapproved")),
      },
      {
        icon: () => <Bandage />,
        textRender: item =>
          item.hasMedicalCertificate === "no" ? t("dontHaveMedicalCertificate") : t("haveMedicalCertificate"),
      },
      {
        icon: () => <Sms />,
        textRender: item => (item.smsSubscribe === "no" ? t("smsSubscriptionOff") : t("smsSubscriptionOn")),
      },
      {
        icon: () => <Email />,
        textRender: item => (item.emailSubscribe === "no" ? t("emailSubscriptionOff") : t("emailSubscriptionOn")),
      },
      {
        icon: () => <Insurance />,
        textRender: item => (item.hasInsurance === "no" ? t("dontHaveInsurance") : t("haveInsurance")),
      },
    ],
  },
});

export default MembersPropertiesReport;
