const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
const DEFAULT_TIME_FORMAT = "hh:mm A";
const DICT_CACHE_DURATION_MS = 5 * 60 * 1000;

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKED_TIME_FORMAT = "HH:MM";
const REDUX_DATE_FORMAT = "YYYYMMDDHHmm";

export {
  DEFAULT_DATE_FORMAT,
  DICT_CACHE_DURATION_MS,
  DEFAULT_TIME_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKED_TIME_FORMAT,
  REDUX_DATE_FORMAT,
};
