import { ActiveMembershipDTO } from "../models/activeMemberships";
import { MemberDTO } from "../models/member";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const boxId = queryParamsService.getBoxId();
const client = getBaseClient();

const getActiveMembers = async (): Promise<MemberDTO[]> => {
  if (!client) {
    return [];
  }

  const result = await client.get<MemberDTO[]>(`/box/${boxId}/getActiveUsersWithMembership`);

  return result.data;
};

// TODO: MOve in a separate file and add all services for memberships and sessions
const getActiveMemberships = async (): Promise<ActiveMembershipDTO[]> => {
  if (!client) {
    return [];
  }

  const result = await client.get<ActiveMembershipDTO[]>(`/box/${boxId}/activeMembers/detailedReport/null`);

  return result.data;
};

const getTransparentMemberships = async (): Promise<ActiveMembershipDTO[]> => {
  if (!client) {
    return [];
  }

  const result = await client.get<ActiveMembershipDTO[]>(`/box/${boxId}/activeMembers/transparentReport/null`);

  return result.data;
};

const membersService = {
  getActiveMembers,
  getActiveMemberships,
  getTransparentMemberships,
};

export default membersService;
