import { createActionCreator, createReducer } from "deox";
import { Order } from "../hooks/useSorting";
import { Member } from "../models/member";

type Sort = {
  key: keyof Member;
  order: Order;
};

type State = {
  activeSort: Sort | null;
};

const changeSort = Object.assign(
  {},
  {
    update: createActionCreator("@@SORT/UPDATE", resolve => (data: Sort) => resolve(data)),
  }
);

const defaultState: State = {
  activeSort: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeSort.update, (state, action) => ({ ...state, activeSort: action.payload })),
]);

const actions = {
  changeSort,
};

export { actions, reducer };

export type { State, Sort };
