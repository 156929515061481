import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { getTaskLabel, StringOrNull, Task } from "./misc";

const toCheckedIdStatus = (checkedId: 0 | 1): string => {
  if (checkedId === 0) {
    return "No";
  }

  if (checkedId === 1) {
    return "Yes";
  }

  return "All";
};

interface TrialClassDTO extends MemberBaseDTO {
  status: string;
  checked_in: 0 | 1;
  date: string;
  time: string;
  coach: string;
  full_path_image_user: string;
  bs_id: number | null;
  bs_name: string | null;
  name: string;
  status_fk: number;
}

interface TrialClass extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  phone: StringOrNull;
  email: string;
  status: string;
  statusFk: string;
  userId: number;
  checkedIn: string;
  date: Date | string;
  time: number;
  fullDate: string;
  coach: string;
  task?: Task;
  taskLabel?: string;
  profileImagePath: string;
  sourceId: string;
  sourceName: string;
  className: string;
}

const toTrialClass = (trialClass: TrialClassDTO): TrialClass => ({
  id: trialClass.id.toString(),
  userId: trialClass.id,
  firstName: trialClass.first_name,
  lastName: trialClass.last_name,
  fullName: `${trialClass.first_name} ${trialClass.last_name}`,
  phone: trialClass.phone,
  email: trialClass.email,
  status: trialClass.status,
  statusFk: trialClass.status_fk.toString(),
  checkedIn: toCheckedIdStatus(trialClass.checked_in),
  date: moment(trialClass.date).toISOString(),
  time: moment.duration(trialClass.time).asHours(),
  fullDate: `${trialClass.date} ${trialClass.time}`,
  coach: trialClass.coach,
  task: trialClass.reminder,
  taskLabel: getTaskLabel(trialClass.reminder),
  profileImagePath: trialClass.full_path_image_user,
  sourceId: trialClass.bs_id ? trialClass.bs_id.toString() : "-1",
  sourceName: trialClass.bs_name ? trialClass.bs_name : t("noSource"),
  className: trialClass.name,
  location: trialClass.location,
});

export type { TrialClassDTO, TrialClass };

export { toTrialClass };
