import moment from "moment";
import { TransactionDTO } from "../models/transaction";
import { getBaseClient } from "./serviceUtils";

const getTransactions = async (from?: Date, to?: Date): Promise<TransactionDTO[]> => {
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<TransactionDTO[]>("reports/global/transactions", {
    fromDate,
    toDate,
  });

  return result.data;
};

const transactionService = {
  getTransactions,
};

export default transactionService;
