import React, { lazy, Suspense } from "react";
import { Route } from "react-router";
import CustomRoute from "./customRoute";

const report = lazy(() => import("./pages/report/report"));
const reportFilterDrawer = lazy(() => import("./pages/report/reportFilterDrawer"));
const reportSort = lazy(() => import("./pages/listSort"));
const reportSummary = lazy(() => import("./pages/mobileReportSummary"));
const reportActions = lazy(() => import("./pages/mobileReportActions"));
const reportMoreMenu = lazy(() => import("./pages/mobileMoreMenu"));
const reportInfo = lazy(() => import("./pages/reportInfo"));
const memberDetails = lazy(() => import("./pages/memberDetails"));
const mobileFilter = lazy(() => import("./pages/mobileFilters"));
const selectFilterPage = lazy(() => import("./pages/selectFilterPage"));
const masterSelectFilterPage = lazy(() => import("./pages/masterSelectPage"));
const masterSinglePeriodFilterPage = lazy(() => import("./pages/masterSinglePeriodPage"));
const masterDoublePeriodFilterPage = lazy(() => import("./pages/masterDoublePeriodPage"));
const periodFilterPage = lazy(() => import("./pages/periodFilterPage"));
const messagePage = lazy(() => import("./pages/messagePage"));
const multipleHoldsPage = lazy(() => import("./pages/multipleHolds"));
const multipleHoldsUpdatePage = lazy(() => import("./pages/multipleHoldsUpdate"));
const mobileMessagePage = lazy(() => import("./pages/mobileMessagePage"));
const mobileMessageSuccessPage = lazy(() => import("./pages/mobileMessageSuccessPage"));
const reportsPage = lazy(() => import("./pages/reportsPage"));
const shiftPage = lazy(() => import("./pages/shiftPage"));
const addShiftPage = lazy(() => import("./pages/addShiftPage"));
const editShiftPage = lazy(() => import("./pages/editShiftPage"));
const deleteShiftPage = lazy(() => import("./pages/deleteShiftPage"));

const Router = (): JSX.Element => (
  <Suspense fallback={<div />}>
    <Route exact path="/reports" component={reportsPage} />
    <CustomRoute exact path="/reports/:pageId" component={report} />
    <CustomRoute exact path="/reports/:pageId/filter" component={reportFilterDrawer} />
    <CustomRoute exact path="/reports/:pageId/summary" component={reportSummary} />
    <CustomRoute exact path="/reports/:pageId/actions" component={reportActions} />
    <CustomRoute exact path="/reports/:pageId/more" component={reportMoreMenu} />
    <CustomRoute exact path="/reports/:pageId/info" component={reportInfo} />
    <CustomRoute exact path="/reports/:pageId/sort" component={reportSort} />
    <CustomRoute exact path="/reports/:pageId/member-details/:memberId" component={memberDetails} />
    <CustomRoute exact path="/reports/:pageId/mobile/filter" component={mobileFilter} />
    <CustomRoute exact path="/reports/:pageId/filters/select/:filterKey/:multi" component={selectFilterPage} />
    <CustomRoute exact path="/reports/:pageId/filters/period/:filterKey" component={periodFilterPage} />
    <CustomRoute exact path="/reports/:pageId/filters/master/select/:filterKey" component={masterSelectFilterPage} />
    <CustomRoute
      exact
      path="/reports/:pageId/filters/master/singlePeriod/:filterKey"
      component={masterSinglePeriodFilterPage}
    />
    <CustomRoute
      exact
      path="/reports/:pageId/filters/master/doublePeriod/:filterKey"
      component={masterDoublePeriodFilterPage}
    />
    <CustomRoute exact path="/reports/:pageId/message/:type" component={messagePage} />
    <CustomRoute exact path="/reports/:pageId/mobile-message/:type" component={mobileMessagePage} />
    <CustomRoute exact path="/reports/:pageId/mobile-message-success/:type" component={mobileMessageSuccessPage} />
    <CustomRoute exact path="/reports/:pageId/multiple-holds" component={multipleHoldsPage} />
    <CustomRoute exact path="/reports/:pageId/multiple-holds-update" component={multipleHoldsUpdatePage} />
    <CustomRoute exact path="/reports/:pageId/shift" component={shiftPage} />
    <CustomRoute exact path="/reports/:pageId/shift/add" component={addShiftPage} />
    <CustomRoute exact path="/reports/:pageId/shift/edit/:shiftId" component={editShiftPage} />
    <CustomRoute exact path="/reports/:pageId/shift/delete/:shiftId" component={deleteShiftPage} />
  </Suspense>
);

export default Router;
