import _ from "lodash";
import moment from "moment";
import { StringOrNull, yesNoToBool, YesNoBoolean } from "./misc";

interface BookingCancellationDTO {
  id: number;
  first_name: StringOrNull;
  last_name: StringOrNull;
  category: StringOrNull;
  user_fk: number;
  date: StringOrNull;
  time: StringOrNull;
  register_time: StringOrNull;
  canceled_time: StringOrNull;
  hours_before: StringOrNull;
  email: StringOrNull;
  allow_sms: YesNoBoolean;
  allow_mailing_list: YesNoBoolean;
  location: StringOrNull;
}

interface BookingCancellation {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  class: StringOrNull;
  // TODO: Fix once the API is updated.
  classId: string;
  userId: number;
  classDate: StringOrNull;
  classTime: number | null;
  registeredAt: StringOrNull;
  cancellationTime: StringOrNull;
  hoursBeforeClass: number | null;
  email: StringOrNull;
  allowSms: boolean | null;
  allowEmail: boolean | null;
  location: StringOrNull;
}

const toBookingCancellation = (b: BookingCancellationDTO): BookingCancellation => {
  const hoursBeforeClass = b.hours_before ? +moment.duration(b.hours_before).asHours().toFixed(1) : null;
  return {
    id: b.id.toString(),
    userId: b.user_fk,
    firstName: b.first_name || "",
    lastName: b.last_name || "",
    fullName: `${b.first_name || ""} ${b.last_name || ""}`,
    // TODO: Fix once the api is updated
    classId: _.camelCase(b.category || "unknown"),
    class: b.category,
    classDate: b.date ? moment(b.date).toISOString() : null,
    classTime: b.time ? moment.duration(b.time).asHours() : null,
    registeredAt: b.register_time ? moment(b.register_time).toISOString() : null,
    cancellationTime: b.canceled_time ? moment(b.canceled_time).toISOString() : null,
    hoursBeforeClass,
    email: b.email,
    allowEmail: yesNoToBool(b.allow_mailing_list),
    allowSms: yesNoToBool(b.allow_sms),
    location: b.location,
  };
};

export type { BookingCancellation, BookingCancellationDTO };
export { toBookingCancellation };
