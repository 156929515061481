import _ from "lodash";
import moment, { Moment } from "moment";
import { ReactNode } from "react";
import { TOption } from "../components/table/types";
import t from "../infrastructure/translations/i18n";
import { Phrases } from "../infrastructure/translations/phrases";
import { Location } from "../models/location";
import { countOccurrences } from "../utils/common";
import { BaseReportEntity } from "./base";

type YesNoBoolean = "yes" | "no" | "unknown";
type YesNoEnum = "yes" | "no" | "all";
type Gender = "male" | "female";
type Task = "taskNone" | "taskFuture" | "taskPast";
type StringOrNull = string | null;
type NumberOrNull = number | null;
type BoolOrNull = boolean | null;

type SummaryFunction =
  | "count"
  | "percentage"
  | "average"
  | "mostUsedPercentage"
  | "mostUsed"
  | "lessUsed"
  | "lessUsedPercentage"
  | "positiveCount"
  | "sum"
  | "sumOfPositiveValues"
  | "positiveCountPercent"
  | "sumOfNegativeValues"
  | "averageOfPercentages";

type TSummaryItem = {
  label: string;
  value: string | number | undefined;
  icon?: ReactNode;
};

// FIXME: this is an almost duplicate of TOption
interface SelectOption<labelType = string> {
  id: number;
  label: labelType;
  icon?: React.ReactNode | JSX.Element;
}

const yesNoToBool = (input: YesNoBoolean): boolean | null =>
  ({
    yes: true,
    no: false,
    unknown: null,
  }[input]);

const getTaskLabel = (task: Task): string => t(task);

const boolToYesNo = (input: boolean | null | undefined): string => {
  if (!input) {
    return "No";
  }

  return "Yes";
};

const getTimeDifferenceInHours = (start: Moment | null, end: Moment | null): number | null => {
  if (!start || !end) {
    return null;
  }

  const difference = end.diff(start);
  const inTime = moment.duration(difference);

  return parseFloat(inTime.asHours().toFixed(1));
};

const summaryRenderValue = (
  key: string,
  aggregator: SummaryFunction,
  label: string,
  entities: BaseReportEntity[],
  dateFormat: string,
  timeFormat: string,
  specificValue?: string,
  formatLabel?: boolean,
  showSpecificValue?: boolean,
  locations?: Location[],
  currentLocationId?: number | null,
  showCurrency?: boolean | undefined,
  toLocaleString?: boolean | undefined,
  formatToTime?: boolean,
  formatToDate?: boolean,
  showHours?: boolean,
  differentKey?: string,
  hasProfilePicture?: boolean
): TSummaryItem | undefined => {
  let values = (entities || []).map(entity => entity[key]).filter(value => !!value);

  if (specificValue) {
    values = values.filter(value => value === specificValue && value);
  }

  if (aggregator === "count") {
    return {
      label,
      value: values.length,
    };
  }

  if (aggregator === "averageOfPercentages") {
    const values = (entities || []).map(entity => +entity[differentKey!]).filter(value => !!value);
    const summedValues = Math.round(_.sum(values) / values.length);
    let averagePercentage = `${summedValues}%`;

    if (!values.length) {
      averagePercentage = "0%";
    }

    return {
      label,
      value: averagePercentage,
    };
  }

  if (aggregator === "sum") {
    const summedValues = _.sum(values);

    if (showCurrency && (currentLocationId || currentLocationId === 0)) {
      const currencySymbol = (locations || []).find(item => item.id === currentLocationId);
      const value = `${currencySymbol ? currencySymbol.currency.currency_symbol : ""} ${
        toLocaleString ? summedValues.toLocaleString() : summedValues
      }`;

      return {
        label,
        value,
      };
    }

    return {
      label,
      value: summedValues.toLocaleString(),
    };
  }

  if (aggregator === "sumOfNegativeValues") {
    const sumOfAllNegativeValues = entities
      .map(entity => entity[key])
      .filter(value => value < 0)
      .reduce((acc, currentValue) => acc + currentValue, 0);

    if (showCurrency && locations && currentLocationId) {
      const currencySymbol = locations?.find(item => item.id === currentLocationId);
      const value = `${currencySymbol} ${Math.abs(sumOfAllNegativeValues)}`;

      return {
        label,
        value,
      };
    }

    return {
      label,
      value: Math.abs(sumOfAllNegativeValues),
    };
  }

  if (aggregator === "sumOfPositiveValues") {
    const sumOfPositive = entities
      .map(v => v[key])
      .filter(v => v > 0)
      .reduce((previous, next) => previous + next, 0);

    if (showCurrency && locations && currentLocationId) {
      const currencySymbol = locations?.find(item => item.id === currentLocationId);
      const value = `${currencySymbol} ${Math.abs(sumOfPositive)}`;

      return {
        label,
        value,
      };
    }

    return {
      label,
      value: Math.abs(sumOfPositive),
    };
  }

  if (aggregator === "average") {
    const averageValue = Math.floor(values.reduce((acc, item) => acc + (item || 0), 0) / values.length);

    return {
      label,
      value: showHours ? `${averageValue || 0} ${t("hours")}` : averageValue || 0,
    };
  }

  if (aggregator === "percentage") {
    const allValues = entities.map(entity => entity[key]).filter(entityValue => !!entityValue);
    const calculateToPercent = (values.length / allValues.length) * 100;
    const percentValue = Number.isInteger(calculateToPercent)
      ? `${calculateToPercent}%`
      : `${calculateToPercent.toFixed(2)}%`;

    return {
      label,
      value: !calculateToPercent ? "0%" : percentValue,
    };
  }

  if (aggregator === "mostUsedPercentage" || aggregator === "mostUsed") {
    const allValues = entities.map(entity => entity[key]).filter(entityValue => !!entityValue);
    // const profilePicture = hasProfilePicture ? "test" : null;

    const mostUsed = _.head(
      _(values)
        .filter(item => item !== " ")
        .countBy()
        .entries()
        .maxBy(_.last)
    );

    const mostUsedCount = countOccurrences(values, mostUsed);
    const calculateToPercent = (mostUsedCount / allValues.length) * 100;
    const percentageValue = Number.isInteger(calculateToPercent)
      ? `${calculateToPercent}%`
      : `${calculateToPercent.toFixed(2)}%`;

    if (allValues.length === 0 || !allValues) {
      return {
        label: !formatLabel ? `${t("none")} (${label})` : label,
        value: aggregator === "mostUsedPercentage" ? "0%" : "N/A",
      };
    }

    if (aggregator === "mostUsed") {
      if (formatToDate && typeof mostUsed === "string") {
        const formattedValue = moment(mostUsed).format(dateFormat);

        return {
          label: !formatLabel ? `${formattedValue} (${label})` : label,
          value: showSpecificValue ? formattedValue : mostUsedCount,
        };
      }

      if (formatToTime && typeof mostUsed === "string") {
        const formattedValue = `${moment(mostUsed).format("HH")}:00`;

        return {
          label: !formatLabel ? `${formattedValue} (${label})` : label,
          value: showSpecificValue ? formattedValue : mostUsedCount,
        };
      }

      return {
        label: !formatLabel ? `${mostUsed} (${label})` : label,
        value: showSpecificValue ? mostUsed : mostUsedCount,
        // icon: profilePicture,
      };
    }

    return {
      label: !formatLabel ? `${mostUsed} (${label})` : t(label as Phrases, { [key]: mostUsed }),
      value: percentageValue,
    };
  }

  if (aggregator === "lessUsed" || aggregator === "lessUsedPercentage") {
    const allValues = entities.map(entity => entity[key]).filter(entityValue => !!entityValue);
    const lessUsed = _.head(
      _(values)
        .filter(item => item !== " ")
        .countBy()
        .entries()
        .minBy(_.last)
    );

    const lessUsedCount = countOccurrences(values, lessUsed);
    const calculateToPercent = (lessUsedCount / allValues.length) * 100;
    const percentageValue = Number.isInteger(calculateToPercent)
      ? `${calculateToPercent}%`
      : `${calculateToPercent.toFixed(2)}%`;

    if (allValues.length === 0 || !allValues) {
      return {
        label: !formatLabel ? `${t("none")} (${label})` : label,
        value: aggregator === "lessUsedPercentage" ? "0%" : "N/A",
      };
    }

    if (aggregator === "lessUsed") {
      if (formatToDate && typeof lessUsed === "string") {
        const formattedValue = moment(lessUsed).format(dateFormat);

        return {
          label: !formatLabel ? `${formattedValue} (${label})` : label,
          value: showSpecificValue ? formattedValue : lessUsedCount,
        };
      }

      if (formatToTime && typeof lessUsed === "string") {
        const formattedValue = moment(lessUsed).format(timeFormat);

        return {
          label: !formatLabel ? `${formattedValue} (${label})` : label,
          value: showSpecificValue ? formattedValue : lessUsedCount,
        };
      }
      return {
        label: !formatLabel ? `${lessUsed} (${label})` : label,
        value: showSpecificValue ? lessUsed : lessUsedCount,
      };
    }

    return {
      label: !formatLabel ? `${lessUsed} (${label})` : t(label as Phrases, { [key]: lessUsed }),
      value: percentageValue,
    };
  }

  if (aggregator === "positiveCount" || aggregator === "positiveCountPercent") {
    const allValues = entities.map(entity => entity[key]).filter(entityValue => !!entityValue);
    const byValue = specificValue ? specificValue : "yes";
    const positiveCount = countOccurrences(values, byValue);
    const calculateToPercent = (positiveCount / allValues.length) * 100;
    const percentageValue = Number.isInteger(calculateToPercent)
      ? `${calculateToPercent}%`
      : `${calculateToPercent.toFixed(2)}%`;

    if (aggregator === "positiveCount") {
      return {
        label,
        value: positiveCount,
      };
    }

    return {
      label,
      value: percentageValue,
    };
  }
};

const dateToDecimalTime = (value: string | null, returnCurrentHour = false): number | null => {
  if (!value) {
    const toTime = moment().format("HH:mm");
    const currentTime = moment.duration(toTime).asHours();
    return returnCurrentHour ? currentTime : null;
  }

  const toTime = moment(value).format("HH:mm");
  return moment.duration(toTime).asHours();
};

const daysOfWeek: SelectOption[] = [
  { id: 0, label: "sunday" },
  { id: 1, label: "monday" },
  { id: 2, label: "tuesday" },
  { id: 3, label: "wednesday" },
  { id: 4, label: "thursday" },
  { id: 5, label: "friday" },
  { id: 6, label: "saturday" },
];

const toDayOfWeek = (value: number | string | null): TOption | null => {
  if (!value && value !== 0) {
    return null;
  }

  if (typeof value === "number") {
    return { id: value.toString(), label: moment().day(value).format("dddd").toLowerCase() };
  }

  const digit = value.match(/\d/g);

  if (!digit) {
    return null;
  }
  const onlyNumbers = digit.join("");

  return {
    id: onlyNumbers,
    label: moment()
      .day(+onlyNumbers)
      .format("dddd")
      .toLowerCase(),
  };
};

// TODO: Almost duplicate in membersOnHoldReport
const holdReasons: SelectOption[] = [
  { id: 1, label: "holdReasonMedicalReason" },
  { id: 2, label: "holdReasonMilitaryDuty" },
  { id: 3, label: "holdReasonVacation" },
  { id: 4, label: "holdReasonStudies" },
  { id: 5, label: "holdReasonOther" },
  { id: 6, label: "holdReasonPregnancy" },
  { id: 7, label: "holdReasonPersonal" },
  { id: 8, label: "holdReasonGrief" },
  { id: 9, label: "holdReasonMarriage" },
  { id: 10, label: "holdReasonCoronavirus" },
];

export type {
  YesNoBoolean,
  YesNoEnum,
  Gender,
  Task,
  SummaryFunction,
  SelectOption,
  TSummaryItem,
  StringOrNull,
  NumberOrNull,
  BoolOrNull,
};

export {
  boolToYesNo,
  yesNoToBool,
  getTaskLabel,
  holdReasons,
  summaryRenderValue,
  getTimeDifferenceInHours,
  dateToDecimalTime,
  daysOfWeek,
  toDayOfWeek,
};
