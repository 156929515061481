import { DebtDTO } from "../models/debt";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getAllDebts = async (): Promise<DebtDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const result = await client.get<DebtDTO[]>(`/box/${boxId}/membersDebts`);

  return result.data;
};

const debtService = {
  getAllDebts,
};

export { debtService };
