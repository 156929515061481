import moment from "moment";
import { AttendanceExternalMemberDTO } from "../models/attendanceExternalMembers";
import { getBaseClient } from "./serviceUtils";

const getAttendanceExternalMembersService = async (from?: Date, to?: Date): Promise<AttendanceExternalMemberDTO[]> => {
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const response = await client.post<AttendanceExternalMemberDTO[]>("reports/global/externalAttendanceReport", {
    fromDate,
    locationFk: "1,2,21",
    toDate,
  });

  return response.data;
};

const attendanceExternalMembersService = {
  getAttendanceExternalMembersService,
};

export default attendanceExternalMembersService;
