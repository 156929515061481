import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { InactiveMember, toInactiveMember } from "../models/inactiveMembers";
import inactiveMembersService from "../services/inactiveMembersService";
import { ApplicationState } from "./store";

interface State {
  all: InactiveMember[] | null;
  selected: InactiveMember[] | null;
  loading: boolean;
}

interface TAllInactiveMembersPayload {
  all: InactiveMember[] | null;
}

interface TSelectedInactiveMembersPayload {
  selected: InactiveMember[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchInactiveMembers = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { inactiveMembers } = getState();

    if (inactiveMembers.all) {
      return;
    }

    dispatch(fetchInactiveMembers.start());

    try {
      const data = await inactiveMembersService.getInactiveMembers();
      dispatch(fetchInactiveMembers.success({ all: data.map(toInactiveMember) }));
    } catch {
      dispatch(fetchInactiveMembers.failure);
    }
  },
  {
    start: createActionCreator("@@INACTIVE_MEMBERS/START_FETCH"),
    success: createActionCreator("@@INACTIVE_MEMBERS/SUCCESS_FETCH", resolve => (payload: TAllInactiveMembersPayload) =>
      resolve(payload.all)
    ),
    failure: createActionCreator("@@INACTIVE_MEMBERS/FAILURE_FETCH"),
  }
);

const setSelectedInactiveMembers = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { inactiveMembers } = getState();

    if (!inactiveMembers.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedInactiveMembers.success({ selected: null }));
      return;
    }

    const selected = inactiveMembers.all.filter(inactiveMember => selectedId.includes(inactiveMember.id));

    dispatch(setSelectedInactiveMembers.success({ selected: selected }));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_INACTIVE_MEMBERS",
      resolve => (payload: TSelectedInactiveMembersPayload) => resolve(payload.selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_INACTIVE_MEMBERS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { inactiveMembers } = getState();

    if (!inactiveMembers.all) {
      return;
    }

    const selected = inactiveMembers.all.find(member => member.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SINGLE_SELECTED_INACTIVE_MEMBER",
      resolve => (payload: InactiveMember) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_INACTIVE_MEMBER/ERROR"),
  }
);

const removeSelectedInactiveMember = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { inactiveMembers } = getState();

    if (!inactiveMembers.all) {
      return;
    }

    const selected = inactiveMembers.all.find(member => member.id === selectedId);

    if (!selected) {
      return dispatch(removeSelectedInactiveMember.error());
    }

    dispatch(removeSelectedInactiveMember.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/REMOVE_SELECTED_LEADS", resolve => (payload: InactiveMember) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SELECTED_LEADS/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchInactiveMembers.start, state => ({ ...state, loading: true })),
  handleAction(fetchInactiveMembers.success, (state, { payload }) => ({
    ...state,
    loading: false,
    all: payload,
  })),
  handleAction(fetchInactiveMembers.failure, state => ({ ...state, loading: false })),
  handleAction(setSelectedInactiveMembers.success, (state, { payload }) => ({
    ...state,
    selected: payload,
  })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelectedInactiveMember.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchInactiveMembers,
  setSelectedInactiveMembers,
  setSingleSelected,
  removeSelectedInactiveMember,
};

export { actions, reducer };

export type { State };
