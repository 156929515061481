import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { RestrictedMember } from "../../models/restrictedMembers";
import csvService from "../../services/csvService";
import { restrictedMembersService } from "../../services/restrictedMembersService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as restrictedMembersActions } from "../../store/restrictedMembersStore";
import { restrictedMembersSelector, selectedRestrictedMembersSelector } from "../reportSelector";
import { Page } from "./page";

const RestrictedMembersReport = (dateFormat: string): Page<RestrictedMember> => ({
  id: "restricted-members-report",
  description: t("restrictedMembersDesc"),
  searchTerms: ["fullName", "phone"],
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "lastSeen",
      label: t("lastSeen"),
    },
  ],
  defaultSort: {
    order: "asc",
    orderBy: "fullName",
  },
  summaryItems: [
    {
      key: "id",
      label: t("restrictedMembers"),
      aggregator: "count",
      toLocaleString: false,
    },
  ],
  fetchDataActions: [
    restrictedMembersActions.fetchRestrictedMembers,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: restrictedMembersActions.setSelected,
  setSingleSelected: restrictedMembersActions.setSingleSelected,
  removeSingleSelected: restrictedMembersActions.removeSingleSelected,
  isDataLoading: state => state.restrictedMembers.loading,
  title: t("restrictedMembers"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 15,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "phone",
      label: t("phone"),
      width: 15,
      valueRenderer: entity => entity.phone,
    },
    {
      id: "lastSeen",
      label: t("lastSeen"),
      valueRenderer: item => (item.lastSeen ? moment(item.lastSeen).format(dateFormat) : t("never")),
      width: 15,
    },
    {
      id: "email",
      label: t("email"),
      width: 15,
      valueRenderer: entity => entity.email,
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getRestrictedMembers(entities, dateFormat),
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: item => <Calendar />,
        textRender: item =>
          t("lastSeenPeriod", {
            lastSeenPeriod: item.lastSeen ? moment(item.lastSeen).format(dateFormat) : t("never"),
          }),
      },
    ],
  },
  entitiesSelector: restrictedMembersSelector,
  selectedEntitiesSelector: selectedRestrictedMembersSelector,
  fetchRawData: restrictedMembersService.getRestrictedMembers,
});

export default RestrictedMembersReport;
