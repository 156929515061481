import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { Phrases } from "../infrastructure/translations/phrases";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";

const toHoldReason = (holdReasonId: number | null): string | null => {
  switch (holdReasonId) {
    case 1:
      return "holdReasonMedicalReason";
    case 2:
      return "holdReasonMilitaryDuty";
    case 3:
      return "holdReasonVacation";
    case 4:
      return "holdReasonStudies";
    case 5:
      return "holdReasonOther";
    case 6:
      return "holdReasonPregnancy";
    case 7:
      return "holdReasonPersonal";
    case 8:
      return "holdReasonGrief";
    case 9:
      return "holdReasonMarriage";
    case 10:
      return "holdReasonCoronavirus";
    default:
      return "unknownHoldReason";
    // return null;
  }
};

interface MembersOnHoldDTO extends MemberBaseDTO {
  start_date: string | null;
  end_date: string | null;
  total_days: number | null;
  suspend_reason_fk: number | null;
  name: string | null;
  membership_user_fk: number;
  id: number;
  created_by_user: string | null;
  location_box_fk: number;
}

interface MembersOnHold extends ReportEntity {
  start: string | null;
  end: string | null;
  holdDays: number | null;
  holdId: number;
  membershipId: number;
  holdReasonId: number;
  holdReason: string;
  userId: number;
  membershipType: string | null;
  doneBy: string | null;
}

const toMemberOnHold = (member: MembersOnHoldDTO): MembersOnHold => ({
  id: member.id.toString(),
  firstName: member.first_name,
  userId: member.user_fk,
  lastName: member.last_name,
  holdId: member.id,
  membershipId: member.membership_user_fk,
  fullName: `${member.first_name} ${member.last_name}`,
  start: member.start_date ? moment(member.start_date).toISOString() : null,
  end: member.end_date ? moment(member.end_date).toISOString() : null,
  holdDays: member.total_days,
  holdReasonId: member.suspend_reason_fk ? member.suspend_reason_fk : -1,
  holdReason: t(toHoldReason(member.suspend_reason_fk) as Phrases),
  membershipType: member.name,
  doneBy: member.created_by_user,
  location: member.location,
  locationId: member.location_box_fk,
});

export type { MembersOnHoldDTO, MembersOnHold };
export { toMemberOnHold };
