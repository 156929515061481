import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { Phrases } from "../infrastructure/translations/phrases";
import { Absence } from "../models/absence";
import { ActiveMembership } from "../models/activeMemberships";
import { AttendanceExternalMember } from "../models/attendanceExternalMembers";
import { Birthday } from "../models/birthday";
import { BookingCancellation } from "../models/bookingCancellation";
import { CanceledMemberships } from "../models/canceledMemberships";
import { ClassesSummary } from "../models/classesSummary";
import { ConvertedLead } from "../models/convertedLeads";
import { Debt } from "../models/debt";
import { EmployeeAttendance } from "../models/employeeAttendancy";
import { Entrance } from "../models/entrance";
import { ExpiredMemberships } from "../models/expiredMemberships";
import { ExpiredSessionPack } from "../models/expiredSessionPacks";
import { ExpiringMembership } from "../models/expiringMemberships";
import { ExpiringSession } from "../models/expiringSessions";
import { ExternalMembership } from "../models/externalMembership";
import { GroupMember } from "../models/groupMembers";
import { InactiveMember } from "../models/inactiveMembers";
import { LateCancellation } from "../models/lateCancellation";
import { Lead } from "../models/leads";
import { LeadInProcess } from "../models/leadsInProcess";
import { LeadStatistics } from "../models/leadsStatistics";
import { LostLead } from "../models/lostLeads";
import { Member } from "../models/member";
import { MembershipForecast } from "../models/membershipForecast";
import { MembersOnHold } from "../models/membersOnHold";
import { MemberProperties } from "../models/membersProperties";
import { boolToYesNo } from "../models/misc";
import { Preservation } from "../models/preservation";
import { Regular } from "../models/regulars";
import { Renewal } from "../models/renewals";
import { RestrictedMember } from "../models/restrictedMembers";
import { Sales } from "../models/sales";
import { Session } from "../models/sessions";
import { ShiftSummary } from "../models/shiftSummary";
import { StaffAction } from "../models/staffActions";
import { Transaction } from "../models/transaction";
import { TrialClass } from "../models/trialClasses";

const convertToCsv = <TEntity>(entities: TEntity[]): string => {
  if (!entities.length) {
    return "";
  }

  const header = Object.keys(entities[0]).join(",");
  const values = entities.map(entity => Object.values(entity).join(","));

  return [header, ...values].join("\n");
};

const getActiveMembersCsv = (members: Member[], dateFormat: string): string => {
  const data = members.map(member => ({
    [t("customerName")]: member.fullName,
    [t("gender")]: t(member.gender as Phrases),
    [t("age")]: member.age,
    [t("phone")]: member.phone,
    [t("createdAt")]: moment(member.createdAt).format(dateFormat),
    [t("organization")]: member.organization,
    [t("taskStatus")]: member.task,
    [t("email")]: member.email,
    [t("location")]: member.location,
    [t("emailSubscription")]: boolToYesNo(member.allowEmails),
    [t("smsSubscription")]: boolToYesNo(member.allowSMS),
  }));

  return convertToCsv(data);
};

const getAllLeadsCsv = (leads: Lead[], dateFormat: string): string => {
  const data = leads.map(lead => ({
    [t("customerName")]: lead.fullName,
    [t("phone")]: lead.phone,
    [t("leadStatus")]: lead.status,
    [t("source")]: lead.source,
    [t("trialClass")]: lead.trialClasses,
    [t("createdAt")]: moment(lead.createdAt).format(dateFormat),
    [t("updatedAt")]: moment(lead.updatedAt).format(dateFormat),
    [t("taskStatus")]: lead.task,
    [t("location")]: lead.location,
  }));

  return convertToCsv(data);
};

const getAllExpiringMembershipsCsv = (expiringMembers: ExpiringMembership[], dateFormat: string): string => {
  const data = expiringMembers.map(expiringMember => ({
    [t("customerName")]: expiringMember.fullName,
    [t("phone")]: expiringMember.phone,
    [t("membershipType")]: expiringMember.membershipType,
    [t("isCoordinator")]: expiringMember.head,
    [t("startedAt")]: moment(expiringMember.startedAt).format(dateFormat),
    [t("endedAt")]: moment(expiringMember.endedAt).format(dateFormat),
    [t("sms")]: expiringMember.allowSMS,
    [t("email")]: expiringMember.allowEmails,
    [t("location")]: expiringMember.location,
    [t("tasks")]: t(expiringMember.task as Phrases),
  }));

  return convertToCsv(data);
};

const getAllLeadsInProcess = (leadsInProcess: LeadInProcess[], dateFormat: string): string => {
  const data = leadsInProcess.map(leadsInProcess => ({
    [t("customerName")]: leadsInProcess.fullName,
    [t("gender")]: leadsInProcess.gender,
    [t("email")]: leadsInProcess.email,
    [t("phone")]: leadsInProcess.phone,
    [t("status")]: leadsInProcess.leadStatus,
    [t("source")]: leadsInProcess.leadSource,
    [t("createdAt")]: moment(leadsInProcess.createdAt).format(dateFormat),
    [t("updatedAt")]: moment(leadsInProcess.updatedAt).format(dateFormat),
    [t("tasks")]: leadsInProcess.task,
    [t("allowSms")]: boolToYesNo(leadsInProcess.allowSMS),
    [t("allowEmail")]: boolToYesNo(leadsInProcess.allowEmail),
    [t("location")]: leadsInProcess.location,
  }));

  return convertToCsv(data);
};

const getActiveMembershipCsv = (activeMemberships: ActiveMembership[], dateFormat: string): string => {
  const data = activeMemberships.map(activeMembership => ({
    [t("customerName")]: activeMembership.fullName,
    [t("phone")]: activeMembership.phone || "",
    [t("email")]: activeMembership.email,
    [t("department")]: activeMembership.department,
    [t("membership")]: activeMembership.membershipType,
    [t("status")]: t(activeMembership.status as Phrases),
    [t("originalPrice")]: activeMembership.price,
    [t("paid")]: activeMembership.paid,
    [t("startedAt")]: activeMembership.start && moment(activeMembership.start).format(dateFormat),
    [t("endedAt")]: activeMembership.end && moment(activeMembership.end).format(dateFormat),
    [t("emailSubscription")]: activeMembership.allowEmails,
    [t("smsSubscription")]: activeMembership.allowSMS,
    [t("createdAt")]: moment(activeMembership.createdAt).format(dateFormat),
  }));

  return convertToCsv(data);
};

const getAllExpiredMemberships = (expiredMemberships: ExpiredMemberships[], dateFormat: string): string => {
  const data = expiredMemberships.map(expiredMembership => ({
    [t("customerName")]: expiredMembership.fullName,
    [t("phone")]: expiredMembership.phone,
    [t("membershipType")]: expiredMembership.membershipType,
    [t("isCoordinator")]: t(expiredMembership.isCoordinator as Phrases),
    [t("membershipCanceled")]: t(expiredMembership.membershipStatus as Phrases),
    [t("endedAt")]: moment(expiredMembership.endedAt).format(dateFormat),
    [t("status")]: t(expiredMembership.customerStatus as Phrases),
    [t("location")]: expiredMembership.location,
  }));

  return convertToCsv(data);
};

const getAllBirthdays = (birthdays: Birthday[], dateFormat: string): string => {
  const data = birthdays.map(birthday => ({
    [t("customerName")]: birthday.fullName,
    [t("phone")]: birthday.phone,
    [t("birthday")]: moment(birthday.birthdayDate).format(dateFormat),
    [t("age")]: birthday.age,
    [t("allowSms")]: birthday.allowSMS,
    [t("allowEmail")]: birthday.allowEmail,
    [t("location")]: birthday.location,
  }));

  return convertToCsv(data);
};

const getAllLostLeads = (lostLeads: LostLead[], dateFormat: string): string => {
  const data = lostLeads.map(lostLead => ({
    [t("customerName")]: lostLead.fullName,
    [t("gender")]: t(lostLead.gender as Phrases),
    [t("phone")]: lostLead.phone,
    [t("email")]: lostLead.email,
    [t("lostReason")]: t(lostLead.lostReason as Phrases),
    [t("createdAt")]: moment(lostLead.createdAt).format(dateFormat),
    [t("lostDate")]: moment(lostLead.lostDate).format(dateFormat),
    [t("leadSource")]: lostLead.leadSource,
    [t("allowSms")]: lostLead.allowSms,
    [t("allowEmail")]: lostLead.allowEmail,
    [t("location")]: lostLead.location,
  }));

  return convertToCsv(data);
};

const getConvertedLeads = (leads: ConvertedLead[], dateFormat: string): string => {
  const data = leads.map(lead => ({
    [t("customerName")]: lead.fullName,
    [t("convertedAt")]: moment(lead.convertedAt).format(dateFormat),
    [t("membershipType")]: lead.membershipType,
    [t("doneBy")]: lead.doneByUser,
    [t("createdAt")]: moment(lead.createdAt).format(dateFormat),
    [t("source")]: lead.source,
    [t("phone")]: lead.phone,
    [t("email")]: lead.email,
    [t("location")]: lead.location,
  }));

  return convertToCsv(data);
};

const getTrialClasses = (trialClasses: TrialClass[], dateFormat: string): string => {
  const data = trialClasses.map(trialClass => ({
    [t("customerName")]: trialClass.fullName,
    [t("phone")]: trialClass.phone,
    [t("email")]: trialClass.email,
    [t("status")]: trialClass.status,
    [t("checkedIn")]: trialClass.checkedIn,
    [t("classDate")]: moment(trialClass.date).format(dateFormat),
    [t("classTime")]: trialClass.time,
    [t("coaches")]: trialClass.coach,
    [t("taskStatus")]: trialClass.task,
    [t("source")]: trialClass.sourceName,
    [t("class")]: trialClass.className,
    [t("location")]: trialClass.location,
  }));

  return convertToCsv(data);
};

const getExpiredSessionPacks = (expiredSessionPacks: ExpiredSessionPack[], dateFormat: string): string => {
  const data = expiredSessionPacks.map(expiredSessionPack => ({
    [t("customerName")]: expiredSessionPack.fullName,
    [t("phone")]: expiredSessionPack.phone,
    [t("email")]: expiredSessionPack.email,
    [t("membershipType")]: expiredSessionPack.membershipType,
    [t("sessionsLeft")]: expiredSessionPack.sessionsLeft,
    [t("endedAt")]: moment(expiredSessionPack.endedAt).format(dateFormat),
    [t("membershipStatus")]: expiredSessionPack.membershipStatus,
    [t("location")]: expiredSessionPack.location,
  }));

  return convertToCsv(data);
};

const getExpiringSessions = (sessions: ExpiringSession[], dateFormat: string): string => {
  const data = sessions.map(session => ({
    [t("customerName")]: session.fullName,
    [t("phone")]: session.phone,
    [t("email")]: session.email,
    [t("membershipType")]: session.membershipType,
    [t("startedAt")]: moment(session.start).format(dateFormat),
    [t("expiredAt")]: moment(session.end).format(dateFormat),
    [t("sessionsLeft")]: session.sessionsLeft,
    [t("futureBooking")]: session.futureBooking,
    [t("membershipStatus")]: t(session.futureMembership as Phrases),
    [t("allowSms")]: boolToYesNo(session.allowSMS),
    [t("allowEmail")]: boolToYesNo(session.allowEmails),
    [t("location")]: session.location,
    [t("taskStatus")]: t(session.task as Phrases),
  }));

  return convertToCsv(data);
};

const getRenewals = (renewals: Renewal[], dateFormat: string): string => {
  const data = renewals.map(renewal => ({
    [t("customerName")]: renewal.fullName,
    [t("phone")]: renewal.phone,
    [t("email")]: renewal.email,
    [t("membershipType")]: renewal.membershipType,
    [t("createdAt")]: moment(renewal.createdAt).format(dateFormat),
    [t("doneBy")]: renewal.doneByUser,
    [t("location")]: renewal.location,
  }));

  return convertToCsv(data);
};

const getSessions = (sessions: Session[], dateFormat: string): string => {
  const data = sessions.map(session => ({
    [t("customerName")]: session.fullName,
    [t("phone")]: session.phone,
    [t("email")]: session.email,
    [t("department")]: session.department,
    [t("membershipType")]: session.membershipType,
    [t("status")]: t(session.status as Phrases),
    [t("price")]: session.price,
    [t("paid")]: session.paid,
    [t("startedAt")]: moment(session.start).format(dateFormat),
    [t("expiredAt")]: moment(session.end).format(dateFormat),
    [t("sessionsLeft")]: session.sessionsLeft,
    [t("futureBooking")]: session.futureBooking,
    [t("totalSessions")]: session.totalSessions,
    [t("createdAt")]: moment(session.createdAt).format(dateFormat),
    [t("allowSms")]: boolToYesNo(session.allowSMS),
    [t("allowEmail")]: boolToYesNo(session.allowEmails),
    [t("location")]: session.location,
  }));

  return convertToCsv(data);
};

const getTransparentSessionPacks = (transparentSessionPacks: Session[], dateFormat: string): string => {
  const data = transparentSessionPacks.map(tsp => ({
    [t("customerName")]: tsp.fullName,
    [t("phone")]: tsp.phone,
    [t("email")]: tsp.email,
    [t("department")]: tsp.department,
    [t("membershipType")]: tsp.membershipType,
    [t("status")]: t(tsp.status as Phrases),
    [t("price")]: tsp.price,
    [t("paid")]: tsp.paid,
    [t("startedAt")]: moment(tsp.start).format(dateFormat),
    [t("expiredAt")]: moment(tsp.end).format(dateFormat),
    [t("sessionsLeft")]: tsp.sessionsLeft,
    [t("futureBooking")]: tsp.futureBooking,
    [t("totalSessions")]: tsp.totalSessions,
    [t("createdAt")]: moment(tsp.createdAt).format(dateFormat),
    [t("allowSms")]: boolToYesNo(tsp.allowSMS),
    [t("allowEmail")]: boolToYesNo(tsp.allowEmails),
    [t("location")]: tsp.location,
  }));

  return convertToCsv(data);
};

const getDebts = (debts: Debt[], dateFormat: string): string => {
  const data = debts.map(debt => ({
    [t("customerName")]: debt.fullName,
    [t("membershipStatus")]: debt.membershipStatus,
    [t("membership")]: debt.membership,
    [t("price")]: debt.price,
    [t("debt")]: debt.debt,
    [t("createdAt")]: moment(debt.createdAt).format(dateFormat),
    [t("doneBy")]: debt.doneBy,
    [t("location")]: debt.location,
  }));

  return convertToCsv(data);
};

const getInactiveMembers = (inactiveMembers: InactiveMember[]): string => {
  const data = inactiveMembers.map(inactiveMember => ({
    [t("customerName")]: inactiveMember.fullName,
    [t("phone")]: inactiveMember.phone,
    [t("email")]: inactiveMember.email,
    [t("allowSms")]: inactiveMember.allowSMS,
    [t("allowEmail")]: inactiveMember.allowEmails,
    [t("location")]: inactiveMember.location,
  }));

  return convertToCsv(data);
};

const getAllSales = (sales: Sales[], dateFormat: string): string => {
  const data = sales.map(sale => ({
    [t("customerName")]: sale.fullName,
    [t("saleDate")]: moment(sale.saleDate).format(dateFormat),
    [t("department")]: sale.departmentName,
    [t("salePerson")]: sale.doneBy,
    [t("productType")]: sale.productType,
    [t("membership")]: sale.membershipName,
    [t("originalPrice")]: sale.sellingPrice,
    [t("discount")]: sale.discount,
    [t("amountPaid")]: sale.paid,
    [t("balance")]: sale.balance,
    [t("incomes")]: sale.value,
    [t("saleType")]: sale.saleType,
    [t("paymentType")]: sale.paymentType,
    [t("location")]: sale.location,
  }));

  return convertToCsv(data);
};

const getGroupMember = (groupMember: GroupMember[], dateFormat: string): string => {
  const data = groupMember.map(g => ({
    [t("customerName")]: g.fullName,
    [t("phone")]: g.phone,
    [t("membership")]: g.membership,
    [t("numberOfMembers")]: g.membersNumber,
    [t("startedAt")]: g.startedAt ? moment(g.startedAt).format(dateFormat) : null,
    [t("endedAt")]: g.endedAt ? moment(g.endedAt).format(dateFormat) : null,
    [t("price")]: g.price,
    [t("paid")]: g.paid,
    [t("location")]: g.location,
  }));

  return convertToCsv(data);
};

const getCanceledMemberships = (canceledMemberships: CanceledMemberships[], dateFormat: string): string => {
  const data = canceledMemberships.map(canceledMembership => ({
    [t("customerName")]: canceledMembership.fullName,
    [t("phone")]: canceledMembership.phone,
    [t("email")]: canceledMembership.email,
    [t("department")]: canceledMembership.department,
    [t("membership")]: canceledMembership.membership,
    [t("startedAt")]: moment(canceledMembership.startedAt).format(dateFormat),
    [t("endedAt")]: moment(canceledMembership.endedAt).format(dateFormat),
    [t("cancelReason")]: canceledMembership.cancelReason,
    [t("cancelComment")]: canceledMembership.cancelComment,
    [t("doneBy")]: canceledMembership.doneBy,
    [t("salesPerson")]: canceledMembership.salesPerson,
    [t("location")]: canceledMembership.location,
  }));

  return convertToCsv(data);
};

const getMembersOnHold = (members: MembersOnHold[], dateFormat: string): string => {
  const data = members.map(m => ({
    [t("customerName")]: m.firstName,
    [t("holdStartedAt")]: m.start ? moment(m.start).format(dateFormat) : "",
    [t("holdEndedAt")]: m.end ? moment(m.end).format(dateFormat) : "",
    [t("suspendReason")]: t(m.holdReason as Phrases),
    [t("membership")]: m.membership,
    [t("doneBy")]: m.doneBy,
    [t("location")]: m.location,
  }));

  return convertToCsv(data);
};

const getEmployeeAttendance = (
  attendance: EmployeeAttendance[],
  dateFormat: string,
  timeFormat: string,
  location?: string
): string => {
  const data = attendance.map(a => ({
    [t("customerName")]: a.fullName,
    [t("date")]: a.date ? moment(a.date).format(dateFormat) : "",
    [t("day")]: a.day ? a.day : "",
    [t("enteringTime")]: a.enteringTime ? moment(a.enteringTime).format(timeFormat) : "",
    [t("enteringLocation")]: a.enteringLocation || "",
    [t("exitingTime")]: a.exitingTimeFull ? moment(a.exitingTimeFull).format(timeFormat) : "",
    [t("exitingLocation")]: a.exitingLocation || "",
    [t("totalHours")]: a.totalHours || "",
    [t("location")]: location || t("unknown"),
  }));

  return convertToCsv(data);
};

const getMembersProperties = (membersProperties: MemberProperties[]): string => {
  const data = membersProperties.map(memberProperties => ({
    [t("customerName")]: memberProperties.fullName,
    [t("membership")]: memberProperties.membershipType,
    [t("insurance")]: t(memberProperties.hasInsurance as Phrases),
    [t("medicalCertificate")]: t(memberProperties.hasMedicalCertificate as Phrases),
    [t("allowSms")]: memberProperties.allowSMS,
    [t("allowEmail")]: memberProperties.allowEmails,
    [t("phone")]: memberProperties.phone,
    [t("email")]: memberProperties.email,
    [t("location")]: memberProperties.location,
  }));

  return convertToCsv(data);
};

const getEntrances = (entrances: Entrance[], dateFormat: string, timeFormat: string): string => {
  const data = entrances.map(e => ({
    [t("customerName")]: e.fullName,
    [t("entranceDate")]: e.entranceDate ? moment(e.entranceDate).format(dateFormat) : t("unknown"),
    [t("entranceTime")]: e.entranceTime ? moment(e.entranceTime).format(timeFormat) : t("unknown"),
    [t("membership")]: e.membership,
    [t("expiredAt")]: e.expiredAt ? moment(e.expiredAt).format(dateFormat) : t("unknown"),
    [t("sessionsLeft")]: e.sessionsLeft ? e.sessionsLeft : t("unknown"),
    [t("entranceWithFab")]: t(e.enteredWithFab as Phrases),
    [t("email")]: e.email,
    [t("phone")]: e.phone,
    [t("location")]: e.location,
  }));

  return convertToCsv(data);
};

const getAllTransactions = (transactions: Transaction[], dateFormat: string): string => {
  const data = transactions.map(transaction => ({
    [t("customerName")]: transaction.fullName,
    [t("transactionDate")]: transaction.transactionDate ? moment(transaction.transactionDate).format(dateFormat) : "",
    [t("customerId")]: transaction.customerId,
    [t("membership")]: transaction.membershipType,
    [t("transactionType")]: transaction.transactionType,
    [t("amount")]: transaction.amount,
    [t("numberOfPayment")]: transaction.numberOfPayments,
    [t("endsWith")]: transaction.endsWith,
    [t("voucher")]: transaction.voucherLink,
    [t("transactionStatus")]: transaction.status,
    [t("doneBy")]: transaction.doneBy,
  }));

  return convertToCsv(data);
};

const getShiftSummaries = (shiftSummaries: ShiftSummary[], dateFormat: string, timeFormat: string): string => {
  const data = shiftSummaries.map(s => ({
    [t("customerName")]: s.fullName,
    [t("date")]: s.date ? moment(s.date).format(dateFormat) : "",
    [t("day")]: s.exactDay,
    [t("time")]: s.time ? moment(s.time).format(timeFormat) : "",
    [t("workout")]: s.workout,
    [t("coach")]: s.coachFullName,
    [t("customerType")]: s.customerType,
    [t("phone")]: s.phone,
    [t("membership")]: s.membershipType,
    [t("expiredAt")]: s.endMembership ? moment(s.endMembership).format(dateFormat) : "",
    [t("sessionsLeft")]: s.sessionsLeft,
    [t("debt")]: s.debt,
    [t("injuries")]: s.injury,
    [t("checkedIn")]: s.checkedIn,
    [t("location")]: s.location,
    [t("email")]: s.email,
  }));

  return convertToCsv(data);
};

const getRegulars = (regulars: Regular[]): string => {
  const data = regulars.map(regular => ({
    [t("customerName")]: regular.fullName,
    [t("seriesName")]: regular.seriesName,
    [t("class")]: regular.class,
    [t("day")]: regular.day,
    [t("time")]: regular.scheduleTime,
    [t("coach")]: regular.coach,
    [t("status")]: t(regular.membershipActive as Phrases),
    [t("location")]: regular.location,
  }));

  return convertToCsv(data);
};

const getRestrictedMembers = (restrictedMembers: RestrictedMember[], dateFormat: string): string => {
  const data = restrictedMembers.map(restrictedMember => ({
    [t("customerName")]: restrictedMember.fullName,
    [t("phone")]: restrictedMember.phone,
    [t("lastSeen")]: restrictedMember.lastSeen ? moment(restrictedMember.lastSeen).format(dateFormat) : t("unknown"),
    [t("email")]: restrictedMember.email,
    [t("location")]: restrictedMember.location,
  }));

  return convertToCsv(data);
};

const getBookingCancellation = (booking: BookingCancellation[], dateFormat: string, timeFormat: string): string => {
  const data = booking.map(b => ({
    [t("customerName")]: b.fullName,
    [t("class")]: b.class,
    [t("classDate")]: b.classDate ? moment(b.classDate).format(dateFormat) : null,
    [t("classTime")]: b.classTime ? moment(b.classTime).format(timeFormat) : null,
    [t("registeredAt")]: b.registeredAt
      ? `${moment(b.registeredAt).format(dateFormat)} ${moment(b.registeredAt).format(timeFormat)}`
      : null,
    [t("cancellationTime")]: b.cancellationTime
      ? `${moment(b.cancellationTime).format(dateFormat)} ${moment(b.cancellationTime).format(timeFormat)}`
      : null,
    [t("hoursBeforeClass")]: b.hoursBeforeClass,
    [t("email")]: b.email,
    [t("allowSms")]: boolToYesNo(b.allowSms),
    [t("allowEmail")]: boolToYesNo(b.allowEmail),
    [t("location")]: b.location,
  }));

  return convertToCsv(data);
};

const getStaffActions = (staffActions: StaffAction[], dateFormat: string, timeFormat: string): string => {
  const data = staffActions.map(s => ({
    [t("date")]: moment(s.date).format(`${dateFormat} ${timeFormat}`),
    [t("day")]: s.dayOfWeek,
    [t("staffMember")]: s.staffMember,
    [t("action")]: s.action,
    [t("subAction")]: s.subAction,
    [t("relatedUser")]: s.member,
  }));

  return convertToCsv(data);
};

const getExternalMemberships = (memberships: ExternalMembership[], dateFormat: string): string => {
  const result = memberships.map(m => ({
    [t("customerName")]: m.fullName,
    [t("phone")]: m.phone,
    [t("externalMembership")]: m.externalMembership,
    [t("firstVisit")]: m.start ? moment(m.start).format(dateFormat) : null,
    [t("location")]: m.location,
  }));

  return convertToCsv(result);
};

const getLateCancellations = (
  lateCancellations: LateCancellation[],
  dateFormat: string,
  timeFormat: string
): string => {
  const data = lateCancellations.map(lC => ({
    [t("customerName")]: lC.fullName,
    [t("membershipType")]: t(lC.membershipType as Phrases),
    [t("membershipName")]: lC.membershipName,
    [t("class")]: lC.seriesName,
    [t("classTime")]: `${moment(lC.scheduleDate).format(dateFormat)} ${moment(lC.scheduleDate).format(timeFormat)}`,
    [t("cancellationHours")]: lC.cancellationHours,
    [t("cancelDate")]: `${moment(lC.cancelDate).format(dateFormat)} ${moment(lC.cancelDate).format(timeFormat)}`,
    [t("allowSms")]: lC.allowSms,
    [t("allowEmail")]: lC.allowEmail,
    [t("location")]: lC.location,
  }));

  return convertToCsv(data);
};

const getAttendanceExternalMembers = (
  attExternalMembers: AttendanceExternalMember[],
  dateFormat: string,
  timeFormat: string
): string => {
  const data = attExternalMembers.map(a => ({
    [t("customerName")]: a.fullName,
    [t("bookingTime")]: moment(a.bookingTime).format(dateFormat),
    [t("class")]: a.class,
    [t("classDate")]: moment(a.classDate).format(dateFormat),
    [t("classDay")]: t(a.classDay as Phrases),
    [t("classTime")]: moment(a.classTime).format(timeFormat),
    [t("coach")]: a.coachName,
    [t("externalMembership")]: a.membershipTypeName,
    [t("cancelledBy")]: a.cancelledBy,
    [t("updatedAt")]: moment(a.updatedAt).format(dateFormat),
    [t("location")]: a.location,
  }));

  return convertToCsv(data);
};

const getMembershipForecasts = (membershipForecasts: MembershipForecast[]): string => {
  const data = membershipForecasts.map(m => ({
    [t("department")]: m.departmentName,
    [t("membership")]: m.membershipType,
    [t("currentStatus")]: m.currentMonth,
    [t(moment().add(1, "month").format("MMMM").toLowerCase() as Phrases)]: m.month2,
    [t(moment().add(2, "month").format("MMMM").toLowerCase() as Phrases)]: m.month3,
    [t(moment().add(3, "month").format("MMMM").toLowerCase() as Phrases)]: m.month4,
    [t(moment().add(4, "month").format("MMMM").toLowerCase() as Phrases)]: m.month5,
    [t(moment().add(5, "month").format("MMMM").toLowerCase() as Phrases)]: m.month6,
    [t(moment().add(6, "month").format("MMMM").toLowerCase() as Phrases)]: m.month7,
    [t(moment().add(7, "month").format("MMMM").toLowerCase() as Phrases)]: m.month8,
    [t(moment().add(8, "month").format("MMMM").toLowerCase() as Phrases)]: m.month9,
    [t(moment().add(9, "month").format("MMMM").toLowerCase() as Phrases)]: m.month10,
    [t(moment().add(10, "month").format("MMMM").toLowerCase() as Phrases)]: m.month11,
    [t(moment().add(11, "month").format("MMMM").toLowerCase() as Phrases)]: m.month12,
  }));

  return convertToCsv(data);
};

const getAbsences = (absences: Absence[], dateFormat: string): string => {
  const data = absences.map(a => ({
    [t("customerName")]: a.fullName,
    [t("phone")]: a.phone,
    [t("lastSeen")]: moment(a.lastSeen).format(dateFormat),
    [t("membershipType")]: a.membershipType,
    [t("allowSms")]: a.allowSms,
    [t("allowEmail")]: a.allowEmails,
    [t("location")]: a.location,
    [t("email")]: a.email,
    [t("cancelled")]: a.cancelled,
  }));

  return convertToCsv(data);
};

const getPreservations = (preservations: Preservation[]): string => {
  const data = preservations.map(pr => ({
    [t("customerName")]: pr.fullName,
    [t("age")]: pr.age,
    [t("week1")]: pr.week1,
    [t("week2")]: pr.week2,
    [t("week3")]: pr.week3,
    [t("week4")]: pr.week4,
    [t("week5")]: pr.week5,
    [t("week6")]: pr.week6,
    [t("week7")]: pr.week7,
    [t("week8")]: pr.week8,
    [t("week9")]: pr.week9,
    [t("week10")]: pr.week10,
  }));

  return convertToCsv(data);
};

const getLeadsStatistics = (leadsStatistics: LeadStatistics[]): string => {
  const data = leadsStatistics.map(l => ({
    [t("source")]: l.sourceName,
    [t("leads")]: l.totalLeads,
    [t("converted")]: l.convertedLeads,
    [t("createdAndConverted")]: l.createdAndConvertedLeads,
    [t("lost")]: l.lostLeads,
    [t("createdAndLost")]: l.createdAndLostLeads,
    [t("bookedClasses")]: l.bookedClasses,
    [t("createdAndBookedClasses")]: l.createdAndBookedClasses,
  }));

  return convertToCsv(data);
};

const getClassesSummary = (classesSummary: ClassesSummary[], dateFormat: string): string => {
  const data = classesSummary.map(c => ({
    [t("date")]: moment(c.date).format(dateFormat),
    [t("day")]: t(c.dayOfWeek as Phrases),
    [t("time")]: c.timeResult,
    [t("status")]: t(c.status as Phrases),
    [t("location")]: c.location,
  }));

  return convertToCsv(data);
};

const csvService = {
  getAttendanceExternalMembers,
  getMembershipForecasts,
  getAbsences,
  getLeadsStatistics,
  getClassesSummary,
  getTransparentSessionPacks,
  getLateCancellations,
  getRegulars,
  getRestrictedMembers,
  getActiveMembersCsv,
  getActiveMembershipCsv,
  getAllBirthdays,
  getAllExpiredMemberships,
  getAllExpiringMembershipsCsv,
  getAllLeadsCsv,
  getAllLeadsInProcess,
  getAllLostLeads,
  getAllSales,
  getAllTransactions,
  getBookingCancellation,
  getCanceledMemberships,
  getConvertedLeads,
  getDebts,
  getEmployeeAttendance,
  getEntrances,
  getExpiredSessionPacks,
  getExpiringSessions,
  getExternalMemberships,
  getGroupMember,
  getMembersProperties,
  getInactiveMembers,
  getMembersOnHold,
  getPreservations,
  getRenewals,
  getSessions,
  getShiftSummaries,
  getStaffActions,
  getTrialClasses,
};

export default csvService;
