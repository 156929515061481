import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import GenderIcon from "../../components/genderIcon";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Female } from "../../icons/female.svg";
import { ReactComponent as Male } from "../../icons/male.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as OutgoingData } from "../../icons/outgoingData.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { LeadInProcess } from "../../models/leadsInProcess";
import csvService from "../../services/csvService";
import leadsService from "../../services/leadsService";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as leadsInProcessActions } from "../../store/leadsInProcessStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { leadsInProcessSelector, selectedLeadsInProcessSelector } from "../reportSelector";
import { Page } from "./page";

const taskOptions: TOption[] = [
  { id: "taskNone", label: "taskNone" },
  { id: "taskFuture", label: "taskFuture" },
  { id: "taskPast", label: "taskPast" },
];

const leadsInProcessReport = (dateFormat: string): Page<LeadInProcess> => ({
  id: "leads-in-process-report",
  description: t("leadsInProcessDescription"),
  entityClick: entity => wrapperActions.openLeadDetails({ leadId: entity.userId.toString() }),
  searchTerms: ["fullName", "phone"],
  title: t("inProcessLeads"),
  rawUserId: "id",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "updatedAt",
  },
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("leads"),
    },
    {
      key: "leadStatus",
      aggregator: "mostUsedPercentage",
      toLocaleString: false,
      label: t("status"),
      formatLabel: false,
    },
    {
      key: "leadSource",
      aggregator: "mostUsedPercentage",
      label: t("source"),
      toLocaleString: false,
      formatLabel: false,
    },
  ],
  entitiesSelector: leadsInProcessSelector,
  selectedEntitiesSelector: selectedLeadsInProcessSelector,
  setSelectedEntities: leadsInProcessActions.setSelectedLeadsInProcess,
  setSingleSelected: leadsInProcessActions.setSingleSelected,
  removeSingleSelected: leadsInProcessActions.removeSingleSelected,
  fetchDataActions: [
    leadsInProcessActions.fetchLeadsInProcess,
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  isLead: true,
  csvGenerator: entities => csvService.getAllLeadsInProcess(entities, dateFormat),
  isDataLoading: state => state.leadsInProcess.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: leadInProcess => leadInProcess.fullName,
      width: 20,
    },
    {
      id: "gender",
      label: t("gender"),
      valueRenderer: leadInProcess => {
        return leadInProcess.gender ? <GenderIcon gender={leadInProcess.gender} /> : null;
      },
      filterType: "toggle",
      valuePrintRenderer: leadInProcess => (!leadInProcess.gender ? t("none") : t(leadInProcess.gender)),
      showFilterChip: true,
      width: 10,
      filterOptionsSelector: () => [
        { id: "male", label: t("male"), icon: <Male /> },
        { id: "female", label: t("female"), icon: <Female /> },
      ],
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: leadInProcess => leadInProcess.phone,
      width: 20,
    },
    {
      id: "leadStatus",
      label: t("status"),
      valueRenderer: leadInProcess =>
        (leadInProcess.leadStatus && leadInProcess.leadStatus === "Converted to Member") ||
        (leadInProcess.leadStatus && leadInProcess.leadStatus === "Lost")
          ? t(leadInProcess.leadStatus as Phrases)
          : leadInProcess.leadStatus,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state =>
        state.filterValues.uniqueFilterValues?.statuses.map(status => {
          // const camelCased = _.camelCase(status.name);

          return {
            id: status.name,
            label:
              status.name === "Converted to Member" || status.name === "Lost" ? t(status.name as Phrases) : status.name,
          };
        }) || [],
    },
    {
      id: "leadSource",
      label: t("source"),
      valueRenderer: leadInProcess => leadInProcess.leadSource,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state =>
        state.filterValues.uniqueFilterValues?.sources.map(source => {
          const camelCased = _.camelCase(source.name);
          return {
            id: source.id.toString(),
            label: t(camelCased as Phrases),
          };
        }) || [],
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      valueRenderer: leadInProcess => moment(leadInProcess.createdAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["all", "thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "updatedAt",
      label: t("updatedAt"),
      valueRenderer: leadInProcess => moment(leadInProcess.updatedAt).format(dateFormat),
      filterType: "period",
      newPage: true,
      periodPrefixes: ["all", "thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      showFilterChip: true,
      width: 15,
    },
    {
      id: "task",
      label: t("taskStatus"),
      valueRenderer: leadInProcess => t((leadInProcess.task || "none") as Phrases),
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => taskOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
  ],
  card: {
    titleRender: (item: LeadInProcess) => item.fullName,
    rows: [
      {
        icon: () => <OutgoingData />,
        textRender: (item: LeadInProcess) => item.leadStatus || "",
      },
      {
        icon: () => <Calendar />,
        textRender: (item: LeadInProcess) => moment(item.createdAt).format(dateFormat),
      },
    ],
  },
  fetchRawData: leadsService.getLeadsInProcess,
});

export default leadsInProcessReport;
