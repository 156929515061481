/* eslint-disable no-console */
import history from "../history";
import { sendMessage } from "../infrastructure/messageBus/messageBus";
import { PageId } from "../pages/pageConfig/page";
import { isInIframe } from "../utils/common";

type UrlPayload = {
  url: string;
  position: "left" | "right" | "top" | "bottom" | "center";
  size: string;
};

type FavoriteReportToggle = {
  reportId: PageId;
  toggled: boolean;
};

type MemberIds = {
  selected: number[];
};

type MemberDetails = {
  memberId: number;
};

type LeadDetails = {
  leadId: string;
};

type StaffDetails = {
  coachId: number;
};

const openPage = (payload: string): void => executeAction(
  () => sendMessage("open_page", { path: payload }),
  () => console.log("Page Opened " + payload)
);

const openDrawer = (payload: UrlPayload): void =>
  executeAction(
    () => sendMessage("open_drawer", { ...payload, url: `${window.location.origin}${payload.url}` }),
    () => history.push(payload.url)
  );

const closeDrawer = (homeUrl: string | undefined = undefined): void =>
  executeAction(
    () => sendMessage("close_drawer"),
    () => {
      if (homeUrl) {
        return history.push(homeUrl);
      }

      return history.goBack();
    }
  );

const addReportToFavorites = (payload: FavoriteReportToggle): void =>
  executeAction(
    () => sendMessage("favorite_report", payload),
    () => console.log(`Toggling favorite status of report ${payload.reportId} to ${payload.toggled}`)
  );

const goBack = (): void =>
  executeAction(
    () => sendMessage("go_back"),
    () => console.log("Requested go back to report list")
  );

const openMemberDetails = (payload: MemberDetails): void =>
  executeAction(
    () => sendMessage("member_details", payload),
    () => console.log(`Requested member details for member ${payload.memberId}`)
  );

const openLeadDetails = (payload: LeadDetails): void =>
  executeAction(
    () => sendMessage("lead_details", payload),
    () => console.log(`Requested lead details for lead ${payload.leadId}`)
  );

const openStaffDetails = (payload: StaffDetails): void =>
  executeAction(
    () => sendMessage("coach_details", payload),
    () => console.log(`Request staff details for staff ${payload.coachId}`)
  );

const sendAppNotification = (payload: MemberIds): void =>
  executeAction(
    () => sendMessage("send_notification", payload),
    () => console.log(`Requested send notification to the following list of users: ${payload.selected.join(",")}`)
  );

const sendSms = (payload: MemberIds): void =>
  executeAction(
    () => sendMessage("send_sms", payload),
    () => console.log(`Requested send SMS to the following list of users: ${payload.selected.join(",")}`)
  );

const componentHasLoaded = (): void =>
  executeAction(
    () => sendMessage("component_ready"),
    () => console.log("Component has been loaded")
  );

const executeAction = (iframeAction: () => void, alternativeAction: () => void) => {
  if (isInIframe()) {
    iframeAction();
    return;
  }

  alternativeAction();
};

const wrapperActions = {
  addReportToFavorites,
  closeDrawer,
  openStaffDetails,
  goBack,
  openPage,
  openDrawer,
  openMemberDetails,
  sendAppNotification,
  openLeadDetails,
  sendSms,
  componentHasLoaded,
};

export default wrapperActions;
