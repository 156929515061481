import { MemberPropertiesDTO } from "../models/membersProperties";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getAllMembersProperties = async (): Promise<MemberPropertiesDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const response = await client.post<MemberPropertiesDTO[]>(`box/${boxId}/checkboxesUserBox`);

  return response.data;
};

const membersPropertiesService = {
  getAllMembersProperties,
};

export default membersPropertiesService;
