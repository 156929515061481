import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { InactiveMember } from "../../models/inactiveMembers";
import csvService from "../../services/csvService";
import inactiveMembersService from "../../services/inactiveMembersService";
import { actions as inactiveMembersActions } from "../../store/inactiveMembersStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { selectedInactiveMembersSelector, inactiveMembersSelector } from "../reportSelector";
import { Page } from "./page";

const InactiveMembersReport = (): Page<InactiveMember> => ({
  id: "inactive-members-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  searchTerms: ["fullName", "phone"],
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  rawUserId: "user_fk",
  summaryItems: [{ key: "id", label: t("nonActiveMembers"), aggregator: "count", toLocaleString: false }],
  description: t("inactiveMembersDesc"),
  defaultSort: {
    order: "asc",
    orderBy: "fullName",
  },
  fetchDataActions: [
    inactiveMembersActions.fetchInactiveMembers,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: inactiveMembersActions.setSelectedInactiveMembers,
  setSingleSelected: inactiveMembersActions.setSingleSelected,
  removeSingleSelected: inactiveMembersActions.removeSelectedInactiveMember,
  isDataLoading: state => state.inactiveMembers.loading,
  title: t("nonActiveMembers"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 20,
      valueRenderer: item => item.fullName,
    },
    {
      id: "phone",
      label: t("phone"),
      width: 80,
      valueRenderer: item => item.phone,
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getInactiveMembers(entities),
  card: {
    titleRender: item => item.fullName,
    rows: [],
  },
  entitiesSelector: inactiveMembersSelector,
  selectedEntitiesSelector: selectedInactiveMembersSelector,
  fetchRawData: inactiveMembersService.getInactiveMembers,
});

export default InactiveMembersReport;
