import moment from "moment";
import { ExpiringMembershipDTO } from "../models/expiringMemberships";
import { ExpiringSessionDTO } from "../models/expiringSessions";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

interface TPost {
  sessionUsers: ExpiringSessionDTO[];
  membershipUsers: ExpiringMembershipDTO[];
}

const client = getBaseClient();
const boxId = queryParamsService.getBoxId();

const getExpiringMemberships = async (from?: Date, to?: Date): Promise<ExpiringMembershipDTO[]> => {
  if (!client) {
    return [];
  }
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<TPost>(`/user/getEndingMembership/${boxId}`, {
    fromDate,
    toDate,
    ended: false,
    locationId: null,
  });

  return result.data.membershipUsers;
};

const getExpiringSessions = async (from?: Date, to?: Date): Promise<ExpiringSessionDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<TPost>(`/user/getEndingMembership/${boxId}`, {
    fromDate,
    toDate,
    ended: false,
    locationId: null,
  });

  return result.data.sessionUsers;
};

const expiringMembershipsService = {
  getExpiringMemberships,
  getExpiringSessions,
};

export default expiringMembershipsService;
