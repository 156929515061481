import moment from "moment";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";

interface TransactionDTO extends MemberBaseDTO {
  transaction_date: string | null;
  membership_type_type: string | null;
  transaction_type: string;
  amount: number;
  payments_number: number;
  last_four_digits: string | null;
  accounting_reference: number;
  transaction_id: string | null;
  accounting_reference_link: string;
  status: string;
  create_by: string;
  voucher_id: number | null;
}

interface Transaction extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: number;
  transactionDate: string | null;
  customerId: number | null;
  membershipType: string | null;
  transactionType: string;
  amount: number;
  numberOfPayments: number;
  endsWith: string | null;
  transactionStatus: string;
  doneBy: string;
  voucher: number | null;
  receiptId: number | null;
  receipt: string;
}

const toTransaction = (transaction: TransactionDTO): Transaction => ({
  id: `${transaction.rivhit_customer_id || -1}${transaction.transaction_id || -1}`,
  firstName: transaction.first_name,
  userId: transaction.user_fk,
  lastName: transaction.last_name,
  fullName: `${transaction.first_name} ${transaction.last_name}`,
  transactionDate: moment(transaction.transaction_date).toISOString(),
  customerId: transaction.rivhit_customer_id,
  membershipType: transaction.membership_type_type,
  transactionType: transaction.transaction_type.toLowerCase(),
  amount: transaction.amount,
  numberOfPayments: transaction.payments_number,
  endsWith: transaction.last_four_digits,
  receiptId: transaction.accounting_reference,
  receipt: transaction.accounting_reference_link,
  transactionStatus: transaction.status,
  doneBy: transaction.create_by,
  voucher: transaction.voucher_id,
});

export { toTransaction };

export type { TransactionDTO, Transaction };
