import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ShiftSummary, toShiftSummary } from "../models/shiftSummary";
import shiftSummaryService from "../services/shiftSummaryService";
import { ApplicationState } from "./store";

interface State {
  all: ShiftSummary[] | null;
  selected: ShiftSummary[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface TPayload {
  all: ShiftSummary[] | null;
}

const fetchShiftSummaries = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchShiftSummaries.start());

    try {
      const data = await shiftSummaryService.getShiftSummaries(from, to);
      dispatch(fetchShiftSummaries.success({ all: data.map(toShiftSummary) }));
    } catch {
      dispatch(fetchShiftSummaries.error());
    }
  },
  {
    start: createActionCreator("@@SHIFT_SUMMARY/START"),
    error: createActionCreator("@@SHIFT_SUMMARY/ERROR"),
    success: createActionCreator("@@SHIFT_SUMMARY/SUCCESS", resolve => (s: TPayload) => resolve(s.all)),
  }
);

const setSelectedShiftSummaries = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { shiftSummary } = getState();

    if (!shiftSummary.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedShiftSummaries.success(null));
    }

    const selected = shiftSummary.all.filter(s => selectedId.includes(s.id));
    dispatch(setSelectedShiftSummaries.success(selected));
  },
  {
    success: createActionCreator("@@SHIFT_SUMMARY/SELECT_ALL/SUCCESS", resolve => (s: ShiftSummary[] | null) =>
      resolve(s)
    ),
  }
);

const setSingleSelect = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { shiftSummary } = getState();

    if (!shiftSummary.all) {
      return;
    }

    const selected = shiftSummary.all.find(s => s.id === selectedId);
    if (!selected) {
      return dispatch(setSingleSelect.error());
    }

    dispatch(setSingleSelect.success(selected));
  },
  {
    error: createActionCreator("@@SHIFT_SUMMARY/SINGLE/ERROR"),
    success: createActionCreator("@@SHIFT_SUMMARY/SINGLE/SUCCESS", resolve => (s: ShiftSummary) => resolve(s)),
  }
);

const removeSelect = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { shiftSummary } = getState();

    if (!shiftSummary.all) {
      return;
    }

    const selected = shiftSummary.all.find(s => s.id === selectedId);
    if (!selected) {
      return dispatch(removeSelect.error());
    }

    dispatch(removeSelect.success(selected));
  },
  {
    error: createActionCreator("@@SHIFT_SUMMARY/REMOVE/ERROR"),
    success: createActionCreator("@@SHIFT_SUMMARY/REMOVE/SUCCESS", resolve => (s: ShiftSummary) => resolve(s)),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchShiftSummaries.start, state => ({ ...state, loading: true })),
  handleAction(fetchShiftSummaries.error, state => ({ ...state, loading: false })),
  handleAction(fetchShiftSummaries.success, (state, { payload }) => ({
    ...state,
    all: payload,
    loading: false,
  })),
  handleAction(setSelectedShiftSummaries.success, (state, action) => ({
    ...state,
    setSelectedShiftSummaries: action.payload,
  })),
  handleAction(setSingleSelect.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelect.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(s => s.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchShiftSummaries,
  setSelectedShiftSummaries,
  setSingleSelect,
  removeSelect,
};

export { actions, reducer };

export type { State };
