import moment from "moment";
import { MembersOnHoldDTO } from "../models/membersOnHold";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getMembersOnHold = async (from?: Date, to?: Date): Promise<MembersOnHoldDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  if (!client) {
    return [];
  }

  const result = await client.get<MembersOnHoldDTO[]>(`/user/getSuspendedUsers/${boxId}/${fromDate}/${toDate}/null`);

  return result.data;
};

const membersOnHoldService = {
  getMembersOnHold,
};

export default membersOnHoldService;
