import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ReactComponent as Female } from "../icons/female.svg";
import { ReactComponent as Male } from "../icons/male.svg";
import { Gender } from "../models/member";

const GenderIcon = ({ gender }: { gender: Gender }): JSX.Element => {
  const styles = useStyles();

  if (gender === "male") {
    return <Male width={27} height={27} className={styles.icon} />;
  }

  if (gender === "female") {
    return <Female width={27} height={27} className={styles.icon} />;
  }

  return gender;
};

export default GenderIcon;

const useStyles = makeStyles(
  theme => ({
    icon: {
      "& path": {
        fill: theme.palette.text.secondary,
      },
    },
  }),
  { name: "genderIcon" }
);
