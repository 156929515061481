import moment from "moment";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { YesNoBoolean, StringOrNull } from "./misc";

interface BirthdayDTO extends MemberBaseDTO {
  birthday: string;
  age: string;
}

interface Birthday extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  phone: StringOrNull;
  birthdayDate: Date | string | null;
  age: number;
  userId: number;
  allowSMS: YesNoBoolean;
  allowEmail: YesNoBoolean;
  location: string;
  locationId: number;
}

const toBirthday = (birthday: BirthdayDTO): Birthday => ({
  id: birthday.id.toString(),
  userId: birthday.user_fk,
  firstName: birthday.first_name,
  lastName: birthday.last_name,
  fullName: `${birthday.first_name} ${birthday.last_name}`,
  phone: birthday.phone,
  birthdayDate: moment(birthday.birthday).isValid()
    ? moment(birthday.birthday)
        .year(+moment().format("YYYY"))
        .toISOString()
    : null,
  age: Math.floor(+birthday.age),
  allowSMS: birthday.allow_sms,
  allowEmail: birthday.allow_mailing_list,
  location: birthday.location,
  locationId: birthday.locations_box_fk,
});

export type { BirthdayDTO, Birthday };

export { toBirthday };
