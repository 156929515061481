import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { MembershipForecast, toMembershipForecast } from "../models/membershipForecast";
import { membershipForecastService } from "../services/membershipForecastService";
import { ApplicationState } from "./store";

interface State {
  all: MembershipForecast[] | null;
  selected: MembershipForecast[] | null;
  loading: boolean;
}

interface TAll {
  all: MembershipForecast[] | null;
}

interface TSelected {
  selected: MembershipForecast[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchMembershipForecast = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membershipForecast } = getState();

    if (membershipForecast.all) {
      return;
    }

    dispatch(fetchMembershipForecast.start());

    try {
      const data = await membershipForecastService.getMembershipForecasts();

      dispatch(
        fetchMembershipForecast.success({
          all: data.map((entity, index) => toMembershipForecast(entity, index, entity.count_mt)),
        })
      );
    } catch {
      dispatch(fetchMembershipForecast.failure());
    }
  },
  {
    start: createActionCreator("@@MEMBERSHIP_FORECAST/START_FETCH"),
    success: createActionCreator("@@MEMBERSHIP_FORECAST/FETCH_SUCCESS", resolve => (payload: TAll) =>
      resolve(payload.all)
    ),
    failure: createActionCreator("@@MEMBERSHIP_FORECAST/FETCH_FAILURE"),
  }
);

const setSelected = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membershipForecast } = getState();

    if (!membershipForecast.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelected.success({ selected: null }));
      return;
    }

    const selected = membershipForecast.all.filter(m => selectedId.includes(m.id));
    dispatch(setSelected.success({ selected: selected }));
  },
  {
    success: createActionCreator("@@REPORTS/SELECTED_MEMBERSHIP_FORECASTS", resolve => (payload: TSelected) =>
      resolve(payload.selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membershipForecast } = getState();

    if (!membershipForecast.all) {
      return;
    }

    const selected = membershipForecast.all.find(m => m.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SINGLE_SELECTED_MEMBERSHIP_FORECAST",
      resolve => (payload: MembershipForecast) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_MEMBERSHIP_FORECAST/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { membershipForecast } = getState();

    if (!membershipForecast.all) {
      return;
    }

    const selected = membershipForecast.all.find(m => m.id === selectedId);

    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/REMOVE_SINGLE_SELECTED_MEMBERSHIP_FORECAST",
      resolve => (payload: MembershipForecast) => resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED_MEMBERSHIP_FORECAST/ERROR"),
  }
);

const actions = {
  fetchMembershipForecast,
  setSelected,
  setSingleSelected,
  removeSingleSelected,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchMembershipForecast.start, state => ({ ...state, loading: true })),
  handleAction(fetchMembershipForecast.success, (state, { payload }) => ({
    ...state,
    loading: false,
    all: payload,
  })),
  handleAction(fetchMembershipForecast.failure, state => ({ ...state, loading: false })),
  handleAction(setSelected.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(m => m.id !== action.payload.id) : null,
  })),
]);

export { reducer, actions };
export type { State };
