import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import GenderIcon from "../../components/genderIcon";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as DeleteMessage } from "../../icons/deleteMessage.svg";
import { ReactComponent as Female } from "../../icons/female.svg";
import { ReactComponent as Male } from "../../icons/male.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { LostLead } from "../../models/lostLeads";
import csvService from "../../services/csvService";
import lostLeadsService from "../../services/lostLeadsService";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as lostLeadsActions } from "../../store/lostLeadsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { lostLeadsSelector, selectedLostLeadsSelector } from "../reportSelector";
import { Page } from "./page";

const lostLeadsReport = (dateFormat: string): Page<LostLead> => ({
  id: "lost-leads-report",
  description: t("lostLeadsDescription"),
  searchTerms: ["fullName", "phone"],
  entityClick: entity => wrapperActions.openLeadDetails({ leadId: entity.userId.toString() }),
  title: t("lostLeads"),
  rawUserId: "id",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
  ],
  defaultSort: {
    order: "desc",
    orderBy: "lostDate",
  },
  defaultFilters: [
    {
      key: "lostDate",
      value: moment().startOf("month").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "lostDate",
      value: moment().endOf("month").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  fetchOnlyData: lostLeadsActions.fetchLostLeads,
  fetchDataActions: [
    filterValuesActions.fetchFilterValues,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  summaryItems: [
    {
      key: "id",
      aggregator: "count",
      toLocaleString: false,
      label: t("leads"),
    },
    {
      key: "lostReason",
      aggregator: "mostUsed",
      toLocaleString: false,
      label: t("mainLostReason"),
      formatLabel: true,
    },
    {
      key: "leadSource",
      aggregator: "mostUsedPercentage",
      toLocaleString: false,
      label: t("source"),
      formatLabel: false,
    },
  ],
  entitiesSelector: lostLeadsSelector,
  selectedEntitiesSelector: selectedLostLeadsSelector,
  setSelectedEntities: lostLeadsActions.setSelectedLostLeads,
  setSingleSelected: lostLeadsActions.setSingleSelected,
  removeSingleSelected: lostLeadsActions.removeSingleSelected,
  isDataLoading: state => state.lostLeads.loading,
  csvGenerator: entities => csvService.getAllLostLeads(entities, dateFormat),
  isLead: false,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: lostLead => lostLead.fullName,
      width: 20,
    },
    {
      id: "lostDate",
      label: t("lostDate"),
      valueRenderer: lostLead => moment(lostLead.lostDate).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "gender",
      label: t("gender"),
      valueRenderer: lostLead => {
        return <GenderIcon gender={lostLead.gender} />;
      },
      filterType: "toggle",
      valuePrintRenderer: lostLead => t(lostLead.gender),
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => [
        { id: "male", label: t("male"), icon: <Male /> },
        { id: "female", label: t("female"), icon: <Female /> },
      ],
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: lostLead => lostLead.phone,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "lostReason",
      label: t("leadLostReasons"),
      valueRenderer: lostLead => (lostLead.lostReason === "-1" ? t("unknownResistenceReason") : lostLead.lostReason),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const reasons = (state.lostLeads.all || []).map(l => l.lostReason);
        const uniqueReasons = _.uniq(reasons);
        return uniqueReasons.map(l => ({
          id: l,
          label: l === "-1" ? t("unknownResistenceReason") : l,
        }));
      },
      width: 15,
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      valueRenderer: lostLead => moment(lostLead.createdAt).format(dateFormat),
      periodPrefixes: ["all", "thisWeek", "thisMonth", "lastThirtyDays", "custom"],
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 15,
    },
    {
      id: "leadSourceId",
      label: t("source"),
      valueRenderer: lostLead => (lostLead.leadSource ? lostLead.leadSource : t("noSource")),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state => {
        const sources = [...(state.filterValues.uniqueFilterValues?.sources || []), { id: "-1", name: t("noSource") }];
        return sources.map(source => ({
          id: source.id.toString(),
          label: source.name,
        }));
      },
    },
  ],
  card: {
    titleRender: (item: LostLead) => item.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: (item: LostLead) => t("leadLostAt", { leadLostAt: moment(item.createdAt).format(dateFormat) }),
      },
      {
        icon: () => <DeleteMessage />,
        textRender: (item: LostLead) => (item.lostReason !== null ? t(item.lostReason as Phrases) : " "),
      },
    ],
  },
  fetchRawPostData: lostLeadsService.getLostLeads,
});

export default lostLeadsReport;
