import { ThemeProvider, jssPreset, StylesProvider } from "@material-ui/core/styles";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import { ConnectedRouter } from "connected-react-router";
import { create } from "jss";
import rtl from "jss-rtl";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Provider as ReduxStoreProvider } from "react-redux";
import { Store, Action } from "redux";
import { PersistConfig, getStoredState, REHYDRATE } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { PersistPartial } from "redux-persist/lib/persistReducer";
import LanguageContext from "./context/languageContext";
import history from "./history";
import { initDict } from "./infrastructure/translations/i18n";
import Router from "./router";
import languageService, { LanguageConfigResponse } from "./services/languageService";
import queryParamsService from "./services/queryParamsService";
import { store, persistedStore, ApplicationState, persistConfig } from "./store/store";
import createTheme from "./theme";

import "moment/locale/he";
import "moment/locale/tr";
import "moment/locale/es";
import "moment/locale/ar";

import "./scss/_index.scss";
import "./scss/global.scss";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const crossBrowserListener = (
  store: Store<PersistPartial, Action<any>>,
  persistConfig: PersistConfig<ApplicationState>
) => async () => {
  const state = await getStoredState(persistConfig);

  store.dispatch({
    type: REHYDRATE,
    key: persistConfig.key,
    payload: state,
  });
};

const App = (): JSX.Element | null => {
  const lang = queryParamsService.getLanguage();
  const [languageConfig, setLanguageConfig] = useState<LanguageConfigResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    languageService.getLanguageConfig(lang).then(response => {
      setLanguageConfig(response);
      initDict(lang.toString(), response?.phrases || {});
      setLoading(false);
      moment.locale(response?.code || "en");
    });
  }, [lang]);

  useEffect(() => {
    window.addEventListener("storage", crossBrowserListener(store, persistConfig));

    return () => window.removeEventListener("storage", crossBrowserListener(store, persistConfig));
  });

  const direction = languageConfig?.direction || "ltr";

  useEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  if (loading) {
    return null;
  }

  const theme = createTheme(languageConfig);

  return (
    <LanguageContext.Provider value={direction}>
      <LocalizationProvider dateAdapter={MomentUtils} dateLibInstance={moment} locale={languageConfig?.code || "en"}>
        <ReduxStoreProvider store={store}>
          <PersistGate persistor={persistedStore}>
            <StylesProvider jss={jss}>
              <ThemeProvider theme={theme}>
                <ConnectedRouter history={history}>
                  <Router />
                </ConnectedRouter>
              </ThemeProvider>
            </StylesProvider>
          </PersistGate>
        </ReduxStoreProvider>
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};

export default App;
