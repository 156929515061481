import moment from "moment";
import { BirthdayDTO } from "../models/birthday";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getBirthdays = async (from?: Date, to?: Date): Promise<BirthdayDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  if (!client) {
    return [];
  }

  const result = await client.post<BirthdayDTO[]>(`user/GetTodayBirthdays/${boxId}`, {
    fromDate,
    toDate,
    locationId: null,
  });

  return result.data;
};

const birthdayService = {
  getBirthdays,
};

export default birthdayService;
