import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Couple } from "../../icons/couple.svg";
import { ReactComponent as MembershipCard } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { GroupMember } from "../../models/groupMembers";
import csvService from "../../services/csvService";
import groupMemberService from "../../services/groupMembersService";
import { actions as groupMemberActions } from "../../store/groupMembersStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { groupMemberSelector, selectedGroupMemberSelector } from "../reportSelector";
import { Page } from "./page";

const GroupMemberReport = (dateFormat: string): Page<GroupMember> => ({
  id: "group-member-coordinator-report",
  description: t("groupMembersDesc"),
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  searchTerms: ["fullName", "phone"],
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membership",
      label: t("membership"),
    },
    {
      id: "endedAt",
      label: t("expiredAt"),
    },
  ],
  defaultFilters: [
    {
      key: "startedAt",
      value: moment().startOf("day").toISOString() as unknown,
      default: true,
      type: "moreThan",
    },
    {
      key: "startedAt",
      value: moment().endOf("day").toISOString() as unknown,
      default: true,
      type: "lessThan",
    },
  ],
  rawUserId: "user_fk",
  defaultSort: {
    order: "desc",
    orderBy: "startedAt",
  },
  summaryItems: [
    {
      aggregator: "count",
      label: t("headGroupUsers"),
      toLocaleString: false,
      key: "id",
    },
    {
      key: "membersNumber",
      toLocaleString: false,
      aggregator: "sum",
      label: t("numOfMembersTotal"),
    },
    {
      key: "membership",
      aggregator: "mostUsed",
      toLocaleString: false,
      label: t("mostPopularPlan"),
      formatLabel: true,
      showSpecificValue: true,
    },
    {
      key: "paid",
      toLocaleString: false,
      aggregator: "sum",
      label: t("paidTotal"),
    },
  ],
  fetchOnlyData: groupMemberActions.fetchGroupMembers,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: groupMemberActions.setSelectedGroupMembers,
  setSingleSelected: groupMemberActions.setSingleSelected,
  removeSingleSelected: groupMemberActions.removeSingleSelected,
  isDataLoading: state => state.groupMembers.loading,
  title: t("headGroupUsers"),
  columns: [
    {
      id: "fullName",
      width: 20,
      label: t("customerName"),
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "phone",
      width: 10,
      label: t("phone"),
      valueRenderer: entity => entity.phone,
    },
    {
      id: "membership",
      label: t("membership"),
      width: 10,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      valueRenderer: entity => t(entity.membership as Phrases),
      filterOptionsSelector: state => {
        const data = (state.groupMembers.all || []).map(value => ({
          id: value.membership,
          label: value.membership,
        }));
        const filteredData = _.uniqBy(data, "id");
        return filteredData.map(item => ({ id: item.id!, label: t(item.label as Phrases) }));
      },
    },
    {
      id: "count",
      label: t("numOfMembers"),
      width: 10,
      filterType: "slider",
      showFilterChip: true,
      valueRenderer: entity => entity.count,
    },
    {
      id: "startedAt",
      label: t("startDateMembership"),
      valueRenderer: entity => (entity.startedAt ? moment(entity.startedAt).format(dateFormat) : null),
      filterType: "period",
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      periodPrefixes: ["all", "today", "thisWeek", "thisMonth", "custom"],
      id: "endedAt",
      label: t("endDateMembership"),
      valueRenderer: entity => (entity.endedAt ? moment(entity.endedAt).format(dateFormat) : null),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "price",
      label: t("originalPrice"),
      valueRenderer: entity => entity.price,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "paid",
      label: t("paid"),
      valueRenderer: entity => entity.paid,
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getGroupMember(entities, dateFormat),
  card: {
    titleRender: entity => entity.fullName,
    rows: [
      {
        icon: () => <MembershipCard />,
        textRender: item => item.membership || "",
      },
      {
        icon: () => <Couple />,
        textRender: item => t("numberOfMembers", { numberOfMembers: item.membersNumber }),
      },
      {
        icon: () => <Calendar />,
        textRender: item =>
          `${moment(item.startedAt).format(dateFormat)} ${
            item.endedAt ? `- ${moment(item.endedAt).format(dateFormat)}` : ""
          }`,
      },
    ],
  },
  entitiesSelector: groupMemberSelector,
  selectedEntitiesSelector: selectedGroupMemberSelector,
  fetchRawPostData: groupMemberService.getGroupMembers,
});

export default GroupMemberReport;
