import { MemberBaseDTO } from "../models/memberBase";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getInactiveMembers = async (): Promise<MemberBaseDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const result = await client.get<MemberBaseDTO[]>(`/box/${boxId}/getNonActiveUsersWithMembership`);

  return result.data;
};

const inactiveMembersService = {
  getInactiveMembers,
};

export default inactiveMembersService;
