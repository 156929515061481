import { RestrictedMemberDTO } from "../models/restrictedMembers";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getRestrictedMembers = async (): Promise<RestrictedMemberDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const result = await client.get<RestrictedMemberDTO[]>(`/box/${boxId}/getRestrictedUsers`);

  return result.data;
};

const restrictedMembersService = {
  getRestrictedMembers,
};

export { restrictedMembersService };
