import i18n, { TOptions, StringMap } from "i18next";
import { Phrases } from "./phrases";

const initDict = async (lang: string, phrases: Record<string, unknown>): Promise<void> => {
  await i18n.init({
    lng: lang,
    debug: true,
    resources: {
      [lang]: { translation: phrases },
    },
    fallbackLng: "1",
    interpolation: {
      escapeValue: false,
    },
  });
};

const t = (key: Phrases, options?: TOptions<StringMap> | string): string => i18n.t(key.toString(), options);

export default t;
export { initDict };
