import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Warmup } from "../../icons/warmup.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { daysOfWeek } from "../../models/misc";
import { Regular } from "../../models/regulars";
import csvService from "../../services/csvService";
import regularsService from "../../services/regularsService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as regularsActions } from "../../store/regularsStore";
import { regularsSelector, selectedRegulars } from "../reportSelector";
import { Page } from "./page";

const statusOptions: TOption[] = [
  { id: "active", label: "active" },
  { id: "inactive", label: "inactive" },
];

const RegularsReport = (timeFormat: string): Page<Regular> => ({
  id: "regulars-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  description: t("regularDesc"),
  rawUserId: "user_fk",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "class",
      label: t("workout"),
    },
  ],
  defaultSort: {
    order: "asc",
    orderBy: "fullName",
  },
  summaryItems: [
    {
      key: "id",
      label: t("regularClients"),
      aggregator: "count",
      toLocaleString: false,
    },
    {
      key: "membershipActive",
      label: t("activeMembers"),
      aggregator: "percentage",
      toLocaleString: false,
      specificValue: "active",
    },
  ],
  fetchDataActions: [regularsActions.fetchRegulars, locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: regularsActions.setSelectedRegulars,
  searchTerms: ["fullName", "seriesName", "class"],
  setSingleSelected: regularsActions.setSingleSelected,
  removeSingleSelected: regularsActions.removeSingleSelected,
  isDataLoading: state => state.regulars.loading,
  title: t("regularClients"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 15,
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "seriesName",
      label: t("seriesName"),
      width: 15,
      valueRenderer: entity => entity.seriesName,
    },
    {
      id: "class",
      label: t("class"),
      valueRenderer: entity => t(entity.class as Phrases),
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const data = (state.regulars.all || []).map(r => ({
          id: r.class,
          label: r.class,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id, label: t(item.label as Phrases) }));
      },
    },
    {
      id: "dayId",
      label: t("day"),
      valueRenderer: entity => (entity.day ? t(entity.day as Phrases) : null),
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => daysOfWeek.map(d => ({ id: d.id.toString(), label: t(d.label as Phrases) })),
    },
    {
      id: "startTime",
      label: t("time"),
      width: 15,
      valueRenderer: entity => entity.scheduleTime,
      newPage: true,
      filterType: "timeSlider",
      showFilterChip: true,
    },
    {
      id: "coach",
      label: t("coach"),
      valueRenderer: entity => t(entity.coach as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const data = (state.regulars.all || []).map(r => ({
          id: r.coach,
          label: r.coach,
        }));
        const uniqueData = _.uniqBy(data, "id");
        return uniqueData.map(item => ({ id: item.id!.toString(), label: t(item.label as Phrases) }));
      },
    },
    {
      id: "membershipActive",
      label: t("memberStatus"),
      valueRenderer: entity => t(entity.membershipActive as Phrases),
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      showAll: t("all"),
      filterOptionsSelector: () => statusOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getRegulars(entities),
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        textRender: item => item.seriesName,
        icon: () => <Warmup />,
      },
      {
        textRender: item =>
          `${t("trainingDay", { trainingDay: item.day })}, ${t("trainingTime", {
            trainingTime: moment().startOf("day").add(item.startTime, "hours").format(timeFormat),
          })}`,
        icon: () => <Clock />,
      },
    ],
  },
  entitiesSelector: regularsSelector,
  selectedEntitiesSelector: selectedRegulars,
  fetchRawData: regularsService.getRegulars,
});

export default RegularsReport;
