import moment from "moment";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull, NumberOrNull } from "./misc";

interface SalesDTO extends MemberBaseDTO {
  department_name: StringOrNull;
  department_id: NumberOrNull;
  membership_type_name: string;
  membership_type_type: StringOrNull;
  membership_type_id: NumberOrNull;
  membership_debt: NumberOrNull;
  membership_price: NumberOrNull;
  action: StringOrNull;
  sub_action: StringOrNull;
  discount: NumberOrNull;
  paid: number;
  created_by_user: StringOrNull;
  count_mt: number;
}

interface Sales extends BaseReportEntity {
  saleDate: string;
  firstName: StringOrNull;
  lastName: StringOrNull;
  fullName: string;
  departmentName: StringOrNull;
  departmentId: NumberOrNull;
  doneBy: StringOrNull;
  productType: StringOrNull;
  membershipName: string;
  userId: number;
  membershipPrice: NumberOrNull;
  discount: NumberOrNull;
  paid: number;
  balance: NumberOrNull;
  value: NumberOrNull;
  saleType: StringOrNull;
  paymentType: StringOrNull;
  membershipId: NumberOrNull;
  location: StringOrNull;
  countMt?: number;
}

const toSale = (sale: SalesDTO): Sales => ({
  id: sale.id.toString(),
  userId: sale.user_fk,
  saleDate: moment(sale.created_at).toISOString(),
  firstName: sale.first_name,
  lastName: sale.last_name,
  fullName: `${sale.first_name} ${sale.last_name}`,
  departmentName: sale.department_name,
  departmentId: sale.department_id,
  doneBy: sale.created_by_user,
  productType: sale.membership_type_type,
  membershipName: sale.membership_type_name,
  membershipId: sale.membership_type_id,
  membershipPrice: sale.membership_price,
  discount: sale.discount,
  paid: sale.paid,
  balance: sale.membership_debt,
  value: sale.membership_price,
  saleType: sale.action,
  paymentType: sale.sub_action || "unknown",
  location: sale.location,
  countMt: sale.count_mt,
});

export { toSale };

export type { SalesDTO, Sales };
