import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { StaffAction, toStaffAction } from "../models/staffActions";
import staffActionsService from "../services/staffActionsService";
import { ApplicationState } from "./store";

interface State {
  all: StaffAction[] | null;
  selected: StaffAction[] | null;
  loading: boolean;
}

interface TPayload {
  all: StaffAction[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchStaffActions = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchStaffActions.start());

    try {
      const data = await staffActionsService.getStaffActions(from, to);
      dispatch(fetchStaffActions.success({ all: data.map(toStaffAction) }));
    } catch {
      dispatch(fetchStaffActions.error());
    }
  },
  {
    start: createActionCreator("@@STAFF_ACTIONS/START_FETCH"),
    success: createActionCreator("@@STAFF_ACTIONS/FETCH_SUCCESS", resolve => (payload: TPayload) => resolve(payload)),
    error: createActionCreator("@@RENEWALS/FETCH_ERROR"),
  }
);

const setSelectedStaffActions = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { staffActions } = getState();

    if (!staffActions.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedStaffActions.success(null));
    }

    const selected = staffActions.all.filter(s => selectedId.includes(s.id));
    dispatch(setSelectedStaffActions.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_STAFF_ACTIONS", resolve => (payload: StaffAction[] | null) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_STAFF_ACTIONS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { staffActions } = getState();

    if (!staffActions.all) {
      return;
    }

    const selectedStaffActions = staffActions.all.find(s => s.id === selectedId);

    if (!selectedStaffActions) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedStaffActions));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_STAFF_ACTIONS/SUCCESS",
      resolve => (payload: StaffAction) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_STAFF_ACTIONS/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { staffActions } = getState();

    if (!staffActions.all) {
      return;
    }

    const selectedStaffActions = staffActions.all.find(s => s.id === selectedId);

    if (!selectedStaffActions) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedStaffActions));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_STAFF_ACTIONS/SUCCESS",
      resolve => (payload: StaffAction) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_STAFF_ACTIONS/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchStaffActions.start, state => ({ ...state, loading: true })),
  handleAction(fetchStaffActions.error, state => ({ ...state, loading: false })),
  handleAction(fetchStaffActions.success, (state, action) => ({
    ...state,
    loading: false,
    all: action.payload.all,
  })),
  handleAction(setSelectedStaffActions.success, (state, action) => ({ ...state, selected: action.payload })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(s => s.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchStaffActions,
  setSelectedStaffActions,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
