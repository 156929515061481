import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar-check.svg";
import { ReactComponent as Membership } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { ExternalMembership } from "../../models/externalMembership";
import csvService from "../../services/csvService";
import externalMembershipService from "../../services/externalMembershipService";
import { actions as externalMembershipActions } from "../../store/externalMembershipStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { externalMembershipsSelector, selectedExternalMembershipsSelector } from "../reportSelector";
import { Page } from "./page";

const externalMembershipReport = (dateFormat: string): Page<ExternalMembership> => ({
  id: "external-memberships-report",
  rawUserId: "user_fk",
  searchTerms: ["fullName", "phone"],
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  title: t("externalMembershipTitle"),
  description: t("externalMembershipDescription"),
  defaultSort: {
    order: "desc",
    orderBy: "start",
  },
  defaultFilters: [
    {
      key: "start",
      value: moment().startOf("month").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "start",
      value: moment().endOf("month").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("externalMembers"),
    },
    {
      key: "externalMembership",
      toLocaleString: false,
      aggregator: "mostUsedPercentage",
      label: t("externalMembership"),
    },
  ],
  entitiesSelector: externalMembershipsSelector,
  setSingleSelected: externalMembershipActions.setSingleSelected,
  removeSingleSelected: externalMembershipActions.removeSelected,
  setSelectedEntities: externalMembershipActions.setSelected,
  selectedEntitiesSelector: selectedExternalMembershipsSelector,
  fetchOnlyData: externalMembershipActions.fetchExternalMemberships,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  fetchRawPostData: externalMembershipService.getExternalMemberships,
  isDataLoading: state => state.externalMemberships.loading,
  csvGenerator: entities => csvService.getExternalMemberships(entities, dateFormat),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: m => m.fullName,
      width: 25,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: m => m.phone,
      width: 25,
    },
    {
      id: "externalMembership",
      label: t("externalMembership"),
      valueRenderer: m => m.externalMembership,
      width: 25,
      filterType: "multipleSearchable",
      newPage: true,
      showAll: t("all"),
      showFilterChip: true,
      filterOptionsSelector: state => {
        const values = (state.externalMemberships.all || []).map(m => ({
          id: m.externalMembership,
          label: m.externalMembership,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(m => ({
          id: m.id!,
          label: m.label!,
        }));
      },
    },
    {
      id: "start",
      label: t("firstVisit"),
      valueRenderer: m => (m.start ? moment(m.start).format(dateFormat) : null),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      periodPrefixes: ["thisWeek", "thisMonth", "previousMonth"],
      width: 25,
    },
  ],
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Membership />,
        textRender: m => m.externalMembership,
      },
      {
        icon: () => <Calendar />,
        textRender: m => (m.start ? t("firstVisitData", { firstVisit: moment(m.start).format(dateFormat) }) : null),
      },
    ],
  },
  isLead: false,
});

export default externalMembershipReport;
