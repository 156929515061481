import moment from "moment";
import { ShiftSummaryDTO } from "../models/shiftSummary";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();

const getShiftSummaries = async (from?: Date, to?: Date): Promise<ShiftSummaryDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<ShiftSummaryDTO[]>("reports/global/shiftReport", {
    fromDate,
    toDate,
  });

  return result.data;
};

const shiftSummaryService = {
  getShiftSummaries,
};

export default shiftSummaryService;
