import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ExpiredSessionPack, toExpiredSessionPack } from "../models/expiredSessionPacks";
import expiredMembershipsService from "../services/expiredMembershipsService";
import { ApplicationState } from "./store";

interface State {
  all: ExpiredSessionPack[] | null;
  selected: ExpiredSessionPack[] | null;
  loading: boolean;
}

interface TPayload {
  all: ExpiredSessionPack[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchAllExpiredSessionPacks = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchAllExpiredSessionPacks.start());

    try {
      const data = await expiredMembershipsService.getSessionUsers(from, to);

      dispatch(fetchAllExpiredSessionPacks.success({ all: data.map(toExpiredSessionPack) }));
    } catch {
      dispatch(fetchAllExpiredSessionPacks.error());
    }
  },
  {
    start: createActionCreator("@@ALL_EXPIRED_SESSION_PACKS/START"),
    success: createActionCreator("@@ALL_EXPIRED_SESSION_PACKS/SUCCESS", resolve => (all: TPayload) => resolve(all)),
    error: createActionCreator("@@ALL_EXPIRED_SESSION_PACKS/ERROR"),
  }
);

const setSelectedExpiredSessionPacks = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiredSessionPacks } = getState();

    if (!expiredSessionPacks.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedExpiredSessionPacks.success(null));
    }

    const selected = expiredSessionPacks.all.filter(expiredSessionPack => selectedId.includes(expiredSessionPack.id));

    dispatch(setSelectedExpiredSessionPacks.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_EXPIRED_SESSION_PACKS",
      resolve => (selected: ExpiredSessionPack[] | null) => resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_EXPIRED_SESSION_PACKS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiredSessionPacks } = getState();

    if (!expiredSessionPacks.all) {
      return;
    }

    const selectedExSessionPack = expiredSessionPacks.all.find(exSessionPack => exSessionPack.id === selectedId);

    if (!selectedExSessionPack) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedExSessionPack));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_EX_SESSION_PACK/SUCCESS",
      resolve => (payload: ExpiredSessionPack) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_EX_SESSION_PACK/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiredSessionPacks } = getState();

    if (!expiredSessionPacks.all) {
      return;
    }

    const selectedExSessionPack = expiredSessionPacks.all.find(exSessionPack => exSessionPack.id === selectedId);

    if (!selectedExSessionPack) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedExSessionPack));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_EX_SESSION_PACK/SUCCESS",
      resolve => (payload: ExpiredSessionPack) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_EX_SESSION_PACK/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchAllExpiredSessionPacks.start, state => ({ ...state, loading: true })),
  handleAction(fetchAllExpiredSessionPacks.error, state => ({ ...state, loading: false })),
  handleAction(fetchAllExpiredSessionPacks.success, (state, { payload }) => ({
    ...state,
    all: payload.all,
    loading: false,
  })),
  handleAction(setSelectedExpiredSessionPacks.success, (state, { payload }) => ({
    ...state,
    selected: payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected
      ? state.selected.filter(selectedSessionPack => selectedSessionPack.id !== payload.id)
      : null,
  })),
]);

const actions = {
  fetchAllExpiredSessionPacks,
  setSelectedExpiredSessionPacks,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };
export type { State };
