import { FilterValuesType } from "../models/filterValues";
import { getBaseClient } from "./serviceUtils";

const getFilterValues = async (): Promise<FilterValuesType | null> => {
  const client = getBaseClient();

  if (!client) {
    return null;
  }

  const result = await client.get("/resources/box/filters/");

  if (!result) {
    return null;
  }

  return result.data;
};

const filterValuesServices = {
  getFilterValues,
};

export default filterValuesServices;
