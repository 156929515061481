import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { toExpiringMembership, ExpiringMembership } from "../models/expiringMemberships";
import expiringMembershipsService from "../services/expiringMembershipsService";
import { ApplicationState } from "./store";

type State = {
  all: ExpiringMembership[] | null;
  selected: ExpiringMembership[] | null;
  loading: boolean;
};

type TPayload = {
  all: ExpiringMembership[] | null;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: true,
};

const fetchExpiringMemberships = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchExpiringMemberships.start());

    try {
      const data = await expiringMembershipsService.getExpiringMemberships(from, to);
      dispatch(fetchExpiringMemberships.success({ all: data.map(toExpiringMembership) }));
    } catch {
      dispatch(fetchExpiringMemberships.error());
      return;
    }
  },
  {
    start: createActionCreator("@@ALL_EXPIRING_MEMBERS/START_FETCH"),
    error: createActionCreator("@@ALL_EXPIRING_MEMBERS/ERROR_FETCH"),
    success: createActionCreator("@@ALL_EXPIRING_MEMBERS/FETCH", resolve => (expiringMemberships: TPayload) =>
      resolve(expiringMemberships)
    ),
  }
);

const setSelectedExpiringMemberships = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiringMemberships } = getState();

    if (!expiringMemberships.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedExpiringMemberships.success(null));
    }

    const selected = expiringMemberships.all.filter(expiringMembership => selectedId.includes(expiringMembership.id));

    dispatch(setSelectedExpiringMemberships.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_EXPIRING_MEMBERS",
      resolve => (setSelectedExpiringMemberships: ExpiringMembership[] | null) =>
        resolve(setSelectedExpiringMemberships)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_EXPIRING_MEMBERS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiringMemberships } = getState();

    if (!expiringMemberships.all) {
      return;
    }

    const selectedExMemberships = expiringMemberships.all.find(exMemberships => exMemberships.id === selectedId);

    if (!selectedExMemberships) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedExMemberships));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_EXPIRING_MEMBERSHIPS/SUCCESS",
      resolve => (payload: ExpiringMembership) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_EXPIRING_MEMBERSHIPS/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { expiringMemberships } = getState();

    if (!expiringMemberships.all) {
      return;
    }

    const selectedExMemberships = expiringMemberships.all.find(exMemberships => exMemberships.id === selectedId);

    if (!selectedExMemberships) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedExMemberships));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_EXPIRING_MEMBERSHIPS/SUCCESS",
      resolve => (payload: ExpiringMembership) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_EXPIRING_MEMBERSHIPS/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchExpiringMemberships.start, state => ({ ...state, loading: true })),
  handleAction(fetchExpiringMemberships.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(fetchExpiringMemberships.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedExpiringMemberships.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedItem => selectedItem.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchExpiringMemberships,
  setSelectedExpiringMemberships,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };
export type { State };
