/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocationChangeAction, LOCATION_CHANGE } from "connected-react-router";
import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Filter } from "../hooks/useFilters";

type MasterFilter = {
  key: string;
  value: string[];
};

type State = {
  activeFilters: Filter<any>[] | null;
  stageFilters: Filter<any>[] | null;
  masterFilters: MasterFilter[] | null;
  stageMasterFilter: MasterFilter[] | null;
};

const changeFilters = Object.assign(
  {},
  {
    add: createActionCreator("@@FILTERS/ADD", resolve => (data: { filter: Filter<any>; }) => resolve(data)),
    remove: createActionCreator("@@FILTERS/REMOVE", resolve => (data: { filter: Filter<any>; }) => resolve(data)),
    reset: createActionCreator("@@FILTERS/RESET", resolve => () => resolve()),
  }
);

const changeStageFilters = Object.assign(
  {},
  {
    add: createActionCreator("@@FILTERS/STAGE/ADD", resolve => (data: { filter: Filter<any>; }) => resolve(data)),
    clearToDefault: createActionCreator("@@FILTERS/STAGE/CLEAR_TO_DEFAULT", resolve => (data: Filter<any>[]) =>
      resolve(data)
    ),
    remove: createActionCreator("@@FILTERS/STAGE/REMOVE", resolve => (data: { filter: Filter<any>; }) => resolve(data)),
    reset: createActionCreator("@@FILTERS/STAGE/RESET", resolve => () => resolve()),
    promote: createActionCreator("@@FILTERS/STAGE/PROMOTE", resolve => () => resolve()),
  }
);

const changeMasterFilters = Object.assign(
  {},
  {
    add: createActionCreator("@@FILTERS/MASTER/ADD", resolve => (data: MasterFilter) => resolve(data)),
  }
);

const changeStageMasterFilter = Object.assign(
  {},
  {
    add: createActionCreator("@@FILTERS/MASTER/STAGED/ADD", resolve => (data: MasterFilter) => resolve(data)),
    clearToDefault: createActionCreator("@@FILTERS/MASTER/STAGE/DEFAULT", resolve => (data: MasterFilter[]) =>
      resolve(data)
    ),
    promote: createActionCreator("@@FILTERS/MASTER/STAGE/PROMOTE", resolve => () => resolve()),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const defaultState: State = {
  activeFilters: null,
  stageFilters: null,
  masterFilters: null,
  stageMasterFilter: null,
};

const addFilterHandler = (
  filters: Filter<any>[] | MasterFilter[],
  newFilter: Filter<any> | MasterFilter,
  type: "filter" | "master"
) => {
  if (type === "filter") {
    const normalFilters = filters as Filter<any>[];
    const normalNew = newFilter as Filter<any>;

    return [...normalFilters.filter(f => !(f.key === normalNew.key && f.type === normalNew.type)), normalNew];
  }

  const masterFilters = filters as MasterFilter[];
  const newMaster = newFilter as MasterFilter;

  return [...masterFilters.filter(f => !(f.key === newMaster.key)), newMaster];
};

const removeFilterHandler = (
  filters: Filter<any>[] | MasterFilter[],
  filter: Filter<any> | MasterFilter,
  type: "filter" | "master"
) => {
  if (type === "filter") {
    const normalFilters = filters as Filter<any>[];
    const normalNew = filter as Filter<any>;

    return [
      ...normalFilters.filter(
        f => !(f.key === normalNew.key && f.type === normalNew.type && normalNew.default === false)
      ),
    ];
  }

  const masterFilters = filters as MasterFilter[];
  const newMaster = filter as MasterFilter;

  return [...masterFilters.filter(f => !(f.key === newMaster.key))];
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeFilters.add, (state, action) =>
    produce(state, draft => {
      draft.activeFilters = addFilterHandler(state.activeFilters || [], action.payload.filter, "filter") as Filter<
        any
      >[];
      draft.stageFilters = addFilterHandler(state.stageFilters || [], action.payload.filter, "filter") as Filter<any>[];
    })
  ),
  handleAction(changeFilters.remove, (state, action) =>
    produce(state, draft => {
      draft.activeFilters = removeFilterHandler(state.activeFilters || [], action.payload.filter, "filter") as Filter<
        any
      >[];
      draft.stageFilters = removeFilterHandler(state.activeFilters || [], action.payload.filter, "filter") as Filter<
        any
      >[];
    })
  ),
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.activeFilters = null;
        draft.stageFilters = null;
        draft.masterFilters = null;
        draft.stageMasterFilter = null;
      });
    }
    return state;
  }),
  handleAction(changeFilters.reset, state => ({ ...state, activeFilters: [] })),
  handleAction(changeStageFilters.add, (state, action) => ({
    ...state,
    stageFilters: addFilterHandler(state.stageFilters || [], action.payload.filter, "filter") as Filter<any>[],
  })),
  handleAction(changeStageFilters.remove, (state, action) => ({
    ...state,
    stageFilters: removeFilterHandler(state.stageFilters || [], action.payload.filter, "filter") as Filter<any>[],
  })),
  handleAction(changeStageFilters.reset, state => ({ ...state, stageFilters: state.activeFilters })),
  handleAction(changeStageFilters.clearToDefault, (state, action) => ({
    ...state,
    stageFilters: action.payload,
  })),
  handleAction(changeStageFilters.promote, state => ({ ...state, activeFilters: state.stageFilters })),
  // MASTER FILTER
  handleAction(changeMasterFilters.add, (state, action) =>
    produce(state, draft => {
      draft.masterFilters = addFilterHandler(state.masterFilters || [], action.payload, "master") as MasterFilter[];
      draft.stageMasterFilter = addFilterHandler(
        state.stageMasterFilter || [],
        action.payload,
        "master"
      ) as MasterFilter[];
    })
  ),
  handleAction(changeStageMasterFilter.add, (state, action) =>
    produce(state, draft => {
      draft.stageMasterFilter = addFilterHandler(
        state.stageMasterFilter || [],
        action.payload,
        "master"
      ) as MasterFilter[];
    })
  ),
  handleAction(changeStageMasterFilter.clearToDefault, (state, action) =>
    produce(state, draft => {
      draft.stageMasterFilter = action.payload;
    })
  ),
  handleAction(changeStageMasterFilter.promote, state =>
    produce(state, draft => {
      draft.masterFilters = state.stageMasterFilter;
    })
  ),
]);

const actions = {
  changeFilters,
  changeStageFilters,
  changeMasterFilters,
  changeStageMasterFilter,
};

export { actions, reducer };

export type { State, MasterFilter };
