import ActionType from "./actionType";

const CHANNEL = "arbox";

const sendMessage = <T>(type: ActionType, payload?: T): void => {
  window.parent.window.postMessage(
    {
      channel: CHANNEL,
      action: type,
      payload: payload || {},
    },
    process.env.REACT_APP_WRAPPER_URL as string
  );
};

export { sendMessage };
