import React from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import { PageId } from "./pages/pageConfig/page";
import PageConfigurationContext from "./pages/pageConfig/pageConfigurationContext";
import { getPageConfigById } from "./pages/pageConfig/reportPageConfig";
import {
  dateFormatSelector,
  timeFormatSelector,
  currentLocationSelector,
  locationsSelector,
  leadsStatisticsDisplaySelector,
} from "./pages/reportSelector";

type TProps = RouteProps & {
  component: React.LazyExoticComponent<React.ComponentType<any>>;
};

type TMatchProps = RouteComponentProps<{
  pageId: PageId;
}>;

export default ({ component: Component, ...rest }: TProps): JSX.Element => {
  const dateFormat = useSelector(dateFormatSelector);
  const timeFormat = useSelector(timeFormatSelector);
  const currentLocationId = useSelector(currentLocationSelector);
  const locations = useSelector(locationsSelector);
  const leadsStatisticsDisplay = useSelector(leadsStatisticsDisplaySelector);

  // TODO fix the current currency
  const getCurrentCurrency = locations.find(location => location.id === currentLocationId) || "";

  return (
    <Route
      {...rest}
      render={(props: TMatchProps) => {
        const currentPage = getPageConfigById(
          props.match.params.pageId,
          dateFormat,
          timeFormat,
          getCurrentCurrency,
          leadsStatisticsDisplay
        );

        return (
          <PageConfigurationContext.Provider value={currentPage}>
            <Component {...props} />
          </PageConfigurationContext.Provider>
        );
      }}
    />
  );
};
