import _ from "lodash";
import moment from "moment";
import React from "react";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { MembershipForecast } from "../../models/membershipForecast";
import csvService from "../../services/csvService";
import { membershipForecastService } from "../../services/membershipForecastService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as membershipForecastActions } from "../../store/membershipForecastStore";
import { actions as messagesActions } from "../../store/messageStore";
import { membershipForecastSelector, selectedMembershipForecastSelector } from "../reportSelector";
import { Page } from "./page";

const MembershipForecastReport = (): Page<MembershipForecast> => ({
  id: "membership-forecast-report",
  description: t("membershipForecastDesc"),
  entityClick: entity => null,
  rawUserId: "id",
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  defaultSort: {
    order: "asc",
    orderBy: "membershipType",
  },
  fetchDataActions: [
    membershipForecastActions.fetchMembershipForecast,
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
  ],
  setSelectedEntities: membershipForecastActions.setSelected,
  hideActions: true,
  setSingleSelected: membershipForecastActions.setSingleSelected,
  removeSingleSelected: membershipForecastActions.removeSingleSelected,
  isDataLoading: state => state.membershipForecast.loading,
  title: t("forecastEnds"),
  columns: [
    {
      id: "departmentName",
      label: t("department"),
      showFilterChip: true,
      filterType: "multipleSearchable",
      newPage: true,
      width: 7.5,
      valueRenderer: entity => (entity.departmentName === "-1" ? t("none") : entity.departmentName),
      filterOptionsSelector: state => {
        const values = (state.membershipForecast.all || []).map(m => m.departmentName);
        const uniqueValues = _.uniq(values);

        return uniqueValues.map(v => ({
          id: v,
          label: v === "-1" ? t("none") : v,
        }));
      },
    },
    {
      id: "membershipType",
      label: t("membership"),
      showFilterChip: true,
      newPage: true,
      width: 7.5,
      filterType: "multipleSearchable",
      valueRenderer: entity => (entity.membershipType === "-1" ? t("unknown") : entity.membershipType),
      // TODO: MEMBERSHIP TYPE memberhsip data from unique values
      filterOptionsSelector: state => {
        const values = (state.membershipForecast.all || []).map(m => m.membershipType);
        const uniqueValues = _.uniq(values);

        return uniqueValues.map(v => ({
          id: v,
          label: v === "-1" ? t("unknown") : v,
        }));
      },
    },
    {
      id: "currentMonth",
      label: t("thisMonth"),
      showFilterChip: true,
      filterType: "slider",
      width: 7,
      valueRenderer: entity => entity.currentMonth,
    },
    {
      id: "month2",
      label: t(moment().add(1, "month").format("MMMM").toLowerCase() as Phrases),
      width: 7,
      showFilterChip: true,
      valueRenderer: entity => entity.month2,
      filterType: "slider",
    },
    {
      id: "month3",
      label: t(moment().add(2, "month").format("MMMM").toLowerCase() as Phrases),
      showFilterChip: true,
      width: 7,
      filterType: "slider",
      valueRenderer: entity => entity.month3,
    },
    {
      id: "month4",
      label: t(moment().add(3, "month").format("MMMM").toLowerCase() as Phrases),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.month4,
    },
    {
      id: "month5",
      label: t(moment().add(4, "month").format("MMMM").toLowerCase() as Phrases),
      showFilterChip: true,
      filterType: "slider",
      width: 7,
      valueRenderer: entity => entity.month5,
    },
    {
      id: "month6",
      label: t(moment().add(5, "month").format("MMMM").toLowerCase() as Phrases),
      showFilterChip: true,
      filterType: "slider",
      width: 7,
      valueRenderer: entity => entity.month6,
    },
    {
      id: "month7",
      label: t(moment().add(6, "month").format("MMMM").toLowerCase() as Phrases),
      width: 7,
      showFilterChip: true,
      filterType: "slider",
      valueRenderer: entity => entity.month7,
    },
    {
      id: "month8",
      label: t(moment().add(7, "month").format("MMMM").toLowerCase() as Phrases),
      filterType: "slider",
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.month8,
    },
    {
      id: "month9",
      showFilterChip: true,
      label: t(moment().add(8, "month").format("MMMM").toLowerCase() as Phrases),
      filterType: "slider",
      width: 7,
      valueRenderer: entity => entity.month9,
    },
    {
      id: "month10",
      label: t(moment().add(9, "month").format("MMMM").toLowerCase() as Phrases),
      width: 7,
      showFilterChip: true,
      filterType: "slider",
      valueRenderer: entity => entity.month10,
    },
    {
      id: "month11",
      filterType: "slider",
      label: t(moment().add(10, "month").format("MMMM").toLowerCase() as Phrases),
      showFilterChip: true,
      width: 7,
      valueRenderer: entity => entity.month11,
    },
    {
      id: "month12",
      label: t(moment().add(11, "month").format("MMMM").toLowerCase() as Phrases),
      showFilterChip: true,
      filterType: "slider",
      width: 7,
      valueRenderer: entity => entity.month12,
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getMembershipForecasts(entities),
  card: {
    titleRender: () => "",
    rows: [
      {
        textRender: () => "",
      },
    ],
  },
  entitiesSelector: membershipForecastSelector,
  selectedEntitiesSelector: selectedMembershipForecastSelector,
  fetchRawData: membershipForecastService.getMembershipForecasts,
  desktopOnly: true,
  withoutCheckboxes: true,
});

export default MembershipForecastReport;
