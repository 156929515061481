import moment from "moment";
import { RenewalDTO } from "../models/renewals";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const client = getBaseClient();
const boxId = queryParamsService.getBoxId();

const getRenewals = async (from?: Date, to?: Date): Promise<RenewalDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<RenewalDTO[]>(`box/${boxId}/getRenewMembershipReport`, {
    fromDate,
    toDate,
  });

  return result.data;
};

const renewalsService = {
  getRenewals,
};

export default renewalsService;
