import moment from "moment";
import { TrialClassDTO } from "../models/trialClasses";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

interface TServicePayload {
  report: TrialClassDTO[];
}

const getTrialClasses = async (from?: Date, to?: Date): Promise<TrialClassDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<TServicePayload>(`lead/getLeadAttendance/${boxId}`, {
    catId: null,
    coachId: null,
    locationId: null,
    reportType: "detailedReport",
    sourceId: null,
    fromDate,
    toDate,
  });

  return result.data.report;
};

const trialClassesService = {
  getTrialClasses,
};

export default trialClassesService;
