import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { LeadInProcess, toLeadInProcess } from "../models/leadsInProcess";
import leadsService from "../services/leadsService";
import { ApplicationState } from "./store";

type State = {
  all: LeadInProcess[] | null;
  selected: LeadInProcess[] | null;
  loading: boolean;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

type TPayload = {
  all: LeadInProcess[] | null;
};

const fetchLeadsInProcess = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsInProcess } = getState();

    if (leadsInProcess.all) {
      return;
    }

    dispatch(fetchLeadsInProcess.start());

    try {
      const data = await leadsService.getLeadsInProcess();
      dispatch(fetchLeadsInProcess.success({ all: data.map(toLeadInProcess) }));
    } catch {
      dispatch(fetchLeadsInProcess.error());
      return;
    }
  },
  {
    start: createActionCreator("@@ALL_LEADS_IN_PROCESS/START_FETCH"),
    error: createActionCreator("@@ALL_LEADS_IN_PROCESS/ERROR_FETCH"),
    success: createActionCreator("@@ALL_LEADS_IN_PROCESS/FETCH", resolve => (leadsInProcess: TPayload) =>
      resolve(leadsInProcess)
    ),
  }
);

const setSelectedLeadsInProcess = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsInProcess } = getState();

    if (!leadsInProcess.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedLeadsInProcess.success(null));
    }

    const selected = leadsInProcess.all.filter(leadsInProcess => selectedId.includes(leadsInProcess.id));

    dispatch(setSelectedLeadsInProcess.success(selected));
  },
  {
    success: createActionCreator(
      "@@REPORTS/SET_SELECTED_LEADS_IN_PROCESS",
      resolve => (setSelectedLeadsInProcess: LeadInProcess[] | null) => resolve(setSelectedLeadsInProcess)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_LEADS_IN_PROCESS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsInProcess } = getState();

    if (!leadsInProcess.all) {
      return;
    }

    const selectedLead = leadsInProcess.all.find(selectedLead => selectedLead.id === selectedId);

    if (!selectedLead) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedLead));
  },
  {
    success: createActionCreator(
      "REPORTS/SET_SINGLE_SELECTED_LEAD_IN_PROCESS/SUCCESS",
      resolve => (payload: LeadInProcess) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_LEAD_IN_PROCESS/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { leadsInProcess } = getState();

    if (!leadsInProcess.all) {
      return;
    }

    const selectedLead = leadsInProcess.all.find(selectedLead => selectedLead.id === selectedId);

    if (!selectedLead) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedLead));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_LEAD_IN_PROCESS/SUCCESS",
      resolve => (payload: LeadInProcess) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_LEAD_IN_PROCESS/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchLeadsInProcess.start, state => ({ ...state, loading: true })),
  handleAction(fetchLeadsInProcess.success, (state, action) => ({
    ...state,
    all: action.payload.all,
    loading: false,
  })),
  handleAction(fetchLeadsInProcess.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedLeadsInProcess.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedItem => selectedItem.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchLeadsInProcess,
  setSelectedLeadsInProcess,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };
export type { State };
