type QueryParam = "box_id" | "token" | "lang_id" | "favorite";

const getBoxId = (): number => {
  const boxId = getQueryParam("box_id");
  return boxId ? +boxId : 0;
};

const getFavoriteStatus = (): boolean => getQueryParam("favorite") === "true";

const getToken = (): string => getQueryParam("token") || "";

const getLanguage = (): number => {
  const lang = getQueryParam("lang_id");
  return lang ? +lang : 1;
};

const getQueryParam = (paramName: QueryParam): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get(paramName);
};

const queryParamsService = {
  getBoxId,
  getFavoriteStatus,
  getToken,
  getLanguage,
};

export default queryParamsService;
