import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as Count } from "../../icons/rowCount.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { EmployeeAttendance } from "../../models/employeeAttendancy";
import csvService from "../../services/csvService";
import employeeAttendancyService from "../../services/employeeAttendaceService";
import { actions as activeEmployeeActions } from "../../store/activeEmployeesStore";
import { actions as attendanceActions } from "../../store/employeeAttendancyStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { attendanceSelector, selectedAttendanceSelector } from "../reportSelector";
import { Page } from "./page";

const employeeAttendanceReport = (dateFormat: string, timeFormat: string): Page<EmployeeAttendance> => ({
  id: "employee-attendance-report",
  title: t("employeesAttendance"),
  description: t("employeeAttendanceDescription"),
  entityClick: entity =>
    wrapperActions.openDrawer({
      url: `/reports/employee-attendance-report/shift/edit/${entity.id}/${window.location.search}`,
      position: "center",
      size: "300px",
    }),
  actions: [
    {
      url: "shift",
      icon: <Bell />,
      title: "addShift",
      size: "300px",
      alwaysActive: true,
    },
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  rawUserId: "user_fk",
  searchTerms: ["fullName"],
  defaultSort: {
    order: "asc",
    orderBy: "date",
  },
  defaultFilters: [
    {
      key: "date",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "date",
      value: moment().endOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("shifts"),
    },
    {
      key: "totalHours",
      toLocaleString: false,
      aggregator: "sum",
      label: t("totalHours"),
    },
    // TODO Add icon avatar !!!
    {
      key: "fullName",
      aggregator: "mostUsed",
      showSpecificValue: true,
      toLocaleString: false,
      formatLabel: true,
      label: t("workedTheMost"),
    },
  ],
  entitiesSelector: attendanceSelector,
  setSingleSelected: attendanceActions.setSingleSelected,
  removeSingleSelected: attendanceActions.removeSelected,
  setSelectedEntities: attendanceActions.setSelected,
  selectedEntitiesSelector: selectedAttendanceSelector,
  fetchOnlyData: attendanceActions.fetchAttendancies,
  fetchRawPostData: employeeAttendancyService.getAttendance,
  fetchDataActions: [
    locationsActions.fetchLocations,
    messagesActions.fetchSmsBank,
    activeEmployeeActions.fetchActiveEmployes,
  ],
  isStaff: true,
  isLead: false,
  isDataLoading: state => state.employeeAttendance.loading,
  csvGenerator: (entities, locationName) =>
    csvService.getEmployeeAttendance(entities, dateFormat, timeFormat, locationName),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: e => e.fullName,
      width: 15,
    },
    {
      id: "date",
      label: t("date"),
      valueRenderer: e => (e.date ? moment(e.date).format(dateFormat) : ""),
      showFilterChip: true,
      filterType: "period",
      periodPrefixes: ["today", "yesterday", "thisWeek", "thisMonth", "custom"],
      newPage: true,
      width: 10,
    },
    {
      id: "day",
      label: t("day"),
      valueRenderer: e => (e.day ? t(e.day as Phrases) : null),
      width: 10,
    },
    {
      id: "enteringTime",
      label: t("enteringTime"),
      valueRenderer: e => (e.enteringTimeFull ? moment().startOf("day").add(e.enteringTime, "hours").hours() : null),
      filterType: "timeSlider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "enteringLocation",
      label: t("placemarkEnter"),
      valueRenderer: e => e.enteringLocation,
      width: 20,
    },
    {
      id: "exitingTime",
      label: t("exitingTime"),
      valueRenderer: e => moment().startOf("day").add(e.enteringTime, "hours").hours(),
      filterType: "timeSlider",
      showFilterChip: true,
      width: 10,
    },
    {
      id: "exitingLocation",
      label: t("placemarkExit"),
      valueRenderer: e => e.exitingLocation,
      width: 20,
    },
    {
      id: "totalHours",
      label: t("totalHours"),
      valueRenderer: e => {
        if (e.totalHours === 0) {
          return "< 1";
        }

        if (!e.totalHours) {
          return null;
        }

        if (Number.isInteger(e.totalHours)) {
          return e.totalHours.toFixed(1);
        }

        return Math.ceil(e.totalHours);
      },
      filterType: "slider",
      showFilterChip: true,
      width: 10,
    },
  ],
  card: {
    titleRender: (e: EmployeeAttendance) => e.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: (e: EmployeeAttendance) => {
          if (!e.date) {
            return null;
          }
          return `${t(e.day as Phrases)}, ${moment(e.date).format(dateFormat)}`;
        },
      },
      {
        icon: () => <Clock />,
        textRender: (e: EmployeeAttendance) =>
          `${e.enteringTimeFull ? moment(e.enteringTimeFull).format(timeFormat) : ""} ${
            e.exitingTime ? `- ${moment(e.enteringTimeFull).format(timeFormat)}` : ""
          }`,
      },
      {
        icon: () => <Count />,
        textRender: (e: EmployeeAttendance) => {
          if (e.totalHours === 0) {
            return t("hoursShiftData", { hours: "< 1" });
          }

          if (!e.totalHours) {
            return null;
          }

          return t("hoursShiftData", { hours: e.totalHours });
        },
      },
    ],
  },
});

export default employeeAttendanceReport;
