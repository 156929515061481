import moment from "moment";
import { ExternalMembershipDTO } from "../models/externalMembership";
import { getBaseClient } from "./serviceUtils";

const getExternalMemberships = async (from?: Date, to?: Date): Promise<ExternalMembershipDTO[]> => {
  const client = getBaseClient();

  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<ExternalMembershipDTO[]>("reports/global/activeExternalMembersReport", {
    fromDate,
    toDate,
    locationFk: null,
  });

  return result.data;
};

const externalMembershipService = {
  getExternalMemberships,
};

export default externalMembershipService;
