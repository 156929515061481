import moment from "moment";
import { ReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { StringOrNull } from "./misc";

interface ConvertedLeadDTO extends MemberBaseDTO {
  membership_type: StringOrNull;
  lead_source: StringOrNull;
  last_modified: StringOrNull;
  created_by_user: StringOrNull;
  created_by_id: number | null;
}

interface ConvertedLead extends ReportEntity {
  email: StringOrNull;
  membershipType: StringOrNull;
  userId: number;
  source: StringOrNull;
  convertedAt: StringOrNull;
  doneByUser: StringOrNull;
  doneByUserId: number | null;
}

const toConvertedLead = (convertedLead: ConvertedLeadDTO): ConvertedLead => ({
  id: convertedLead.id.toString(),
  userId: convertedLead.user_fk,
  firstName: convertedLead.first_name,
  lastName: convertedLead.last_name,
  fullName: `${convertedLead.first_name} ${convertedLead.last_name}`,
  convertedAt: moment(convertedLead.last_modified).toISOString(),
  membershipType: convertedLead.membership_type,
  doneByUser: convertedLead.created_by_user,
  doneByUserId: convertedLead.created_by_id,
  createdAt: moment(convertedLead.created_at).toISOString(),
  source: !convertedLead.lead_source ? "noSource" : convertedLead.lead_source,
  phone: convertedLead.phone,
  email: convertedLead.email,
  location: convertedLead.location,
});

export type { ConvertedLeadDTO, ConvertedLead };

export { toConvertedLead };
