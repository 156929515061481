import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { Gender, StringOrNull, yesNoToBool } from "./misc";

const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

interface LeadInProcessDTO extends MemberBaseDTO {
  lead_source: StringOrNull;
  lead_status: StringOrNull;
}

interface LeadInProcess extends BaseReportEntity {
  fullName: string;
  gender: Gender | null;
  email: string;
  phone: string | null;
  leadStatus: StringOrNull;
  userId: number;
  leadSource: StringOrNull;
  createdAt: string;
  updatedAt: string;
  task: StringOrNull;
  location: string;
}

const toLeadInProcess = (leadInProcess: LeadInProcessDTO): LeadInProcess => ({
  id: leadInProcess.id.toString(),
  userId: leadInProcess.id,
  firstName: leadInProcess.first_name ? leadInProcess.first_name : t("unknown"),
  lastName: leadInProcess.last_name ? leadInProcess.last_name : t("unknown"),
  //TODO We are disregarded all names, which contain null within first_name or last_name!
  fullName:
    !leadInProcess.first_name || !leadInProcess.last_name
      ? t("unknown")
      : `${leadInProcess.first_name} ${leadInProcess.last_name}`,
  gender: leadInProcess.gender,
  email: leadInProcess.email,
  phone: !leadInProcess.phone || !leadInProcess.phone.match(phoneRegex) ? null : leadInProcess.phone,
  leadStatus: leadInProcess.lead_status,
  leadSource: leadInProcess.lead_source,
  createdAt: moment(leadInProcess.created_at).toISOString(),
  updatedAt: moment(leadInProcess.updated_at).toISOString(),
  task: leadInProcess.reminder,
  location: leadInProcess.location,
  allowSMS: yesNoToBool(leadInProcess.allow_sms),
  allowEmails: yesNoToBool(leadInProcess.allow_mailing_list),
});

export type { LeadInProcessDTO, LeadInProcess };

export { phoneRegex, toLeadInProcess };
