import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { StringOrNull, toDayOfWeek } from "./misc";

const toBookingStatus = (cancelled: StringOrNull, registered: number): string => {
  if (cancelled) {
    return "bookingCanceled";
  }

  if (registered === 1) {
    return "booked";
  }

  return "checkedIn";
};

interface AttendanceExternalMemberDTO {
  user_fk: number;
  first_name: string;
  last_name: string;
  created_at: string;
  category: string;
  date: string;
  day_of_week: string;
  time: string;
  coach: string;
  full_path_image_user: string;
  membership_type_name: string;
  cancelled_by: StringOrNull;
  location: string;
  updated_at: string;
  cancelled: StringOrNull;
  registered: number;
}

interface AttendanceExternalMember {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  bookingTime: string;
  class: string;
  classDate: string;
  classDay: string;
  classTime: number;
  userId: number;
  coachName: string;
  userProfilePicture: string;
  membershipTypeName: string;
  bookingStatus: string;
  cancelledBy: StringOrNull;
  updatedAt: string;
  location: string;
}

const toAttendanceExternalMember = (a: AttendanceExternalMemberDTO, index: number): AttendanceExternalMember => ({
  id: index.toString(),
  firstName: a.first_name,
  userId: a.user_fk,
  lastName: a.last_name,
  fullName: `${a.first_name} ${a.last_name}`,
  bookingTime: moment(a.created_at).toISOString(),
  class: a.category,
  classDate: moment(a.date).toISOString(),
  classDay: toDayOfWeek(a.day_of_week)?.label as string,
  classTime: moment.duration(a.time).asHours(),
  coachName: a.coach !== " " ? a.coach : t("unknown"),
  userProfilePicture: a.full_path_image_user,
  membershipTypeName: a.membership_type_name,
  cancelledBy: a.cancelled_by ? a.cancelled_by : t("unknown"),
  updatedAt: moment(a.updated_at).toISOString(),
  location: a.location,
  bookingStatus: toBookingStatus(a.cancelled, a.registered),
});

export { toAttendanceExternalMember };

export type { AttendanceExternalMemberDTO, AttendanceExternalMember };
