import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Debt, toDebt } from "../models/debt";
import { debtService } from "../services/debtService";
import { ApplicationState } from "./store";

interface State {
  all: Debt[] | null;
  selected: Debt[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface DebtsPayload {
  all: Debt[] | null;
}

interface SelectedDebtsPayload {
  selected: Debt[] | null;
}

const fetchDebts = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { debt } = getState();

    if (debt.all) {
      return;
    }

    dispatch(fetchDebts.start());

    try {
      const data = await debtService.getAllDebts();
      dispatch(fetchDebts.success({ all: data.map(toDebt) }));
    } catch {
      dispatch(fetchDebts.failure());
    }
  },
  {
    start: createActionCreator("@@DEBTS/START_FETCH"),
    success: createActionCreator("@@DEBTS/FETCH_SUCCESS", resolve => (payload: DebtsPayload) => resolve(payload.all)),
    failure: createActionCreator("@@DEBTS/FETCH_FAILURE"),
  }
);

const setSelectedDebts = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { debt } = getState();

    if (!debt.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedDebts.success({ selected: null }));
      return;
    }

    const selected = debt.all.filter(debt => selectedId.includes(debt.id));
    dispatch(setSelectedDebts.success({ selected: selected }));
  },
  {
    success: createActionCreator("@@REPORTS/SELECTED_DEBTS", resolve => (payload: SelectedDebtsPayload) =>
      resolve(payload.selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { debt } = getState();

    if (!debt.all) {
      return;
    }

    const selected = debt.all.find(debt => debt.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_DEBT", resolve => (payload: Debt) => resolve(payload)),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_DEBT/ERROR"),
  }
);

const removeSelectedDebt = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { debt } = getState();

    if (!debt.all) {
      return;
    }

    const selected = debt.all.find(debt => debt.id === selectedId);

    if (!selected) {
      return dispatch(removeSelectedDebt.error());
    }

    dispatch(removeSelectedDebt.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED_DEBT", resolve => (payload: Debt) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED_DEBT/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchDebts.start, state => ({ ...state, loading: true })),
  handleAction(fetchDebts.success, (state, { payload }) => ({ ...state, loading: false, all: payload })),
  handleAction(fetchDebts.failure, state => ({ ...state, loading: false })),
  handleAction(setSelectedDebts.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSelectedDebt.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(l => l.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchDebts,
  setSelectedDebts,
  removeSelectedDebt,
  setSingleSelected,
};

export { actions, reducer };

export type { State };
