interface Currency {
  currency: string;
  currency_symbol: string;
}

interface LocationDTO {
  accRef: null;
  address: string;
  appendsObject: null;
  average_duration_time: null;
  block_book_time: number;
  boxFk: number;
  chip_entrance_count: number;
  countryCode: string | null;
  currency: Currency;
  date_format: string;
  debit_block: number;
  id: number;
  insurance: number;
  location: string;
  logo: string | null;
  max_users_allowed: number | null;
  medical_cert: number;
  passage_block_time: number;
  passage_block_time_session: number;
  rivhitId: null;
  rivhitRecurringSalesGroupId: null;
  timeZone: string; // Asia/Jerusalem
  time_format: string;
  voice_center_code: null;
}

interface Location {
  address: string;
  boxId: number;
  timeFormat: string;
  dateFormat: string;
  id: number;
  name: string;
  currency: Currency;
}

const toLocation = (locationDto: LocationDTO): Location => ({
  address: locationDto.address,
  boxId: locationDto.boxFk,
  timeFormat: locationDto.time_format,
  dateFormat: locationDto.date_format,
  id: locationDto.id,
  name: locationDto.location,
  currency: locationDto.currency,
});

export type { LocationDTO, Location };

export { toLocation };
