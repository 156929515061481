import moment from "moment";
import { BaseReportEntity } from "./base";

interface EntranceDTO {
  id: number;
  first_name: string;
  last_name: string;
  user_fk: number;
  created_at: string | null;
  membership_type_name: string | null;
  end: string | null;
  rfid: number | null;
  sessions_left: number | null;
  email: string | null;
  phone: string | null;
  location: string | null;
  locations_box_fk: number;
}

interface Entrance extends BaseReportEntity {
  userId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  entranceDate: string | null;
  entranceTime: string | null;
  membership: string | null;
  expiredAt: string | null;
  enteredWithFab: string;
  sessionsLeft: number | null;
  email: string | null;
  phone: string | null;
  location: string | null;
  locationId: number;
}

const toEntrance = (e: EntranceDTO): Entrance => ({
  id: e.id.toString(),
  userId: e.user_fk,
  firstName: e.first_name,
  lastName: e.last_name,
  fullName: `${e.first_name} ${e.last_name}`,
  entranceDate: e.created_at ? moment(e.created_at).toISOString() : null,
  entranceTime: e.created_at ? moment(e.created_at).toISOString() : null,
  membership: e.membership_type_name ? e.membership_type_name : null,
  expiredAt: e.end ? moment(e.end).toISOString() : null,
  enteredWithFab: e.rfid ? "yes" : "no",
  sessionsLeft: e.sessions_left,
  email: e.email,
  phone: e.phone,
  location: e.location,
  locationId: e.locations_box_fk,
});

export type { EntranceDTO, Entrance };
export { toEntrance };
