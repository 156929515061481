import { Avatar } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as PersonalTrainer } from "../../icons/personalTrainer.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Warmup } from "../../icons/warmup.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ShiftSummary } from "../../models/shiftSummary";
import csvService from "../../services/csvService";
import shiftSummaryService from "../../services/shiftSummaryService";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as shiftSummaryActions } from "../../store/shiftSummaryStore";
import { shiftSummarySelector, selectedShiftSummarySelector } from "../reportSelector";
import { Page } from "./page";

const filterOptions: TOption[] = [
  { id: "no", label: "no" },
  { id: "yes", label: "yes" },
];

const shiftSummaryReport = (dateFormat: string, timeFormat: string): Page<ShiftSummary> => ({
  id: "shift-summary-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "date",
      label: t("date"),
    },
    {
      id: "timeDigit",
      label: t("time"),
    },
    {
      id: "workout",
      label: t("workout"),
    },
  ],
  title: t("shifts"),
  searchTerms: ["workout", "coachFullName", "fullName", "injury"],
  description: t("shiftSummaryDesc"),
  rawUserId: "user_fk",
  summaryItems: [
    {
      key: "id",
      label: t("bookings"),
      aggregator: "count",
      toLocaleString: false,
    },
    {
      key: "checkedIn",
      label: t("totalCheckIn"),
      aggregator: "positiveCountPercent",
      toLocaleString: false,
    },
    {
      key: "workout",
      aggregator: "mostUsed",
      label: t("mostPopularClass"),
      formatLabel: true,
      toLocaleString: false,
      showSpecificValue: true,
    },
  ],
  defaultSort: {
    order: "asc",
    orderBy: "date",
  },
  defaultFilters: [
    {
      key: "date",
      value: moment().startOf("day").toISOString() as unknown,
      default: true,
      type: "moreThan",
    },
    {
      key: "date",
      value: moment().endOf("day").toISOString() as unknown,
      default: true,
      type: "lessThan",
    },
  ],
  columns: [
    {
      id: "date",
      label: t("date"),
      valueRenderer: entity => moment(entity.date).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["today", "yesterday", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 8,
    },
    {
      id: "exactDay",
      label: t("day"),
      valueRenderer: entity => entity.exactDay,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 8,
      filterOptionsSelector: state => {
        const values = (state.shiftSummary.all || []).map(s => ({ id: s.exactDay, label: s.exactDay }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id, label: v.label }));
      },
    },
    {
      id: "timeDigit",
      label: t("time"),
      width: 8,
      valueRenderer: entity => moment(entity.time).format(timeFormat),
      filterType: "timeSlider",
    },
    {
      id: "workout",
      label: t("class"),
      valueRenderer: entity => entity.workout,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 8,
      filterOptionsSelector: state => {
        const values = (state.shiftSummary.all || []).map(s => ({ id: s.workout, label: s.workout }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id, label: v.label }));
      },
    },
    {
      id: "coachFullName",
      label: t("coach"),
      valueRenderer: entity => entity.coachFullName,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 8,
      filterOptionsSelector: state => {
        const values = (state.shiftSummary.all || []).map(s => ({
          id: s.coachFullName,
          label: s.coachFullName,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({
          id: v.id,
          label: v.label,
          icon: <Avatar style={{ height: 30, width: 30 }}>{v.label![0]}</Avatar>,
        }));
      },
    },
    {
      id: "customerType",
      label: t("customerType"),
      valueRenderer: entity => t(entity.customerType as Phrases),
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 8,
      filterOptionsSelector: state => {
        const values = (state.shiftSummary.all || []).map(s => s.customerType);
        const uniqueValues = _.uniq(values);
        return uniqueValues && uniqueValues.map(v => ({ id: v.toString(), label: t(v as Phrases) }));
      },
    },
    {
      id: "fullName",
      width: 8,
      label: t("customerName"),
      valueRenderer: entity => entity.fullName,
    },
    {
      id: "membershipType",
      label: t("membership"),
      valueRenderer: entity => entity.membershipType,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 8,
      filterOptionsSelector: state => {
        const values = (state.shiftSummary.all || []).map(s => ({
          id: s.membershipType,
          label: s.membershipType,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!.toString(), label: v.label }));
      },
    },
    {
      id: "endMembership",
      label: t("endDateMembership"),
      valueRenderer: entity =>
        entity.endMembership ? moment(entity.endMembership).format(dateFormat) : t("unlimitedMembership"),
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 8,
    },
    {
      id: "sessionsLeft",
      label: t("sessionsLeft"),
      valueRenderer: entity => entity.sessionsLeft,
      filterType: "slider",
      showFilterChip: true,
      newPage: true,
      width: 8,
    },
    {
      id: "debt",
      label: t("debt"),
      valueRenderer: entity => entity.debt,
      filterType: "slider",
      showFilterChip: true,
      newPage: true,
      width: 8,
    },
    {
      id: "injury",
      label: t("injury"),
      valueRenderer: entity => (entity.injury ? t(entity.injury as Phrases) : null),
      width: 8,
    },
    {
      id: "checkedIn",
      label: t("checkedIn"),
      valueRenderer: entity => entity.checkedIn,
      width: 8,
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      filterOptionsSelector: () => filterOptions.map(option => ({ id: option.id, label: option.label })),
    },
  ],
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: item =>
          `${moment(item.date).format(`${"dddd"}, ${dateFormat}`)} ${moment(item.time).format(timeFormat)}`,
      },
      {
        icon: () => <PersonalTrainer />,
        textRender: item => t("personalTrainerData", { personalTrainer: item.coachFullName }),
      },
      {
        icon: () => <Warmup />,
        textRender: item => item.workout,
      },
    ],
  },
  fetchOnlyData: shiftSummaryActions.fetchShiftSummaries,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  setSelectedEntities: shiftSummaryActions.setSelectedShiftSummaries,
  setSingleSelected: shiftSummaryActions.setSingleSelect,
  removeSingleSelected: shiftSummaryActions.removeSelect,
  isDataLoading: state => state.shiftSummary.loading,
  entitiesSelector: shiftSummarySelector,
  selectedEntitiesSelector: selectedShiftSummarySelector,
  fetchRawPostData: shiftSummaryService.getShiftSummaries,
  isLead: false,
  csvGenerator: entities => csvService.getShiftSummaries(entities, dateFormat, timeFormat),
});

export default shiftSummaryReport;
