import moment from "moment";
interface ExternalMembershipDTO {
  user_fk: number;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  membership_type: string | null;
  created_at: string | null;
  location: string | null;
}

interface ExternalMembership {
  id: string;
  firstName: string | null;
  lastName: string | null;
  fullName: string;
  userId: number;
  phone: string | null;
  externalMembership: string | null;
  start: string | null;
  location: string | null;
}

const toExternalMembership = (m: ExternalMembershipDTO): ExternalMembership => ({
  id: m.user_fk.toString(),
  firstName: m.first_name,
  lastName: m.last_name,
  fullName: `${m.first_name || ""} ${m.last_name || ""}`,
  phone: m.phone,
  userId: m.user_fk,
  externalMembership: m.membership_type,
  start: m.created_at ? moment(m.created_at).toISOString() : null,
  location: m.location,
});

export type { ExternalMembership, ExternalMembershipDTO };

export { toExternalMembership };
