import moment from "moment";
import t from "../infrastructure/translations/i18n";
import { Phrases } from "../infrastructure/translations/phrases";
import { StringOrNull } from "./misc";

interface AbsenceDTO {
  id: number;
  user_fk: number;
  first_name: string;
  last_name: string;
  phone: StringOrNull;
  last_seen: StringOrNull;
  name: StringOrNull;
  allow_sms: string;
  allow_mailing_list: string;
  location: string;
  email: string;
  cancelled: 0 | 1;
}

interface Absence {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: StringOrNull;
  userId: number;
  lastSeen: StringOrNull;
  allowSms: string;
  allowEmails: string;
  location: string;
  email: string;
  cancelled: 0 | 1;
  membershipType: StringOrNull;
}

const toAbsence = (a: AbsenceDTO): Absence => ({
  id: a.id.toString(),
  userId: a.user_fk,
  firstName: a.first_name,
  lastName: a.last_name,
  fullName: `${a.first_name} ${a.last_name}`,
  phone: a.phone,
  lastSeen: a.last_seen ? moment(a.last_seen).toISOString() : null,
  membershipType: a.name ? t(a.name as Phrases) : t("unknown"),
  allowSms: t(a.allow_sms as Phrases),
  allowEmails: t(a.allow_mailing_list as Phrases),
  location: a.location,
  email: a.email,
  cancelled: a.cancelled,
});

export { toAbsence };

export type { Absence, AbsenceDTO };
