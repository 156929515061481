import moment from "moment";
import { ClassesSummaryDTO } from "../models/classesSummary";
import { MasterFilter } from "../store/filtersStore";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getAllClassesSummary = async (data?: MasterFilter[]): Promise<ClassesSummaryDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client || !data) {
    return [];
  }

  const type = data.find(d => d.key === "type")?.value[0];
  const date = data.find(d => d.key === "date")?.value;

  if (!type || (!date || !date[0] || !date[1])) {
    return [];
  }

  const momentFromDate = moment(date[0]);
  const momentToDate = moment(date[1]);

  const fromDate = momentFromDate.isValid() ? momentFromDate.format("YYYY-MM-DD") : null;
  const toDate = momentToDate.isValid() ? momentToDate.format("YYYY-MM-DD") : null;

  const response = await client.post<ClassesSummaryDTO[]>(`schedule/${boxId}/getSchedulesBetweenDates`, {
    attendenceSelect: type,
    coachFk: null,
    fromDate,
    location: null,
    toDate,
  });

  return response.data;
};

const classesSummaryService = {
  getAllClassesSummary,
};

export default classesSummaryService;
