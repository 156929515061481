import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { LostLead, toLostLead } from "../models/lostLeads";
import lostLeadsService from "../services/lostLeadsService";
import { ApplicationState } from "./store";

type State = {
  all: LostLead[] | null;
  selected: LostLead[] | null;
  loading: boolean;
};

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

type TPayload = {
  all: LostLead[] | null;
};

const fetchLostLeads = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchLostLeads.start());

    try {
      const data = await lostLeadsService.getLostLeads(from, to);
      dispatch(fetchLostLeads.success({ all: data.map(toLostLead) }));
    } catch {
      dispatch(fetchLostLeads.error());
    }
  },
  {
    start: createActionCreator("@@ALL_LOST_LEADS/START_FETCH"),
    success: createActionCreator("@@ALL_LOST_LEADS/FETCH", resolve => (all: TPayload) => resolve(all)),
    error: createActionCreator("@@ALL_LOST_LEADS/ERROR_FETCH"),
  }
);

const setSelectedLostLeads = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { lostLeads } = getState();

    if (!lostLeads.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedLostLeads.success(null));
    }

    const selected = lostLeads.all.filter(lostLead => selectedId.includes(lostLead.id));
    dispatch(setSelectedLostLeads.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_LOST_LEADS", resolve => (selected: LostLead[] | null) =>
      resolve(selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_LOST_LEADS/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { lostLeads } = getState();

    if (!lostLeads.all) {
      return;
    }

    const selectedLostLead = lostLeads.all.find(lostLead => lostLead.id === selectedId);

    if (!selectedLostLead) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedLostLead));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_LOST_LEAD/SUCCESS", resolve => (payload: LostLead) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_LOST_LEAD/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { lostLeads } = getState();

    if (!lostLeads.all) {
      return;
    }

    const selectedLostLead = lostLeads.all.find(lostLead => lostLead.id === selectedId);

    if (!selectedLostLead) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedLostLead));
  },
  {
    success: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_LOST_LEAD/SUCCESS", resolve => (payload: LostLead) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_LOST_LEAD/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchLostLeads.start, state => ({ ...state, loading: true })),
  handleAction(fetchLostLeads.success, (state, { payload }) => ({
    ...state,
    all: payload.all,
    loading: false,
  })),
  handleAction(fetchLostLeads.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedLostLeads.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedLostLead => selectedLostLead.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchLostLeads,
  setSelectedLostLeads,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
