import _ from "lodash";
import moment from "moment";
import React from "react";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as FullTank } from "../../icons/fullTank.svg";
import { ReactComponent as PersonalTrainer } from "../../icons/personalTrainer.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ClassesSummary } from "../../models/classesSummary";
import classesSummaryService from "../../services/classesSummaryService";
import csvService from "../../services/csvService";
import { actions as classesSummaryActions } from "../../store/classesSummaryStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { classesSummarySelector, selectedClassesSummarySelector } from "../reportSelector";
import { Page } from "./page";

const options: TOption[] = [
  { id: "checkIn", label: "checkIn" },
  { id: "registered", label: "registered" },
];

const ClassesSummaryReport = (dateFormat: string, timeFormat: string, locationCurrency: any): Page<ClassesSummary> => ({
  id: "classes-summary-report",
  rawUserId: "id",
  entityClick: entity => null, //TODO fix once michael confirms
  description: t("classesSummaryDesc"),
  summaryItems: [
    {
      key: "class",
      label: t("classes"),
      aggregator: "count",
      toLocaleString: false,
    },
    {
      key: "id",
      label: t("bookings"),
      aggregator: "count",
      toLocaleString: false,
    },
    {
      key: "bookedMembers",
      label: t("occupationRate"),
      aggregator: "averageOfPercentages",
      differentKey: "occupationRate",
      toLocaleString: false,
    },
    {
      key: "attendanceRate",
      label: t("avgAttandanceRate"),
      aggregator: "averageOfPercentages",
      differentKey: "attendanceRate",
      toLocaleString: false,
    },
    {
      key: "coachesTotalWage",
      label: t("coachesTotalWage"),
      aggregator: "sum",
      showCurrency: true,
      toLocaleString: false,
    },
  ],
  actions: [],
  defaultSort: {
    order: "asc",
    orderBy: "date",
  },
  fetchDataActions: [locationsActions.fetchLocations],
  setSelectedEntities: classesSummaryActions.setSelectedClassesSummary,
  withoutCheckboxes: true,
  setSingleSelected: classesSummaryActions.setSingleSelected,
  removeSingleSelected: classesSummaryActions.removeSingleSelected,
  isDataLoading: state => state.canceledMemberships.loading,
  title: t("coachesSchedules"),
  isLead: false,
  columns: [
    {
      id: "date",
      label: t("scheduleDate"),
      valueRenderer: item => moment(item.date).format(dateFormat),
      width: 10,
    },
    {
      id: "dayOfWeek",
      label: t("day"),
      width: 7,
      valueRenderer: entity => t(entity.dayOfWeek as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.classesSummary.all || []).map(c => ({
          id: c.dayOfWeek,
          label: c.dayOfWeek,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "startTime",
      label: t("time"),
      width: 10,
      valueRenderer: entity => entity.timeResult,
      // newPage: true,
      // filterType: "timeSlider",
      // showFilterChip: true,
    },
    {
      id: "totalMinutes",
      label: t("classDuration"),
      filterType: "slider",
      showFilterChip: true,
      width: 10,
      valueRenderer: entity => t("totalMinutesDuration", { totalMinutesDuration: entity.totalMinutes }),
    },
    {
      id: "status",
      label: t("status"),
      width: 10,
      valueRenderer: entity => t(entity.status as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.classesSummary.all || []).map(c => ({
          id: c.status,
          label: c.status,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "coachesFk",
      label: t("coaches"),
      width: 10,
      valueRenderer: entity => entity.coachesNames,
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = state.classesSummary.all || [];
        if (!values.length) {
          return [];
        }
        const firstCoaches = values.map(c => ({ id: c.coachFk, label: c.coachName }));
        const secondCoaches = values.map(c => ({ id: c.secondCoachFk, label: c.secondCoachName }));
        const allCoaches = [...firstCoaches, ...secondCoaches];
        const uniqueValues = _.uniqBy(allCoaches, "id").filter(a => a.id);
        return uniqueValues.map(v => ({ id: v.id.toString(), label: t(v.label as Phrases) }));
      },
    },
    {
      id: "class",
      label: t("class"),
      width: 10,
      valueRenderer: entity => t(entity.class as Phrases),
      showFilterChip: true,
      newPage: true,
      filterType: "multipleSearchable",
      filterOptionsSelector: state => {
        const values = (state.classesSummary.all || []).map(c => ({
          id: c.class,
          label: c.class,
        }));
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(v => ({ id: v.id!, label: t(v.label as Phrases) }));
      },
    },
    {
      id: "bookedMembers",
      label: t("numOfBooking"),
      filterType: "slider",
      showFilterChip: true,
      width: 10,
      valueRenderer: entity => `${entity.bookedMembers} (${entity.occupationRate}%)`,
    },
    {
      id: "checkedInMembers",
      label: t("checkIn"),
      filterType: "slider",
      showFilterChip: true,
      width: 10,
      valueRenderer: entity => `${entity.checkedInMembers} (${entity.attendanceRate}%)`,
    },
    {
      id: "standByMembers",
      label: t("standby"),
      filterType: "slider",
      showFilterChip: true,
      width: 10,
      valueRenderer: entity => entity.standByMembers,
    },
    {
      id: "coachesTotalWage",
      label: t("wage"),
      filterType: "slider",
      showFilterChip: true,
      width: 10,
      valueRenderer: entity =>
        entity.coachesTotalWage
          ? `${locationCurrency ? locationCurrency.currency.currency_symbol : ""} ${entity.coachesTotalWage}`
          : t("noWage"),
    },
  ],
  csvGenerator: entities => csvService.getClassesSummary(entities, dateFormat),
  entitiesSelector: classesSummarySelector,
  selectedEntitiesSelector: selectedClassesSummarySelector,
  fetchPostRequest: classesSummaryActions.fetchClassesSummary,
  fetchRawMasterData: classesSummaryService.getAllClassesSummary,
  card: {
    titleRender: item => item.coachName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: item =>
          `${moment(item.date).format(`${"dddd"}, ${dateFormat}`)} ${moment(item.startTime).format(timeFormat)}`,
      },
      {
        icon: () => <PersonalTrainer />,
        textRender: item =>
          t("personalTrainerData", {
            personalTrainer: item.coachName ? item.coachName : t("unknown"),
          }),
      },
      {
        icon: () => <FullTank />,
        textRender: item =>
          t("bookingRecords", { bookedMembers: item.bookedMembers, occupationRate: item.occupationRate }),
      },
    ],
  },
  hideActions: true,
  masterFilter: {
    mainLabel: t("individualWageCalculationBy"),
    defaultFilters: [
      {
        key: "type",
        value: ["checkIn"],
      },
      {
        key: "date",
        value: [moment().startOf("month").toISOString(), moment().endOf("month").toISOString()],
      },
    ],
    filters: [
      {
        id: "type",
        label: t("type"),
        filterType: "masterSearchable",
        options: () => options.map(o => ({ ...o, label: t(o.label as Phrases) })),
        connector: t("from"),
      },
      {
        id: "date",
        label: t("scheduleDate"),
        filterType: "doublePeriod",
        periodPrefixes: ["today", "thisWeek", "thisMonth", "custom"],
      },
    ],
  },
});

export default ClassesSummaryReport;
