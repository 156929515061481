import { NumberOrNull } from "./misc";

interface TWeekCell {
  value: string;
  icon: string;
}

interface PreservationDTO {
  first_name: string;
  last_name: string;
  user_fk: number;
  age: NumberOrNull;
  week1: string;
  week2: string;
  week3: string;
  week4: string;
  week5: string;
  week6: string;
  week7: string;
  week8: string;
  week9: string;
  week10: string;
}

interface Preservation {
  firstName: string;
  lastName: string;
  userId: number;
  fullName: string | null;
  age: NumberOrNull;
  id: string;
  week1: number;
  ratingWeek1: string;
  week2: number;
  ratingWeek2: string;
  week3: number;
  ratingWeek3: string;
  week4: number;
  ratingWeek4: string;
  week5: number;
  ratingWeek5: string;
  week6: number;
  ratingWeek6: string;
  week7: number;
  ratingWeek7: string;
  week8: number;
  ratingWeek8: string;
  week9: number;
  ratingWeek9: string;
  week10: number;
  ratingWeek10: string;
}

const getCellInformation = (firstCell: string, secondCell: string): string => {
  return parseInt(firstCell) > parseInt(secondCell)
    ? "up"
    : parseInt(firstCell) === parseInt(secondCell)
    ? "equal"
    : "down";
};

const toPreservation = (pr: PreservationDTO): Preservation => ({
  id: pr.user_fk.toString(),
  firstName: pr.first_name,
  userId: pr.user_fk,
  lastName: pr.last_name,
  fullName: `${pr.first_name} ${pr.last_name}`.trim() ? `${pr.first_name} ${pr.last_name}`.trim() : null,
  age: pr.age,
  week1: parseInt(pr.week1),
  week2: parseInt(pr.week2),
  week3: parseInt(pr.week3),
  week4: parseInt(pr.week4),
  week5: parseInt(pr.week5),
  week6: parseInt(pr.week6),
  week7: parseInt(pr.week7),
  week8: parseInt(pr.week8),
  week9: parseInt(pr.week9),
  week10: parseInt(pr.week10),
  ratingWeek1: "equals",
  ratingWeek2: getCellInformation(pr.week2, pr.week1),
  ratingWeek3: getCellInformation(pr.week3, pr.week2),
  ratingWeek4: getCellInformation(pr.week4, pr.week3),
  ratingWeek5: getCellInformation(pr.week5, pr.week4),
  ratingWeek6: getCellInformation(pr.week6, pr.week5),
  ratingWeek7: getCellInformation(pr.week7, pr.week6),
  ratingWeek8: getCellInformation(pr.week8, pr.week7),
  ratingWeek9: getCellInformation(pr.week9, pr.week8),
  ratingWeek10: getCellInformation(pr.week10, pr.week9),
});

export type { Preservation, PreservationDTO };

export { toPreservation };
