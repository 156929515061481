import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { TrialClass, toTrialClass } from "../models/trialClasses";
import trialClassesService from "../services/trialClassesService";
import { ApplicationState } from "./store";

interface State {
  all: TrialClass[] | null;
  selected: TrialClass[] | null;
  loading: boolean;
}

interface TPayload {
  all: TrialClass[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchTrialClasses = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchTrialClasses.start());

    try {
      const data = await trialClassesService.getTrialClasses(from, to);
      dispatch(fetchTrialClasses.success({ all: data.map(toTrialClass) }));
    } catch {
      dispatch(fetchTrialClasses.error());
    }
  },
  {
    start: createActionCreator("@@TRIAL_CLASSES/START_FETCH"),
    error: createActionCreator("@@TRIAL_CLASSES/FETCH_ERROR"),
    success: createActionCreator("@@TRIAL_CLASSES/FETCH_SUCCESS", resolve => (payload: TPayload) => resolve(payload)),
  }
);

const setSelectedTrialClasses = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { trialClasses } = getState();

    if (!trialClasses.all) {
      return;
    }

    if (!selectedId) {
      return dispatch(setSelectedTrialClasses.success(null));
    }
    const selected = trialClasses.all.filter(trialClass => selectedId.includes(trialClass.id));

    dispatch(setSelectedTrialClasses.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_TRIAL_CLASSES", resolve => (payload: TrialClass[] | null) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_TRIAL_CLASSES/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { trialClasses } = getState();

    if (!trialClasses.all) {
      return;
    }

    const selectedTrialClass = trialClasses.all.find(trialClass => trialClass.id === selectedId);

    if (!selectedTrialClass) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedTrialClass));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_TRIAL_CLASS/SUCCESS", resolve => (payload: TrialClass) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_TRIAL_CLASS/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { trialClasses } = getState();

    if (!trialClasses.all) {
      return;
    }

    const selectedTrialClass = trialClasses.all.find(trialClass => trialClass.id === selectedId);

    if (!selectedTrialClass) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedTrialClass));
  },
  {
    success: createActionCreator(
      "REPORTS/REMOVE_SINGLE_SELECTED_TRIAL_CLASS/SUCCESS",
      resolve => (payload: TrialClass) => resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_TRIAL_CLASS/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchTrialClasses.start, state => ({ ...state, loading: true })),
  handleAction(fetchTrialClasses.error, state => ({ ...state, loading: false })),
  handleAction(fetchTrialClasses.success, (state, action) => ({
    ...state,
    loading: false,
    all: action.payload.all,
  })),
  handleAction(setSelectedTrialClasses.success, (state, action) => ({
    ...state,
    selected: action.payload,
  })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedTrialClass => selectedTrialClass.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchTrialClasses,
  setSelectedTrialClasses,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
