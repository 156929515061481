import moment from "moment";
import { EntranceDTO } from "../models/entrance";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getEntrances = async (from?: Date, to?: Date): Promise<EntranceDTO[]> => {
  const client = getBaseClient();
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const result = await client.get<EntranceDTO[]>(`box/${boxId}/usersEnters/${fromDate}/${toDate}`);

  return result.data;
};

const entranceService = {
  getEntrances,
};

export default entranceService;
