import moment from "moment";
import { BaseReportEntity } from "./base";
import { MemberBaseDTO } from "./memberBase";
import { Task, yesNoToBool } from "./misc";

const futureMembershipTransform = (membership: 0 | 1) => {
  if (membership === 0) {
    return "no";
  }

  if (membership === 1) {
    return "yes";
  }

  return "";
};

const coordinatorTransform = (membership: 0 | 1 | null): string => {
  if (membership === 1) {
    return "yes";
  }

  return "no";
};

interface ExpiringMembershipDTO extends MemberBaseDTO {
  active: number;
  membership_type: string;
  end: string;
  start: string;
  deactivate_time: null;
  membership_user_id: number;
  head: 0 | 1 | null;
  cancelled: string;
  has_future_membership: 0 | 1;
  reminder: Task;
  muid: number;
}

interface ExpiringMembership extends BaseReportEntity {
  membershipType: string;
  head: string;
  startedAt: string;
  endedAt: string;
  fullName: string;
  userId: number;
  futureMembership: string;
  phone: string | null;
  email: string | null;
  allowSMS: boolean | null;
  allowEmails: boolean | null;
  location: string;
  task: Task;
  locationId: number;
}

const toExpiringMembership = (expiringMembership: ExpiringMembershipDTO): ExpiringMembership => ({
  id: expiringMembership.muid.toString(),
  userId: expiringMembership.user_fk,
  firstName: expiringMembership.first_name,
  lastName: expiringMembership.last_name,
  fullName: `${expiringMembership.first_name} ${expiringMembership.last_name}`,
  location: expiringMembership.location,
  membershipType: expiringMembership.membership_type,
  head: coordinatorTransform(expiringMembership.head),
  startedAt: moment(expiringMembership.start).toISOString(),
  futureMembership: futureMembershipTransform(expiringMembership.has_future_membership),
  endedAt: moment(expiringMembership.end).toISOString(),
  phone: expiringMembership.phone,
  email: expiringMembership.email,
  allowSMS: yesNoToBool(expiringMembership.allow_sms),
  allowEmails: yesNoToBool(expiringMembership.allow_mailing_list),
  task: expiringMembership.reminder,
  locationId: expiringMembership.locations_box_fk,
});

export type { ExpiringMembershipDTO, ExpiringMembership };
export { toExpiringMembership, coordinatorTransform };
