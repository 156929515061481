import { createReducer, createActionCreator } from "deox";
import { Dispatch } from "redux";
import { THyperlink } from "../components/customComponents/customNotification";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../constants";
import { Location } from "../models/location";
import { actions as locationActions } from "./locationsStore";
import { ApplicationState } from "./store";

type TNotification = {
  id: string;
  type: "success" | "info" | "warning" | "error";
  description?: string;
  hasHyperlink?: THyperlink;
  dynamicValues?: string[];
};

type State = {
  dateFormat: string;
  timeFormat: string;
  currentLocation: number | null;
  notifications: TNotification[];
};

const defaultState: State = {
  dateFormat: DEFAULT_DATE_FORMAT,
  timeFormat: DEFAULT_TIME_FORMAT,
  currentLocation: null,
  notifications: [],
};

const addNotification = Object.assign(
  (notification: TNotification) => (dispatch: Dispatch) => {
    dispatch(addNotification.success(notification));
  },
  {
    success: createActionCreator("@@ADD_NOTIFICATION_SUCCESS", resolve => (n: TNotification) => resolve(n)),
  }
);

const removeNotification = Object.assign(
  (notificationId: string) => (dispatch: Dispatch) => {
    dispatch(removeNotification.success(notificationId));
  },
  {
    success: createActionCreator("@@REMOVE_NOTIFICATION_SUCCESS", resolve => (nId: string) => resolve(nId)),
  }
);

const removeAllNotifications = Object.assign(
  () => (dispatch: Dispatch) => {
    dispatch(removeAllNotifications.success());
  },
  {
    success: createActionCreator("@@REMOVE_ALL_NOTIFICATIONS_SUCCESS", resolve => () => resolve()),
  }
);

const updateCurrentLocation = Object.assign(
  (currentLocation: number | null) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    if (!currentLocation) {
      dispatch(updateCurrentLocation.remove());
      return;
    }

    const { settings } = getState();

    const { locations } = getState();

    if (settings.currentLocation === currentLocation && !currentLocation) {
      return;
    }

    const location = locations.locations?.find(l => l.id === currentLocation);

    if (!location) {
      return;
    }

    try {
      dispatch(updateCurrentLocation.success(location));
    } catch {
      return;
    }
  },
  {
    remove: createActionCreator("@@CURRENT_LOCATION/REMOVE"),
    success: createActionCreator("@@CURRENT_LOCATION/UPDATE", resolve => (currentLocation: Location) =>
      resolve(currentLocation)
    ),
  }
);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(locationActions.fetchLocations.success, (state, action) => ({
    ...state,
    dateFormat: action.payload.length ? action.payload[0].dateFormat : state.dateFormat,
    timeFormat: action.payload.length ? action.payload[0].timeFormat : state.timeFormat,
  })),
  handleAction(updateCurrentLocation.success, (state, action) => ({
    ...state,
    dateFormat: action.payload.dateFormat,
    timeFormat: action.payload.timeFormat,
  })),
  handleAction(updateCurrentLocation.success, (state, action) => ({ ...state, currentLocation: action.payload.id })),
  handleAction(updateCurrentLocation.remove, state => ({ ...state, currentLocation: null })),
  handleAction(addNotification.success, (state, action) => ({
    ...state,
    notifications: [...state.notifications, action.payload],
  })),
  handleAction(removeNotification.success, (state, action) => ({
    ...state,
    notifications: state.notifications.length ? [...state.notifications.filter(n => n.id !== action.payload)] : [],
  })),
  handleAction(removeAllNotifications.success, state => ({
    ...state,
    notifications: [],
  })),
]);

const actions = {
  updateCurrentLocation,
  addNotification,
  removeNotification,
  removeAllNotifications,
};

export { actions, reducer };

export type { State, TNotification };
