import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Sales, toSale } from "../models/sales";
import salesService from "../services/salesService";
import { MasterFilter } from "./filtersStore";
import { ApplicationState } from "./store";

interface State {
  all: Sales[] | null;
  selected: Sales[] | null;
  loading: boolean;
}

interface TSalesPayload {
  all: Sales[] | null;
}

interface TSelectedSalesPayload {
  selected: Sales[] | null;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

const fetchAllSales = Object.assign(
  (from?: Date, to?: Date) => async (dispatch: Dispatch) => {
    dispatch(fetchAllSales.start());

    try {
      const data = await salesService.getAllSales(from, to);
      dispatch(fetchAllSales.success({ all: data.map(toSale) }));
    } catch {
      dispatch(fetchAllSales.error());
    }
  },
  {
    start: createActionCreator("SALES_FETCH/START"),
    success: createActionCreator("SALES_FETCH/SUCCESS", resolve => (payload: TSalesPayload) => resolve(payload.all)),
    error: createActionCreator("SALES_FETCH/ERROR"),
  }
);

const fetchAllSummarizedSales = Object.assign(
  (postData: MasterFilter[]) => async (dispatch: Dispatch) => {
    dispatch(fetchAllSummarizedSales.start());

    try {
      const data = await salesService.getALlSummarizedSales(postData);
      dispatch(fetchAllSummarizedSales.success({ all: data.map(toSale) }));
    } catch {
      dispatch(fetchAllSummarizedSales.error());
    }
  },
  {
    start: createActionCreator("SALES_SUMMARY_FETCH/START"),
    success: createActionCreator("SALES_SUMMARY_FETCH/SUCCESS", resolve => (payload: TSalesPayload) => resolve(payload.all)),
    error: (createActionCreator("SALES_SUMMARY_FETCH/ERROR"))
  }
);

const setSelectedSales = Object.assign(
  (selectedIds: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sales } = getState();

    if (!sales.all) {
      return;
    }

    if (!selectedIds) {
      return dispatch(setSelectedSales.success({ selected: null }));
    }

    const selected = sales.all.filter(sale => selectedIds?.includes(sale.id));
    dispatch(setSelectedSales.success({ selected: selected }));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SELECTED_SALES", resolve => (payload: TSelectedSalesPayload) =>
      resolve(payload.selected)
    ),
    error: createActionCreator("@@REPORTS/SET_SELECTED_SALES/ERROR"),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sales } = getState();

    if (!sales.all) {
      return;
    }

    const selectedSale = sales.all.find(sale => sale.id === selectedId);

    if (!selectedSale) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selectedSale));
  },
  {
    success: createActionCreator("REPORTS/SET_SINGLE_SELECTED_SALE/SUCCESS", resolve => (payload: Sales) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/SET_SINGLE_SELECTED_SALE/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { sales } = getState();

    if (!sales.all) {
      return;
    }

    const selectedSale = sales.all.find(sale => sale.id === selectedId);

    if (!selectedSale) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selectedSale));
  },
  {
    success: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_SALE/SUCCESS", resolve => (payload: Sales) =>
      resolve(payload)
    ),
    error: createActionCreator("REPORTS/REMOVE_SINGLE_SELECTED_SALE/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchAllSales.start, state => ({ ...state, loading: true })),
  handleAction(fetchAllSummarizedSales.start, state => ({ ...state, loading: true })),
  handleAction(fetchAllSales.success, (state, action) => produce(state, draft => {
    draft.loading = false;
    draft.all = action.payload;
  })),
  handleAction(fetchAllSummarizedSales.success, (state, action) => produce(state, draft => {
    draft.loading = false;
    draft.all = action.payload;
  })),
  handleAction(fetchAllSummarizedSales.error, state => ({ ...state, loading: false })),
  handleAction(fetchAllSales.error, state => ({ ...state, loading: false })),
  handleAction(setSelectedSales.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? [...state.selected, payload] : [payload],
  })),
  handleAction(removeSingleSelected.success, (state, { payload }) => ({
    ...state,
    selected: state.selected ? state.selected.filter(selectedSale => selectedSale.id !== payload.id) : null,
  })),
]);

const actions = {
  fetchAllSales,
  setSelectedSales,
  fetchAllSummarizedSales,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
