import moment from "moment";
import { BaseReportEntity } from "./base";

interface ExpiredSessionPackDTO {
  membership_type_name: string;
  sessions_left: number;
  end: string | null;
  cancelled: string;
  id: number;
  user_fk: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  location: string;
}

interface ExpiredSessionPack extends BaseReportEntity {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: number;
  phone: string | null;
  email: string;
  membershipType: string;
  sessionsLeft: number;
  endedAt: Date | null | string;
  membershipStatus: string;
  location: string;
}

const toExpiredSessionPack = (expiredSessionPack: ExpiredSessionPackDTO): ExpiredSessionPack => ({
  id: expiredSessionPack.id.toString(),
  userId: expiredSessionPack.user_fk,
  firstName: expiredSessionPack.first_name,
  lastName: expiredSessionPack.last_name,
  fullName: `${expiredSessionPack.first_name} ${expiredSessionPack.last_name}`,
  phone: expiredSessionPack.phone,
  email: expiredSessionPack.email,
  membershipType: expiredSessionPack.membership_type_name,
  sessionsLeft: expiredSessionPack.sessions_left,
  endedAt: expiredSessionPack.end ? moment(expiredSessionPack.end).toISOString() : null,
  membershipStatus: expiredSessionPack.cancelled.toLowerCase() === "yes" ? "sessionCanceled" : "sessionExpired",
  location: expiredSessionPack.location,
});

export type { ExpiredSessionPackDTO, ExpiredSessionPack };

export { toExpiredSessionPack };
