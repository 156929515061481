import moment from "moment";

interface LeadStatisticsDTO {
  source_name: string; //source
  total_leads: number; //leads
  converted_leads: string; //converted
  converted_this_month: number; //created and converted
  lost_leads: string; //lost
  converted_percentage: number;
  lost_percentage: number;
  created_this_month_and_lost: number; //created and lost
  lead_schedules: number; //booked classes
  lead_schedules_this_month: number; //created and booked classes
  month?: string;
}

interface LeadStatistics {
  id: string;
  sourceName: string;
  totalLeads: number;
  convertedLeads: string;
  convertedPercentage: number;
  createdAndConvertedLeads: number;
  lostLeads: string;
  lostPercentage: number;
  createdAndLostLeads: number;
  bookedClasses: number;
  createdAndBookedClasses: number;
  monthName?: string | null;
  month?: number | null;
}

const toLeadStatistics = (l: LeadStatisticsDTO, indexAsId: number): LeadStatistics => {
  return {
    id: indexAsId.toString(),
    sourceName: l.source_name,
    totalLeads: l.total_leads,
    convertedPercentage: l.converted_percentage ? l.converted_percentage : 0,
    convertedLeads: l.converted_leads,
    createdAndConvertedLeads: l.converted_this_month,
    lostLeads: l.lost_leads,
    lostPercentage: l.lost_percentage ? l.lost_percentage : 0,
    createdAndLostLeads: l.created_this_month_and_lost,
    bookedClasses: l.lead_schedules,
    createdAndBookedClasses: l.lead_schedules_this_month,
    monthName: l.month
      ? moment()
        .month(+l.month - 1)
        .format("MMMM")
        .toLowerCase()
      : "",
    month: l.month ? +l.month : undefined,
  };
};

export { toLeadStatistics };

export type { LeadStatisticsDTO, LeadStatistics };
