import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createReducer, createActionCreator } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Regular, toRegular } from "../models/regulars";
import regularsService from "../services/regularsService";
import { ApplicationState } from "./store";

interface State {
  all: Regular[] | null;
  selected: Regular[] | null;
  loading: boolean;
}

const defaultState: State = {
  all: null,
  selected: null,
  loading: false,
};

interface RegularsPayload {
  all: Regular[] | null;
}

interface SelectedRegularsPayload {
  selected: Regular[] | null;
}

const fetchRegulars = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { regulars } = getState();

    if (regulars.all) {
      return;
    }

    dispatch(fetchRegulars.start());

    try {
      const data = await regularsService.getRegulars();
      dispatch(fetchRegulars.success({ all: data.map(toRegular) }));
    } catch {
      dispatch(fetchRegulars.failure());
    }
  },
  {
    start: createActionCreator("@@REGULARS/START_FETCH"),
    success: createActionCreator("@@REGULARS/FETCH_SUCCESS", resolve => (payload: RegularsPayload) =>
      resolve(payload.all)
    ),
    failure: createActionCreator("@@REGULARS/FETCH_FAILURE"),
  }
);

const setSelectedRegulars = Object.assign(
  (selectedId: string[] | null) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { regulars } = getState();

    if (!regulars.all) {
      return;
    }

    if (!selectedId) {
      dispatch(setSelectedRegulars.success({ selected: null }));
      return;
    }

    const selected = regulars.all.filter(r => selectedId.includes(r.id));
    dispatch(setSelectedRegulars.success({ selected: selected }));
  },
  {
    success: createActionCreator("@@REPORTS/SELECTED_REGULARS", resolve => (payload: SelectedRegularsPayload) =>
      resolve(payload.selected)
    ),
  }
);

const setSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { regulars } = getState();

    if (!regulars.all) {
      return;
    }

    const selected = regulars.all.find(r => r.id === selectedId);

    if (!selected) {
      return dispatch(setSingleSelected.error());
    }

    dispatch(setSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_REGULAR", resolve => (payload: Regular) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/SET_SINGLE_SELECTED_REGULAR/ERROR"),
  }
);

const removeSingleSelected = Object.assign(
  (selectedId: string) => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { regulars } = getState();

    if (!regulars.all) {
      return;
    }

    const selected = regulars.all.find(r => r.id === selectedId);

    if (!selected) {
      return dispatch(removeSingleSelected.error());
    }

    dispatch(removeSingleSelected.success(selected));
  },
  {
    success: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED_REGULAR", resolve => (payload: Regular) =>
      resolve(payload)
    ),
    error: createActionCreator("@@REPORTS/REMOVE_SINGLE_SELECTED_REGULAR/ERROR"),
  }
);

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
        draft.selected = null;
      });
    }
    return state;
  }),
  handleAction(fetchRegulars.start, state => ({ ...state, loading: true })),
  handleAction(fetchRegulars.success, (state, { payload }) => ({ ...state, loading: false, all: payload })),
  handleAction(fetchRegulars.failure, state => ({ ...state, loading: false })),
  handleAction(setSelectedRegulars.success, (state, { payload }) => ({ ...state, selected: payload })),
  handleAction(setSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? [...state.selected, action.payload] : [action.payload],
  })),
  handleAction(removeSingleSelected.success, (state, action) => ({
    ...state,
    selected: state.selected ? state.selected.filter(r => r.id !== action.payload.id) : null,
  })),
]);

const actions = {
  fetchRegulars,
  setSelectedRegulars,
  setSingleSelected,
  removeSingleSelected,
};

export { actions, reducer };

export type { State };
