import { Avatar } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import { ReactComponent as Class } from "../../icons/warmup.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { TrialClass } from "../../models/trialClasses";
import csvService from "../../services/csvService";
import trialClassesService from "../../services/trialClassesService";
import { actions as filterValuesActions } from "../../store/filterValuesStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { actions as trialClassesActions } from "../../store/trialClassesStore";
import { trialClassesSelector, selectedTrialClassesSelector } from "../reportSelector";
import { Page } from "./page";

const checkedInOptions: TOption[] = [
  { id: "yes", label: "yes" },
  { id: "no", label: "no" },
];

const taskOptions: TOption[] = [
  { id: "taskNone", label: "taskNone" },
  { id: "taskFuture", label: "taskFuture" },
  { id: "taskPast", label: "taskPast" },
];

const trialClassesReport = (dateFormat: string, timeFormat: string): Page<TrialClass> => ({
  id: "trial-classes-report",
  entityClick: entity => wrapperActions.openLeadDetails({ leadId: entity.userId.toString() }),
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "date",
      label: t("date"),
    },
    {
      id: "time",
      label: t("time"),
    },
    {
      id: "className",
      label: t("workout"),
    },
  ],
  description: t("trialClassDesc"),
  searchTerms: ["fullName", "phone"],
  rawUserId: "id",
  defaultSort: {
    order: "asc",
    orderBy: "fullName",
  },
  defaultFilters: [
    {
      key: "date",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "date",
      value: moment().endOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("leads"),
    },
    {
      toLocaleString: false,
      key: "coach",
      aggregator: "mostUsed",
      label: t("mostPopularCoach"),
      formatLabel: true,
      showSpecificValue: true,
      icon: (
        // TODO: Fix the avatar to hold actual info of user
        <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQdUz8iOQLDeG71hYS5rZkGzsRwIOiGUiWlZw&usqp=CAU" />
      ),
      // hasProfilePicture: true,
    },
    {
      key: "status",
      aggregator: "mostUsedPercentage",
      toLocaleString: false,
      label: t("status"),
    },
  ],
  fetchOnlyData: trialClassesActions.fetchTrialClasses,
  fetchDataActions: [
    messagesActions.fetchSmsBank,
    locationsActions.fetchLocations,
    filterValuesActions.fetchFilterValues,
  ],
  setSelectedEntities: trialClassesActions.setSelectedTrialClasses,
  setSingleSelected: trialClassesActions.setSingleSelected,
  removeSingleSelected: trialClassesActions.removeSingleSelected,
  isDataLoading: state => state.trialClasses.loading,
  title: t("leadsAttendance"),
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      width: 20,
      valueRenderer: item => item.fullName,
    },
    {
      id: "phone",
      label: t("phone"),
      width: 20,
      valueRenderer: item => item.phone,
    },
    {
      id: "statusFk",
      label: t("status"),
      valueRenderer: item =>
        item.status === "Converted to Member" || item.status === "Lost" ? t(item.status as Phrases) : item.status,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      width: 15,
      filterOptionsSelector: state =>
        state.filterValues.uniqueFilterValues?.statuses.map(status => {
          // const camelCased = _.camelCase(status.name);

          return {
            id: status.id.toString(),
            label:
              status.name === "Converted to Member" || status.name === "Lost" ? t(status.name as Phrases) : status.name,
          };
        }) || [],
    },
    {
      id: "sourceId",
      label: t("source"),
      valueRenderer: lead => lead.sourceName,
      filterType: "multipleSearchable",
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: state => {
        const sources = [...(state.filterValues.uniqueFilterValues?.sources || []), { id: -1, name: t("noSource") }];
        return sources.map(source => ({
          id: source.id.toString(),
          label: source.name,
        }));
      },
    },
    {
      id: "checkedIn",
      label: t("checkedIn"),
      width: 15,
      valueRenderer: item => item.checkedIn,
      filterType: "searchable",
      showAll: t("all"),
      newPage: true,
      showFilterChip: true,
      filterOptionsSelector: () =>
        checkedInOptions.map(option => ({ id: option.id, label: t(option.label as Phrases) })),
    },
    {
      id: "className",
      label: t("class"),
      width: 15,
      valueRenderer: item => item.className,
      filterType: "searchable",
      newPage: true,
      showFilterChip: true,
      filterOptionsSelector: state => {
        const values = (state.trialClasses.all || []).map(trialClass => trialClass.className);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(value => ({ id: value, label: value }));
      },
    },
    {
      id: "date",
      label: t("scheduleDate"),
      valueRenderer: item => moment(item.date).format(dateFormat),
      filterType: "period",
      newPage: true,
      periodPrefixes: ["today", "yesterday", "thisWeek", "lastSevenDays", "custom"],
      showFilterChip: true,
      width: 15,
    },
    {
      id: "time",
      label: t("time"),
      width: 15,
      valueRenderer: item => moment(item.fullDate).format(timeFormat),
      filterType: "timeSlider",
    },
    {
      id: "coach",
      label: t("coaches"),
      width: 15,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      valueRenderer: item => item.coach,
      filterOptionsSelector: state => {
        const values = (state.trialClasses.all || [])
          .map(trial => ({
            id: trial.coach,
            label: trial.coach,
            icon: <Avatar>{trial.coach[0]}</Avatar>,
          }))
          .filter(t => !!t.id);
        const uniqueValues = _.uniqBy(values, "id");
        return uniqueValues.map(value => ({ id: value.id.toString(), label: value.label, icon: value.icon }));
      },
    },
    {
      id: "task",
      label: t("taskStatus"),
      valueRenderer: item => t(item.task as Phrases),
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => taskOptions.map(r => ({ id: r.id.toString(), label: t(r.label as Phrases) })),
    },
  ],
  isLead: false,
  csvGenerator: entities => csvService.getTrialClasses(entities, dateFormat),
  card: {
    titleRender: item => item.fullName,
    rows: [
      {
        icon: () => <Calendar />,
        textRender: item => moment(item.date).format(dateFormat),
      },
      {
        icon: () => <Clock />,
        textRender: item => moment(item.fullDate).format(timeFormat),
      },
      {
        icon: () => <Class />,
        textRender: item => item.className,
      },
    ],
  },
  entitiesSelector: trialClassesSelector,
  selectedEntitiesSelector: selectedTrialClassesSelector,
  fetchRawPostData: trialClassesService.getTrialClasses,
});

export default trialClassesReport;
