import moment from "moment";
import { AbsenceDTO } from "../models/absence";
import { MasterFilter } from "../store/filtersStore";
import { getBaseClient } from "./serviceUtils";

const getAbsences = async (data?: MasterFilter[]): Promise<AbsenceDTO[]> => {
  const client = getBaseClient();

  if (!client || !data || !data.length) {
    return [];
  }

  const foundFromDate = data.find(a => a.key === "fromDate")?.value[0];
  const foundType = data.find(a => a.key === "type")?.value[0];

  if (!foundFromDate || !foundType) {
    return [];
  }

  const from_date = moment(foundFromDate).format("YYYY-MM-DD");
  const to_date = moment().format("YYYY-MM-DD");

  const result = await client.post<AbsenceDTO[]>("reports/global/missingMembers", {
    from_date,
    to_date,
    locationId: null,
    type: foundType,
  });

  return result.data;
};

const absenceService = {
  getAbsences,
};

export default absenceService;
