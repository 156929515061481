import { MembershipForecastDTO } from "../models/membershipForecast";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

const getMembershipForecasts = async (): Promise<MembershipForecastDTO[]> => {
  const client = getBaseClient();
  const boxId = queryParamsService.getBoxId();

  if (!client) {
    return [];
  }

  const result = await client.get<MembershipForecastDTO[]>(`box/${boxId}/activeMembers/forecastEnds/null`);

  return result.data;
};

const membershipForecastService = {
  getMembershipForecasts,
};

export { membershipForecastService };
