import moment from "moment";
import { StringOrNull, getTimeDifferenceInHours } from "./misc";

interface LateCancellationDTO {
  id: number;
  user_fk: number;
  first_name: string;
  last_name: string;
  allow_sms: string;
  allow_mailing_list: string;
  series_name: StringOrNull;
  date: string;
  time: string;
  deleted_at: string;
  membership_type_type: string;
  membership_type_name: string;
  location: string;
}

interface LateCancellation {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  membershipType: string;
  userId: number;
  membershipName: string;
  seriesName: StringOrNull;
  scheduleDate: string;
  cancelDate: string | null;
  cancellationHours: number | null;
  allowSms: string;
  allowEmail: string;
  location: string;
}

const toLateCancellation = (lateCancellation: LateCancellationDTO): LateCancellation => ({
  id: lateCancellation.id.toString(),
  firstName: lateCancellation.first_name,
  lastName: lateCancellation.last_name,
  userId: lateCancellation.user_fk,
  fullName: `${lateCancellation.first_name} ${lateCancellation.last_name}`,
  membershipType: lateCancellation.membership_type_type,
  membershipName: lateCancellation.membership_type_name,
  seriesName: lateCancellation.series_name ? lateCancellation.series_name.split(",").splice(0, 1).join(":") : null,
  scheduleDate: moment(`${lateCancellation.date} ${lateCancellation.time}`).toISOString(),
  cancelDate: lateCancellation.deleted_at ? moment(lateCancellation.deleted_at).toISOString() : null,
  allowSms: lateCancellation.allow_sms,
  allowEmail: lateCancellation.allow_mailing_list,
  location: lateCancellation.location,
  cancellationHours:
    lateCancellation.time && lateCancellation.date && lateCancellation.deleted_at
      ? getTimeDifferenceInHours(
          moment(lateCancellation.deleted_at),
          moment(`${lateCancellation.date} ${lateCancellation.time}`)
        )
      : null,
});

export { toLateCancellation };

export type { LateCancellationDTO, LateCancellation };
