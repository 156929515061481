import moment from "moment";
import React from "react";
import { ColumnData, MasterFilterItem, TOption } from "../../components/table/types";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { LeadStatistics } from "../../models/leadsStatistics";
import csvService from "../../services/csvService";
import leadStatisticsService from "../../services/leadsStatisticsService";
import { actions as leadsStatisticsActions, LeadsStatisticsDisplay } from "../../store/leadsStatisticsStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { leadStatisticsSelector, selectedLeadsStatisticsSelector } from "../reportSelector";
import { Page } from "./page";

const timePeriodOptions: TOption[] = [
  { id: "monthly", label: "monthlyDisplay" },
  { id: "all", label: "yearSummary" },
  { id: "dates", label: "custom" },
];

const displayTimeOptions: TOption[] = [
  { id: "months", label: "months" },
  { id: "sources", label: "sources" },
];

const generateLastFourYears = (): TOption[] => {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().subtract(4, "y");
  while (dateStart.diff(dateEnd, "years") >= 0) {
    years.push(dateStart.format("YYYY"));
    dateStart.subtract(1, "year");
  }

  return years.map(y => ({ id: y, label: y }));
};

const monthOptions: TOption[] = [
  { id: "1", label: "january" },
  { id: "2", label: "february" },
  { id: "3", label: "march" },
  { id: "4", label: "april" },
  { id: "5", label: "may" },
  { id: "6", label: "june" },
  { id: "7", label: "july" },
  { id: "8", label: "august" },
  { id: "9", label: "september" },
  { id: "10", label: "october" },
  { id: "11", label: "november" },
  { id: "12", label: "december" },
];

const renderTable = (display: LeadsStatisticsDisplay): ColumnData<LeadStatistics>[] => {
  if (display.timePeriod === "all" && display.displayType === "months") {
    return [
      {
        id: "month",
        label: t("month"),
        valueRenderer: entity => t(entity.monthName as Phrases),
        width: 10,
      },
      {
        id: "totalLeads",
        label: t("newLeads"),
        valueRenderer: entity => entity.totalLeads,
        width: 10,
      },
      {
        id: "convertedLeads",
        label: t("converted"),
        valueRenderer: entity => entity.convertedLeads,
        width: 10,
      },
      {
        id: "createdAndConvertedLeads",
        label: t("createAndConvertThisMonth"),
        valueRenderer: entity => entity.createdAndConvertedLeads,
        width: 10,
      },
      {
        id: "lost",
        label: t("markedAsLosts"),
        valueRenderer: entity => entity.lostLeads,
        width: 10,
      },
      {
        id: "createdAndLostLeads",
        label: t("createdThisMonthAndLost"),
        valueRenderer: entity => entity.createdAndLostLeads,
        width: 10,
      },
      {
        id: "bookedClasses",
        label: t("bookedForClasses"),
        valueRenderer: entity => entity.bookedClasses,
        width: 10,
      },
      {
        id: "createdAndBookedClasses",
        label: t("leadScheduleUser"),
        valueRenderer: entity => entity.createdAndBookedClasses,
        width: 10,
      },
    ];
  }

  if (display.timePeriod === "all" && display.displayType === "sources") {
    return [
      {
        id: "sourceName",
        label: t("source"),
        valueRenderer: entity => (entity.sourceName ? entity.sourceName : t("noSource")),
        width: 10,
      },
      {
        id: "totalLeads",
        label: t("newLeads"),
        valueRenderer: entity => entity.totalLeads,
        width: 10,
      },
      {
        id: "convertedLeads",
        label: t("converted"),
        valueRenderer: entity => entity.convertedLeads,
        width: 10,
      },
      {
        id: "convertedPercentage",
        label: t("convertedPercentage"),
        valueRenderer: entity => `${entity.convertedPercentage.toFixed(2)}%`,
        width: 10,
      },
      {
        id: "lost",
        label: t("markedAsLosts"),
        valueRenderer: entity => entity.lostLeads,
        width: 10,
      },
      {
        id: "lostPercentage",
        label: t("lostPercentage"),
        valueRenderer: entity => `${entity.lostPercentage.toFixed(2)}%`,
        width: 10,
      },
    ];
  }

  return [
    {
      id: "sourceName",
      label: t("source"),
      valueRenderer: entity => (entity.sourceName ? entity.sourceName : t("noSource")),
      width: 10,
    },
    {
      id: "totalLeads",
      label: t("newLeads"),
      valueRenderer: entity => entity.totalLeads,
      width: 10,
    },
    {
      id: "convertedLeads",
      label: t("converted"),
      valueRenderer: entity => entity.convertedLeads,
      width: 10,
    },
    {
      id: "createdAndConvertedLeads",
      label: t("createAndConvertThisMonth"),
      valueRenderer: entity => entity.createdAndConvertedLeads,
      width: 10,
    },
    {
      id: "lost",
      label: t("markedAsLosts"),
      valueRenderer: entity => entity.lostLeads,
      width: 10,
    },
    {
      id: "createdAndLostLeads",
      label: t("createdThisMonthAndLost"),
      valueRenderer: entity => entity.createdAndLostLeads,
      width: 10,
    },
    {
      id: "bookedClasses",
      label: t("bookedForClasses"),
      valueRenderer: entity => entity.bookedClasses,
      width: 10,
    },
    {
      id: "createdAndBookedClasses",
      label: t("leadScheduleUser"),
      valueRenderer: entity => entity.createdAndBookedClasses,
      width: 10,
    },
  ];
};

const renderFilters = (timePeriod: string | null): MasterFilterItem[] => {
  switch (timePeriod) {
    case "all":
      return [
        {
          id: "timePeriod",
          label: t("timePeriod"),
          filterType: "masterSearchable",
          options: () => timePeriodOptions.map(d => ({ id: d.id, label: t(d.label as Phrases) })),
        },
        {
          id: "displayType",
          label: t("displayType"),
          filterType: "masterSearchable",
          options: () => displayTimeOptions.map(d => ({ id: d.id, label: t(d.label as Phrases) })),
        },
        {
          id: "year",
          label: t("year"),
          filterType: "masterSearchable",
          options: () => generateLastFourYears(),
        },
        {
          id: "location",
          label: t("location"),
          filterType: "masterSearchable",
          options: state => {
            const locations = (state.locations.locations || []).map(l => ({ id: l.id.toString(), label: l.name }));
            return [{ id: "null", label: t("allLocations") }, ...locations];
          },
        },
      ];

    case "monthly":
      return [
        {
          id: "timePeriod",
          label: t("timePeriod"),
          filterType: "masterSearchable",
          options: () => timePeriodOptions.map(d => ({ id: d.id, label: t(d.label as Phrases) })),
        },
        {
          id: "year",
          label: t("year"),
          filterType: "masterSearchable",
          options: () => generateLastFourYears(),
        },
        {
          id: "month",
          label: t("month"),
          filterType: "masterSearchable",
          options: () => monthOptions.map(m => ({ id: m.id.toString(), label: t(m.label as Phrases) })),
        },
        {
          id: "location",
          label: t("location"),
          filterType: "masterSearchable",
          options: state => {
            const locations = (state.locations.locations || []).map(l => ({ id: l.id.toString(), label: l.name }));
            return [{ id: "null", label: t("allLocations") }, ...locations];
          },
        },
      ];

    case "dates":
      return [
        {
          id: "timePeriod",
          label: t("timePeriod"),
          filterType: "masterSearchable",
          options: () => timePeriodOptions.map(d => ({ id: d.id, label: t(d.label as Phrases) })),
        },
        {
          id: "date",
          label: t("fromDate"),
          filterType: "doublePeriod",
          periodPrefixes: ["thisMonth", "previousMonth", "thisYear", "custom"],
        },
        {
          id: "location",
          label: t("location"),
          filterType: "masterSearchable",
          options: state => {
            const locations = (state.locations.locations || []).map(l => ({ id: l.id.toString(), label: l.name }));
            return [{ id: "null", label: t("allLocations") }, ...locations];
          },
        },
      ];

    default:
      return [
        {
          id: "timePeriod",
          label: t("timePeriod"),
          filterType: "masterSearchable",
          options: () => timePeriodOptions.map(d => ({ id: d.id, label: t(d.label as Phrases) })),
        },
        {
          id: "location",
          label: t("location"),
          filterType: "masterSearchable",
          options: state => {
            const locations = (state.locations.locations || []).map(l => ({ id: l.id.toString(), label: l.name }));
            return [{ id: "null", label: t("allLocations") }, ...locations];
          },
        },
      ];
  }
};

const lostLeadsReport = (display: LeadsStatisticsDisplay | undefined): Page<LeadStatistics> | null => {
  if (!display) {
    return null;
  }
  return {
    id: "leads-statistics-report",
    description: t("leadsStatisticsDesc"),
    entityClick: entity => null,
    title: t("leadsStatistics"),
    rawUserId: "id",
    actions: [
      {
        url: "message/sms",
        icon: <SendIcon />,
        title: "newSmsMessage",
        size: "750px",
      },
      {
        url: "message/push",
        icon: <Bell />,
        title: "sendNotification",
        size: "750px",
      },
    ],
    defaultSort: {
      order: "asc",
      orderBy: display.timePeriod === "all" ? "month" : "sourceName",
    },
    fetchDataActions: [locationsActions.fetchLocations],
    hideActions: true,
    hideFilters: true,
    entitiesSelector: leadStatisticsSelector,
    selectedEntitiesSelector: selectedLeadsStatisticsSelector,
    setSelectedEntities: leadsStatisticsActions.setSelectedLeadsStatistics,
    setSingleSelected: leadsStatisticsActions.setSingleSelected,
    removeSingleSelected: leadsStatisticsActions.removeSingleSelected,
    isDataLoading: state => state.lostLeads.loading,
    csvGenerator: entities => csvService.getLeadsStatistics(entities),
    isLead: false,
    columns: renderTable(display),
    card: {
      titleRender: () => "",
      rows: [
        {
          textRender: () => "",
        },
      ],
    },
    fetchRawMasterData: leadStatisticsService.getLeadsStatistics,
    desktopOnly: true,
    fetchPostRequest: leadsStatisticsActions.fetchLeadsStatistics,
    //TODO Find a better way, without "withoutCheckboxes" property
    withoutCheckboxes: true,
    masterFilter: {
      mainLabel: t("settings"),
      defaultFilters: [
        {
          key: "date",
          value: [moment().startOf("month").toISOString(), moment().endOf("month").toISOString()],
        },
        {
          key: "timePeriod",
          value: ["monthly"],
        },
        {
          key: "year",
          value: [moment().year().toString()],
        },
        {
          key: "month",
          value: [moment().add(1, "months").month().toString()],
        },
        {
          key: "location",
          value: ["null"],
        },
        {
          key: "displayType",
          value: display.timePeriod === "all" ? ["months"] : ["sources"],
        },
      ],
      filters: renderFilters(display.timePeriod),
    },
  };
};

export default lostLeadsReport;
