import { createSelector } from "reselect";
import { Filter } from "../hooks/useFilters";
import { Absence } from "../models/absence";
import { ActiveEmployee } from "../models/activeEmployees";
import { ActiveMembership } from "../models/activeMemberships";
import { AttendanceExternalMember } from "../models/attendanceExternalMembers";
import { Birthday } from "../models/birthday";
import { BookingCancellation } from "../models/bookingCancellation";
import { CanceledMemberships } from "../models/canceledMemberships";
import { ClassesSummary } from "../models/classesSummary";
import { ConvertedLead } from "../models/convertedLeads";
import { Debt } from "../models/debt";
import { EmployeeAttendance } from "../models/employeeAttendancy";
import { Entrance } from "../models/entrance";
import { ExpiredMemberships } from "../models/expiredMemberships";
import { ExpiredSessionPack } from "../models/expiredSessionPacks";
import { ExpiringMembership } from "../models/expiringMemberships";
import { ExpiringSession } from "../models/expiringSessions";
import { ExternalMembership } from "../models/externalMembership";
import { GroupMember } from "../models/groupMembers";
import { InactiveMember } from "../models/inactiveMembers";
import { LateCancellation } from "../models/lateCancellation";
import { Lead } from "../models/leads";
import { LeadInProcess } from "../models/leadsInProcess";
import { LeadStatistics } from "../models/leadsStatistics";
import { Location } from "../models/location";
import { LostLead } from "../models/lostLeads";
import { Member } from "../models/member";
import { MembershipForecast } from "../models/membershipForecast";
import { MembersOnHold } from "../models/membersOnHold";
import { MemberProperties } from "../models/membersProperties";
import { Preservation } from "../models/preservation";
import { Regular } from "../models/regulars";
import { Renewal } from "../models/renewals";
import { RestrictedMember } from "../models/restrictedMembers";
import { Sales } from "../models/sales";
import { Session } from "../models/sessions";
import { ShiftSummary } from "../models/shiftSummary";
import { StaffAction } from "../models/staffActions";
import { Transaction } from "../models/transaction";
import { TrialClass } from "../models/trialClasses";
import { MasterFilter } from "../store/filtersStore";
import { LeadsStatisticsDisplay } from "../store/leadsStatisticsStore";
import { TNotification } from "../store/settingsStore";
import { Sort } from "../store/sortStore";
import { ApplicationState } from "../store/store";

// ALL ACTIVE MEMBERS
const activeMembersSelector = createSelector<ApplicationState, Member[] | null, Member[]>(
  state => state.members.all,
  members => members || []
);

const selectedMembersSelector = createSelector<ApplicationState, Member[] | null, Member[] | null>(
  state => state.members.selected,
  members => members
);

// ACTIVE MEMBERSHIPS
const activeMembershipsSelector = createSelector<ApplicationState, ActiveMembership[] | null, ActiveMembership[]>(
  state => state.activeMemberships.all,
  activeMemberships => activeMemberships || []
);

const selectedActiveMembershipsSelector = createSelector<
  ApplicationState,
  ActiveMembership[] | null,
  ActiveMembership[] | null
>(
  state => state.activeMemberships.selected,
  activeMemberships => activeMemberships
);

// ACTIVE EMPLOYEES
const activeEmployeesSelector = createSelector<ApplicationState, ActiveEmployee[] | null, ActiveEmployee[]>(
  state => state.activeEmployees.all,
  employees => employees || []
);

// ALL LEADS
const allLeadsSelector = createSelector<ApplicationState, Lead[] | null, Lead[]>(
  state => state.leads.all,
  allLeads => allLeads || []
);

const selectedLeadsSelector = createSelector<ApplicationState, Lead[] | null, Lead[] | null>(
  state => state.leads.selected,
  leads => leads
);

// MEMBERS ON HOLD
const membersOnHoldSelector = createSelector<ApplicationState, MembersOnHold[] | null, MembersOnHold[]>(
  state => state.membersOnHold.all,
  all => all || []
);

const selectedMembersOnHoldSelector = createSelector<ApplicationState, MembersOnHold[] | null, MembersOnHold[] | null>(
  state => state.membersOnHold.selected,
  selected => selected
);

// ENTRANCE
const entranceSelector = createSelector<ApplicationState, Entrance[] | null, Entrance[]>(
  state => state.entrances.all,
  e => e || []
);

const selectedEntranceSelector = createSelector<ApplicationState, Entrance[] | null, Entrance[] | null>(
  state => state.entrances.selected,
  e => e
);

// EMPLOYEE ATTENDANCE
const attendanceSelector = createSelector<ApplicationState, EmployeeAttendance[] | null, EmployeeAttendance[]>(
  state => state.employeeAttendance.all,
  all => all || []
);

const selectedAttendanceSelector = createSelector<
  ApplicationState,
  EmployeeAttendance[] | null,
  EmployeeAttendance[] | null
>(
  state => state.employeeAttendance.selected,
  selected => selected
);

// EXPIRING MEMBERSHIPS
const expiringMembersSelector = createSelector<ApplicationState, ExpiringMembership[] | null, ExpiringMembership[]>(
  state => state.expiringMemberships.all,
  allExpiringMemberships => allExpiringMemberships || []
);

const selectedExpiringMembersSelector = createSelector<
  ApplicationState,
  ExpiringMembership[] | null,
  ExpiringMembership[] | null
>(
  state => state.expiringMemberships.selected,
  expiringMemberships => expiringMemberships
);

const externalMembershipsSelector = createSelector<ApplicationState, ExternalMembership[] | null, ExternalMembership[]>(
  state => state.externalMemberships.all,
  m => m || []
);

const selectedExternalMembershipsSelector = createSelector<
  ApplicationState,
  ExternalMembership[] | null,
  ExternalMembership[] | null
>(
  state => state.externalMemberships.selected,
  m => m
);

// EXPIRING SESSIONS
const expiringSessionsSelector = createSelector<ApplicationState, ExpiringSession[] | null, ExpiringSession[]>(
  state => state.expiringSessions.all,
  expiringSessions => expiringSessions || []
);

const selectedExpiringSessionsSelector = createSelector<
  ApplicationState,
  ExpiringSession[] | null,
  ExpiringSession[] | null
>(
  state => state.expiringSessions.selected,
  selectedExpiringSessions => selectedExpiringSessions
);

// CONVERTED LEADS
const convertedLeadsSelector = createSelector<ApplicationState, ConvertedLead[] | null, ConvertedLead[]>(
  state => state.convertedLeads.all,
  convertedLeads => convertedLeads || []
);

const selectedConvertedLeadsSelector = createSelector<ApplicationState, ConvertedLead[] | null, ConvertedLead[] | null>(
  state => state.convertedLeads.selected,
  selectedConvertedLeads => selectedConvertedLeads
);

// LEADS IN PROCESS
const leadsInProcessSelector = createSelector<ApplicationState, LeadInProcess[] | null, LeadInProcess[]>(
  state => state.leadsInProcess.all,
  allLeadsInProcess => allLeadsInProcess || []
);

const selectedLeadsInProcessSelector = createSelector<ApplicationState, LeadInProcess[] | null, LeadInProcess[] | null>(
  state => state.leadsInProcess.selected,
  selectedLeadsInProcess => selectedLeadsInProcess
);

// TRANSACTION
const transactionSelector = createSelector<ApplicationState, Transaction[] | null, Transaction[]>(
  state => state.transaction.all,
  allTransactions => allTransactions || []
);

const selectedTransactionsSelector = createSelector<ApplicationState, Transaction[] | null, Transaction[] | null>(
  state => state.transaction.selected,
  selectedTransactions => selectedTransactions
);

// BOOKING CANCELLATION
const bookingCancellationSelector = createSelector<
  ApplicationState,
  BookingCancellation[] | null,
  BookingCancellation[]
>(
  state => state.bookingCancellations.all,
  b => b || []
);

const selectedBookingCancellationSelector = createSelector<
  ApplicationState,
  BookingCancellation[] | null,
  BookingCancellation[] | null
>(
  state => state.bookingCancellations.selected,
  b => b
);

//DATE / TIME SELECTOR / CURRENT LOCATION

const dateFormatSelector = createSelector<ApplicationState, string, string>(
  state => state.settings.dateFormat,
  dateFormat => dateFormat
);

const timeFormatSelector = createSelector<ApplicationState, string, string>(
  state => state.settings.timeFormat,
  timeFormat => timeFormat
);

const currentLocationSelector = createSelector<ApplicationState, number | null, number | null>(
  state => state.settings.currentLocation,
  currentLocation => currentLocation || null
);

const notificationsSelector = createSelector<ApplicationState, TNotification[] | [], TNotification[]>(
  state => state.settings.notifications,
  notifications => notifications || []
);

// FILTER VALUES
const filterSelector = createSelector<ApplicationState, Filter<unknown>[] | null, Filter<unknown>[]>(
  state => state.filters.activeFilters,
  filters => filters || []
);

// STAGED (temporary) FILTERS
const stageFiltersSelector = createSelector<ApplicationState, Filter<unknown>[] | null, Filter<unknown>[]>(
  state => state.filters.stageFilters,
  filters => filters || []
);

// MASTER FILTER
const masterFilterSelector = createSelector<ApplicationState, MasterFilter[] | null, MasterFilter[]>(
  state => state.filters.masterFilters,
  filter => filter || []
);

const stageMasterFilterSelector = createSelector<ApplicationState, MasterFilter[] | null, MasterFilter[]>(
  state => state.filters.stageMasterFilter,
  filter => filter || []
);

// LOCATION
const locationsSelector = createSelector<ApplicationState, Location[] | null, Location[]>(
  state => state.locations.locations,
  locations => locations || []
);

// SORT
const sortSelector = createSelector<ApplicationState, Sort | null, Sort | null>(
  state => state.sort.activeSort,
  sort => sort
);

// BIRTHDAY
const birthdaysSelector = createSelector<ApplicationState, Birthday[] | null, Birthday[]>(
  state => state.birthdays.all,
  allBirthdays => allBirthdays || []
);

const selectedBirthdaysSelector = createSelector<ApplicationState, Birthday[] | null, Birthday[]>(
  state => state.birthdays.selected,
  selectedBirthday => selectedBirthday || []
);

// CLASSES SUMMARY
const classesSummarySelector = createSelector<ApplicationState, ClassesSummary[] | null, ClassesSummary[]>(
  state => state.classesSummary.all,
  classesSummary => classesSummary || []
);

const selectedClassesSummarySelector = createSelector<
  ApplicationState,
  ClassesSummary[] | null,
  ClassesSummary[] | null
>(
  state => state.classesSummary.selected,
  selectedClassesSummary => selectedClassesSummary
);

//LOST LEADS
const lostLeadsSelector = createSelector<ApplicationState, LostLead[] | null, LostLead[]>(
  state => state.lostLeads.all,
  allLostLeads => allLostLeads || []
);

const selectedLostLeadsSelector = createSelector<ApplicationState, LostLead[] | null, LostLead[] | null>(
  state => state.lostLeads.selected,
  selectedLostLeads => selectedLostLeads
);

// EXPIRED MEMBERSHIPS
const expiredMembersSelector = createSelector<ApplicationState, ExpiredMemberships[] | null, ExpiredMemberships[]>(
  state => state.expiredMemberships.all,
  memberships => memberships || []
);

const selectedExpiredMembersSelector = createSelector<
  ApplicationState,
  ExpiredMemberships[] | null,
  ExpiredMemberships[] | null
>(
  state => state.expiredMemberships.selected,
  selectedExpiredMemberships => selectedExpiredMemberships
);

// TRIAL CLASSES
const trialClassesSelector = createSelector<ApplicationState, TrialClass[] | null, TrialClass[]>(
  state => state.trialClasses.all,
  allTrialClasses => allTrialClasses || []
);

const selectedTrialClassesSelector = createSelector<ApplicationState, TrialClass[] | null, TrialClass[] | null>(
  state => state.trialClasses.selected,
  selectedTrialClasses => selectedTrialClasses
);

// EXPIRED SESSION PACKS
const expiredSessionPacksSelector = createSelector<ApplicationState, ExpiredSessionPack[] | null, ExpiredSessionPack[]>(
  state => state.expiredSessionPacks.all,
  allExpiredSessionPacks => allExpiredSessionPacks || []
);

const selectedExpiredSessionPacksSelector = createSelector<
  ApplicationState,
  ExpiredSessionPack[] | null,
  ExpiredSessionPack[] | null
>(
  state => state.expiredSessionPacks.selected,
  selectedExpiredSessionPacks => selectedExpiredSessionPacks
);

// RENEWALS
const renewalsSelector = createSelector<ApplicationState, Renewal[] | null, Renewal[]>(
  state => state.renewals.all,
  allRenewals => allRenewals || []
);

const selectedRenewalsSelector = createSelector<ApplicationState, Renewal[] | null, Renewal[] | null>(
  state => state.renewals.selected,
  selectedRenewals => selectedRenewals
);

// SESSIONS
const sessionsSelector = createSelector<ApplicationState, Session[] | null, Session[]>(
  state => state.sessions.all,
  allSessions => allSessions || []
);

const selectedSessionsSelector = createSelector<ApplicationState, Session[] | null, Session[] | null>(
  state => state.sessions.selected,
  selected => selected
);

const debtSelector = createSelector<ApplicationState, Debt[] | null, Debt[]>(
  state => state.debt.all,
  allDebts => allDebts || []
);

const selectedDebtsSelector = createSelector<ApplicationState, Debt[] | null, Debt[]>(
  state => state.debt.selected,
  selectedDebts => selectedDebts || []
);

// INACTIVE MEMBERS

const inactiveMembersSelector = createSelector<ApplicationState, InactiveMember[] | null, InactiveMember[]>(
  state => state.inactiveMembers.all,
  allInactiveMembers => allInactiveMembers || []
);

const selectedInactiveMembersSelector = createSelector<ApplicationState, InactiveMember[] | null, InactiveMember[]>(
  state => state.inactiveMembers.selected,
  selectedInactiveMembers => selectedInactiveMembers || []
);

// SALES
const salesSelector = createSelector<ApplicationState, Sales[] | null, Sales[]>(
  state => state.sales.all,
  allSales => allSales || []
);

const selectedSalesSelector = createSelector<ApplicationState, Sales[] | null, Sales[]>(
  state => state.sales.selected,
  selectedSales => selectedSales || []
);

// GROUP MEMBER
const groupMemberSelector = createSelector<ApplicationState, GroupMember[] | null, GroupMember[]>(
  state => state.groupMembers.all,
  groupMembers => groupMembers || []
);

const selectedGroupMemberSelector = createSelector<ApplicationState, GroupMember[] | null, GroupMember[]>(
  state => state.groupMembers.selected,
  selectedGroupMembers => selectedGroupMembers || []
);

// REGULARS
const regularsSelector = createSelector<ApplicationState, Regular[] | null, Regular[]>(
  state => state.regulars.all,
  allRegulars => allRegulars || []
);

const selectedRegulars = createSelector<ApplicationState, Regular[] | null, Regular[]>(
  state => state.regulars.selected,
  selectedRegulars => selectedRegulars || []
);

// RESTRICTED MEMBERS
const restrictedMembersSelector = createSelector<ApplicationState, RestrictedMember[] | null, RestrictedMember[]>(
  state => state.restrictedMembers.all,
  allRestrictedMembers => allRestrictedMembers || []
);

const selectedRestrictedMembersSelector = createSelector<
  ApplicationState,
  RestrictedMember[] | null,
  RestrictedMember[]
>(
  state => state.restrictedMembers.selected,
  selectedRestrictedMembers => selectedRestrictedMembers || []
);

// LATE CANCELLATIONS
const lateCancellationsSelector = createSelector<ApplicationState, LateCancellation[] | null, LateCancellation[]>(
  state => state.lateCancellations.all,
  allLateCancellations => allLateCancellations || []
);

const selectedLateCancellationsSelector = createSelector<
  ApplicationState,
  LateCancellation[] | null,
  LateCancellation[]
>(
  state => state.lateCancellations.selected,
  selectedLateCancellations => selectedLateCancellations || []
);

// CANCELED MEMBERSHIPS
const canceledMembershipsSelector = createSelector<
  ApplicationState,
  CanceledMemberships[] | null,
  CanceledMemberships[]
>(
  state => state.canceledMemberships.all,
  canceledMemberships => canceledMemberships || []
);

const selectedCanceledMembershipsSelector = createSelector<
  ApplicationState,
  CanceledMemberships[] | null,
  CanceledMemberships[]
>(
  state => state.canceledMemberships.selected,
  selectedCanceledMemberships => selectedCanceledMemberships || []
);

// MEMBERS PROPERTIES
const membersPropertiesSelector = createSelector<ApplicationState, MemberProperties[] | null, MemberProperties[]>(
  state => state.membersProperties.allMembersProperties,
  allMembersProperties => allMembersProperties || []
);

const selectedMembersPropertiesSelector = createSelector<
  ApplicationState,
  MemberProperties[] | null,
  MemberProperties[]
>(
  state => state.membersProperties.selectedMembersProperties,
  selectedMembersProperties => selectedMembersProperties || []
);

// STAFF ACTIONS
const staffActionsSelector = createSelector<ApplicationState, StaffAction[] | null, StaffAction[]>(
  state => state.staffActions.all,
  staffActions => staffActions || []
);

const selectedStaffActionsSelector = createSelector<ApplicationState, StaffAction[] | null, StaffAction[]>(
  state => state.staffActions.selected,
  selectedStaffActions => selectedStaffActions || []
);

// SHIFT SUMMARY
const shiftSummarySelector = createSelector<ApplicationState, ShiftSummary[] | null, ShiftSummary[]>(
  state => state.shiftSummary.all,
  allShiftSummaries => allShiftSummaries || []
);

const selectedShiftSummarySelector = createSelector<ApplicationState, ShiftSummary[] | null, ShiftSummary[] | null>(
  state => state.shiftSummary.selected,
  selectedShiftSummary => selectedShiftSummary
);

// MEMBERSHIP FORECAST
const membershipForecastSelector = createSelector<ApplicationState, MembershipForecast[] | null, MembershipForecast[]>(
  state => state.membershipForecast.all,
  membershipForecast => membershipForecast || []
);

const selectedMembershipForecastSelector = createSelector<
  ApplicationState,
  MembershipForecast[] | null,
  MembershipForecast[] | null
>(
  state => state.membershipForecast.selected,
  selectedMembershipForecast => selectedMembershipForecast
);

// ABSENCE
const absencesSelector = createSelector<ApplicationState, Absence[] | null, Absence[]>(
  state => state.absences.all,
  allAbsences => allAbsences || []
);

const selectedAbsencesSelector = createSelector<ApplicationState, Absence[] | null, Absence[] | null>(
  state => state.absences.selected,
  selectedAbsences => selectedAbsences
);

// LEADS STATISTICS
const leadStatisticsSelector = createSelector<ApplicationState, LeadStatistics[] | null, LeadStatistics[]>(
  state => state.leadsStatistics.all,
  leadsStatistics => leadsStatistics || []
);

const selectedLeadsStatisticsSelector = createSelector<
  ApplicationState,
  LeadStatistics[] | null,
  LeadStatistics[] | null
>(
  state => state.leadsStatistics.selected,
  selectedLeadsStatistics => selectedLeadsStatistics
);

const leadsStatisticsDisplaySelector = createSelector<ApplicationState, LeadsStatisticsDisplay, LeadsStatisticsDisplay>(
  state => state.leadsStatistics.display,
  eachEntityHasMonth => eachEntityHasMonth
);

// TRANSPARENT SESSION PACKS
const transparentSessionPacksSelector = createSelector<ApplicationState, Session[] | null, Session[]>(
  state => state.transparentSessionPacks.all,
  transparentSessionPacks => transparentSessionPacks || []
);

const selectedTransparentSessionPacksSelector = createSelector<ApplicationState, Session[] | null, Session[] | null>(
  state => state.transparentSessionPacks.selected,
  selectedTransparentSessionPacks => selectedTransparentSessionPacks
);

// PRESERVATION
const preservationSelector = createSelector<ApplicationState, Preservation[] | null, Preservation[]>(
  state => state.preservation.all,
  preservation => preservation || []
);

const selectedPreservationsSelector = createSelector<ApplicationState, Preservation[] | null, Preservation[] | null>(
  state => state.preservation.selected,
  selectedPreservations => selectedPreservations
);

// TRANSPARENT MEMBERSHIPS
const transparentMembershipsSelector = createSelector<ApplicationState, ActiveMembership[] | null, ActiveMembership[]>(
  state => state.transparentMemberships.all,
  transparentMemberships => transparentMemberships || []
);

const selectedTransparentMembershipsSelector = createSelector<
  ApplicationState,
  ActiveMembership[] | null,
  ActiveMembership[] | null
>(
  state => state.transparentMemberships.selected,
  selectedTransparentMemberships => selectedTransparentMemberships
);

// ATTENDANCE EXTERNAL MEMBERS
const attendanceExternalMembersSelector = createSelector<
  ApplicationState,
  AttendanceExternalMember[] | null,
  AttendanceExternalMember[]
>(
  state => state.attendanceExternalMembers.all,
  attendanceExternalMembers => attendanceExternalMembers || []
);

const selectedAttendanceExternalMembersSelector = createSelector<
  ApplicationState,
  AttendanceExternalMember[] | null,
  AttendanceExternalMember[] | null
>(
  state => state.attendanceExternalMembers.selected,
  selectedAttendanceExternalMembers => selectedAttendanceExternalMembers
);

// GET Locations and currentLocationId
const getLocationsSelector = createSelector<ApplicationState, Location[] | null, Location[]>(
  state => state.locations.locations,
  locations => locations || []
);

const getCurrentLocationIdSelector = createSelector<ApplicationState, number | null, number>(
  state => state.settings.currentLocation,
  currentLocation => currentLocation || 0
);

// SEND SMS / NOTIFICATION / SMS PROVIDER
const smsBankSelector = createSelector<ApplicationState, number | null, number>(
  state => state.messages.messageSms?.smsBank || null,
  smsLeft => smsLeft || 0
);

const smsMessageSelector = createSelector<ApplicationState, string | null, string>(
  state => state.messages.messageText || null,
  message => message || ""
);

const providerCharacterSelector = createSelector<ApplicationState, number | null, number>(
  state => state.messages.messageProvider,
  characters => characters || 0
);

// MISC
const filtersByKeySelector = <TValue = unknown>(id: string) => (state: ApplicationState): Filter<TValue>[] =>
  state.filters.activeFilters?.filter(f => f.key === id) || [];

const stageFiltersByKeySelector = <TValue = unknown>(id: string) => (state: ApplicationState): Filter<TValue>[] =>
  state.filters.stageFilters?.filter(f => f.key === id) || [];

const masterFilterByKeySelector = (id: string) => (state: ApplicationState): MasterFilter | null =>
  state.filters.masterFilters?.find(f => f.key === id) || null;

const stagedMasterFiltersByKeySelector = (id: string) => (state: ApplicationState): MasterFilter | null =>
  state.filters.stageMasterFilter?.find(f => f.key === id) || null;

// const dateFormatSelector = createSelector<ApplicationState, string, string>(
//   state => state.settings.dateFormat,
//   dateFormat => dateFormat
// );

export {
  notificationsSelector,
  masterFilterByKeySelector,
  stagedMasterFiltersByKeySelector,
  attendanceExternalMembersSelector,
  absencesSelector,
  activeEmployeesSelector,
  activeMembershipsSelector,
  activeMembersSelector,
  allLeadsSelector,
  attendanceSelector,
  birthdaysSelector,
  bookingCancellationSelector,
  canceledMembershipsSelector,
  classesSummarySelector,
  convertedLeadsSelector,
  currentLocationSelector,
  dateFormatSelector,
  debtSelector,
  leadsStatisticsDisplaySelector,
  entranceSelector,
  expiredMembersSelector,
  expiredSessionPacksSelector,
  expiringMembersSelector,
  expiringSessionsSelector,
  externalMembershipsSelector,
  filtersByKeySelector,
  filterSelector,
  getCurrentLocationIdSelector,
  getLocationsSelector,
  groupMemberSelector,
  inactiveMembersSelector,
  lateCancellationsSelector,
  leadsInProcessSelector,
  leadStatisticsSelector,
  locationsSelector,
  lostLeadsSelector,
  masterFilterSelector,
  membershipForecastSelector,
  membersOnHoldSelector,
  membersPropertiesSelector,
  preservationSelector,
  providerCharacterSelector,
  regularsSelector,
  renewalsSelector,
  restrictedMembersSelector,
  salesSelector,
  selectedAbsencesSelector,
  selectedActiveMembershipsSelector,
  selectedAttendanceExternalMembersSelector,
  selectedAttendanceSelector,
  selectedBirthdaysSelector,
  selectedBookingCancellationSelector,
  selectedCanceledMembershipsSelector,
  selectedClassesSummarySelector,
  selectedConvertedLeadsSelector,
  selectedDebtsSelector,
  selectedEntranceSelector,
  selectedExpiredMembersSelector,
  selectedExpiredSessionPacksSelector,
  selectedExpiringMembersSelector,
  selectedExpiringSessionsSelector,
  selectedExternalMembershipsSelector,
  selectedGroupMemberSelector,
  selectedInactiveMembersSelector,
  selectedLateCancellationsSelector,
  selectedLeadsInProcessSelector,
  selectedLeadsSelector,
  selectedLeadsStatisticsSelector,
  selectedLostLeadsSelector,
  selectedMembershipForecastSelector,
  selectedMembersOnHoldSelector,
  selectedMembersPropertiesSelector,
  selectedMembersSelector,
  selectedPreservationsSelector,
  selectedRegulars,
  selectedRenewalsSelector,
  selectedRestrictedMembersSelector,
  selectedSalesSelector,
  selectedSessionsSelector,
  selectedShiftSummarySelector,
  selectedStaffActionsSelector,
  selectedTransactionsSelector,
  selectedTransparentMembershipsSelector,
  selectedTransparentSessionPacksSelector,
  selectedTrialClassesSelector,
  sessionsSelector,
  shiftSummarySelector,
  smsBankSelector,
  smsMessageSelector,
  sortSelector,
  staffActionsSelector,
  stageFiltersByKeySelector,
  stageFiltersSelector,
  stageMasterFilterSelector,
  timeFormatSelector,
  transactionSelector,
  transparentMembershipsSelector,
  transparentSessionPacksSelector,
  trialClassesSelector,
};
