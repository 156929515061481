interface ActiveEmployeeDTO {
  id: number;
  active: number;
  user_fk: number;
  first_name: string;
  last_name: string;
  full_path_image_user: string;
}

interface ActiveEmployee {
  id: string;
  active: number;
  userId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  userImgPath: string;
}

const toActiveEmployee = (a: ActiveEmployeeDTO): ActiveEmployee => ({
  id: a.id.toString(),
  active: a.active,
  userId: a.user_fk,
  firstName: a.first_name,
  lastName: a.last_name,
  fullName: `${a.first_name} ${a.last_name}`,
  userImgPath: a.full_path_image_user,
});

export { toActiveEmployee };

export type { ActiveEmployeeDTO, ActiveEmployee };
