import _ from "lodash";
import moment from "moment";
import React from "react";
import wrapperActions from "../../actions/wrapperActions";
import { TOption } from "../../components/table/types";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as MembershipCard } from "../../icons/membershipCard.svg";
import { ReactComponent as Bell } from "../../icons/notifications.svg";
import { ReactComponent as SendIcon } from "../../icons/send.svg";
import t from "../../infrastructure/translations/i18n";
import { Phrases } from "../../infrastructure/translations/phrases";
import { ExpiringMembership } from "../../models/expiringMemberships";
import csvService from "../../services/csvService";
import expiringMembersService from "../../services/expiringMembershipsService";
import { actions as expiringMembersActions } from "../../store/expiringMembershipsStore";
import { actions as locationsActions } from "../../store/locationsStore";
import { actions as messagesActions } from "../../store/messageStore";
import { expiringMembersSelector, selectedExpiringMembersSelector } from "../reportSelector";
import { Page } from "./page";

const taskOptions: TOption[] = [
  { id: "taskNone", label: "taskNone" },
  { id: "taskFuture", label: "taskFuture" },
  { id: "taskPast", label: "taskPast" },
];

const coordinatorOptions: TOption[] = [
  { id: "yes", label: "yes" },
  { id: "no", label: "no" },
];

const futureMembershipOptions: TOption[] = [
  { id: "no", label: "no" },
  { id: "yes", label: "yes" },
];

const expiringMembershipsReport = (dateFormat: string): Page<ExpiringMembership> => ({
  id: "expiring-memberships-report",
  entityClick: entity => wrapperActions.openMemberDetails({ memberId: entity.userId }),
  description: t("expiringMembershipsDescription"),
  searchTerms: ["fullName", "phone"],
  actions: [
    {
      url: "message/sms",
      icon: <SendIcon />,
      title: "newSmsMessage",
      size: "750px",
    },
    {
      url: "message/push",
      icon: <Bell />,
      title: "sendNotification",
      size: "750px",
    },
  ],
  dynamicChips: [
    {
      id: "firstName",
      label: t("firstName"),
    },
    {
      id: "lastName",
      label: t("lastName"),
    },
    {
      id: "membershipType",
      label: t("membership"),
    },
    {
      id: "endedAt",
      label: t("expiredAt"),
    },
  ],
  rawUserId: "user_fk",
  title: t("membershipExpiring"),
  defaultSort: {
    order: "desc",
    orderBy: "endedAt",
  },
  defaultFilters: [
    {
      key: "endedAt",
      value: moment().startOf("day").toISOString() as unknown,
      type: "moreThan",
      default: true,
    },
    {
      key: "endedAt",
      value: moment().add(14, "days").startOf("day").toISOString() as unknown,
      type: "lessThan",
      default: true,
    },
  ],
  summaryItems: [
    {
      key: "id",
      toLocaleString: false,
      aggregator: "count",
      label: t("plan"),
    },
    {
      key: "membershipType",
      toLocaleString: false,
      aggregator: "mostUsed",
      label: t("mostPopularPlan"),
      formatLabel: true,
      showSpecificValue: true,
    },
  ],
  entitiesSelector: expiringMembersSelector,
  selectedEntitiesSelector: selectedExpiringMembersSelector,
  setSelectedEntities: expiringMembersActions.setSelectedExpiringMemberships,
  setSingleSelected: expiringMembersActions.setSingleSelected,
  removeSingleSelected: expiringMembersActions.removeSingleSelected,
  fetchOnlyData: expiringMembersActions.fetchExpiringMemberships,
  fetchDataActions: [locationsActions.fetchLocations, messagesActions.fetchSmsBank],
  isLead: false,
  csvGenerator: entities => csvService.getAllExpiringMembershipsCsv(entities, dateFormat),
  isDataLoading: state => state.expiringMemberships.loading,
  columns: [
    {
      id: "fullName",
      label: t("customerName"),
      valueRenderer: expiringMembership => expiringMembership.fullName,
      width: 20,
    },
    {
      id: "phone",
      label: t("phone"),
      valueRenderer: expiringMembership => expiringMembership.phone,
      width: 10,
    },
    {
      id: "membershipType",
      label: t("membership"),
      valueRenderer: expiringMembership => expiringMembership.membershipType,
      width: 15,
      filterType: "multipleSearchable",
      showFilterChip: true,
      newPage: true,
      filterOptionsSelector: state => {
        const values = (state.expiringMemberships.all || []).map(membership => membership.membershipType);
        const uniqueValues = _.uniq(values);
        return uniqueValues.map(v => ({ id: v, label: v }));
      },
    },
    {
      id: "head",
      label: t("isCoordinator"),
      valueRenderer: expiringMembership => t(expiringMembership.head as Phrases),
      filterType: "searchable",
      newPage: true,
      showAll: t("all"),
      showFilterChip: true,
      width: 10,
      filterOptionsSelector: () => coordinatorOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
    {
      id: "startedAt",
      label: t("startDateMembership"),
      valueRenderer: expiringMembership => moment(expiringMembership.startedAt).format(dateFormat),
      filterType: "period",
      periodPrefixes: ["all", "lastThirtyDays", "custom"],
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "endedAt",
      label: t("endDateMembership"),
      valueRenderer: expiringMembership => moment(expiringMembership.endedAt).format(dateFormat),
      periodPrefixes: ["today", "thisMonth", "nextTwoWeeks", "custom"],
      filterType: "period",
      newPage: true,
      showFilterChip: true,
      width: 10,
    },
    {
      id: "futureMembership",
      label: t("searchIsFutureMembership"),
      valueRenderer: expiringMembership => t(expiringMembership.futureMembership as Phrases),
      filterType: "searchable",
      showAll: t("all"),
      newPage: true,
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => futureMembershipOptions.map(m => ({ id: m.id, label: t(m.label as Phrases) })),
    },
    {
      id: "task",
      label: t("taskStatus"),
      valueRenderer: expiringMembership => t(expiringMembership.task),
      filterType: "multipleSearchable",
      showFilterChip: true,
      width: 15,
      filterOptionsSelector: () => taskOptions.map(r => ({ id: r.id, label: t(r.label as Phrases) })),
    },
  ],
  card: {
    titleRender: (item: ExpiringMembership) => item.fullName,
    rows: [
      {
        icon: () => <MembershipCard />,
        textRender: (item: ExpiringMembership) => t(item.membershipType as Phrases),
      },
      {
        icon: () => <Calendar />,
        textRender: (item: ExpiringMembership) =>
          t("expiringOn", { expireDate: moment(item.endedAt).format(dateFormat) }),
      },
    ],
  },
  fetchRawPostData: expiringMembersService.getExpiringMemberships,
});

export default expiringMembershipsReport;
