import moment from "moment";
import { ExpiredMembershipsDTO } from "../models/expiredMemberships";
import { ExpiredSessionPackDTO } from "../models/expiredSessionPacks";
import queryParamsService from "./queryParamsService";
import { getBaseClient } from "./serviceUtils";

// type TServicePayload = {
//   membershipUsers: ExpiredMembershipsDTO[];
//   sessionUsers: ExpiredSessionPackDTO[];
// };

const client = getBaseClient();
const boxId = queryParamsService.getBoxId();

const getAllExpiredMemberships = async (from?: Date, to?: Date): Promise<ExpiredMembershipsDTO[]> => {
  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  if (!client) {
    return [];
  }

  const result = await client.post<ExpiredMembershipsDTO[]>(`user/getEndingMembership/${boxId}`, {
    fromDate,
    toDate,
    ended: true,
    locationBox: null,
  });

  return result.data;
};

const getSessionUsers = async (from?: Date, to?: Date): Promise<ExpiredSessionPackDTO[]> => {
  if (!client) {
    return [];
  }

  const fromDate = from ? moment(from).format("YYYY-MM-DD") : null;
  const toDate = to ? moment(to).format("YYYY-MM-DD") : null;

  const result = await client.post<ExpiredSessionPackDTO[]>(`user/getEndedSessionPacksUsers/${boxId}`, {
    fromDate,
    toDate,
    locationBox: null,
  });

  return result.data;
};

const expiredMembershipsService = {
  getAllExpiredMemberships,
  getSessionUsers,
};

export default expiredMembershipsService;
