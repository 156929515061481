import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ActiveEmployee } from "../models/activeEmployees";
import activeEmployeesService from "../services/activeEmployeesService";

interface State {
  all: ActiveEmployee[] | null;
}

const fetchActiveEmployes = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const data = await activeEmployeesService.getActiveEmployees();
      dispatch(fetchActiveEmployes.success(data));
    } catch {
      dispatch(fetchActiveEmployes.error());
    }
  },
  {
    success: createActionCreator("@@ACTIVE_EMPLOYEES/SUCCESS", resolve => (payload: ActiveEmployee[]) =>
      resolve(payload)
    ),
    error: createActionCreator("@@ACTIVE_EMPLOYEES/ERROR"),
  }
);

const defaultState: State = {
  all: null,
};

const changeLocation = createActionCreator(LOCATION_CHANGE);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeLocation, (state, action: LocationChangeAction<{ pageId: string; }>) => {
    const splitPath = action.payload.location.pathname.split("/").filter(i => !!i);
    if (splitPath.length === 2 && splitPath[0] === "reports" && !action.payload.isFirstRendering) {
      return produce(state, draft => {
        draft.all = null;
      });
    }
    return state;
  }),
  handleAction(fetchActiveEmployes.success, (state, { payload }) => ({ ...state, all: payload })),
  handleAction(fetchActiveEmployes.error, state => ({ ...state })),
]);

const actions = {
  fetchActiveEmployes,
};

export { reducer, actions };
export type { State };
